import { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { devices } from 'constants/mediaConstants';
import { Namespaces } from 'languages';
import { getMonthName, getY, getYM } from 'helpers/DateTimeUtils';

import { PaymentsListType } from 'store/PaymentsListStore';

import { Loader } from 'components/Loader/Loader';
import { EmptyResult } from 'components/EmptyResult/EmptyResult';

import { Flex } from 'styled/Flex';

import { PaymentsItem } from 'pages/PaymentsList/PaymentItem';
import { SubscriptionsStore, SubscriptionsListType } from 'store/SubscriptionStore';

const Header = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  user-select: none;
`;

const ServiceHeader = styled.div`
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  user-select: none;
`;

const PaymentItemHeader = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin: 0 15px 12px;
  @media ${devices.mobile} {
    font-size: 20px;
  }
`;

const LoadMoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 130px;
  margin: 25px auto 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  box-sizing: border-box;
  border-radius: 19px;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.colors.darkBlack};

  @media ${devices.mobile} {
    max-height: 38px;
    width: 130px;
    padding: 12px 30px;
    font-size: 12px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

type PropsType = {
  subscriptionItem: SubscriptionsListType;
};

export const SubscriptionPaymentsList = observer((props: PropsType) => {
  const {
    subscriptionItem: {
      id,
      service: { name },
    },
  } = props;
  const {
    pageTransaction,
    paymentsList,
    getSubscriptionPaymentHistory,
    clearPaymentList,
    loadMore,
    loading,
  } = SubscriptionsStore;
  const { t } = useTranslation(Namespaces.UI);
  const [sortedPaymentsListData, setSortedPaymentsListData] = useState<
    PaymentsListType[][]
  >([]);

  useEffect(() => {
    getSubscriptionPaymentHistory(id);
  }, [getSubscriptionPaymentHistory, id]);

  const paymentsListData = toJS(paymentsList);
  const pageData = toJS(pageTransaction);

  useEffect(() => {
    if (paymentsListData?.length && paymentsListData[0]) {
      let paymentsListGroup: PaymentsListType[] = [];
      const sortedPaymentsList: PaymentsListType[][] = [];
      let { paidAt } = paymentsListData[0];
      for (let i = 0; i < paymentsListData.length; i++) {
        if (getYM(paidAt) === getYM(paymentsListData[i].paidAt)) {
          paymentsListGroup.push(paymentsListData[i]);
        } else {
          sortedPaymentsList.push(paymentsListGroup);
          paidAt = paymentsListData[i].paidAt;
          paymentsListGroup = [paymentsListData[i]];
        }
      }
      setSortedPaymentsListData(sortedPaymentsList.concat([paymentsListGroup]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsList]);

  useEffect(() => {
    return () => {
      clearPaymentList();
    };
  }, [clearPaymentList]);

  const handleRefreshDiscount = () => {
    getSubscriptionPaymentHistory(id);
  };

  const paymentsListCard = (paymentGroup: PaymentsListType[]) => {
    const { id: paymentGroupId, paidAt } = paymentGroup[0];

    return (
      <div key={paymentGroupId}>
        <Flex justifyContent="space-between" alignItems="center" margin="35px 0 0">
          <PaymentItemHeader>
            {`${t(getMonthName(paidAt) as string)} ${getY(paidAt)}`}
          </PaymentItemHeader>
        </Flex>
        {paymentGroup.map((item: PaymentsListType) => {
          return <PaymentsItem key={item.id} paymentItem={item} />;
        })}
      </div>
    );
  };
  const renderPaymentItems = () => {
    if (!paymentsListData?.length) {
      return (
        <EmptyResult
          text={t('noPaymentsMessage')}
          action={handleRefreshDiscount}
          loading={loading}
        />
      );
    }
    return sortedPaymentsListData.map((paymentGroup: PaymentsListType[]) => {
      return paymentsListCard(paymentGroup);
    });
  };

  return (
    <>
      <>
        <Header>{t('subscriptionTransactionHistory')}</Header>
        <ServiceHeader>{name}</ServiceHeader>

        {!paymentsListData ? (
          <Loader />
        ) : (
          <>
            {renderPaymentItems()}
            {loadMore && !!sortedPaymentsListData.length && (
              <LoadMoreButton
                onClick={() => {
                  if (!loading) {
                    getSubscriptionPaymentHistory(id, pageData + 1, paymentsListData);
                  }
                }}
              >
                {loading ? <Loader small /> : t('loadMore')}
              </LoadMoreButton>
            )}
          </>
        )}
      </>
    </>
  );
});
