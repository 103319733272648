import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const PageTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  user-select: none;
  margin: 0;
`;
