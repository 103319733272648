import styled, { DefaultTheme } from 'styled-components';
import { devices } from 'constants/mediaConstants';
import { PrimaryButton } from 'styled/PrimaryButton';

type CardAddonProps = {
  theme: DefaultTheme;
  isCollapsed: boolean;
  disabled: boolean;
};

export const AddonCard = styled.div`
  position: relative;
  margin: 20px 0;
  color: ${(props: CardAddonProps) => props.theme.colors.darkBlack};
  padding: 26px 26px 10px 26px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 20px;
  line-height: 20px;
  opacity: ${(props: CardAddonProps) => (props.disabled ? '0.3' : '1')};

  @media ${devices.mobile} {
    padding: 16px 18px 10px 10px;
  }
`;

export const AddonHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: 12px;
  line-height: 24px;
`;

export const AddonTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  @media ${devices.mobile} {
    font-size: 16px;
  },
`;

export const AddonPrice = styled.div`
  margin-left: 5px;
  font-style: normal;
  white-space: nowrap;
  font-weight: 700;
  font-size: 22px;
  @media${devices.mobile} {
    font-size: 14px;
  }
`;

export const AddonDescription = styled.div`
  margin-bottom: 12px;
  font-family:  ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  @media ${devices.mobile} {
    font-size: 14px;
    padding-right: 20px;
  },
`;

export const AddonDescriptionShort = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
  padding-right: 40px;
  font-family:  ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.middleDark};
  @media ${devices.mobile} {
    font-size: 14px;
    padding-right: 20px;
  },
`;

export const AddonAddButton = styled(PrimaryButton)`
  max-height: 37px;
  width: 80px;
  padding: 17px 50px;
  font-size: 14px;

  @media ${devices.mobile} {
    padding: 12px 30px;
    font-size: 12px;
  },
`;
