import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { History, LocationState } from 'history';
import { Operation } from 'fast-json-patch';
import {
  callApi,
  convertCheckoutsToLocalStorage,
  patchApi,
  postApi,
  deleteApi,
  urls,
} from 'helpers';
import { CheckoutStore, CheckoutInfo } from './CheckoutStore';
import { UserStore } from './UserStore';

export type PriceReducer = {
  balance: number;
  currency: string;
  id: number;
  name: string;
  profile: { id: number };
  type: string;
  discountPercentage?: number;
};

export type User = {
  firstName?: string;
  id?: string;
  surname?: string;
  token?: string;
  email?: string;
  avatarUrl?: string;
  phoneNumber?: string;
  activeVoucherBookings?: number;
  activeMembershipsBookings?: number;
  activeSubscriptions?: number;
  badgeCount?: number;
  activeEntryBookings?: number;
  activeWaitingLists?: number;
  priceReducers?: PriceReducer[];
};

type EmailSignInData = {
  email: string;
  password: string;
};

type UserResetPassword = {
  password: string;
  hash: string;
};

type GoogleSignInData = {
  code: string;
};

type FacebookSignInData = {
  facebookId: string;
};

type UserRegistration = {
  email: string;
  firstName: string;
  surname: string;
  password: string;
  timeZoneId: string;
};

type UserSyncRequestData = {
  checkoutIds: number[];
};

// Model the application state.
export class Login {
  loading = false;

  isDeleteUserAccountLoading = false;

  user: User = {};

  constructor() {
    makeAutoObservable(this);
  }

  setUserInfo = (data: User) => {
    const userInfo = toJS(data);
    this.user = userInfo;
  };

  setUserInfoLocalStorage = (user: User) => {
    const localStorageUserInfo = {
      id: user.id,
      firstName: user.firstName,
      surname: user.surname,
      avatarUrl: user.avatarUrl,
      token: user.token,
    };

    localStorage.setItem('auth', JSON.stringify(localStorageUserInfo));
  };

  onUserAuthenticated = async (user: User, history: History<LocationState>) => {
    this.setUserInfoLocalStorage(user);

    await this.syncUserData();

    // Needs to be called after sync since this will trigger status poll
    const userInfo = toJS(user);
    this.setUserInfo(userInfo);

    if (localStorage.redirectUser) {
      history.push(urls.services(localStorage.profileId));
      localStorage.removeItem('redirectUser');
      return;
    } else if (localStorage.prevPath) {
      history.replace(localStorage.prevPath);
      localStorage.removeItem('prevPath');
      return;
    }

    history.goBack();
  };

  emailSignIn = async (user: EmailSignInData, history: History<LocationState>) => {
    try {
      const res = await postApi<EmailSignInData>(`v1/auth/login`, user);
      if (res.status === 200) {
        const data = await res.json();
        await this.onUserAuthenticated(data, history);
      } else {
        const data = await res.json();
        return data.message as string;
      }
    } catch (e) {
      console.error(e);
    }
    return '';
  };

  googleSignIn = async (
    googleId: GoogleSignInData,
    history: History<LocationState>,
    setLoadingSocial: (status: string) => void
  ) => {
    try {
      const res = await postApi<GoogleSignInData>(`v1/auth/google`, googleId);
      if (res.status === 200) {
        const data = await res.json();
        await this.onUserAuthenticated(data, history);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingSocial('');
    }
  };

  facebookSignIn = async (
    facebookId: FacebookSignInData,
    history: History<LocationState>,
    setLoadingSocial: (status: string) => void
  ) => {
    try {
      const res = await postApi(`v1/auth/facebook`, {
        accessToken: facebookId,
      });
      if (res.status === 200) {
        const data = await res.json();
        await this.onUserAuthenticated(data, history);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingSocial('');
    }
  };

  appleSignIn = async (code: { code: string }, history: History<LocationState>) => {
    try {
      const res = await postApi(`v1/auth/apple`, code);
      if (res.status === 200) {
        const data = await res.json();
        await this.onUserAuthenticated(data, history);
      }
    } catch (e) {
      console.error(e);
    }
  };

  registration = async (userData: UserRegistration, history: History<LocationState>) => {
    try {
      const res = await postApi<UserRegistration>(`v1/auth/register`, userData);
      if (res.status === 200) {
        const data = await res.json();
        await this.onUserAuthenticated(data, history);
      }
      if (res.status === 400) {
        const data = await res.json();
        return data.message as string;
      }
    } catch (e) {
      console.error(e);
    }
    return '';
  };

  resetPassword = async (password: string, hash: string) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await postApi<UserResetPassword>(`v1/auth/forgot-password`, {
        password,
        hash,
      });
      if (res.status === 200) {
        await res.json();
      }
      if (res.status === 400) {
        const data = await res.json();
        return data.message as string;
      }
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
    return '';
  };

  forgotPassword = async (email: string) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(`v1/auth/forgot-password?email=${email}`);
      if (res.status === 200) {
        return '';
      }
      const data = await res.json();
      return data.message as string;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  logout = async (callback?: () => void) => {
    try {
      const res = await postApi(`v1/auth/logout`, {});
      this.clearUserData();
      if (res.status === 200) {
        if (callback) {
          callback();
          localStorage.redirectUser = true;
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  getUserInfo = async () => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(`v1/users/me`);
      if (res.status === 200) {
        const data = await res.json();
        this.setUserInfo({ ...this.user, ...data, token: this.user.token });
      }
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  changeUserInfo = async (
    fields: Operation[],
    callback: (error?: { code: string; message: string }) => void
  ) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await patchApi(`v1/users/me`, fields);
      const data = await res.json();
      if (res.status === 200) {
        const updatedUserData = { ...this.user, ...data, token: this.user.token };
        this.setUserInfo(updatedUserData);
        this.setUserInfoLocalStorage(updatedUserData);
        callback();
      } else {
        const { code, message } = data;
        callback({ code, message });
      }
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateUserAvatar = async (
    img: File,
    callback: (error?: { code: string; message: string }) => void
  ) => {
    try {
      const formData = new FormData();
      formData.append('File', img);
      const res = await postApi(`v1/users/me/avatar`, formData);
      const data = await res.json();
      if (res.status === 200) {
        const updatedUserData = { ...this.user, ...data, token: this.user.token };
        this.setUserInfo(updatedUserData);
        this.setUserInfoLocalStorage(updatedUserData);
        callback();
      } else {
        const { code, message } = data;
        callback({ code, message });
      }
    } catch (e) {
      console.error(e);
    }
  };

  syncUserData = async () => {
    try {
      let dataToSync = {} as UserSyncRequestData;

      if (localStorage.checkouts) {
        const pendingCheckouts = JSON.parse(localStorage.checkouts) as CheckoutInfo[];
        dataToSync.checkoutIds = pendingCheckouts.map((c) => c.id);
      }

      if (Object.keys(dataToSync).length > 0) {
        await postApi<UserSyncRequestData>(`v1/users/me/sync`, dataToSync);
      }
    } catch (e) {
      console.error(e);
    }
  };

  getUserStatus = async (profileId?: number) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(
        `v1/users/me/status${profileId ? `?profileId=${profileId}` : ''}`
      );
      const data = await res.json();
      const {
        badgeCount,
        activeEntryBookings,
        activeMembershipsBookings,
        activeVoucherBookings,
        priceReducers,
        activeWaitingLists,
        activeSubscriptions,
      } = data;

      runInAction(() => {
        this.setUserInfo({
          ...this.user,
          badgeCount,
          activeEntryBookings,
          activeMembershipsBookings,
          activeVoucherBookings,
          priceReducers,
          activeWaitingLists,
          activeSubscriptions,
        });
      });

      const checkouts = data.pendingCheckouts || [];
      CheckoutStore.setCheckoutsInfo(checkouts);
      if (checkouts.length > 0) {
        localStorage.checkouts = JSON.stringify(
          convertCheckoutsToLocalStorage(checkouts)
        );
      } else {
        localStorage.removeItem('checkouts');
      }
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteUserAccount = async (
    callback: (error?: { code: string; message: string }) => void
  ) => {
    try {
      this.isDeleteUserAccountLoading = true;
      const res = await deleteApi(`v1/users/me`);
      if (res.status === 200) {
        this.clearUserData();
        callback();
      } else {
        const data = await res.json();
        const { code, message } = data;
        callback({ code, message });
      }
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this.isDeleteUserAccountLoading = false;
      });
    }
  };

  isUserLoggedIn = () => {
    if (this.user?.id) {
      return true;
    }

    const auth = localStorage.getItem('auth');
    return auth ? true : false;
  };

  clearUserData = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('checkouts');
    CheckoutStore.setCheckoutsInfo([]);
    CheckoutStore.setCheckoutInfo(undefined);
    CheckoutStore.setCheckout(undefined);
    UserStore.setUserProxies([]);
    runInAction(() => {
      this.user = {};
    });
  };
}

export const LoginStore = new Login();
