import styled from 'styled-components';

export const AvatarWrapper = styled.div<{ size?: number; radius?: string }>`
  width: ${({ size }) => (size ? `${size}px` : '100px')};
  min-width: ${({ size }) => (size ? `${size}px` : '100px')};
  height: ${({ size }) => (size ? `${size}px` : '100px')};
  min-height: ${({ size }) => (size ? `${size}px` : '100px')};
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    border-radius: ${({ radius }) => radius || '50%'};
    object-fit: contain;
  }
`;

export const UpdateAvatar = styled.div`
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: white;

  > img {
    width: 30px;
    height: 30px;
  }
`;

export const Counter = styled.div`
  position: absolute;
  right: -10px;
  top: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 21px;
  color: ${({ theme }) => theme.colors.white};
  background: #fc8575;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  z-index: 1;
  span {
    font-size: 13px;
    font-style: normal;
    font-family: '${({ theme }) => theme.fonts.sfProText}';
    font-weight: 400;
    user-select: none;
    letter-spacing: -0.1em;
    text-indent: -0.1em;
  }
`;
