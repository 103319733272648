import styled from 'styled-components';
import { devices } from 'constants/mediaConstants';
import { Loader } from './Loader/Loader';
import { CustomIcon } from './CustomIcon/CustomIcon';

const SocialLoginButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 310px;
  height: 54px;
  margin: 15px auto;
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-size: 16px;
  cursor: pointer;
  pointer-events: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : 'auto')};
  @media${devices.mobile} {
    width: 290px;
  }

  :hover {
    opacity: 0.8;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CompanyName = styled.b`
  margin-left: 4px;
  color: ${(props) => props.theme.colors.darkBlack};
  cursor: pointer;
  user-select: none;
`;

type SocialButtonProps = {
  disabled: boolean;
  action: () => void;
  title: string;
  iconURL: string;
  typeText: string;
  isLoading: boolean;
};

export const SocialLoginButton = ({
  disabled,
  action,
  title,
  iconURL,
  isLoading = false,
  typeText,
}: SocialButtonProps) => {
  return (
    <SocialLoginButtonStyled
      disabled={disabled}
      onClick={action}
      data-testid="socialLoginButton"
    >
      {isLoading ? (
        <Loader small />
      ) : (
        <ContentWrapper>
          <CustomIcon icon={iconURL} height="20px" width="20px" margin="0 13px 0 0" />
          {typeText}
          <CompanyName>{title}</CompanyName>
        </ContentWrapper>
      )}
    </SocialLoginButtonStyled>
  );
};
