import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import { LoginStore } from 'store/LoginStore';

const Spinner = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  i {
    background-color: #009ea0;
  }
`;

export const AppleRedirect = () => {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const { appleSignIn } = LoginStore;
  const params = new URLSearchParams(search);
  const code = params.get('code') || '';
  appleSignIn({ code }, history);
  return <Spinner size="large" data-testid="apple-redirect" />;
};
