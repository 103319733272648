import { FC } from 'react';
import {
  DeleteAccountModalBody,
  DeleteAccountModalButtonCancel,
  DeleteAccountModalButtonConfirm,
  DeleteAccountModalButtonContainer,
  DeleteAccountModalText,
  DeleteAccountModalTitle,
  IconClose,
  DeleteAccountModal as $DeleteAccountModal,
} from '../styles';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import CloseIcon from 'assets/icons/close.svg';
import AlertImage from 'assets/icons/alert.svg';
import { devices } from 'constants/mediaConstants';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';

interface IProps {
  show: boolean;
  onClose: () => void;
  action: () => void;
}

export const DeleteAccountModal: FC<IProps> = ({ show, onClose, action }) => {
  const { t } = useTranslation(Namespaces.UI);

  return show ? (
    <$DeleteAccountModal>
      <DeleteAccountModalBody>
        <IconClose>
          <CustomIcon
            width="30px"
            height="30px"
            icon={CloseIcon}
            onClick={onClose}
            hover
            cursor
            device={devices.mobile}
            deviceWidth="25px"
            deviceHeight="25px"
          />
        </IconClose>
        <CustomIcon width="72px" height="72px" margin="0 auto" icon={AlertImage} />
        <DeleteAccountModalTitle>{t('deleteAccountModalTitle')}</DeleteAccountModalTitle>
        <DeleteAccountModalText>{t('deleteAccountModalText')}</DeleteAccountModalText>
        <DeleteAccountModalButtonContainer>
          <DeleteAccountModalButtonConfirm onClick={action}>
            {t('deleteAccountModalButtonConfirm')}
          </DeleteAccountModalButtonConfirm>
          <DeleteAccountModalButtonCancel onClick={onClose}>
            {t('deleteAccountModalButtonCancel')}
          </DeleteAccountModalButtonCancel>
        </DeleteAccountModalButtonContainer>
      </DeleteAccountModalBody>
    </$DeleteAccountModal>
  ) : (
    <></>
  );
};
