import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';
import Close from 'assets/icons/close.svg';

import { PrimaryButton } from 'styled/PrimaryButton';
import { CustomIcon } from '../../components/CustomIcon/CustomIcon';

const ModalWrapper = styled.div`
  position: absolute;
  top: 84px;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100vh - 84px);
  padding: 20px;
  background: ${({ theme }) => theme.colors.lightBlack};
  cursor: default;
`;

const ModalBody = styled.div`
  position: relative;
  max-width: 545px;
  height: 100%;
  margin: 0 auto;
  padding: 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;

  @media${devices.mobile} {
    padding: 20px;
  }
`;

const InlineBlockText = styled.div`
  display: inline;
`;

const Text = styled.div`
  width: 100%;
  height: 80%;
  margin-top: 50px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  overflow: auto;
  user-select: text;
  white-space: pre-wrap;

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.middleGray};
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: 6px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  @media${devices.widgetDesktop} {
    height: calc(100vh - 300px);
    margin-top: 15px;
  }

  @media${devices.mobile} {
    height: calc(100vh - 240px);
    margin-top: 15px;
  }
`;

const BoldText = styled(Text)`
  height: 40px;
  margin-top: 33px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
`;

const OkButton = styled(PrimaryButton)`
  width: 260px;
  margin: 40px auto 0;

  @media${devices.mobile} {
    margin: 20px auto 0;
  }
`;

type ModalProps = {
  title: string;
  description: string;
  close: () => void;
};

const parseText = (text: string) => {
  const words = text.split(' ');
  const elements = [];
  let currentElementText = '';
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (word.startsWith('http:') || word.startsWith('https:')) {
      elements.push(
        <InlineBlockText key={`terms_text_${i}`}>{currentElementText}</InlineBlockText>
      );
      currentElementText = ' ';
      elements.push(
        <Link key={`terms_text_link_${i}`} href={word} target="_blank">
          {word}
        </Link>
      );
    } else {
      currentElementText += `${word} `;
    }
  }
  elements.push(
    <InlineBlockText key={'terms_text'}>{currentElementText}</InlineBlockText>
  );
  return <>{[...elements]}</>;
};

export const TermsModal = (props: ModalProps) => {
  const { title, description, close } = props;
  const { t } = useTranslation(Namespaces.UI);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <ModalWrapper data-testid="modal">
      <ModalBody>
        <CustomIcon
          position="absolute"
          width="30px"
          height="30px"
          icon={Close}
          onClick={close}
          hover
        />
        <BoldText>{title}</BoldText>
        <Text>{parseText(description)}</Text>
        <OkButton onClick={close}>{t('ok')}</OkButton>
      </ModalBody>
    </ModalWrapper>
  );
};
