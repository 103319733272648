import { FC, useState } from 'react';
import {
  ServiceHeader,
  Name,
  NumberPanel,
  ArrowUp,
  ArrowDown,
  ServiceBody,
} from './styles';

interface IProps {
  header: string;
  children: JSX.Element;
  serviceCount: number;
  isExpandedByDefault: boolean;
}

export const ServicePanel: FC<IProps> = ({
  header,
  children,
  serviceCount,
  isExpandedByDefault,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);

  return (
    <>
      <ServiceHeader
        isExpanded={isExpanded}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        data-testid="servicePanel"
      >
        <Name>{header}</Name>
        <NumberPanel
          isExpanded={isExpanded}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <div style={{ fontSize: '17px' }}>{serviceCount}</div>
          {isExpanded ? <ArrowUp size={22} /> : <ArrowDown size={22} />}
        </NumberPanel>
      </ServiceHeader>
      <ServiceBody isExpanded={isExpanded}>{children}</ServiceBody>
    </>
  );
};
