import styled from 'styled-components';
import Layout from './Layout';
import { devices } from 'constants/mediaConstants';

export default styled(Layout)<{ withPaddingTop: boolean; collapsed: boolean }>`
  position: relative;
  margin: 30px 20px 0px;

  padding: ${(props) =>
    props.withPaddingTop
      ? '25px 0 25px 0'
      : props.collapsed
      ? '0 0 25px 0'
      : '25px 0 25px 0'};
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: ${(props: { collapsed: boolean }) => (props.collapsed ? 'auto' : 'pointer')};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  @media ${devices.mobile} {
    padding: ${(props) =>
      props.withPaddingTop ? '20px 0 20px 0' : props.collapsed ? '0 0 25px 0' : '20px 0'};
  }
`;
