import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';
import { CheckoutStore } from 'store/CheckoutStore';

import { Loader } from 'components/Loader/Loader';
import { PrimaryButton } from 'styled/PrimaryButton';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import AlertImage from 'assets/icons/alert.svg';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

const ModalBody = styled.div`
  position: relative;
  width: 545px;
  padding: 65px 65px 65px 65px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    padding: 25px;
  }
`;

const CancelModalText = styled.div`
  margin: 25px 0 7px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
`;

const CancelModalSubText = styled.div`
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const ConfirmButton = styled(PrimaryButton)`
  box-sizing: border-box;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: #6f6f6f;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #a6a6a6;
  border-radius: 25px;

  @media ${devices.mobile} {
    margin-bottom: 10px;
  }
`;

const CancelButton = styled(PrimaryButton)`
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkBlack};
  border-radius: 28px;

  @media ${devices.mobile} {
    margin: 5px auto 10px;
  }
`;

export const RemoveRecipientConfirmationModal = observer(
  (props: {
    checkoutId: number;
    checkoutItemId: number;
    recipientId: number;
    transactionLinkHash: string | null;
    onConfirmClicked: () => void;
    onCloseClicked: () => void;
  }) => {
    const {
      checkoutId,
      checkoutItemId,
      recipientId,
      transactionLinkHash,
      onConfirmClicked,
      onCloseClicked,
    } = props;
    const { t } = useTranslation(Namespaces.UI);

    const { removeCheckoutItemRecipient } = CheckoutStore;

    const [isLoading, setIsLoading] = useState(false);

    return (
      <Modal>
        <ModalBody>
          <CustomIcon width="72px" height="72px" margin="0 auto" icon={AlertImage} />
          <CancelModalText>{t('checkoutItemDeleteUserProxyTitle')}</CancelModalText>
          <CancelModalSubText>
            {t('checkoutItemDeleteUserProxySubtitle')}
          </CancelModalSubText>
          <ButtonWrap>
            <ConfirmButton
              onClick={() => {
                if (isLoading) return;
                setIsLoading(true);
                removeCheckoutItemRecipient(
                  checkoutId,
                  checkoutItemId,
                  recipientId,
                  transactionLinkHash,
                  () => {
                    onConfirmClicked();
                  }
                );
              }}
            >
              {isLoading ? <Loader small /> : t('checkoutItemDeleteUserProxyYes')}
            </ConfirmButton>
            <CancelButton onClick={() => onCloseClicked()}>{t('noKeepIt')}</CancelButton>
          </ButtonWrap>
        </ModalBody>
      </Modal>
    );
  }
);
