import { makeAutoObservable, runInAction, toJS } from 'mobx';
import {
  callApi,
  postApi,
  deleteApi,
  clearLocalStorage,
  convertCheckoutsToLocalStorage,
  parseLocalStorageCheckout,
} from 'helpers';
import { PaymentStore } from './PaymentStore';
import { ErrorStore } from './ErrorStore';
import * as apiErrorCodes from '../constants/apiErrorCodeConstants';

export type AddCheckoutItemData = {
  serviceId: number;
  startTime?: string;
  endTime?: string;
  resourceGroupId?: number;
  resourceId?: number;
  entryId?: number;
  allEntries?: boolean;
};

type CheckoutAddonData = {
  id: number;
  quantity?: number;
};

export type CheckoutInfo = {
  addons?: Addon[];
  id: number;
  itemCount: number;
  profileId?: number;
  price: number;
  currency: string;
  profile?: {
    id: number;
    logoUrl?: string;
  };
};

export type CheckoutData = {
  id: number;
  createdAt: string;
  currency: string;
  customerMessageMode: string;
  customerMessagePlaceholder: string;
  itemCount: number;
  items: CheckoutItem[];
  paymentMethods: string[];
  paymentMode: string;
  phoneNumberMode: string;
  promotionCodeDiscount?: number;
  price: number;
  pricePerUnit: 'minute' | 'hour' | 'day' | 'week' | 'month';
  profile: {
    id: number;
    name: string;
    logoUrl: string;
  };
  promotionCodeMode: string;
  status: string;
  promotionCode?: { code: string; id: number };
  priceExclVat: number;
  phoneNumber?: string;
  checkoutId?: number;
  termsAndConditions?: string;
  termsAndConditionsMode?: string;
  documentSigningMode?: string;
  documentSigningDeliveryMethod?: string;
  documentSigningStatus?: string;
  documentSigningUrl?: string;
  customerMessage?: string;
  addonsMode?: string;
  hasMandatoryAddons?: boolean;
  addons: Addon[];
  bankIDMode?: string;
  bankIDStatus?: string;
  bankIDTransactionId?: number;
};

export type CheckoutItem = {
  id: number;
  currency: string;
  price: number;
  pricePerUnit: 'minute' | 'hour' | 'day' | 'week' | 'month';
  priceExclVat: number;
  service: {
    balance: number;
    description: string;
    id: number;
    name: string;
    type: string;
    isBookPerOccasion: boolean;
  };
  validTo: string;
  quantity: number;
  priceReductions: {
    type: string;
    name: string;
    description: string;
    reduction: number;
  }[];
  startTime: string;
  endTime: string;
  proxyUsersMode: string;
  recipients: CheckoutItemRecipient[];
  maxRecipients?: number;
};

export type CheckoutItemRecipient = {
  id: number;
  userProxyId: number;
  transactionLinkHash?: string;
};

export type Addon = {
  id: number;
  serviceId: number;
  type: string;
  isAdded: boolean;
  currency: string;
  description: string;
  name: string;
  price: number;
  minQuantity: number;
  maxQuantity: number;
};

type CheckoutFieldErrors = {
  addonsMandatory?: string;
  phoneNumber?: string;
  customerMessage?: string;
  documentSigningStatus?: string;
  checkoutItemRecipient?: string | CheckoutFieldErrorDataType;
  bankID?: CheckoutFieldErrorDataType;
};

export type CheckoutFieldErrorDataType = {
  errorCode: string;
  errorMessage: string;
};

export type PaymentInfo = {
  phoneNumber: string;
  customerMessage?: string;
};

export type BankIDData = {
  transactionId: number;
  status: string;
  hintCode: string;
  autoStartToken: string;
  qrData: string;
};

type PaymentLinkResponse = {
  profileId?: number;
  checkoutId?: number;
  error?: string;
};

class Checkout {
  checkout: CheckoutData | undefined = undefined;

  paymentInfo: PaymentInfo | undefined = undefined;

  checkoutsInfo: CheckoutInfo[] | [] = [];

  checkoutInfo: CheckoutInfo | undefined = undefined;

  checkoutFieldErrors: CheckoutFieldErrors = {};

  promotionError = '';

  bankIDData: BankIDData | undefined | null;

  constructor() {
    makeAutoObservable(this);
  }

  getCheckout = async (
    checkoutId: number | string,
    transactionLinkHash?: string,
    callback?: () => void
  ) => {
    try {
      const url = `v1/checkouts/${checkoutId}${
        transactionLinkHash ? `?transactionLinkHash=${transactionLinkHash}` : ''
      }`;
      const res = await callApi(url);
      const data = await res.json();
      if (data.status === 400 || data.status === 404 || data.status === 403) {
        clearLocalStorage('checkouts');
        this.setCheckoutsInfo([]);
        ErrorStore.setError({ ...data, place: 'checkout' });
        return;
      }
      if (data.status === 500) {
        ErrorStore.setError({ ...data, place: 'checkout' });
        return;
      }

      if (data.id) {
        this.setCheckout(data);
        if (!transactionLinkHash) {
          this.setCheckoutsInfo([data]);
        }
      } else {
        const newCheckoutsInfo = this.checkoutsInfo.filter(
          (checkout) => checkout.id !== checkoutId
        );
        this.setCheckoutsInfo(newCheckoutsInfo);
        this.setCheckoutInfo(undefined);
        if (newCheckoutsInfo.length) {
          localStorage.checkouts = JSON.stringify(
            convertCheckoutsToLocalStorage(newCheckoutsInfo)
          );
        } else {
          clearLocalStorage('checkouts');
        }
      }

      if (callback) {
        callback();
      }
    } catch (e) {
      console.error(e);
    }
  };

  getCheckoutFromHash = async (
    transactionLinkHash: string,
    setLoading: (value: boolean) => void
  ) => {
    try {
      const res = await callApi(`v1/checkouts/transaction-links/${transactionLinkHash}`);
      const data = await res.json();
      if (data.status === 400 || data.status === 404) {
        // eslint-disable-next-line
        return { error: data.code as string };
      }
      // eslint-disable-next-line
      return data as PaymentLinkResponse;
    } catch (e) {
      console.error(e);
      // eslint-disable-next-line
      return { error: 'error' };
    } finally {
      setLoading(false);
    }
  };

  addItemToCheckout = async (
    profileId: string,
    checkoutItemData: AddCheckoutItemData[],
    setLoading: (value: boolean) => void
  ) => {
    try {
      let res;
      if (localStorage.checkouts) {
        const checkoutsInfo: CheckoutInfo[] = JSON.parse(localStorage.checkouts);
        const checkoutInfo = checkoutsInfo.find(
          (checkoutItem: CheckoutInfo) => checkoutItem.profileId === +profileId
        );
        if (checkoutInfo) {
          const { id: checkoutId } = checkoutInfo;
          res = await postApi(`v1/checkouts/${checkoutId}/items`, checkoutItemData);
          const data = await res.json();
          const { id, profile, itemCount, price, currency } = data.checkout;
          this.setCheckoutInfo({ id, profile, itemCount, price, currency });
          const newCheckoutStorage: CheckoutInfo[] = checkoutsInfo.map((checkoutItem) => {
            return checkoutItem.id === id
              ? { id, profileId: profile.id, itemCount, price, currency }
              : checkoutItem;
          });

          localStorage.checkouts = JSON.stringify(newCheckoutStorage);
          this.setCheckoutsInfo([{ id, profile, itemCount, price, currency }]);
        } else {
          res = await postApi(
            `v1/profiles/${profileId}/checkouts/items`,
            checkoutItemData
          );
          const data = await res.json();
          const { id, profile, itemCount, price, currency } = data.checkout;
          const currentCheckouts = parseLocalStorageCheckout(
            JSON.parse(localStorage.checkouts)
          );
          const newCheckout: {
            id: number;
            profile: { id: number };
            itemCount: number;
            price: number;
            currency: string;
          } = { id, profile, itemCount, price, currency };
          currentCheckouts.push(newCheckout);
          localStorage.checkouts = JSON.stringify(
            convertCheckoutsToLocalStorage(currentCheckouts)
          );
          this.setCheckoutInfo({ id, profile, itemCount, price, currency });
          this.setCheckoutsInfo(currentCheckouts);
        }
      } else {
        res = await postApi(`v1/profiles/${profileId}/checkouts/items`, checkoutItemData);
        const data = await res.json();
        const { id, profile, itemCount, price, currency } = data.checkout;
        const checkoutData = [{ id, profile, itemCount, price, currency }];
        localStorage.checkouts = JSON.stringify(
          convertCheckoutsToLocalStorage(checkoutData)
        );
        this.setCheckoutsInfo(checkoutData);
        this.setCheckoutInfo({ id, profile, itemCount, price, currency });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  removeItemFromCheckout = async (
    checkoutId: number | string,
    itemId: number | string,
    callback: () => void
  ) => {
    try {
      const res = await deleteApi(`v1/checkouts/${checkoutId}/items/${itemId}`);
      const data = await res.json();
      const { id, profile, itemCount, price, currency } = data.checkout;
      if (!itemCount) {
        runInAction(() => {
          this.checkout = undefined;
        });
        runInAction(() => {
          this.checkoutInfo = undefined;
        });
        const checkouts = JSON.parse(localStorage.checkouts);
        const newCheckoutStorage = checkouts.filter(
          (checkout: CheckoutInfo) => checkout.id !== id
        );
        if (newCheckoutStorage.length) {
          localStorage.checkouts = JSON.stringify(
            convertCheckoutsToLocalStorage(newCheckoutStorage)
          );
        } else {
          clearLocalStorage('checkouts');
          callback();
        }

        this.setCheckoutsInfo(newCheckoutStorage);
        callback();
        return;
      }
      const newCheckoutStorage: CheckoutInfo[] = this.checkoutsInfo.map(
        (checkoutItem: CheckoutInfo) =>
          checkoutItem.id === id
            ? { id, profile, itemCount, price, currency }
            : checkoutItem
      );
      this.setCheckout(data.checkout);
      this.setCheckoutInfo({ id, profile, itemCount, price, currency });
      this.setCheckoutsInfo(newCheckoutStorage);
      localStorage.checkouts = JSON.stringify(
        convertCheckoutsToLocalStorage(newCheckoutStorage)
      );
    } catch (e) {
      console.error(e);
    }
  };

  addCheckoutItemRecipient = async (
    checkoutId: number | string,
    checkoutItemId: number | string,
    recipient: CheckoutItemRecipient,
    callback: (success: boolean) => void
  ) => {
    let success = false;
    try {
      const response = await postApi(
        `v1/checkouts/${checkoutId}/items/${checkoutItemId}/recipients`,
        recipient
      );
      const data = await response.json();
      if (response.ok) {
        success = true;
        this.setCheckout(data.checkout);
      } else if (data.code) {
        this.setErrorMessage(data.code, data.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      callback(success);
    }
  };

  removeCheckoutItemRecipient = async (
    checkoutId: number | string,
    checkoutItemId: number | string,
    recipientId: number | string,
    transactionLinkHash: string | null,
    callback: () => void
  ) => {
    try {
      const res = await deleteApi(
        `v1/checkouts/${checkoutId}/items/${checkoutItemId}/recipients/${recipientId}${
          transactionLinkHash ? `?transactionLinkHash=${transactionLinkHash}` : ''
        }`
      );
      const data = await res.json();
      this.setCheckout(data.checkout);
      callback();
    } catch (e) {
      console.error(e);
    }
  };

  addAddonToCheckout = async (
    checkoutId: number | string,
    checkoutAddonData: CheckoutAddonData[],
    transactionLinkHash: string | null,
    setLoading: (value: boolean) => void
  ) => {
    try {
      const res = await postApi(`v1/checkouts/${checkoutId}/addons`, {
        addons: checkoutAddonData,
        transactionLinkHash,
      });
      const data = await res.json();
      this.setCheckout(data.checkout);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  setCheckout = (checkout: CheckoutData | undefined) => {
    this.checkout = checkout;
  };

  removeCheckout = (checkoutId: number) => {
    const checkouts = toJS(this.checkoutsInfo);
    if (checkouts) {
      const currentCheckouts: CheckoutInfo[] = checkouts.filter(
        (checkout: CheckoutInfo) => checkout.id !== checkoutId
      );

      this.setCheckoutInfo(undefined);
      if (currentCheckouts.length) {
        this.setCheckoutsInfo(currentCheckouts);
        localStorage.checkouts = JSON.stringify(
          convertCheckoutsToLocalStorage(currentCheckouts)
        );
      } else {
        localStorage.removeItem('checkouts');
        this.setCheckoutsInfo([]);
      }
    }
  };

  getCheckoutInfo = (profileId: string | number) => {
    if (localStorage.checkouts) {
      const checkouts: CheckoutInfo[] = JSON.parse(localStorage.checkouts);
      this.setCheckoutsInfo(checkouts);
      this.checkoutInfo = checkouts.find((checkout) => checkout.id === +profileId);
    }
  };

  setCheckoutsInfo = (checkouts: CheckoutInfo[]) => {
    this.checkoutsInfo = checkouts;
  };

  setCheckoutInfo = (data: CheckoutInfo | undefined) => {
    this.checkoutInfo = data;
  };

  addPromotionCode = async (
    checkoutId: number,
    code: string,
    transactionLinkHash: string | null,
    callback?: (updatedCheckout: CheckoutData | null) => void
  ) => {
    try {
      const body = transactionLinkHash ? { code, transactionLinkHash } : { code };
      const res = await postApi(`v1/checkouts/${checkoutId}/promotion-code`, body);
      const data = await res.json();
      const { success, checkout } = data;
      if (success) {
        this.setCheckout(checkout);
        this.setPromotionError('');
      } else {
        this.setPromotionError('errorCoupon');
      }

      if (callback) {
        callback(checkout);
      }
    } catch (e) {
      console.error(e);
      if (callback) {
        callback(null);
      }
    }
  };

  removePromotionCode = async (
    checkoutId: number,
    codeId: number,
    transactionLinkHash: string | null,
    callback?: (updatedCheckout: CheckoutData | null) => void
  ) => {
    try {
      const url = `v1/checkouts/${checkoutId}/promotion-code/${codeId}${
        transactionLinkHash ? `?transactionLinkHash=${transactionLinkHash}` : ''
      }`;
      const res = await deleteApi(url);
      const data = await res.json();

      if (data.success) {
        this.setCheckout(data.checkout);
      }

      if (callback) {
        callback(data.checkout);
      }
    } catch (e) {
      console.error(e);
      if (callback) {
        callback(null);
      }
    }
  };

  getCheckoutDataRequestBody = (transactionLinkHash: string | null) => {
    const paymentInfo = toJS(this.paymentInfo);
    const paymentData: {
      phoneNumber?: string;
      customerMessage?: string;
      transactionLinkHash?: string;
    } = {};

    paymentData.phoneNumber =
      paymentInfo?.phoneNumber || (this.checkout?.phoneNumber as string);
    if (paymentData.phoneNumber && paymentData.phoneNumber.indexOf('+') < 0) {
      paymentData.phoneNumber = `+${paymentData.phoneNumber}`;
    }

    paymentData.customerMessage = paymentInfo?.customerMessage;
    paymentData.transactionLinkHash = transactionLinkHash || undefined;

    return paymentData;
  };

  startBankIDAuth = async (
    checkoutId: number,
    transactionLinkHash: string | null,
    callback: (bankIdData: BankIDData) => void
  ) => {
    const res = await postApi(`v1/checkouts/${checkoutId}/bankid/auth`, {
      transactionLinkHash,
    });
    const data = await res.json();
    this.setBankIDData(data);
    if (data.status === 400) {
      this.setErrorMessage(data.code, data.message);
    }

    callback(data);
  };

  collectBankID = async (
    bankIDTransactionId: number,
    callback: (bankIdData: BankIDData) => void
  ) => {
    const res = await postApi(`v1/bankid/${bankIDTransactionId}/collect`, null);
    const data = await res.json();
    this.setBankIDData(data);
    if (data.status === 400) {
      this.setErrorMessage(data.code, data.message);
    }

    callback(data);
  };

  sendSigningDocument = async (
    checkoutId: number,
    callback: (updatedCheckout: CheckoutData) => void,
    transactionLinkHash: string | null
  ) => {
    const res = await postApi(
      `v1/checkouts/${checkoutId}/signing-documents/send`,
      this.getCheckoutDataRequestBody(transactionLinkHash)
    );
    const data = await res.json();
    if (data.id) {
      this.setCheckout(data);
    } else if (data.status === 400) {
      this.setErrorMessage(data.code, data.message);
    }
    callback(data);
  };

  verifySigningDocument = async (
    checkoutId: number,
    callback: (success: boolean) => void
  ) => {
    await this.getCheckout(checkoutId);
    const updatedCheckout = toJS(this.checkout);
    callback(updatedCheckout?.documentSigningStatus === 'signed');
  };

  replaceStore = (newCheckout: CheckoutData) => {
    const checkoutsInfo = toJS(this.checkoutsInfo);
    const newCheckoutsInfo = toJS(checkoutsInfo).filter(
      (checkoutItem) => checkoutItem.id !== newCheckout.id
    );

    this.setCheckoutInfo(undefined);
    if (newCheckoutsInfo.length) {
      localStorage.checkouts = JSON.stringify(
        convertCheckoutsToLocalStorage(newCheckoutsInfo)
      );
      this.setCheckout(newCheckout);
      this.setCheckoutsInfo(newCheckoutsInfo);
    } else {
      localStorage.removeItem('checkouts');
    }
  };

  setErrorMessage = (code: string, message: string) => {
    if (code === apiErrorCodes.PHONE_INVALID) {
      this.setFieldError('phoneNumber', message);
    } else if (code === apiErrorCodes.CUSTOMER_MESSAGE_INVALID) {
      this.setFieldError('customerMessage', message);
    } else if (code === apiErrorCodes.CHECKOUT_ITEM_RECIPIENT_MANDATORY) {
      this.setFieldError('checkoutItemRecipient', message, code);
    } else if (code === apiErrorCodes.CHECKOUT_ITEM_RECIPIENT_ALREADY_BOOKED) {
      this.setFieldError('checkoutItemRecipient', message, code);
    } else if (code === apiErrorCodes.CHECKOUT_ITEM_RECIPIENT_BOOKING_FULL) {
      this.setFieldError('checkoutItemRecipient', message, code);
    } else if (code === apiErrorCodes.CHECKOUT_ITEM_MAX_RECIPIENTS) {
      this.setFieldError('checkoutItemRecipient', message, code);
    } else if (code === apiErrorCodes.CHECKOUT_BANKID_MANDATORY) {
      this.setFieldError('bankID', message, code);
    } else if (code === apiErrorCodes.BANKID_COULD_NOT_START_TRANSACTION) {
      this.setFieldError('bankID', message, code);
    }
  };

  payOnArrival = async (
    id: number,
    transactionLinkHash: string | null,
    callback: (checkout?: CheckoutData) => void
  ) => {
    try {
      const res = await postApi(
        `v1/checkouts/${id}/book`,
        this.getCheckoutDataRequestBody(transactionLinkHash)
      );
      const data = await res.json();
      if (data.id) {
        callback(data);
        this.setPaymentInfo(undefined);
      } else if (data.status === 400) {
        this.setErrorMessage(data.code, data.message);
        callback();
        ErrorStore.setError({ ...data, place: 'onArrival' });
      } else {
        ErrorStore.setError({ ...data, place: 'onArrival' });
      }
    } catch (e) {
      callback(undefined);
      console.error(e);
    }
  };

  createInvoiceTransaction = async (
    checkoutId: string,
    transactionLinkHash: string | null,
    callback?: (hasError?: boolean) => void
  ) => {
    const paymentInfo = toJS(this.paymentInfo);
    if (!this.checkout && !paymentInfo) {
      return;
    }

    try {
      const res = await postApi(
        `v1/checkouts/${checkoutId}/transactions/invoice`,
        this.getCheckoutDataRequestBody(transactionLinkHash)
      );
      const data = await res.json();
      if (data.checkout) {
        PaymentStore.setTransaction(data);
        this.setCheckout(data.checkout);
        if (callback) {
          callback();
        }
        this.setPaymentInfo(undefined);
      } else if (data.status === 400 && callback) {
        this.setErrorMessage(data.code, data.message);
        ErrorStore.setError({ ...data, place: 'invoiceTransaction' });
        callback(true);
      } else {
        ErrorStore.setError({ ...data, place: 'invoiceTransaction' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  createCardTransaction = async (
    checkoutId: string,
    transactionLinkHash: string | null,
    callback?: (hasError?: boolean) => void
  ) => {
    try {
      const res = await postApi(
        `v1/checkouts/${checkoutId}/transactions/card`,
        this.getCheckoutDataRequestBody(transactionLinkHash)
      );
      const data = await res.json();
      if (data.checkout) {
        PaymentStore.setTransaction(data);
        this.setCheckout(data.checkout);
        if (callback) {
          callback();
        }
      } else if (data.status === 400 && callback) {
        this.setErrorMessage(data.code, data.message);
        ErrorStore.setError({ ...data, place: 'cardTransaction' });
        callback(true);
      } else {
        ErrorStore.setError({ ...data, place: 'cardTransaction' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  createSwishTransaction = async (
    checkoutId: string,
    transactionLinkHash: string | null,
    callback?: (hasError?: boolean, transactionId?: number) => void
  ) => {
    try {
      const res = await postApi(
        `v1/checkouts/${checkoutId}/transactions/swish`,
        this.getCheckoutDataRequestBody(transactionLinkHash)
      );
      const data = await res.json();
      if (data.checkout) {
        PaymentStore.setTransaction(data);
        this.setCheckout(data.checkout);
        if (callback) {
          callback(false, data.id);
        }
      } else if (data.status === 400 && callback) {
        this.setErrorMessage(data.code, data.message);
        ErrorStore.setError({ ...data, place: 'swishTransaction' });
        callback(true);
      } else {
        ErrorStore.setError({ ...data, place: 'swishTransaction' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  setPaymentInfo = (data: PaymentInfo | undefined) => {
    this.paymentInfo = data;
  };

  setFieldError = (field: string, errorMessage: string, errorCode?: string) => {
    if (!errorCode) {
      this.checkoutFieldErrors = { ...this.checkoutFieldErrors, [field]: errorMessage };
    } else {
      this.checkoutFieldErrors = {
        ...this.checkoutFieldErrors,
        [field]: { errorMessage, errorCode },
      };
    }
  };

  clearFieldErrors = () => {
    this.checkoutFieldErrors = {};
  };

  getStorageCheckoutData = () => {
    if (localStorage.checkouts && !toJS(this.checkout)) {
      const id = JSON.parse(localStorage.checkouts)[0]?.id;
      this.getCheckout(id);
    }
  };

  setPromotionError = (value: string) => {
    this.promotionError = value;
  };

  setBankIDData = (value: BankIDData | null) => {
    this.bankIDData = value;
  };
}

export const CheckoutStore = new Checkout();
