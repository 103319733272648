import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';

export const HeaderStyled = styled.header<{ withoutBottomShadow?: boolean }>`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 84px;
  top: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.middleGray};
  z-index: 1;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
  ${({ withoutBottomShadow }) =>
    withoutBottomShadow && 'box-shadow: 0 -10px 10px rgb(0 0 0 / 16%);'}
`;

export const HeaderItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 18px 0px 26px;
`;

export const BackBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const HeaderText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 18px;
  letter-spacing: 0.25px;
  font-weight: 400;
`;

export const BackButton = styled.div`
  width: 46px;
  height: 46px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

export const HomeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 50%;
  width: 46px;
  height: 46px;
  cursor: pointer;
  > div {
    position: relative;
    bottom: 1px;
  }
  &:hover {
    opacity: 0.6;
  }
  @media ${devices.mobile} {
    left: ${(props: { isTitle?: boolean }) => (props.isTitle ? '10px' : '170px')};
  }
`;
