import { ReactNode, SyntheticEvent } from 'react';
import styled from 'styled-components';

type CustomIconProps = {
  children?: ReactNode;
  icon: string;
  position?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  height?: string;
  width?: string;
  cursor?: string | boolean;
  borderRadius?: string;
  rotate?: string | boolean;
  disabled?: boolean;
  margin?: string;
  background?: string;
  device?: string;
  deviceHeight?: string;
  deviceWidth?: string;
  deviceMargin?: string;
  hover?: boolean;
  transition?: string;
  onClick?: (event?: SyntheticEvent<HTMLDivElement>) => void;
};

const Icon = styled.div`
  ${(props: CustomIconProps) => (props.position ? `position: ${props.position}` : '')};
  ${(props: CustomIconProps) => (props.top ? `top: ${props.top}` : '')};
  ${(props: CustomIconProps) => (props.right ? `right: ${props.right}` : '')};
  ${(props: CustomIconProps) => (props.bottom ? `bottom: ${props.bottom}` : '')};
  ${(props: CustomIconProps) => (props.left ? `left: ${props.left}` : '')};
  height: ${(props: CustomIconProps) => props.height};
  width: ${(props: CustomIconProps) => props.width};
  ${(props: CustomIconProps) => (props.margin ? `margin: ${props.margin}` : '')};
  ${(props: CustomIconProps) =>
    props.background ? `background: ${props.background}` : ''};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props: CustomIconProps) => props.icon});
  ${(props: CustomIconProps) =>
    props.borderRadius ? `border-radius: ${props.borderRadius}` : ''};
  ${(props: CustomIconProps) => props.rotate as string};
  ${(props: CustomIconProps) =>
    props.transition ? `transition: ${props.transition}` : ''};
  cursor: ${(props: CustomIconProps) => props.cursor as string};
  ${(props: CustomIconProps) => (props.disabled ? 'opacity: 0.3' : '')};
  user-select: none;
  ${(props: CustomIconProps) =>
    props.device
      ? `@media${props.device} {
          ${props.deviceWidth ? `width: ${props.deviceWidth}` : ''};
          ${props.deviceHeight ? `height: ${props.deviceHeight}` : ''};
          ${props.deviceMargin ? `margin: ${props.deviceMargin}` : ''};
  }`
      : ''};
  ${(props: CustomIconProps) =>
    props.hover
      ? `&:hover {
    opacity: 0.6;
  }`
      : ''};
`;

export const CustomIcon = (props: CustomIconProps) => {
  const {
    icon,
    height,
    width,
    borderRadius,
    rotate,
    cursor,
    disabled,
    margin,
    device,
    position,
    top,
    right,
    bottom,
    left,
    background,
    deviceHeight,
    deviceWidth,
    deviceMargin,
    hover,
    children,
    transition,
    onClick,
  } = props;
  return (
    <Icon
      icon={icon}
      height={height}
      width={width}
      borderRadius={borderRadius}
      rotate={rotate ? 'transform: rotate(180deg)' : ''}
      cursor={cursor ? 'pointer' : 'auto'}
      disabled={disabled}
      margin={margin}
      position={position}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      background={background}
      device={device}
      deviceHeight={deviceHeight}
      deviceWidth={deviceWidth}
      deviceMargin={deviceMargin}
      hover={hover}
      transition={transition}
      onClick={onClick}
    >
      {children}
    </Icon>
  );
};
