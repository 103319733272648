import { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { devices } from 'constants/mediaConstants';
import { Namespaces } from 'languages';
import { getPrice } from 'helpers';
import { getTime, getYMD } from 'helpers/DateTimeUtils';

import { HeaderStore } from 'store/HeaderStore';
import { Course, ServiceStore, ResourceType } from 'store/ServiceStore';
import { WaitingListStore, WaitingItemType } from 'store/WaitingListStore';
import { LoginStore } from 'store/LoginStore';
import { Loader } from 'components/Loader/Loader';
import { EmptyResult } from 'components/EmptyResult/EmptyResult';

import CloseIcon from 'assets/icons/close.svg';
import AlertImage from 'assets/icons/alert.svg';
import infoIcon from 'assets/icons/infoIcon.svg';
import PriceIcon from 'assets/icons/price.svg';

import { Flex } from 'styled/Flex';
import Layout from 'styled/Layout';

import { WaitingItem } from './WaitingItem';
import { Avatar } from 'components/Avatar';
import { RootPageTitle } from 'components/RootPageTitle';

const WaitingsElement = styled(Layout)`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

const ModalBody = styled.div`
  position: relative;
  width: 545px;
  max-height: 100vh;
  overflow: auto;
  padding: 72px 38px 42px 34px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    padding: 72px 25px 30px;
  }
`;

const CardHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-top: 12px;
  @media ${devices.mobile} {
    font-size: 22px;
  }
`;

const CardDescription = styled.div`
  margin: 14px 0 24px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.middleDark};
  @media ${devices.mobile} {
    font-size: 14px;
    padding-right: 20px;
  } ;
`;

const CardValidBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
`;

const WaitingDurationBlock = styled.div`
  width: 100%;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px 10px 0 0;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;
const WaitingStartEndBlock = styled.div`
  display: flex;
  width: 100%;
`;

const WaitingStartBlock = styled.div`
  width: 100%;
  margin-top: -1px;
  margin-right: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 0 0 0 10px;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;
const WaitingEndBlock = styled.div`
  width: 100%;
  margin-top: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 0 0 10px 0;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;

const CardDateTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  @media ${devices.mobile} {
    font-size: 11px;
  } ;
`;

const GroupLeaderBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;
const GroupLeaderName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  margin-right: auto;
  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

const CardDateValues = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  white-space: nowrap;

  @media ${devices.mobile} {
    font-size: 12px;
  } ;
`;

const Hour = styled.div`
  margin-left: 10px;
`;

const GroupLeadersBlock = styled.div`
  margin: 40px 0;
`;

const GroupLeadersBlockName = styled.div`
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

const Price = styled.div`
  margin-left: 5px;
  font-style: normal;
  white-space: nowrap;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  @media${devices.mobile} {
    font-size: 13px;
  }
`;

const CardPriceIcon = styled.div`
  height: 18px;
  width: 18px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${PriceIcon});
`;

const IconClose = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-image: url(${CloseIcon});
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media ${devices.mobile} {
    right: 30px;
    top: 30px;
    height: 25px;
    width: 25px;
  }
`;

const CancelIcon = styled.div`
  width: 72px;
  height: 72px;
  margin: 0 auto;
  background-size: contain;
  background-image: url(${AlertImage});
`;

const CancelModalText = styled.div`
  margin: 25px 0 7px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
`;

const CancelModalSubText = styled.div`
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const ModalCancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: #6f6f6f;
  border: 1px solid #a6a6a6;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
  @media ${devices.mobile} {
    margin-bottom: 10px;
  }
`;

const ModalRejectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkBlack};
  border-radius: 28px;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.9;
  }
  @media ${devices.mobile} {
    margin: 5px auto 10px;
  }
`;

const WaitingItemHeader = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 20px;
  }
`;

const WaitingItemDescription = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  justify-content: ${(props: { isEnd?: boolean }) =>
    props.isEnd ? 'flex-end' : 'space-between'};
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.lightBlack};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const CardItem = styled.div`
  position: relative;
  margin: 25px 0;
  padding: 26px 34px 30px;
  border-radius: 30px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  background: ${({ theme }) => theme.colors.white};

  @media ${devices.mobile} {
    padding: 16px 20px 30px;
  }
`;

const InfoIcon = styled.div`
  height: 22px;
  width: 22px;
  padding-right: 22px;
  background-size: cover;
  background-image: url(${infoIcon});
  cursor: pointer;
`;

const ModalCardName = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 22px;
  }
`;

const ModalCardDescription = styled.div`
  margin: 10px 0 27px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 18px;
  line-height: 21px;

  @media ${devices.mobile} {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

const ModalContactsEmail = styled.a`
  margin-left: 55px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  text-decoration: underline;
  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

export const WaitingListPage = observer(() => {
  const { getWaitingList, waitingList, loading, leaveWaitingListItem } = WaitingListStore;
  const { setBackButton } = HeaderStore;
  const { getUserStatus } = LoginStore;
  const { fetchService, service } = ServiceStore;
  const { t } = useTranslation(Namespaces.UI);
  const [modalType, setModalType] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const [isIdModalOpen, setIdOpenModal] = useState<number[]>([]);

  const [waitingItem, setActiveWaitingItem] = useState<WaitingItemType | undefined>();

  useEffect(() => {
    setBackButton(true);
    getWaitingList();
    setActiveWaitingItem(undefined);
  }, [getWaitingList, setBackButton]);

  useEffect(() => {
    if (waitingItem && modalType === 'info') {
      fetchService(`${waitingItem.service.id}`);
    }
  }, [waitingItem, modalType, fetchService]);

  const waitingListData = toJS(waitingList);

  const handleRemoveItem = () => {
    getUserStatus();
    setModalType('');
    setModalLoading(false);
  };
  const handleRefreshWaitingList = () => {
    getWaitingList();
  };

  const renderLeaderInfoModal = (resources: ResourceType[]) => {
    return (
      <Modal>
        <ModalBody>
          <IconClose
            onClick={() => {
              setIdOpenModal([]);
            }}
          />
          {resources.map((element: ResourceType) => {
            if (isIdModalOpen.includes(element.id)) {
              return (
                <div key={element.id}>
                  <Avatar
                    size={115}
                    url={element.avatarUrl}
                    style={{ marginRight: '22px' }}
                  />
                  <ModalCardName>{element.name}</ModalCardName>
                  <ModalCardDescription>{element.description}</ModalCardDescription>
                  <Flex>
                    {element.email && (
                      <>
                        <ModalTitle>{t('email')}</ModalTitle>
                        <ModalContactsEmail href={`mailto:${element.email}`}>
                          {element.email}
                        </ModalContactsEmail>
                      </>
                    )}
                  </Flex>
                </div>
              );
            }
            return null;
          })}
        </ModalBody>
      </Modal>
    );
  };

  const renderModalInfoBody = () => {
    const currentService = toJS(service);
    if (!currentService) {
      return <Loader />;
    }
    const {
      name,
      description,
      price,
      currency,
      startTime,
      endTime,
      resources,
    } = currentService as Course;
    return (
      <>
        <Flex>
          <Flex flexDirection="column">
            <CardHeader>{name}</CardHeader>
            <CardDescription>{description}</CardDescription>
            <Flex alignItems="center">
              <CardPriceIcon />
              <Price>{getPrice(price, currency)}</Price>
            </Flex>
            <CardValidBlock>
              <WaitingDurationBlock>
                <CardDateTitle>{t('courseDuration').toUpperCase()}</CardDateTitle>
                <CardDateValues>{`${getYMD(startTime, true)} ~ ${getYMD(
                  endTime,
                  true
                )}`}</CardDateValues>
              </WaitingDurationBlock>
              <WaitingStartEndBlock>
                <WaitingStartBlock>
                  <CardDateTitle>{t('bookingStart').toUpperCase()}</CardDateTitle>
                  <CardDateValues>
                    {getYMD(startTime, true)}
                    <Hour>{getTime(startTime, true)}</Hour>
                  </CardDateValues>
                </WaitingStartBlock>
                <WaitingEndBlock>
                  <CardDateTitle>{t('bookingEnd').toUpperCase()}</CardDateTitle>
                  <CardDateValues>
                    {getYMD(endTime, true)}
                    <Hour>{getTime(endTime, true)}</Hour>
                  </CardDateValues>
                </WaitingEndBlock>
              </WaitingStartEndBlock>
            </CardValidBlock>
            {resources?.length && (
              <GroupLeadersBlock>
                <GroupLeadersBlockName>{t('groupLeaders')}</GroupLeadersBlockName>
                {resources.map((element: ResourceType) => {
                  return (
                    <GroupLeaderBlock key={element.id}>
                      <Avatar
                        size={64}
                        url={element.avatarUrl}
                        style={{ marginRight: '22px' }}
                      />
                      <GroupLeaderName>{element.name}</GroupLeaderName>
                      <InfoIcon
                        onClick={() => {
                          setIdOpenModal([element.id]);
                        }}
                      />
                    </GroupLeaderBlock>
                  );
                })}
              </GroupLeadersBlock>
            )}
          </Flex>
        </Flex>
        {isIdModalOpen.length ? renderLeaderInfoModal(resources) : null}
      </>
    );
  };

  const renderModalCancelBody = () => {
    const { id } = waitingItem as WaitingItemType;
    return (
      <>
        <CancelIcon />
        <CancelModalText>{t('leaveWaitingList')}</CancelModalText>
        <CancelModalSubText>
          {t('leaveWaitingMessage')}
          <>?</>
        </CancelModalSubText>
        <ButtonWrap>
          <ModalCancelButton
            onClick={() => {
              if (!modalLoading) {
                setModalLoading(true);
                leaveWaitingListItem(id, handleRemoveItem);
              }
            }}
          >
            {modalLoading ? <Loader small /> : t('yes')}
          </ModalCancelButton>
          <ModalRejectButton
            onClick={() => {
              setModalType('');
            }}
          >
            {t('no')}
          </ModalRejectButton>
        </ButtonWrap>
      </>
    );
  };

  const waitingListCard = (bookingGroup: WaitingItemType[]) => {
    const {
      id,
      service: { description, name },
    } = bookingGroup[0];

    return (
      <div key={id}>
        <CardItem>
          <Flex justifyContent="flex-end">
            <InfoIcon
              onClick={() => {
                setModalType('info');
                setActiveWaitingItem(bookingGroup[0]);
              }}
            />
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <WaitingItemHeader>{name}</WaitingItemHeader>
          </Flex>
          <WaitingItemDescription>{description}</WaitingItemDescription>
          {bookingGroup.map((item: WaitingItemType) => {
            return (
              <WaitingItem
                key={item.id}
                waitingItem={item}
                setModalType={setModalType}
                setActiveWaitingItem={setActiveWaitingItem}
              />
            );
          })}
        </CardItem>
      </div>
    );
  };

  const renderWaitingItems = () => {
    if (!waitingListData?.length) {
      return (
        <EmptyResult
          text={t('noWaitingListsMessage')}
          action={handleRefreshWaitingList}
          loading={loading}
        />
      );
    }
    return waitingListData.map((waitingListGroup: WaitingItemType[]) => {
      return waitingListCard(waitingListGroup);
    });
  };

  return (
    <>
      <>
        <WaitingsElement>
          <RootPageTitle title={t('myWaitingLists')} />
          {!waitingListData ? <Loader /> : renderWaitingItems()}
        </WaitingsElement>
      </>

      {modalType && (
        <Modal>
          <ModalBody>
            {modalType === 'info' ? (
              <IconClose
                onClick={() => {
                  setModalType('');
                }}
              />
            ) : null}
            {modalType === 'info' ? renderModalInfoBody() : null}
            {modalType === 'cancel' ? renderModalCancelBody() : null}
          </ModalBody>
        </Modal>
      )}
    </>
  );
});
