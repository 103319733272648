import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router';
import { urls } from 'helpers';

import { SubscriptionAddCardForm } from './SubscriptionAddCardForm';
import { SubscriptionsStore } from 'store/SubscriptionStore';

import { Loader } from 'components/Loader/Loader';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';

import { HeaderStore } from 'store/HeaderStore';

import CardIconSvg from 'assets/icons/card.svg';

import Layout from 'styled/Layout';
import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const CardPaymentBlock = styled.div`
  padding: 24px 31px 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;

  @media ${devices.mobile} {
    padding: 24px 11px 30px;
  },
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 11px;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  user-select: none;
`;

export const SubscriptionAddCardPage = observer(() => {
  const {
    getAddSubscriptionPaymentMethodSetup,
    addPaymentMethodSetup,
    addSubscriptionPaymentMethod,
  } = SubscriptionsStore;
  const history = useHistory();
  const { t } = useTranslation(Namespaces.UI);
  const { setBackButton } = HeaderStore;
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const { id } = useParams<{ id: string }>();
  const stripePublishKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const addPaymentMethodSetupData = toJS(addPaymentMethodSetup);
  const stripeClientSecret = addPaymentMethodSetupData?.stripe?.clientSecret;

  useEffect(() => {
    if (!stripePromise) {
      setBackButton(true);
      setStripePromise(loadStripe(stripePublishKey as string));
    }
  }, [stripePromise, stripePublishKey, setBackButton]);

  useEffect(() => {
    getAddSubscriptionPaymentMethodSetup(id, 'card');
  }, [id, getAddSubscriptionPaymentMethodSetup]);

  const onStripeCardAdded = (paymentMethodId: string) => {
    // Card is now registered, connect it to subscription
    addSubscriptionPaymentMethod(id, { stripePaymentMethodId: paymentMethodId }, () => {
      history.push(urls.subscriptions);
    });
  };

  return (
    <Layout>
      <>
        <CardPaymentBlock>
          <Flex alignItems="center" margin="0 0 20px 0">
            <IconWrap>
              <CustomIcon width="19px" height="13px" icon={CardIconSvg} />
            </IconWrap>
            <Title>{t('subscriptionAddCardTitle')}</Title>
          </Flex>

          {stripeClientSecret ? (
            <Elements stripe={stripePromise}>
              <SubscriptionAddCardForm
                clientSecret={stripeClientSecret}
                onCardAdded={(paymentMethodId: string) =>
                  onStripeCardAdded(paymentMethodId)
                }
              />
            </Elements>
          ) : (
            <Loader />
          )}
        </CardPaymentBlock>
      </>
    </Layout>
  );
});
