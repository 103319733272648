import { UserPanel } from 'containers/UserPanel/UserPanel';
import { FC } from 'react';
import { Wrapper, PageTitle } from './styles';

interface IProps {
  title: string;
}

export const RootPageTitle: FC<IProps> = ({ title }) => {
  return (
    <Wrapper>
      <div>
        <UserPanel variant="pageTitle" />
      </div>
      <PageTitle>{title}</PageTitle>
    </Wrapper>
  );
};
