import { FC, Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import { useHistory } from 'react-router';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
import OutsideClickHandler from 'react-outside-click-handler';

import { Hamburger } from 'components/Hamburger/Hamburger';
import { parseLocalStorageCheckout, urls } from 'helpers';
import { Namespaces } from 'languages';

import { CheckoutStore } from 'store/CheckoutStore';
import { HeaderStore } from 'store/HeaderStore';
import { LoginStore } from 'store/LoginStore';
import { Avatar } from 'components/Avatar';
import { Flex } from 'styled/Flex';
import {
  UserPanelWrap,
  Dropdown,
  DropdownItem,
  Line,
  NotificationMenu,
  UserBlock,
} from './styles';

interface IProps {
  variant?: 'pageTitle' | 'header';
}

export const UserPanel: FC<IProps> = observer(({ variant = 'header' }) => {
  const history = useHistory();
  const { checkoutsInfo, setCheckoutsInfo } = CheckoutStore;
  const { setTitle, setBackButton } = HeaderStore;
  const { logout, user, getUserStatus } = LoginStore;
  let checkoutsInfoData = toJS(checkoutsInfo);
  const userData = toJS(user);
  const { t } = useTranslation(Namespaces.UI);

  const [active, setActive] = useState(false);

  const mappedLinks = [
    {
      title: t('bookings'),
      link: urls.bookings,
      counter: userData.activeEntryBookings,
    },
    {
      title: t('waitingLists'),
      link: urls.waitingList,
      counter: userData.activeWaitingLists,
      line: true,
    },

    {
      title: t('payments'),
      link: urls.payments,
    },
    {
      title: t('subscriptions'),
      link: urls.subscriptions,
      counter: userData.activeSubscriptions,
      line: true,
    },
    {
      title: t('vouchers'),
      link: urls.vouchers,
      counter: userData.activeVoucherBookings,
    },
    {
      title: t('memberships'),
      link: urls.memberships,
      counter: userData.activeMembershipsBookings,
      line: true,
    },
    {
      title: t('account'),
      link: urls.account,
      line: true,
    },
  ];

  useEffect(() => {
    if (localStorage.auth) {
      let profileId = localStorage.profileId;

      // Mostly for dev but if user changes url to a different profile,
      // localStorage is not yet updated here to the newest one.
      // Check url for latest one if possible
      const currentUrl = window.location.href;
      const profilesUrlIndex = currentUrl.indexOf('profiles/');
      if (profilesUrlIndex > -1) {
        let urlAfterProfiles = currentUrl.substring(profilesUrlIndex + 9);
        const nextSlashIndex = urlAfterProfiles.indexOf('/');
        if (nextSlashIndex > -1) {
          urlAfterProfiles = urlAfterProfiles.substring(0, nextSlashIndex);
          let urlProfileId = parseInt(urlAfterProfiles, 10);
          if (!isNaN(urlProfileId)) {
            profileId = urlProfileId;
          }
        }
      }

      getUserStatus(profileId);
    }
  }, [getUserStatus, setCheckoutsInfo]);

  if (!checkoutsInfoData.length && localStorage.checkouts) {
    checkoutsInfoData = parseLocalStorageCheckout(JSON.parse(localStorage.checkouts));
  }

  return (
    <UserPanelWrap data-testid="user-panel">
      <OutsideClickHandler
        onOutsideClick={() => {
          setActive(false);
        }}
      >
        <Flex alignItems="center">
          <UserBlock
            variant={variant}
            isActive={active}
            onClick={() => {
              if (userData.id) {
                setActive(!active);
              } else {
                localStorage.prevPath =
                  history.location.pathname + history.location.search;
                setBackButton(true);
                setTitle(t('loginTitle'));
                history.push('/auth');
              }
            }}
          >
            <Hamburger active={active} />
            {variant === 'header' && (
              <Avatar
                size={40}
                url={userData.avatarUrl || ''}
                counter={userData.badgeCount || undefined}
              />
            )}
            <Dropdown active={active} variant={variant}>
              {mappedLinks.map((item) => (
                <Fragment key={item.title}>
                  <DropdownItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setActive(false);
                      history.push(item.link);
                    }}
                  >
                    {item.title}
                    {item.counter ? (
                      <NotificationMenu color="#000000">{item.counter}</NotificationMenu>
                    ) : null}
                  </DropdownItem>
                  {item.line && <Line />}
                </Fragment>
              ))}

              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  setActive(false);
                  setBackButton(true);
                  if (history.location.pathname === '/account') {
                    logout(() => {
                      history.push('/auth');
                    });
                  } else {
                    history.push(urls.services(localStorage.profileId));
                    logout();
                  }
                }}
              >
                {t('logout')}
              </DropdownItem>
            </Dropdown>
          </UserBlock>
        </Flex>
      </OutsideClickHandler>
    </UserPanelWrap>
  );
});
