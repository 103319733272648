import styled, { DefaultTheme } from 'styled-components';

export const UserBlock = styled.div<{
  variant: 'pageTitle' | 'header';
  isActive: boolean;
}>`
  position: relative;
  display: flex;
  gap: 15px;
  align-items: center;
  min-width: ${({ variant }) => (variant === 'pageTitle' ? '47px' : '115px')};
  height: ${({ variant }) => (variant === 'pageTitle' ? '47px' : '56px')};
  padding: ${({ variant }) => variant !== 'pageTitle' && '0 5px 0 20px'};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  justify-content: ${({ variant }) => variant === 'pageTitle' && 'center'};
  border-radius: 50px;
  cursor: pointer;
  .checkbox {
    margin-left: ${({ isActive, variant }) =>
      variant === 'pageTitle' ? (isActive ? '3.5px' : '0') : '6px'};
    transition: margin-left 0.3s ease-out;
  }
`;

export const UserPanelWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
`;

export const Dropdown = styled.div(
  ({
    active,
    theme,
    variant,
  }: {
    active: boolean;
    theme: DefaultTheme;
    variant: 'pageTitle' | 'header';
  }) => `
  display: ${active ? 'block' : 'none'};
  position: absolute;
  top: 100%;
  right: ${variant === 'pageTitle' ? '-155px' : '0'};
  z-index: 100;
  margin-top: 5px;
  padding: 10px 0;
  background: ${theme.colors.white};
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
`
);
export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  padding: 5px 40px;
  width: 200px;
  :hover {
    background: ${({ theme }) => theme.colors.lightGray};
  }
`;
export const NotificationMenu = styled.div(
  (props: { color: string; theme: DefaultTheme }) => `
  display: flex;
  justify-content: center;
  width: 23px;
  height: 23px;
  margin: 0px 0px 0px 4px;
  border-radius: 20px;
  background: ${props.color};
  color: ${props.theme.colors.white};
  line-height: 23px;
  font-style: normal;
  font-family: ${props.theme.fonts.sfProText};
  font-size: 13px;
`
);

export const Line = styled.div`
  height: 1px;
  margin: 0 40px;
  background: ${({ theme }) => theme.colors.middleGray};
`;
