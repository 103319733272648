export enum Namespaces {
  UI = 'ui',
}

/**
 * Returns the language part of the browser locale, meaning if locale is "en-US" only "en" is returned.
 */
export function getBrowserLanguage() {
  let { language } = navigator;
  if (language) {
    if (language.indexOf('-') > -1) {
      [language] = language.split('-');
    }
  }

  return language;
}
