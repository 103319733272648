import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { callApi } from 'helpers';

type ServiceCategory = {
  id: number;
  name: string;
};

export type PriceReductionsType = {
  type: string;
  id: number;
};

export interface ServiceType {
  type: string;
  id: number;
  name: string;
  category: ServiceCategory;
  description: string;
  price: number;
  fromPrice?: number;
  pricePerUnit: string;
  currency: string;
  durationMinutes?: number;
  startTime?: string;
  endTime?: string;
  spotsLeft?: number;
  balance?: number;
  priceReductions?: PriceReductionsType[];
}

export interface ServicesListType {
  [key: string]: ServiceType[];
}

class ServiceList {
  services: ServicesListType | undefined = undefined;

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchServices = async (id: string, categoryIds: string[]) => {
    try {
      runInAction(() => {
        this.loading = true;
      });

      let url = `v1/profiles/${id}/services?page=1&perPage=200`;
      if (categoryIds?.length > 0) {
        categoryIds.forEach((categoryId) => (url += `&categoryIds=${categoryId}`));
      }

      const res = await callApi(url);
      const { data } = await res.json();
      const services: ServicesListType = {};
      let iterator = 0;
      for (let i = 0; i < data.length; i += 1) {
        if (services[data[i].category?.name]) {
          services[data[i].category.name].push(data[i]);
        } else if (data[i].category) {
          services[data[i].category?.name] = [];
          services[data[i].category?.name].push(data[i]);
        } else {
          services[`emptyCategory${iterator}`] = [data[i]];
          iterator++;
        }
      }
      runInAction(() => {
        this.services = services;
      });
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  getServices = () => toJS(this.services);
}

export const ServiceListStore = new ServiceList();
