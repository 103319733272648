import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';

import { getDefaultPhoneCountry } from 'helpers';
import { Namespaces } from 'languages';
import PhoneInput from 'react-phone-input-2';

import { DONT_ASK, MANDATORY } from 'constants/checkoutConstants';
import { devices } from 'constants/mediaConstants';

import { CheckoutData, CheckoutStore } from 'store/CheckoutStore';

import PhoneIcon from 'assets/icons/phone.svg';
import ContactIcon from 'assets/icons/contactEdit.svg';
import CheckSuccess from 'assets/icons/check.svg';

import 'react-phone-input-2/lib/style.css';
import './styles.scss';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const ContactBlock = styled.div`
  padding: 27px 41px 11px 51px;

  @media ${devices.mobile} {
    padding: 27px 21px 11px 11px;
  }
`;

const ContactRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`;

const InputBlock = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 19px 17px 16px;
  color: #a6a6a6;
  background: ${(props: { error?: boolean }) => (props.error ? '#FFFBFB' : '#ffffff')};
  border-radius: 10px;
  border: ${(props: { error?: boolean }) =>
    props.error ? '1px solid #FF033D' : '1px solid #dcdcde'};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  .react-tel-input {
    input {
      font-family: ${({ theme }) => theme.fonts.sfProDisplay};
      background: ${(props: { error?: boolean }) =>
        props.error ? '#FFFBFB' : '#ffffff'};
      @media ${devices.mobile} {
        font-size: 17px;
      }
    }
  }

  textarea {
    background: ${(props: { error?: boolean }) => (props.error ? '#FFFBFB' : '#ffffff')};
  }

  @media ${devices.mobile} {
    padding: 10px 17px;
    font-size: 17px;
  }
`;

const Input = styled.textarea`
  width: 100%;
  min-height: 30px;
  height: 55px;
  color: ${({ theme }) => theme.colors.darkBlack};
  border: 0;
  outline: none;
  ::placeholder {
    color: #a6a6a6;
  }
`;

const ErrorMessage = styled.div`
  margin: -10px 0 0 25px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

const RequiredCircle = styled.div`
  position: absolute;
  right: -20px;
  width: 12px;
  height: 12px;
  margin-left: 9px;
  background: #fc8575;
  border-radius: 50%;
`;

export const CustomerInfoCard = observer(
  (props: {
    phoneNumberMode: string;
    customerMessageMode: string;
    checkout: CheckoutData;
    phoneNumber: string;
    setPhoneNumber: (phoneNumber: string) => void;
    customerMessage: string;
    setCustomerMessage: (phoneNumber: string) => void;
  }) => {
    const { checkoutFieldErrors, setFieldError, setPaymentInfo } = CheckoutStore;
    const {
      phoneNumberMode,
      customerMessageMode,
      checkout,
      phoneNumber,
      setPhoneNumber,
      customerMessage,
      setCustomerMessage,
    } = props;
    const [validNumber, setValidNumber] = useState(false);
    const [validMessage, setValidMessage] = useState(false);
    const checkoutData = toJS(checkout);

    useEffect(() => {
      setPaymentInfo({
        phoneNumber: phoneNumber || '',
        customerMessage: customerMessage || '',
      });
      if (customerMessage) {
        setValidMessage(true);
      }
    }, [
      setPaymentInfo,
      checkoutData.phoneNumber,
      checkoutData.customerMessage,
      customerMessage,
      phoneNumber,
    ]);

    useEffect(() => {
      if (checkoutData.phoneNumber === phoneNumber) {
        setValidNumber(true);
      }
    }, [checkoutData.phoneNumber, phoneNumber]);

    const { t } = useTranslation(Namespaces.UI);
    const errors = toJS(checkoutFieldErrors);
    const renderIcon = () => {
      return (
        validMessage && (
          <CustomIcon
            position="absolute"
            right="-25px"
            width="14px"
            height="12px"
            icon={CheckSuccess}
            cursor
            hover
          />
        )
      );
    };
    const renderRequiredIcon = (isValid: boolean) => {
      return isValid ? (
        <CustomIcon
          position="absolute"
          right="-25px"
          width="14px"
          height="12px"
          icon={CheckSuccess}
          cursor
          hover
        />
      ) : (
        <RequiredCircle />
      );
    };
    return (
      <ContactBlock id="contact-block">
        {phoneNumberMode !== DONT_ASK && (
          <>
            <ContactRow>
              <CustomIcon
                width="14px"
                height="20px"
                margin="0 7px 0 0"
                icon={PhoneIcon}
              />
              <InputBlock error={!!errors.phoneNumber}>
                <PhoneInput
                  inputStyle={{ color: '#181818' }}
                  country={getDefaultPhoneCountry()}
                  value={phoneNumber}
                  onChange={(phone) => {
                    setFieldError('phoneNumber', '');
                    setPhoneNumber(phone);
                    setPaymentInfo({ phoneNumber: phone, customerMessage });
                    if (phone.length > 0) {
                      setValidNumber(true);
                    } else {
                      setValidNumber(false);
                    }
                  }}
                  masks={{ se: '.. ... .. ..' }}
                />
              </InputBlock>
              {phoneNumberMode === MANDATORY && renderRequiredIcon(validNumber)}
            </ContactRow>
            {errors.phoneNumber && (
              <ErrorMessage>{`*${errors.phoneNumber}`}</ErrorMessage>
            )}
          </>
        )}
        {customerMessageMode !== DONT_ASK && (
          <>
            <ContactRow>
              <CustomIcon
                width="14px"
                height="20px"
                margin="0 7px 0 0"
                icon={ContactIcon}
              />
              <InputBlock error={!!errors.customerMessage}>
                <Input
                  placeholder={
                    checkout.customerMessagePlaceholder ||
                    t('customerMessagePlaceholder', {
                      profileName: checkout.profile.name,
                    })
                  }
                  value={customerMessage}
                  onChange={(e) => {
                    setFieldError('customerMessage', '');
                    setCustomerMessage(e.target.value);
                    setPaymentInfo({ customerMessage: e.target.value, phoneNumber });
                    if (e.target.value.length > 0) {
                      setValidMessage(true);
                    } else {
                      setValidMessage(false);
                    }
                  }}
                />
              </InputBlock>
              {customerMessageMode === MANDATORY
                ? renderRequiredIcon(validMessage)
                : renderIcon()}
            </ContactRow>
            {errors.customerMessage && (
              <ErrorMessage>{`*${errors.customerMessage}`}</ErrorMessage>
            )}
          </>
        )}
      </ContactBlock>
    );
  }
);
