import { useEffect, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { parseLocalStorageCheckout, urls, getCurrencyName } from 'helpers';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';

import shoppingBagIcon from 'assets/icons/shoppingBag.svg';
import arrowRightIcon from 'assets/icons/arrowRightWhite.svg';

import { CheckoutStore } from 'store/CheckoutStore';
import { PrimaryButton } from 'styled/PrimaryButton';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const fadeIn = keyframes`
  from {
    bottom: -90px;
  }
  to {
    bottom: 0;
  }
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: 84px;
  max-width: 720px;
  padding: 18px;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  animation: ${fadeIn} 0.7s ease-in-out;
  animation-iteration-count: 1;
`;

const CheckoutButton = styled(PrimaryButton)`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  @media${devices.widgetDesktop} {
  }

  @media${devices.mobile} {
    font-size: 14px;
  }
`;

const LeftContainer = styled.div`
  flex: 1;
`;

const MiddleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ItemCountLabel = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-size: 14px;
  font-weight: 400;
  width: 32px;
  height: 32px;
  text-align: center;
  padding-top: 10px;
`;

const MiddleContainerTextContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

const PriceAmountLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-size: 18px;
  font-weight: 700;
  white-space: pre-wrap;
`;

const PriceCurrencyLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-size: 18px;
  font-weight: 500;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
`;

const GoToCheckoutLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-size: 14px;
  font-weight: 400;
`;

const GoToCheckoutHideIfNarrowLabel = styled(GoToCheckoutLabel)`
  @media${devices.mobile} {
    display: none;
  }
`;

export const CheckoutFooter = observer(() => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(Namespaces.UI);

  const [hidden, setHidden] = useState(false);

  const { checkoutsInfo } = CheckoutStore;
  let checkoutsInfoData = toJS(checkoutsInfo);
  if (!checkoutsInfoData.length && localStorage.checkouts) {
    checkoutsInfoData = parseLocalStorageCheckout(JSON.parse(localStorage.checkouts));
  }

  const profileId = localStorage.getItem('profileId');
  const checkouts = checkoutsInfoData.filter(
    (checkout) => checkout && profileId && checkout.profile?.id === +profileId
  );
  const currentCheckout = checkouts.length ? checkouts[0] : null;
  const currentCheckoutId = currentCheckout?.id || 0;

  const disabledUrls = useMemo(() => {
    return {
      [urls.checkout(localStorage.profileId, currentCheckoutId)]: true,
      [urls.invoice(currentCheckoutId)]: true,
      [urls.cardPayment(currentCheckoutId)]: true,
      [urls.swishPayment(currentCheckoutId)]: true,
      [urls.auth]: true,
      [urls.forgotPassword]: true,
    };
  }, [currentCheckoutId]);

  useEffect(() => {
    // As checkout paths in some cases can contain ids not available, make sure footer is never shown on its pages
    setHidden(disabledUrls[location.pathname] || location.pathname.includes('/checkout'));
  }, [location, disabledUrls]);

  const openCheckout = () => {
    if (currentCheckoutId && profileId) {
      const isEqualPath =
        history.location.pathname === urls.checkout(profileId, currentCheckoutId);
      if (!isEqualPath) {
        history.push(urls.checkout(profileId, currentCheckoutId));
      }
    }
  };

  return !hidden && currentCheckout ? (
    <Footer>
      <CheckoutButton onClick={openCheckout}>
        <LeftContainer></LeftContainer>
        <MiddleContainer>
          <CustomIcon width="32px" height="32px" icon={shoppingBagIcon} />
          <ItemCountLabel>{currentCheckout.itemCount}</ItemCountLabel>

          {currentCheckout.price > 0 && (
            <MiddleContainerTextContainer>
              <PriceAmountLabel>{`${currentCheckout.price.toLocaleString()} `}</PriceAmountLabel>
              <PriceCurrencyLabel>
                {getCurrencyName(currentCheckout.currency)}
              </PriceCurrencyLabel>
            </MiddleContainerTextContainer>
          )}
        </MiddleContainer>
        <RightContainer>
          {currentCheckout.price > 0 ? (
            <GoToCheckoutHideIfNarrowLabel>
              {t('checkoutFooterGoToCheckout')}
            </GoToCheckoutHideIfNarrowLabel>
          ) : (
            <GoToCheckoutLabel>{t('checkoutFooterGoToCheckout')}</GoToCheckoutLabel>
          )}
          <CustomIcon
            width="18px"
            height="18px"
            margin="0 0 0 12px"
            icon={arrowRightIcon}
          />
        </RightContainer>
      </CheckoutButton>
    </Footer>
  ) : null;
});
