import { devices } from 'constants/mediaConstants';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import styled from 'styled-components';

export const ServiceHeader = styled.div<{ isExpanded: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px 20px;
  padding: 0px 25px 0px 30px;
  height: 60px;
  background: #ffffff;
  border-radius: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.15px;
  cursor: pointer;
  user-select: none;
  box-shadow: ${({ isExpanded }) =>
    isExpanded ? '0px 5px 15px rgba(0, 0, 0, 0.2)' : 'none'};
  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }
`;
export const Name = styled.p`
  user-select: none;
  font-size: 17px;
  margin: auto 0;
`;

export const NumberPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0 3px 10px;
  color: ${(props: { isExpanded: boolean }) =>
    props.isExpanded ? '#2D2D2E' : '#A6A6A6'};
  background: #f5f5f6;
  border: 1px solid transparent;
  border-radius: 10px;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;

  ${ServiceHeader}:hover & {
    color: #2d2d2e;
  }
`;

export const ServiceBody = styled.div`
  height: ${(props: { isExpanded: boolean }) => (props.isExpanded ? '100%' : 0)};
  overflow: hidden;
`;

export const ArrowUp = styled(MdKeyboardArrowUp)`
  cursor: pointer;
`;
export const ArrowDown = styled(MdKeyboardArrowDown)`
  cursor: pointer;
`;
