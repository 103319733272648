import { makeAutoObservable } from 'mobx';

class Header {
  title: string | undefined = undefined;

  isBackButton: boolean | undefined = undefined;

  withBottomShadow: boolean = true;

  profileId = '';

  constructor() {
    makeAutoObservable(this);
  }

  setTitle = (title: string) => {
    this.title = title;
  };

  setBackButton = (status: boolean) => {
    this.isBackButton = status;
  };

  clearTitle = () => {
    this.title = '';
  };

  setProfileId = (profileId: string) => {
    this.profileId = profileId;
  };

  setBottomShadow = (status: boolean) => {
    this.withBottomShadow = status;
  };
}

export const HeaderStore = new Header();
