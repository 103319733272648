import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router';

import { Loader } from 'components/Loader/Loader';
import { urls } from 'helpers';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';

import { PaymentStore, Transaction, TransactionInvoiceData } from 'store/PaymentStore';
import { CheckoutData, CheckoutStore } from 'store/CheckoutStore';
import { HeaderStore } from 'store/HeaderStore';

import { PrimaryButton } from 'styled/PrimaryButton';
import { ErrorStore } from 'store/ErrorStore';
import { useEffect } from 'react';

type FieldsData = {
  identityNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  street: string;
  postal: string;
  city: string;
  country: string;
  companyNumber: string;
  company: string;
  invoiceReference: string;
};

const ConfirmBlock = styled.div`
  margin-top: 40px;
  padding: 28px 60px 28px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 20px;
  font-family:  ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  @media ${devices.mobile} {
    margin-top: 15px;
    padding: 18px 20px 12px;
  },
`;

const ConfirmTitle = styled.div`
  margin-bottom: 12px;
`;

const ConfirmDescription = styled.div`
  margin: 0 auto 25px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`;

const ConfirmDescriptionBold = styled.span`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
`;

const ConfirmButton = styled(PrimaryButton)`
  max-width: 300px;
  max-height: 56px;
  margin: 28px auto 0px auto;

  @media ${devices.mobile} {
    padding: 14px 50px;
    max-width: 220px;
  },
`;

const TermsError = styled.div`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.red};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

type Props = {
  disabled: boolean;
  isIndividual: boolean;
  checkIsValid: () => boolean;
  transactionData: Transaction;
  fieldsData: FieldsData;
  loading: boolean;
  setLoader: (value: boolean) => void;
};

export const ConfirmInvoice = observer((props: Props) => {
  const { t } = useTranslation(Namespaces.UI);
  const history = useHistory();
  const {
    disabled,
    isIndividual,
    checkIsValid,
    transactionData,
    fieldsData,
    loading,
    setLoader,
  } = props;
  const { payTransaction } = PaymentStore;
  const { replaceStore } = CheckoutStore;
  const { setBackButton } = HeaderStore;
  const { error, removeError } = ErrorStore;
  const { allowedCountries } = transactionData.invoiceData as TransactionInvoiceData;
  const {
    identityNumber,
    firstName,
    lastName,
    email,
    phoneNumber,
    street,
    postal,
    city,
    country,
    companyNumber,
    company,
    invoiceReference,
  } = fieldsData;

  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  ) as string;

  const successInvoice = (newCheckout: CheckoutData) => {
    replaceStore(newCheckout);
    setBackButton(false);
    setLoader(false);
    history.push(urls.confirmation);
  };

  useEffect(() => {
    if (error) {
      setLoader(false);
      () => removeError();
    }
    return () => {
      if (error) {
        removeError();
      }
    };
  }, [error, removeError, setLoader]);

  return (
    <ConfirmBlock>
      <ConfirmTitle>{t('confirmYourOrder')}</ConfirmTitle>
      {transactionData.subscriptionSetup && (
        <>
          <ConfirmDescription>
            {t('subscriptionConfirmInvoiceDescription1')}
            <ConfirmDescriptionBold>
              {`${t('subscriptionConfirmInvoiceDescription2', {
                time: t(transactionData.subscriptionSetup.billingPeriod),
              })}`}
            </ConfirmDescriptionBold>
            {t('subscriptionConfirmInvoiceDescription3')}
          </ConfirmDescription>
        </>
      )}
      {!loading ? (
        <ConfirmButton
          size="large"
          disabled={disabled}
          onClick={() => {
            if (checkIsValid() && !disabled) {
              setLoader(true);
              if (error) {
                removeError();
              }
              if (isIndividual) {
                payTransaction(
                  transactionData.id,
                  {
                    type: 'individual',
                    personalIdentityNumber: identityNumber,
                    firstName,
                    surname: lastName,
                    email,
                    phoneNumber,
                    address: street,
                    postalCode: postal,
                    city,
                    transactionLinkHash,
                    countryCode:
                      allowedCountries.find((el) => el.name === country)?.code || 'SE',
                  },
                  (newCheckout) => {
                    successInvoice(newCheckout);
                  }
                );
              } else {
                payTransaction(
                  transactionData.id,
                  {
                    type: 'company',
                    companyNumber,
                    companyName: company,
                    companyReference: invoiceReference,
                    firstName,
                    surname: lastName,
                    email,
                    phoneNumber,
                    address: street,
                    postalCode: postal,
                    city,
                    transactionLinkHash,
                    countryCode:
                      allowedCountries.find((el) => el.name === country)?.code || 'SE',
                  },
                  (newCheckout) => {
                    successInvoice(newCheckout);
                  }
                );
              }
            }
          }}
        >
          {t('confirm')}
        </ConfirmButton>
      ) : (
        <ConfirmButton size="large" disabled={loading}>
          {loading ? <Loader isWhite small /> : t('confirm')}
        </ConfirmButton>
      )}
      {error && error.place === 'payTransaction' && (
        <TermsError>{error.message || t('errorMessageDefault')}</TermsError>
      )}
    </ConfirmBlock>
  );
});
