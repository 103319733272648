import { makeAutoObservable, runInAction } from 'mobx';
import { callApi, postApi } from 'helpers';

export type UserProxy = {
  id: number;
  firstName: string;
  surname: string;
  birthDay: string;
  email?: string;
};

class User {
  userProxies: UserProxy[] = [];
  
  isAddUserProxyLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getUserProxies = async () => {
    try {
      const response = await callApi(`v1/users/me/user-proxies`);
      const responseData = await response.json();
      this.setUserProxies(responseData);
    } catch (e) {
      console.error(e);
    }
  };

  addUserProxy = async (
    userProxy: UserProxy,
    callback: (success: boolean, errorCode?: string) => void
  ) => {
    this.isAddUserProxyLoading = true;
    const response = await postApi(`v1/users/me/user-proxies`, userProxy);
    const responseData = await response.json();

    runInAction(() => {
      this.isAddUserProxyLoading = false;
    });

    if (response.status === 200) {
      this.setUserProxies([...this.userProxies, responseData as UserProxy]);
      callback(true);
    }
    else {
      callback(false, responseData.code);
    }

  };

  setUserProxies = (userProxies: UserProxy[]) => {
    this.userProxies = userProxies;
  };
}

export const UserStore = new User();
