import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { PriceReducer } from 'store/LoginStore';
import { getPrice, urls } from 'helpers';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';
import { DISCOUNT_TYPE_MEMBERSHIP } from 'constants/discountConstants';
import Peace from 'assets/icons/Peace-icon.svg';
import Membership from 'assets/icons/membership-icon.svg';
import Voucher from 'assets/icons/voucher-icon.svg';
import { useHistory } from 'react-router';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const VOUCHER_POPUP_HEIGHT = 86;

const AlertBody = styled.div`
  display: flex;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  margin: -108px 15px 20px;
  height: ${VOUCHER_POPUP_HEIGHT}px;
  border-radius: 25px;
  background-color: #1d1d1f;
  animation-duration: 5s;
  animation-name: scale;
  @keyframes scale {
    0% {
      transform: scale(0);
      margin-top: 0;
    }

    20% {
      transform: scale(1);
    }
    70% {
      transform: scale(1);
      margin-top: 0;
    }
    80% {
      margin-top: -108px;
      overflow: hidden;
    }
    100% {
      margin-top: -108px;
    }
  }
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const AlertText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0 15px;
  @media${devices.mobile} {
    font-size: 18px;
  }
`;

const VoucherBody = styled.div`
  display: flex;
  padding: 9px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.middleGray};
  cursor: pointer;
  &:last-child {
    border-bottom: 0;
  }
`;

const VoucherName = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;

  @media${devices.mobile} {
    width: 65%;
    font-size: 12px;
  }
`;

const VoucherValue = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: auto;
  white-space: nowrap;
  @media${devices.mobile} {
    font-size: 12px;
  }
`;

const getAnimationMargin = (length: number) => {
  const paddingHeight = 17;
  const elementHeight = 60;
  const marginTop =
    ((length + 1) * elementHeight + VOUCHER_POPUP_HEIGHT + paddingHeight) * -1;
  const marginBottom = VOUCHER_POPUP_HEIGHT + 66 + length * 20.1;
  return `${marginTop}px 15px ${marginBottom}px`;
};

const VoucherList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -20px 15px 20px;
  padding: 6px 17px 0 17px;
  background: #ffffff;
  border-radius: 0px 0px 20px 20px;
  animation-duration: 5s;
  animation-name: listMoveDown;
  @keyframes listMoveDown {
    0% {
      margin: ${(props: { length: number }) => `${getAnimationMargin(props.length)}`};
    }
    80% {
      margin: ${(props: { length: number }) => `${getAnimationMargin(props.length)}`};
    }

    100% {
      margin: -20px 15px 20px;
    }
  }
`;

const AnimationText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation-duration: 2s;
  animation-name: slideText;
  overflow: hidden;
  @keyframes slideText {
    0% {
      opacity: 0;
      margin-right: -150%;
    }

    15% {
      opacity: 0;
    }

    90% {
      opacity: 1;
    }

    100% {
      margin-right: 0;
    }
  }
`;

export const DiscountAlert = (props: { priceReducers: PriceReducer[] }) => {
  const { priceReducers } = props;
  const history = useHistory();
  const { t } = useTranslation(Namespaces.UI);
  const generateAlertText = () => {
    return (
      <>
        <span>{`${t('availableDiscounts')} `}</span>
        <BoldText>{t('discounts').toLocaleLowerCase()}</BoldText>
        <span>!</span>
      </>
    );
  };

  const renderDiscountValue = (elem: PriceReducer) => {
    if (elem.type === DISCOUNT_TYPE_MEMBERSHIP) {
      return (
        elem.discountPercentage && (
          <VoucherValue>{`${elem.discountPercentage}%`}</VoucherValue>
        )
      );
    }
    return <VoucherValue>{getPrice(elem.balance, elem.currency)}</VoucherValue>;
  };

  const renderVoucherListItem = (element: PriceReducer, index: number) => {
    return (
      <VoucherBody
        key={index}
        onClick={(e) => {
          e.stopPropagation();
          if (element.type === DISCOUNT_TYPE_MEMBERSHIP) {
            history.push(urls.memberships);
          } else {
            history.push(urls.vouchers);
          }
        }}
      >
        {element.type === DISCOUNT_TYPE_MEMBERSHIP ? (
          <CustomIcon width="20px" height="20px" margin="0 11px 0 0 " icon={Membership} />
        ) : (
          <CustomIcon width="20px" height="20px" icon={Voucher} margin="0 11px 0 0 " />
        )}
        <VoucherName title={element.name}>{element.name}</VoucherName>
        {renderDiscountValue(element)}
      </VoucherBody>
    );
  };
  return (
    <>
      <AlertBody>
        <AnimationText>
          <CustomIcon icon={Peace} width="26px" height="36px" />
          <AlertText>{generateAlertText()}</AlertText>
        </AnimationText>
      </AlertBody>
      <VoucherList length={priceReducers.length}>
        {priceReducers.map((el, ind) => {
          return renderVoucherListItem(el, ind);
        })}
      </VoucherList>
    </>
  );
};
