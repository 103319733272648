import styled from 'styled-components';
import voucherLeft from 'assets/icons/voucher-left.svg';
import voucherRight from 'assets/icons/voucher-right.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const IconDiv = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const IconMain = styled.div`
  background-color: #fd6c7c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 11px;
  color: ${({ theme }) => theme.colors.white};
`;

type VoucherProps = {
  text: string;
};

export const VoucherIcon = ({ text }: VoucherProps) => {
  return (
    <IconDiv>
      <CustomIcon width="3px" height="24px" margin="0 -1px 0 0" icon={voucherLeft} />
      <IconMain>{text}</IconMain>
      <CustomIcon width="3px" height="24px" margin="0 0 0 -1px" icon={voucherRight} />
    </IconDiv>
  );
};
