import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ConfirmInvoice } from 'pages/Payment/Invoice/ConfirmInvoice';
import { PaymentSpecification } from 'pages/Payment/PaymentSpecification';

import { Loader } from 'components/Loader/Loader';
import { Input } from 'components/Input';
import { getDefaultPhoneCountry } from 'helpers';
import { Namespaces } from 'languages';
import PhoneInput from 'react-phone-input-2';
import { devices } from 'constants/mediaConstants';
import { MANDATORY } from 'constants/checkoutConstants';

import { CheckoutStore } from 'store/CheckoutStore';
import { PaymentStore, TransactionInvoiceData } from 'store/PaymentStore';
import { HeaderStore } from 'store/HeaderStore';

import EditCircle from 'assets/icons/editCircle.svg';
import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';
import Check from 'assets/icons/checkBlack.svg';
import CheckSuccess from 'assets/icons/check.svg';

import Layout from 'styled/Layout';
import { Flex } from 'styled/Flex';
import { useLocation, useParams } from 'react-router';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { renderInputBorder } from 'helpers/StylesUtils';

const BillingBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 20px;
  padding: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  @media${devices.mobile} {
    padding-bottom: 25px;
  }
`;

const BillingHeader = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
`;

const Edit = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-image: url(${EditCircle});

  @media${devices.mobile} {
    width: 42px;
  }
`;

const InformationBlock = styled.div`
  padding: 45px 60px 26px 80px;

  @media${devices.mobile} {
    padding: 25px 20px 20px 20px;
  }
`;

const InformationHeader = styled.div`
  margin: 0 0 25px -10px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;

const TypeCheckbox = styled.div`
  color: ${(props: { active?: boolean }) => (props.active ? '#000000' : '#A6A6A6')};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
`;

const Toggle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 44px;
  height: 27px;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 30px;
  cursor: pointer;
`;

const ToggleElement = styled.div`
  position: absolute;
  left: ${(props: { active?: boolean }) => (props.active ? '20px' : '2px')};
  width: 22px;
  height: 22px;
  background: white;
  border-radius: 50%;
  transition: 0.3s;
`;

const InputWrap = styled.div`
  margin-top: ${(props: { size?: string }) => (props.size === 'sm' ? '20px' : '30px')};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  user-select: none;

  @media${devices.mobile} {
    margin-top: ${(props: { size?: string }) => (props.size === 'sm' ? '20px' : '30px')};
  }
`;

const ContactRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 11px 0 13px;
`;
const InputBlock = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 25px 17px 4px;
  color: #a6a6a6;
  background: ${(props: { error: string }) => (props.error ? '#FFFBFB' : '#ffffff')};
  border: ${(props: { error: string; focus: boolean }) =>
    renderInputBorder(props.focus, props.error)};
  border-radius: 10px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  .react-tel-input {
    input {
      font-family: ${({ theme }) => theme.fonts.sfProDisplay};
      background: ${(props: { error: string }) => (props.error ? '#FFFBFB' : '#ffffff')};
    }
  }
`;

const ErrorMessage = styled.div`
  margin: -10px 0 0 25px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

const RequiredCircle = styled.div`
  position: absolute;
  right: -20px;
  width: 10px;
  height: 10px;
  margin-left: 9px;
  background: #fc8575;
  border-radius: 50%;
`;

const InputHeader = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  color: #a6a6a6;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;

const DropdownInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 55px;
  margin-top: 11px;
  padding: 0 24px 0 17px;
  border-radius: 10px;
  background: ${(props: { error: string }) => (props.error ? '#FFFBFB' : '#ffffff')};
  border: ${(props: { error: string }) =>
    props.error ? '1px solid #FF033D' : '1px solid #DCDCDE'};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  user-select: none;
  cursor: pointer;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
`;
const DropdownBody = styled.div`
  position: absolute;
  top: 105%;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 5px 0;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
`;
const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  padding: 0 20px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 57px;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.lightGray};
  }
`;

const DropdownError = styled.div`
  position: absolute;
  bottom: -25px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

const CheckSuccessIcon = styled.div`
  position: absolute;
  right: -25px;
  width: 14px;
  height: 12px;
  background: url(${CheckSuccess});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

export const InvoicePayment = observer(() => {
  const { t } = useTranslation(Namespaces.UI);
  const [activeCountryDropdown, setActiveCountryDropdown] = useState(false);
  const [isCompanyInvoice, setIsCompanyInvoice] = useState(false);
  const [country, setCountry] = useState('');
  const [company, setCompany] = useState('');
  const [identityNumber, setIdentityNumber] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [invoiceReference, setInvoiceReference] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [postal, setPostal] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [focus, setFocus] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [verified, setVerified] = useState<{ [key: string]: boolean }>({});
  const [allowedCountryData, setAllowedCountryData] = useState<{ [key: string]: string }>(
    {}
  );
  const [loading, setLoader] = useState(false);
  const { id: checkoutId } = useParams<{ id: string }>();
  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  ) as string;
  const {
    checkout,
    getCheckout,
    setCheckout,
    getStorageCheckoutData,
    createInvoiceTransaction,
  } = CheckoutStore;
  const { transactionInfo, getTransaction } = PaymentStore;
  const { setBackButton } = HeaderStore;
  const transactionData = toJS(transactionInfo);
  const checkoutData = toJS(checkout);

  useEffect(() => {
    getStorageCheckoutData();
  }, [getStorageCheckoutData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!transactionData) {
      // No transaction data probably because of refresh.
      // Fetch checkout info (mostly for phone number in transaction request) and create a new transaction
      getCheckout(checkoutId, transactionLinkHash, () => {
        createInvoiceTransaction(checkoutId, transactionLinkHash);
      });
    }
  }, [
    createInvoiceTransaction,
    getCheckout,
    checkoutId,
    transactionData,
    transactionLinkHash,
  ]);

  const autoPopulateFields = useCallback(
    (data: TransactionInvoiceData) => {
      const {
        email: userEmail,
        firstName: userName,
        phoneNumber: userNumber,
        surname,
      } = data;
      const verifiedFields = {
        email: false,
        firstName: false,
        phoneNumber: false,
        lastName: false,
        country: true,
      };
      if (userEmail) {
        setEmail(userEmail);
        verifiedFields.email = true;
      }
      if (userName) {
        setFirstName(userName);
        verifiedFields.firstName = true;
      }
      if (userNumber || checkoutData?.phoneNumber) {
        setPhoneNumber(userNumber || checkoutData?.phoneNumber || '');
        verifiedFields.phoneNumber = true;
      }
      if (surname) {
        setLastName(surname);
        verifiedFields.lastName = true;
      }
      if (transactionData?.invoiceData?.allowedCountries?.length && !country) {
        setCountry(transactionData?.invoiceData.allowedCountries[0].name);
      }
      setVerified(verifiedFields);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactionData?.invoiceData?.allowedCountries?.length, country]
  );

  useEffect(() => {
    setBackButton(true);
    if (transactionInfo?.invoiceData) {
      autoPopulateFields(toJS(transactionInfo.invoiceData));
    }
  }, [transactionInfo, getTransaction, setCheckout, setBackButton, autoPopulateFields]);

  useEffect(() => {
    const transactionInfoData = toJS(transactionInfo);
    if (transactionInfoData?.invoiceData) {
      const newData = transactionInfoData.invoiceData.allowedCountries.filter((el) => {
        return el.name === country;
      });
      setAllowedCountryData(newData[0]);
      setCompany('');
      setIdentityNumber('');
      setCompanyNumber('');
      setInvoiceReference('');
    }
    return () => {
      setAllowedCountryData({});
    };
  }, [country, transactionInfo]);

  if (!checkoutData || !transactionData?.invoiceData) {
    return <Loader />;
  }

  const { phoneNumberMode } = checkoutData;
  const { allowedCountries } = transactionData.invoiceData;

  const clearFields = () => {
    setIdentityNumber('');
    setCompanyNumber('');
    setInvoiceReference('');
  };

  const validateFields = (companyNumberRegex: RegExp, identityNumberRegex: RegExp) => {
    let scrolled = false;
    const newErrors: { [key: string]: string } = {};
    const newVerified: { [key: string]: boolean } = {
      country: true,
      company: true,
      companyNumber: true,
      invoiceReference: true,
      firstName: true,
      lastName: true,
      phoneNumber: true,
      email: true,
      postal: true,
      street: true,
      city: true,
      identityNumber: true,
    };
    let isValid = true;
    if (!country.length) {
      newErrors.country = t('errorCountry');
      newVerified.country = false;
      document.getElementById('country')?.scrollIntoView();
      scrolled = true;
      isValid = false;
    }

    if (!firstName.length) {
      newErrors.firstName = t('errorFirstName');
      newVerified.firstName = false;
      isValid = false;
      if (!scrolled) {
        document.getElementById('firstName')?.scrollIntoView();
        scrolled = true;
      }
    }
    if (!lastName.length) {
      newErrors.lastName = t('errorLastName');
      newVerified.lastName = false;
      if (!scrolled) {
        document.getElementById('lastName')?.scrollIntoView();
        scrolled = true;
      }
      isValid = false;
      scrolled = true;
    }
    if (!phoneNumber.length) {
      newErrors.phoneNumber = t('errorPhoneNumber');
      newVerified.phoneNumber = false;
      if (!scrolled) {
        document.getElementById('phoneNumber')?.scrollIntoView();
        scrolled = true;
      }
      isValid = false;
      scrolled = true;
    }
    if (!email.length) {
      newErrors.email = t('errorEmailField');
      newVerified.email = false;
      if (!scrolled) {
        document.getElementById('email')?.scrollIntoView();
        scrolled = true;
      }
      isValid = false;
      scrolled = true;
    }
    if (!postal.length) {
      newErrors.postal = t('errorPostal');
      newVerified.postal = false;
      if (!scrolled) {
        document.getElementById('postal')?.scrollIntoView();
        scrolled = true;
      }
      isValid = false;
      scrolled = true;
    }
    if (!street.length) {
      newErrors.street = t('errorStreet');
      newVerified.street = false;
      if (!scrolled) {
        document.getElementById('street')?.scrollIntoView();
        scrolled = true;
      }
      isValid = false;
      scrolled = true;
    }
    if (!city.length) {
      newErrors.city = t('errorCity');
      newVerified.city = false;
      if (!scrolled) {
        document.getElementById('city')?.scrollIntoView();
        scrolled = true;
      }
      isValid = false;
      scrolled = true;
    }
    if (isCompanyInvoice) {
      if (!company.length) {
        newErrors.company = t('errorCompany');
        newVerified.company = false;
        if (!scrolled) {
          document.getElementById('company')?.scrollIntoView();
          scrolled = true;
        }
        isValid = false;
        scrolled = true;
      }
      if (!companyNumber.length) {
        newErrors.companyNumber = t('errorCompanyNumber');
        newVerified.companyNumber = false;
        if (!scrolled) {
          document.getElementById('companyNumber')?.scrollIntoView();
          scrolled = true;
        }
        isValid = false;
      } else if (!companyNumberRegex.test(companyNumber)) {
        newErrors.companyNumber = t('errorCompanyNumberField');
        newVerified.companyNumber = false;
        if (!scrolled) {
          document.getElementById('companyNumber')?.scrollIntoView();
          scrolled = true;
        }
        isValid = false;
      }
    } else if (!isCompanyInvoice && !identityNumber.length) {
      newErrors.identityNumber = t('errorIdentityNumber');
      newVerified.identityNumber = false;
      if (!scrolled) {
        document.getElementById('identityNumber')?.scrollIntoView();
        scrolled = true;
      }
      isValid = false;
      scrolled = true;
    } else if (!isCompanyInvoice && !identityNumberRegex.test(identityNumber)) {
      newErrors.identityNumber = t('errorCompanyNumberField');
      newVerified.identityNumber = false;
      if (!scrolled) {
        document.getElementById('identityNumber')?.scrollIntoView();
        scrolled = true;
      }
      isValid = false;
      scrolled = true;
    }
    setVerified(newVerified);
    setErrors(newErrors);
    return isValid;
  };

  const getConfirmDisable = () => {
    return !!Object.values(errors).filter((error) => error !== '').length;
  };

  const identityNumberRegex = new RegExp(allowedCountryData?.personalIdentityNumberRegex);
  const companyNumberRegex = new RegExp(allowedCountryData?.companyNumberRegex);

  const setValueToMask = (
    value: string,
    mask: string,
    prevValue: string,
    setValue: (test: string) => void
  ) => {
    const separator = mask.split('').find((el) => el !== mask[0]);
    if (separator && mask.length > value.length - 1) {
      const separatorPosition = mask.indexOf(separator);
      let newValue = '';
      for (let i = 0; i < value.length; i++) {
        if (prevValue.includes(separator) && !value.includes(separator)) {
          return;
        }
        if (i === separatorPosition && prevValue.length < value.length) {
          newValue += separator;
        }
        if (value[i] !== separator) {
          newValue += value[i];
        }
        if (
          i === separatorPosition &&
          prevValue.length > value.length &&
          value.length - 1 > separatorPosition
        ) {
          newValue += separator;
        }
      }
      setValue(newValue);
    } else if (mask.length > value.length - 1) {
      setValue(value);
    }
  };

  const removeError = (error: string) => {
    setErrors({ ...errors, [error]: '' });
  };

  const renderCompanyFields = () => {
    return (
      <InputWrap size="sm">
        {t('invoiceCompanyInfoTitle')}
        <Input
          id="company"
          verified={verified.company}
          value={company}
          title={t('invoiceCompanyFieldTitle')}
          disabled={loading}
          error={errors.company}
          change={(value: string) => {
            removeError('company');
            setCompany(value);
            if (!value) {
              setVerified({ ...verified, company: false });
            } else if (value.length > 0) {
              setVerified({ ...verified, company: true });
            }
          }}
          required
        />
        <Input
          id="companyNumber"
          verified={verified.companyNumber}
          value={companyNumber}
          title={t('invoiceCompanyNumberFieldTitle')}
          disabled={loading}
          error={errors.companyNumber}
          change={(value: string) => {
            removeError('companyNumber');
            setValueToMask(
              value,
              allowedCountryData?.companyNumberMask,
              companyNumber,
              setCompanyNumber
            );
            if (companyNumberRegex.test(value)) {
              setVerified({ ...verified, companyNumber: true });
            } else {
              setVerified({ ...verified, companyNumber: false });
            }
          }}
          required
          placeholder={allowedCountryData?.companyNumberPlaceHolder}
        />
        <Input
          value={invoiceReference}
          title={t('invoiceCompanyReferenceFieldTitle')}
          disabled={loading}
          error={errors.invoiceReference}
          change={(value: string) => {
            removeError('invoiceReference');
            setInvoiceReference(value);
            if (!value) {
              setVerified({ ...verified, invoiceReference: false });
            } else if (value.length > 0) {
              setVerified({ ...verified, invoiceReference: true });
            }
          }}
        />
      </InputWrap>
    );
  };
  const renderIndividualFields = () => {
    return (
      <InputWrap size="sm">
        {t('invoicePersonalIdentityNumberTitle')}
        <Input
          id="identityNumber"
          verified={verified.identityNumber}
          value={identityNumber}
          title={t('invoicePersonalIdentityNumberFieldTitle')}
          disabled={loading}
          error={errors.identityNumber}
          change={(value: string) => {
            removeError('identityNumber');
            setValueToMask(
              value,
              allowedCountryData?.personalIdentityNumberMask,
              identityNumber,
              setIdentityNumber
            );
            if (identityNumberRegex.test(value)) {
              setVerified({ ...verified, identityNumber: true });
            } else {
              setVerified({ ...verified, identityNumber: false });
            }
          }}
          required
          placeholder={allowedCountryData?.personalIdentityNumberPlaceHolder}
        />
      </InputWrap>
    );
  };
  return (
    <Layout>
      <PaymentSpecification
        transactionData={transactionData}
        checkoutData={checkoutData}
      />
      <BillingBlock>
        <BillingHeader>
          <Edit />
          {t('invoiceBillingTitle')}
        </BillingHeader>
        <InformationBlock>
          <InformationHeader>{t('invoiceTypeTitle')}</InformationHeader>
          <Flex alignItems="center" justifyContent="space-between" width="220px">
            <TypeCheckbox active={!isCompanyInvoice}>
              {t('invoiceTypeIndividual')}
            </TypeCheckbox>
            <Toggle
              onClick={() => {
                if (!loading) {
                  setIsCompanyInvoice(!isCompanyInvoice);
                  setErrors({});
                  setVerified({});
                  clearFields();
                  autoPopulateFields(
                    transactionData.invoiceData as TransactionInvoiceData
                  );
                }
              }}
            >
              <ToggleElement active={isCompanyInvoice} />
            </Toggle>
            <TypeCheckbox active={isCompanyInvoice}>
              {t('invoiceTypeCompany')}
            </TypeCheckbox>
          </Flex>
          <InputWrap>
            {t('invoiceCountryTitle')}
            <DropdownInput
              onClick={() => {
                if (!loading) {
                  setActiveCountryDropdown(!activeCountryDropdown);
                }
              }}
              error={errors.country}
            >
              <DropdownHeader>
                {country || t('invoiceCountryTitle')}
                {activeCountryDropdown ? (
                  <CustomIcon width="14px" height="8px" icon={ArrowUp} cursor hover />
                ) : (
                  <CustomIcon width="14px" height="8px" icon={ArrowDown} cursor hover />
                )}
              </DropdownHeader>
              {activeCountryDropdown && (
                <DropdownBody>
                  {allowedCountries.map(
                    (el: { name: string; code: string }, index: number) => (
                      <DropdownItem
                        key={index}
                        onClick={() => {
                          removeError('country');
                          setCountry(el.name);
                          setVerified({ ...verified, country: true });
                        }}
                      >
                        {el.name}
                        {country === el.name ? (
                          <CustomIcon
                            width="14px"
                            height="11px"
                            icon={Check}
                            cursor
                            hover
                          />
                        ) : null}
                      </DropdownItem>
                    )
                  )}
                </DropdownBody>
              )}
              {!verified.country ? <RequiredCircle /> : <CheckSuccessIcon />}
              {errors.country && <DropdownError>{`*${errors.country}`}</DropdownError>}
            </DropdownInput>
          </InputWrap>

          {isCompanyInvoice ? renderCompanyFields() : renderIndividualFields()}
          <InputWrap>
            {t('accountName')}
            <Input
              id="firstName"
              verified={verified.firstName}
              value={firstName}
              title={t('firstName')}
              disabled={loading}
              error={errors.firstName}
              change={(value: string) => {
                removeError('firstName');
                setFirstName(value);
                if (!value) {
                  setVerified({ ...verified, firstName: false });
                } else if (value.length > 0) {
                  setVerified({ ...verified, firstName: true });
                }
              }}
              required
            />
            <Input
              id="surname"
              verified={verified.lastName}
              value={lastName}
              change={(value: string) => {
                removeError('lastName');
                setLastName(value);
                if (!value) {
                  setVerified({ ...verified, lastName: false });
                } else if (value.length > 0) {
                  setVerified({ ...verified, lastName: true });
                }
              }}
              title={t('surname')}
              disabled={loading}
              error={errors.lastName}
              required
            />
          </InputWrap>
          <InputWrap>
            {t('phone')}
            <>
              <ContactRow>
                <InputHeader>{t('phoneNumber')}</InputHeader>
                <InputBlock error={errors.phoneNumber} focus={focus}>
                  <PhoneInput
                    inputStyle={{ color: '#181818' }}
                    disabled={loading}
                    country={getDefaultPhoneCountry()}
                    value={phoneNumber}
                    onChange={(phone) => {
                      removeError('phoneNumber');
                      setPhoneNumber(phone);
                      if (phone.length > 0) {
                        setVerified({ ...verified, phoneNumber: true });
                      } else {
                        setVerified({ ...verified, phoneNumber: false });
                      }
                    }}
                    onFocus={() => {
                      setFocus(true);
                    }}
                    onBlur={() => {
                      setFocus(false);
                    }}
                    masks={{ se: '.. ... .. ..' }}
                  />
                </InputBlock>
                {phoneNumberMode === MANDATORY && !verified.phoneNumber ? (
                  <RequiredCircle />
                ) : (
                  <CheckSuccessIcon />
                )}
              </ContactRow>
              {errors.phoneNumber && (
                <ErrorMessage>{`*${errors.phoneNumber}`}</ErrorMessage>
              )}
            </>
          </InputWrap>
          <InputWrap>
            {t('email')}
            <Input
              id="email"
              verified={verified.email}
              value={email}
              title={t('email')}
              disabled={loading}
              error={errors.email}
              change={(value: string) => {
                removeError('email');
                setEmail(value);
                if (!value) {
                  setVerified({ ...verified, email: false });
                } else {
                  setVerified({
                    ...verified,
                    email: true,
                  });
                }
              }}
              required
            />
          </InputWrap>
          <InputWrap>
            {t('invoiceBillingAddressTitle')}
            <Input
              id="street"
              verified={verified.street}
              value={street}
              title={t('invoiceStreetFieldTitle')}
              disabled={loading}
              error={errors.street}
              change={(value: string) => {
                removeError('street');
                setStreet(value);
                if (!value) {
                  setVerified({ ...verified, street: false });
                } else if (value.length > 0) {
                  setVerified({ ...verified, street: true });
                }
              }}
              required
            />
            <Input
              id="postal"
              verified={verified.postal}
              value={postal}
              title={t('invoicePostalFieldTitle')}
              disabled={loading}
              error={errors.postal}
              change={(value: string) => {
                removeError('postal');
                setPostal(value);
                if (!value) {
                  setVerified({ ...verified, postal: false });
                } else if (value.length > 0) {
                  setVerified({ ...verified, postal: true });
                }
              }}
              required
            />
            <Input
              id="city"
              verified={verified.city}
              value={city}
              title={t('invoiceCityFieldTitle')}
              disabled={loading}
              error={errors.city}
              change={(value: string) => {
                removeError('city');
                setCity(value);
                if (!value) {
                  setVerified({ ...verified, city: false });
                } else if (value.length > 0) {
                  setVerified({ ...verified, city: true });
                }
              }}
              required
            />
          </InputWrap>
          <ConfirmInvoice
            disabled={getConfirmDisable()}
            transactionData={transactionData}
            isIndividual={!isCompanyInvoice}
            checkIsValid={() => validateFields(companyNumberRegex, identityNumberRegex)}
            loading={loading}
            setLoader={setLoader}
            fieldsData={{
              country,
              company,
              identityNumber,
              companyNumber,
              invoiceReference,
              firstName,
              lastName,
              phoneNumber,
              email,
              postal,
              street,
              city,
            }}
          />
        </InformationBlock>
      </BillingBlock>
    </Layout>
  );
});
