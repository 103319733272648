import { useState, useRef, useEffect } from 'react';

import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';
import { getPrice, urls } from 'helpers';
import { useHistory } from 'react-router-dom';

import { devices } from 'constants/mediaConstants';

import { Addon } from 'store/CheckoutStore';
import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';
import CheckCircle from 'assets/icons/Check-circle.svg';

import {
  AddonCard,
  AddonHeaderContainer,
  AddonTitle,
  AddonPrice,
  AddonDescription,
  AddonDescriptionShort,
  AddonAddButton,
} from './AddonComponents';

export const CheckoutAppointmentAddon = observer(
  (props: { addon: Addon; onAddAddonClicked: () => void }) => {
    const { addon, onAddAddonClicked } = props;
    const { currency, name, price, isAdded, description } = addon;
    const history = useHistory();
    const { profileId } = useParams<{ profileId: string }>();
    const [isCollapsed, setCollapsed] = useState(false);
    const addonRef = useRef(null);
    const [maxWidthString, setMaxWidthString] = useState(0);
    const { t } = useTranslation(Namespaces.UI);

    useEffect(() => {
      if (addonRef && addonRef.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-next-line
        setMaxWidthString(addonRef.current.clientWidth / 7);
      }
    }, []);

    return (
      <AddonCard isCollapsed={!description} disabled={isAdded} ref={addonRef}>
        <AddonHeaderContainer>
          <AddonTitle>{name}</AddonTitle>
          <AddonPrice>{getPrice(price, currency)}</AddonPrice>
        </AddonHeaderContainer>
        {description && (
          <>
            {isCollapsed ? (
              <AddonDescription>{description}</AddonDescription>
            ) : (
              <AddonDescriptionShort>{description}</AddonDescriptionShort> || null
            )}
          </>
        )}

        <Flex justifyContent="flex-end">
          {!isAdded ? (
            <AddonAddButton
              onClick={() => {
                history.push(urls.service(profileId, `${addon.serviceId}`));
                onAddAddonClicked();
              }}
            >
              {t('add')}
            </AddonAddButton>
          ) : (
            <CustomIcon
              width="60px"
              height="60px"
              margin="0 15px 0 0"
              icon={CheckCircle}
              device={devices.mobile}
              deviceHeight="40px"
              deviceWidth="40px"
            />
          )}
        </Flex>
        {description && description.length > maxWidthString && (
          <CustomIcon
            width="20px"
            height="12px"
            margin="22px auto 10px"
            icon={isCollapsed ? ArrowUp : ArrowDown}
            onClick={(e) => {
              if (e) {
                e.stopPropagation();
                setCollapsed(!isCollapsed);
              }
            }}
            hover
            cursor
          />
        )}
      </AddonCard>
    );
  }
);
