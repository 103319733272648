import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Loader } from 'components/Loader/Loader';
import { AccountPage } from './styles';
import { useAccountSettings } from 'hooks/useAccountSettings';
import { DeleteAccountModal } from './DeleteAccountModal';
import { AccountForm } from './AccountForm';
import { Namespaces } from 'languages';
import { useTranslation } from 'react-i18next';
import { RootPageTitle } from 'components/RootPageTitle';

export const Account = observer(() => {
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const { t } = useTranslation(Namespaces.UI);
  const { deleteAccount, user } = useAccountSettings(true);

  const handleDeleteAccount = useCallback(() => {
    setShowDeleteAccountModal(false);
    deleteAccount();
  }, [deleteAccount]);

  return !user ? (
    <Loader />
  ) : (
    <AccountPage data-testid="account">
      <RootPageTitle title={t('accountTitle')} />
      <AccountForm setShowDeleteAccountModal={setShowDeleteAccountModal} />
      <DeleteAccountModal
        onClose={() => setShowDeleteAccountModal(false)}
        show={showDeleteAccountModal}
        action={handleDeleteAccount}
      />
    </AccountPage>
  );
});
