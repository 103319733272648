export type Colors = Record<string, string>;
export type Fonts = Record<string, string>;
export type AppTheme = Record<string, string>;

export type Palette = {
  main: string;
  contrastText: string;
};

export interface Theme {
  colors: Colors;
  fonts: Fonts;
  appTheme: AppTheme;
}

const colors = {
  white: '#ffffff',
  lightGray: '#f5f5f5',
  middleLightGray: '#6F6F6F',
  middleGray: '#dcdcdc',
  darkGray: '#a6a6a6',
  lightBlack: '#2d2d2d',
  middleDark: '#333333',
  darkBlack: '#181818',
  darkBlack2: '#2e2e2e',
  black: '#000000',
  green: '#18ca99',
  peach: '#fc8575',
  yellow: '#FFA832',
  red: '#ff033d',
  whiteRed: '#FD2B55',
};

export const theme: Theme = {
  colors,
  fonts: {
    sfProDisplay: 'SF Pro Display',
    sfProText: 'SF Pro Text',
  },
  appTheme: {
    primaryButtonBackground: colors.darkBlack,
    primaryButtonTextColor: colors.white,
    secondaryButtonBackground: colors.white,
    secondaryButtonTextColor: colors.darkBlack,
  },
};
