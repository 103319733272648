import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { getPrice } from 'helpers';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/Loader/Loader';
import { devices } from 'constants/mediaConstants';

import { Namespaces } from 'languages';
import { Addon, CheckoutStore } from 'store/CheckoutStore';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';
import CheckCircle from 'assets/icons/Check-circle.svg';

import { Flex } from 'styled/Flex';

import {
  AddonCard,
  AddonHeaderContainer,
  AddonTitle,
  AddonPrice,
  AddonDescription,
  AddonDescriptionShort,
  AddonAddButton,
} from './AddonComponents';

export const CheckoutMembershipAddon = observer(
  (props: {
    addon: Addon;
    onAddAddonClicked: () => void;
    transactionLinkHash: string | null;
  }) => {
    const { addon, onAddAddonClicked, transactionLinkHash } = props;
    const { currency, id, name, price, isAdded, description } = addon;
    const { checkoutId } = useParams<{ checkoutId: string }>();
    const [loading, setLoading] = useState(false);
    const [isCollapsed, setCollapsed] = useState(false);
    const { addAddonToCheckout } = CheckoutStore;
    const addonRef = useRef(null);
    const [maxWidthString, setMaxWidthString] = useState(0);
    const { t } = useTranslation(Namespaces.UI);
    const getRequestData = () => {
      return [
        {
          id,
        },
      ];
    };
    useEffect(() => {
      if (addonRef && addonRef.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-next-line
        setMaxWidthString(addonRef.current.clientWidth / 7);
      }
    }, []);
    return (
      <AddonCard isCollapsed={!description} disabled={isAdded} ref={addonRef}>
        <AddonHeaderContainer>
          <AddonTitle>{name}</AddonTitle>
          <AddonPrice>{getPrice(price, currency)}</AddonPrice>
        </AddonHeaderContainer>
        {description && (
          <>
            {isCollapsed ? (
              <AddonDescription>{description}</AddonDescription>
            ) : (
              <AddonDescriptionShort>{description}</AddonDescriptionShort> || null
            )}
          </>
        )}
        <Flex justifyContent="flex-end">
          {!isAdded ? (
            <AddonAddButton
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  addAddonToCheckout(
                    checkoutId,
                    getRequestData(),
                    transactionLinkHash,
                    setLoading
                  );
                  onAddAddonClicked();
                }
              }}
            >
              {loading ? <Loader isWhite small /> : t('add')}
            </AddonAddButton>
          ) : (
            <CustomIcon
              width="60px"
              height="60px"
              margin="0 15px 0 0"
              icon={CheckCircle}
              device={devices.mobile}
              deviceHeight="40px"
              deviceWidth="40px"
            />
          )}
        </Flex>
        {description && description.length > maxWidthString && (
          <CustomIcon
            width="20px"
            height="12px"
            margin="0 auto"
            icon={isCollapsed ? ArrowUp : ArrowDown}
            onClick={(e) => {
              if (e) {
                e.stopPropagation();
                setCollapsed(!isCollapsed);
              }
            }}
            hover
            cursor
          />
        )}
      </AddonCard>
    );
  }
);
