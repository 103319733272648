import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';

import AlertImage from 'assets/icons/alert.svg';
import { PrimaryButton } from 'styled/PrimaryButton';
import { ErrorStore } from 'store/ErrorStore';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const ErrorBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  padding: 50px 100px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;

  @media${devices.mobile} {
    padding: 20px;
    margin: 15px;
  }
`;

const BoldText = styled.div`
  margin-top: 33px;
  color: ${({ theme }) => theme.colors.red};
  font-weight: bold;
  font-size: 22px;
  line-height: 38px;
  text-align: center;
  @media${devices.mobile} {
    font-size: 18px;
    margin: 15px;
  }
`;

const GoButton = styled(PrimaryButton)`
  width: 200px;
  margin: 40px auto 0;

  @media${devices.mobile} {
    margin: 20px auto 0;
    width: 200px;
  }
`;

type ErrorProps = {
  errorMessage?: string;
  buttonName?: string;
  callback?: () => void;
};

export const Error = (props: ErrorProps) => {
  const { errorMessage, buttonName = 'ok', callback } = props;
  const { t } = useTranslation(Namespaces.UI);
  const { removeError } = ErrorStore;
  useEffect(
    () => () => {
      removeError();
    },
    [removeError]
  );
  return (
    <ErrorBlock>
      <CustomIcon
        width="72px"
        height="72px"
        margin="0 auto"
        icon={AlertImage}
        device={devices.mobile}
        deviceHeight="52px"
        deviceWidth="52px"
      />
      <BoldText>{errorMessage || t('errorMessageDefault')}</BoldText>
      {callback && <GoButton onClick={() => callback()}>{t(buttonName)}</GoButton>}
    </ErrorBlock>
  );
};
