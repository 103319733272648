import { makeAutoObservable, runInAction } from 'mobx';
import { callApi } from 'helpers';

type CalendarSettings = {
  disabledDates: string[];
  defaultSelectedDate: string | null;
  hasAvailableDates: boolean;
  isRangeAllowed: boolean;
  noAvailabilityText: string | null;
  minRangeDays: number | null;
  maxRangeDays: number | null;
};

class Calendar {
  isLoading = false;

  calendarSettings: CalendarSettings = {
    disabledDates: [],
    defaultSelectedDate: null,
    hasAvailableDates: false,
    isRangeAllowed: false,
    noAvailabilityText: null,
    minRangeDays: null,
    maxRangeDays: null,
  };

  selectedDates: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getCalendarSettings = async (
    serviceId: number,
    start: string,
    end: string,
    callback?: (calendarSettings: CalendarSettings) => void
  ) => {
    runInAction(() => {
      this.isLoading = true;
    });

    const res = await callApi(
      `v1/appointments/${serviceId}/calendar?startTime=${start}&endTime=${end}`,
      {
        headers: {
          'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }
    );

    const calendarSettings = await res.json();
    this.setCalendarSettings(calendarSettings);

    if (callback) {
      callback(calendarSettings);
    }

    runInAction(() => {
      this.isLoading = false;
    });
  };

  setCalendarSettings = (calendarSettings: CalendarSettings) => {
    this.calendarSettings = calendarSettings;
  };

  setCalendarSelectedDates = (dates: string[]) => {
    this.selectedDates = dates;
  };

  clearCalendar = () => {};
}

export const CalendarStore = new Calendar();
