export type PageViewEvent = {
  id: string;
  name: string;
  type: string;
};

export type CTAEvent = {
  id: string;
  name: string;
  type: string;
  pageId: string;
  pageName: string;
};

// Page events
export const PAGE_VIEW_TYPE = 'pageView';

export const PAGE_ID_SERVICE_LIST = 'serviceList';
export const PAGE_ID_SERVICE_DETAILS = 'serviceDetails';
export const PAGE_ID_CHECKOUT = 'checkout';
export const PAGE_ID_CHECKOUT_SWISH_PAYMENT = 'checkoutSwishPayment';
export const PAGE_ID_CHECKOUT_CARD_PAYMENT = 'checkoutCardPayment';
export const PAGE_ID_CHECKOUT_INVOICE_PAYMENT = 'checkoutInvoicePayment';
export const PAGE_ID_CHECKOUT_COMPLETED = 'checkoutCompleted';

export const PAGE_NAME_SERVICE_LIST = 'Agendo - Service list';
export const PAGE_NAME_SERVICE_DETAILS = 'Agendo - Service details';
export const PAGE_NAME_CHECKOUT = 'Agendo - Checkout';
export const PAGE_NAME_CHECKOUT_SWISH_PAYMENT = 'Agendo - Checkout swish payment';
export const PAGE_NAME_CHECKOUT_CARD_PAYMENT = 'Agendo - Checkout card payment';
export const PAGE_NAME_CHECKOUT_INVOICE_PAYMENT = 'Agendo - Checkout invoice payment';
export const PAGE_NAME_CHECKOUT_COMPLETED = 'Agendo - Checkout completed';

// CTA events
export const CTA_TYPE_CLICK = 'click';

export const CTA_ID_SERVICE_DETAILS_ADD_BOOKING = 'serviceDetailsAddBooking';

export const CTA_NAME_SERVICE_DETAILS_ADD_BOOKING = 'Agendo - Add booking';

export const getPageViewEvent = (url: string) => {
  let pageViewEvent = {} as PageViewEvent;
  pageViewEvent.type = PAGE_VIEW_TYPE;
  if (url.includes('services')) {
    if (!url.includes('services/')) {
      pageViewEvent.id = PAGE_ID_SERVICE_LIST;
      pageViewEvent.name = PAGE_NAME_SERVICE_LIST;
    } else {
      pageViewEvent.id = PAGE_ID_SERVICE_DETAILS;
      pageViewEvent.name = PAGE_NAME_SERVICE_DETAILS;
    }
  } else if (url.includes('checkout')) {
    if (url.includes('swish')) {
      pageViewEvent.id = PAGE_ID_CHECKOUT_SWISH_PAYMENT;
      pageViewEvent.name = PAGE_NAME_CHECKOUT_SWISH_PAYMENT;
    } else if (url.includes('card')) {
      pageViewEvent.id = PAGE_ID_CHECKOUT_CARD_PAYMENT;
      pageViewEvent.name = PAGE_NAME_CHECKOUT_CARD_PAYMENT;
    } else if (url.includes('invoice')) {
      pageViewEvent.id = PAGE_ID_CHECKOUT_INVOICE_PAYMENT;
      pageViewEvent.name = PAGE_NAME_CHECKOUT_INVOICE_PAYMENT;
    } else {
      pageViewEvent.id = PAGE_ID_CHECKOUT;
      pageViewEvent.name = PAGE_NAME_CHECKOUT;
    }
  } else if (url.includes('confirmation')) {
    pageViewEvent.id = PAGE_ID_CHECKOUT_COMPLETED;
    pageViewEvent.name = PAGE_NAME_CHECKOUT_COMPLETED;
  }

  return pageViewEvent.id ? pageViewEvent : null;
};

export const getCTAEvent = (
  type: string,
  id: string,
  name: string,
  pageId: string,
  pageName: string
) => {
  let event = {} as CTAEvent;
  event.type = type;
  event.id = id;
  event.name = name;
  event.pageId = pageId;
  event.pageName = pageName;
  return event;
};

export const postPageViewEventMessage = (event: PageViewEvent) => {
  window.parent.postMessage(event, getPostMessageTargetOrigin());
};

export const postCTAEventMessage = (event: CTAEvent) => {
  window.parent.postMessage(event, getPostMessageTargetOrigin());
};

export const getAddBookingCTAEvent = () => {
  return {
    type: CTA_TYPE_CLICK,
    id: CTA_ID_SERVICE_DETAILS_ADD_BOOKING,
    name: CTA_NAME_SERVICE_DETAILS_ADD_BOOKING,
    pageId: PAGE_ID_SERVICE_DETAILS,
    pageName: PAGE_NAME_SERVICE_DETAILS,
  } as CTAEvent;
};

const getPostMessageTargetOrigin = () => {
  let origin =
    window.location != window.parent.location
      ? document.referrer
      : document.location.href;

  if (!origin) {
    origin = '*';
  }

  return origin;
};
