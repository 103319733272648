/* eslint-disable prefer-template */
import { Operation } from 'fast-json-patch';
import { getBrowserLanguage } from 'languages';
import { CheckoutInfo } from 'store/CheckoutStore';

const API_URL = process.env.REACT_APP_API_URL || '';

export const urls = {
  companies: '/',
  services: (profileId: string | number) => `/profiles/${profileId}/services`,
  service: (profileId: string, serviceId: string) =>
    `${urls.services(profileId)}/${serviceId}`,
  auth: '/auth',
  profiles: '/profiles',
  forgotPassword: '/forgotPassword',
  appleRedirect: '/auth/apple/login-redirect',
  checkout: (profileId: string | number, checkoutId: string | number) =>
    `/profiles/${profileId}/checkout/${checkoutId}`,
  confirmation: '/confirmation',
  invoice: (id: string | number) => `/checkout/${id}/invoice`,
  invoiceWithTransactionLinkHash: (id: string | number, transactionLinkHash: string) =>
    `/checkout/${id}/invoice?transactionLinkHash=${transactionLinkHash}`,
  cardPayment: (id: string | number) => `/checkout/${id}/card`,
  cardPaymentWithTransactionLinkHash: (
    id: string | number,
    transactionLinkHash: string
  ) => `/checkout/${id}/card?transactionLinkHash=${transactionLinkHash}`,
  swishPayment: (id: string | number) => `/checkout/${id}/swish`,
  swishPaymentWithTransactionLinkHash: (
    id: string | number,
    transactionLinkHash: string
  ) => `/checkout/${id}/swish?transactionLinkHash=${transactionLinkHash}`,
  resetPassword: '/auth/reset-password',
  account: '/account',
  bookings: '/bookings',
  waitingList: '/waiting-lists',
  payments: '/payments',
  subscriptions: '/subscriptions',
  subscriptionChangeCard: (id: string | number) => `/subscriptions/${id}/change-card`,
  vouchers: '/vouchers',
  memberships: '/memberships',
  themeEditor: '/themeEditor',
  paymentLink: (transactionLinkHash: string) => `/pay/${transactionLinkHash}`,
  checkoutWithTransactionLinkHash: (
    profileId: string | number,
    checkoutId: string | number,
    transactionLinkHash: string
  ) =>
    `/profiles/${profileId}/checkout/${checkoutId}?transactionLinkHash=${transactionLinkHash}`,
};

export type ResponseError = {
  /** The error message */
  error: string;
};

const defaultHeaders = {
  'Client-Platform': 'WEB_BOOKING',
  'Client-Version': '1.0.0',
  'Accept-Language': getBrowserLanguage(),
};

const ContentType = {
  'Content-type': 'application/json',
};

/**
 * Calls api.
 *
 * @param path - The api url
 * @param init - The request options
 */
export const callApi = async (path: string, init?: RequestInit, isFormData = false) => {
  const userData = localStorage.getItem('auth');
  const token: string = userData ? JSON.parse(userData).token : '';
  const authHeaders: Record<string, string> = token
    ? { Authorization: `Bearer ${token}` }
    : {};

  const res = await fetch(`${API_URL}${path}`, {
    ...init,
    headers: {
      ...(!isFormData ? ContentType : ''),
      ...defaultHeaders,
      ...authHeaders,
      ...init?.headers,
    },
  });
  return res;
};

/**
 * Calls POST api.
 *
 * @param path - The api url
 * @param body - The request body
 * @param init - The request options
 * @returns A Promise resolving to the response
 */
export const postApi = <B>(path: string, body: B, init?: RequestInit) => {
  const isFormData = body instanceof FormData;
  const requestBody = isFormData ? body : JSON.stringify(body);
  return callApi(
    path,
    {
      method: 'POST',
      body: requestBody,
      ...init,
    },
    isFormData
  );
};

export const patchApi = async (path: string, body: Operation[]) => {
  const userData = localStorage.getItem('auth');
  const token: string = userData ? JSON.parse(userData).token : '';
  const patchHeaders = {
    ...defaultHeaders,
    'Content-type': 'application/json-patch+json',
  };

  const authHeaders = token ? { Authorization: `Bearer ${token}` } : {};
  const res = await fetch(`${API_URL}${path}`, {
    headers: token ? { ...patchHeaders, ...authHeaders } : patchHeaders,
    method: 'PATCH',
    body: JSON.stringify(body),
  });
  return res;
};

export const deleteApi = (path: string, init?: RequestInit) => {
  return callApi(path, { method: 'DELETE', ...init });
};

export const catWord = (word: string, number: number) => word.slice(0, number);

export const getPrice = (
  price: number,
  currencyCode: string,
  returnEmptyIfZero: boolean = true,
  pricePerUnit?: string
) => {
  if (returnEmptyIfZero && (!price || price === 0)) return '';

  let priceString = `${price} ${getCurrencyName(currencyCode)}`;
  if (pricePerUnit) {
    priceString += ` / ${pricePerUnit.toLowerCase()}`;
  }

  return priceString;
};

export const getCurrencyName = (currencyCode: string) => {
  if (currencyCode === 'SEK') {
    return 'kr';
  }

  return currencyCode;
};

export const getDefaultPhoneCountry = () => {
  const language = getBrowserLanguage();
  if (language === 'sv') return 'se';
  return 'gb';
};

export const convertCheckoutsToLocalStorage = (checkoutsData: CheckoutInfo[]) => {
  return checkoutsData.reduce(
    (
      acc: {
        id: number;
        profileId: number;
        itemCount: number;
        price: number;
        currency: string;
      }[],
      checkout: CheckoutInfo
    ) => {
      if (checkout.profile) {
        const {
          id,
          profile: { id: profileId },
          itemCount,
          price,
          currency,
        } = checkout;
        acc.push({ id, profileId, itemCount, price, currency });
      }
      return acc;
    },
    []
  );
};

export const parseLocalStorageCheckout = (checkoutsData: CheckoutInfo[]) => {
  return checkoutsData.reduce(
    (
      acc: {
        id: number;
        profile: { id: number };
        itemCount: number;
        price: number;
        currency: string;
      }[],
      checkout: CheckoutInfo
    ) => {
      if (checkout.profileId) {
        const { id, profileId, itemCount, price, currency } = checkout;
        acc.push({ id, profile: { id: profileId }, itemCount, price, currency });
      }
      return acc;
    },
    []
  );
};

export const saveFile = (content: ArrayBuffer, fileName: string, type: string) => {
  const file = new Blob([content], { type });
  const a = document.createElement('a');
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    document.body.removeChild(a);
  }, 0);
};

export const clearLocalStorage = (value: string[] | string) => {
  if (typeof value === 'string') {
    localStorage.removeItem(value);
  } else {
    value.forEach((name) => {
      localStorage.removeItem(name);
    });
  }
};
