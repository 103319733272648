import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import PhoneInput from 'react-phone-input-2';
import { getPrice, getDefaultPhoneCountry, urls } from 'helpers';
import { isMobile } from 'react-device-detect';

import { Loader } from 'components/Loader/Loader';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';

import { HeaderStore } from 'store/HeaderStore';
import { PaymentStore, SwishCreatePaymentRequest } from 'store/PaymentStore';
import { CheckoutStore } from 'store/CheckoutStore';
import { ErrorStore } from 'store/ErrorStore';

import BigSwishIcon from 'assets/icons/swish_big.svg';

import Layout from 'styled/Layout';
import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { PrimaryButton } from 'styled/PrimaryButton';
import { PaymentSpecification } from 'pages/Payment/PaymentSpecification';
import { SwishStatusCheck } from './SwishStatusCheck';
import { renderInputBorder } from 'helpers/StylesUtils';

const CardPaymentBlock = styled.div`
  padding: 24px 31px 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;

  @media ${devices.mobile} {
    padding: 24px 11px 30px;
  } ;
`;

const TotalBlock = styled.div`
  margin: 40px 20px 40px 20px;
  padding: 24px 0px 24px 0px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 20px;
`;

const Price = styled.div`
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  user-select: none;
`;

const BorderBlock = styled.div`
  position: relative;
  padding: 40px 27px 30px 30px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 20px;
`;

const BorderBlockTitle = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 12px;
  white-space: nowrap;
  background: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;

const OrLine = styled.div`
  position: relative;
  width: 50%;
  height: 20px;
  margin: 42px auto 0;
  border-top: 1px solid ${({ theme }) => theme.colors.middleGray};
`;

const MCommerceContainer = styled.div`
  margin-bottom: 30px;
`;

const OrContainer = styled.div`
  margin-bottom: 30px;
`;

const OrText = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 12px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.middleGray};
  background: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`;

const ECommerceContainer = styled.div``;

const PhoneInputWrap = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  user-select: none;
  margin-bottom: 30px;
`;

const ContactRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputBlock = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 25px 17px 4px;
  color: #a6a6a6;
  background: ${(props: { error: string }) => (props.error ? '#FFFBFB' : '#ffffff')};
  border: ${(props: { error: string; focus: boolean }) =>
    renderInputBorder(props.focus, props.error)};
  border-radius: 10px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  .react-tel-input {
    input {
      font-family: ${({ theme }) => theme.fonts.sfProDisplay};
      background: ${(props: { error: string }) => (props.error ? '#FFFBFB' : '#ffffff')};
      @media ${devices.mobile} {
        font-size: 17px;
      }
    }
  }
`;

const InputHeader = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  color: #a6a6a6;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;

const ConfirmButton = styled(PrimaryButton)`
  max-width: 300px;
  max-height: 56px;
  margin: 28px auto 0px auto;

  @media ${devices.mobile} {
    padding: 14px 50px;
    max-width: 220px;
  } ;
`;

const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.red};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-top: 12px;
  @media ${devices.mobile} {
  }
`;

const FLOW_TYPE_ECOMMERCE = 'eCommerce';
const FLOW_TYPE_MCOMMERCE = 'mCommerce';

export const SwishPayment = observer(() => {
  const { t } = useTranslation(Namespaces.UI);
  const { setBackButton } = HeaderStore;
  const {
    checkout,
    getCheckout,
    getStorageCheckoutData,
    createSwishTransaction,
    replaceStore,
  } = CheckoutStore;
  const { transactionInfo, createSwishTransactionPayment } = PaymentStore;
  const { error, removeError } = ErrorStore;
  const history = useHistory();
  const { id: checkoutId } = useParams<{ id: string }>();
  const checkoutData = toJS(checkout);
  const transactionData = toJS(transactionInfo);
  const errorData = toJS(error);
  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  ) as string;

  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoader] = useState(false);
  const [focus, setFocus] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [swishFlowType, setSwishFlowType] = useState('');
  const [isFirstAttempt, setIsFirstAttempt] = useState(true);

  useEffect(() => {
    getStorageCheckoutData();
  }, [getStorageCheckoutData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!transactionData) {
      // No transaction data probably because of refresh.
      // Fetch checkout info (mostly for phone number in transaction request) and create a new transaction
      getCheckout(checkoutId, transactionLinkHash, () => {
        createSwishTransaction(checkoutId, transactionLinkHash);
      });
    }
    setBackButton(true);
  }, [
    createSwishTransaction,
    getCheckout,
    checkoutId,
    transactionData,
    transactionLinkHash,
    setBackButton,
  ]);

  if (!checkoutData || !transactionData) {
    return <Loader />;
  }

  const onPayClicked = (swishFlowType: string) => {
    if (loading) return;

    setSwishFlowType(swishFlowType);
    setLoader(true);
    removeError();

    if (isFirstAttempt) {
      startSwishPayment(transactionData.id, swishFlowType);
    } else {
      // Create new transaction for each attempt
      createSwishTransaction(
        checkoutId,
        transactionLinkHash,
        (hasError, transactionId) => {
          if (!hasError) {
            startSwishPayment(transactionId as number, swishFlowType);
          }
        }
      );
    }

    setIsFirstAttempt(false);
  };

  const startSwishPayment = (transactionId: number, swishFlowType: string) => {
    var requestBody: SwishCreatePaymentRequest = {};
    if (swishFlowType == FLOW_TYPE_ECOMMERCE) {
      requestBody.phoneNumber = phoneNumber || checkoutData?.phoneNumber || '';

      if (requestBody.phoneNumber && requestBody.phoneNumber.indexOf('+') < 0) {
        requestBody.phoneNumber = `+${requestBody.phoneNumber}`;
      }
    }

    createSwishTransactionPayment(
      transactionId,
      requestBody,
      (hasError, swishResponse) => {
        setLoader(false);
        if (!hasError) {
          if (swishFlowType == FLOW_TYPE_MCOMMERCE && swishResponse?.token) {
            const swishAppUrl = `swish://paymentrequest?token=${swishResponse.token}`;
            window.location.replace(swishAppUrl);
          }

          setPaymentInProgress(true);
        }
      }
    );
  };

  const getSwishErrorMessage = (errorCode: string, errorMessage?: string) => {
    if (errorCode === 'SWISH_ERROR_REQUEST_IN_PROGRESS') {
      return t('swishErrorRequestInProgress');
    }

    return errorMessage || t('errorMessageDefault');
  };

  const { amount: transactionAmount, currency } = transactionData;

  // Hide m commerce flow for now until swish app link (swish://) is reliably opened within iframe
  const showMCommerce = false;

  return (
    <Layout>
      <PaymentSpecification
        transactionData={transactionData}
        checkoutData={checkoutData}
      />
      <>
        {paymentInProgress && (
          <SwishStatusCheck
            flowType={swishFlowType}
            transactionLinkHash={transactionLinkHash}
            onCancelled={() => setPaymentInProgress(false)}
            onFailed={() => {
              setPaymentInProgress(false);
            }}
            onSuccess={() => {
              replaceStore(checkoutData);
              setBackButton(false);
              history.push(urls.confirmation);
            }}
          />
        )}
        {!paymentInProgress && (
          <CardPaymentBlock>
            <Flex justifyContent="center">
              <CustomIcon width="107px" height="130px" icon={BigSwishIcon} />
            </Flex>
            <TotalBlock>
              <Price>{getPrice(transactionAmount, currency, false)}</Price>
            </TotalBlock>
            {isMobile && showMCommerce && (
              <>
                <MCommerceContainer>
                  <BorderBlock>
                    <BorderBlockTitle>{t('swishMCommerceTitle')}</BorderBlockTitle>
                    <ConfirmButton
                      size="large"
                      disabled={loading && swishFlowType === FLOW_TYPE_MCOMMERCE}
                      onClick={() => {
                        onPayClicked(FLOW_TYPE_MCOMMERCE);
                      }}
                    >
                      {loading && swishFlowType === FLOW_TYPE_MCOMMERCE ? (
                        <Loader small />
                      ) : (
                        t('swishPayButton')
                      )}
                    </ConfirmButton>
                    {errorData &&
                      swishFlowType === FLOW_TYPE_MCOMMERCE &&
                      errorData.place === 'createSwishTransaction' && (
                        <ErrorText>
                          {getSwishErrorMessage(errorData.code, errorData.message)}
                        </ErrorText>
                      )}
                  </BorderBlock>
                </MCommerceContainer>
                <OrContainer>
                  <OrLine>
                    <OrText>{t('or')}</OrText>
                  </OrLine>
                </OrContainer>
              </>
            )}
            <ECommerceContainer>
              <BorderBlock>
                <BorderBlockTitle>{t('swishECommerceTitle')}</BorderBlockTitle>
                <PhoneInputWrap>
                  <>
                    <ContactRow>
                      <InputHeader>{t('phoneNumber')}</InputHeader>
                      <InputBlock error="" focus={focus}>
                        <PhoneInput
                          inputStyle={{ color: '#181818' }}
                          disabled={loading}
                          country={getDefaultPhoneCountry()}
                          value={phoneNumber || checkoutData?.phoneNumber}
                          onChange={(phone) => {
                            setPhoneNumber(phone);
                          }}
                          onFocus={() => {
                            setFocus(true);
                          }}
                          onBlur={() => {
                            setFocus(false);
                          }}
                          masks={{ se: '.. ... .. ..' }}
                        />
                      </InputBlock>
                    </ContactRow>
                  </>
                </PhoneInputWrap>
                <ConfirmButton
                  size="large"
                  disabled={loading && swishFlowType === FLOW_TYPE_ECOMMERCE}
                  onClick={() => {
                    onPayClicked(FLOW_TYPE_ECOMMERCE);
                  }}
                >
                  {loading && swishFlowType === FLOW_TYPE_ECOMMERCE ? (
                    <Loader isWhite small />
                  ) : (
                    t('swishPayButton')
                  )}
                </ConfirmButton>

                {errorData &&
                  swishFlowType === FLOW_TYPE_ECOMMERCE &&
                  errorData.place === 'createSwishTransaction' && (
                    <ErrorText>
                      {getSwishErrorMessage(errorData.code, errorData.message)}
                    </ErrorText>
                  )}
              </BorderBlock>
            </ECommerceContainer>
          </CardPaymentBlock>
        )}
      </>
    </Layout>
  );
});
