import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  position: relative;
  background: white;
  border-radius: 0px 0px 30px 30px;
  box-shadow: 0px 5px 6px -3px rgb(0 0 0 / 16%);
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  padding: 0px 20px 0px 20px;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 33px 20px 40px;
  @media${devices.mobile} {
    margin: 10px 15px 20px 25px;
  }
`;

export const CalendarTitleContainer = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 24px;
  @media${devices.mobile} {
    font-size: 17px;
  }
`;

export const CalendarTitleMonth = styled.div`
  margin-right: 10px;
`;

export const CalendarContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 5px;
`;

export const TodayButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 38px;
  margin: 0 20px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 19px;
  font-size: 17px;
  user-select: none;
  cursor: pointer;
  @media${devices.mobile} {
    font-size: 14px;
  }
`;

export const SelectedDatesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const SelectedDatesTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

export const MissingDateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-left: 10px;
  padding: 10px 18px;
  background-color: ${(props: { isStartDate: boolean }) =>
    props.isStartDate ? 'rgba(24, 202, 153, 0.05)' : 'rgba(253, 43, 85, 0.05)'};
  border: 1px solid;
  border-color: ${(props: { isStartDate: boolean }) =>
    props.isStartDate ? 'rgba(24, 202, 153, 1)' : 'rgba(253, 43, 85, 1)'};
  border-radius: 14px;

  @media ${devices.mobile} {
    padding: 8px 14px;
  }
`;

export const SelectedPeriodLabel = styled.div`
  font-size: 14px;
  margin-left: 10px;
`;
