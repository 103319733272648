import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/Loader/Loader';
import { Namespaces } from 'languages';

import GhostImage from 'assets/icons/ghost.svg';

import { PrimaryButton } from 'styled/PrimaryButton';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const BookingMessage = styled.div`
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  white-space: pre-wrap;
`;

const Button = styled(PrimaryButton)`
  width: 220px;
  height: 50px;
  margin: 20px auto;
`;

export const EmptyResult = (props: {
  text: string;
  action: () => void;
  buttonText?: string;
  loading: boolean;
  showButton?: boolean;
}) => {
  const { t } = useTranslation(Namespaces.UI);
  const { text, action, buttonText, loading, showButton = true } = props;
  return (
    <div data-testid="emptyResult">
      <CustomIcon
        width="124px"
        height="120px"
        margin="60px auto 30px"
        icon={GhostImage}
      />
      <BookingMessage>{text}</BookingMessage>
      {showButton && (
        <Button
          onClick={() => {
            if (!loading) {
              action();
            }
          }}
          disabled={loading}
        >
          {loading ? <Loader isWhite small /> : buttonText || t('refresh')}
        </Button>
      )}
    </div>
  );
};
