import { useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { getPrice } from 'helpers';
import { isDueDate, getYMD } from 'helpers/DateTimeUtils';

import { devices } from 'constants/mediaConstants';

import { PaymentsListType } from 'store/PaymentsListStore';

import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';
import CheckIcon from 'assets/icons/checkmark.svg';
import Refunded from 'assets/icons/Refunded.svg';
import LinkIcon from 'assets/icons/LinkIcon.svg';
import DownloadReceiptIcon from 'assets/icons/DownloadReceiptIcon.svg';
import Invoice from 'assets/icons/invoice.svg';
import Visa from 'assets/icons/visa.svg';
import Mastercard from 'assets/icons/mastercard.svg';
import Swish from 'assets/icons/swish.svg';
import PaySite from 'assets/icons/paySite.svg';
import AlertImage from 'assets/icons/alert.svg';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';
import { PaymentStore } from 'store/PaymentStore';
import { Loader } from 'components/Loader/Loader';

const Price = styled.div`
  margin-left: auto;
  font-style: normal;
  white-space: nowrap;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  @media${devices.mobile} {
    font-size: 13px;
  }
`;

const CardItem = styled.div`
  position: relative;
  align-items: center;
  margin: 5px 0;
  padding: 20px 30px 20px 41px;
  border-radius: 30px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  background: ${({ theme }) => theme.colors.white};

  @media ${devices.mobile} {
    padding: 20px;
  }
`;

const TransactionRefundsBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 14px;
`;

const TransactionBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

const TransactionItemBlock = styled.div`
  min-height: 80px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px 31px 13px 19px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px ;
  @media ${devices.mobile} {
    padding: 10px;
  },
`;
const IconBlock = styled.div`
  width: 75px;
`;

const TransactionData = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.lightBlack};
  @media ${devices.mobile} {
    font-size: 12px;
  },
`;

const TransactionPrice = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.lightBlack};
  @media ${devices.mobile} {
    font-size: 12px;
  },
`;

const TransactionRefundedPrice = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.peach};
  @media${devices.mobile} {
    font-size: 13px;
  }
`;

const ScheduleItemDate = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  white-space: nowrap;
  @media${devices.mobile} {
    font-size: 13px;
  }
`;

const GreenBlock = styled.div`
  display: flex;
  margin: 0 auto 0 10px;
`;

const ServiceName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 2px 0 0px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.middleLightGray};
  @media${devices.mobile} {
    font-size: 13px;
  }
`;

const StatusGreenName = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.green};
  @media (max-width: 520px) {
    display: none;
  },
`;

const TransactionDateBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  @media ${devices.mobile} {
    margin-right: 0;
  },
`;

const DownloadReceiptIconBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TransactionPriceBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100px;
  @media ${devices.mobile} {
    width: 80px;
  },
`;

const StatusOrangeName = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.peach};
  @media (max-width: 520px)} {
    display: none;
  },
`;
const StatusRedName = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.red};
  @media (max-width: 520px) {
    display: none;
  },
`;

const LoaderPosition = styled.div`
  position: absolute;
  right: -130px;
`;

type PropsType = {
  paymentItem: PaymentsListType;
};

export const PaymentsItem = observer((props: PropsType) => {
  const { paymentItem } = props;
  const { downloadTransactionPdf, loading } = PaymentStore;
  const {
    id,
    paidAt,
    invoiceExpirationDate,
    paymentStatus,
    amount,
    currency,
    paymentType,
    refunds,
    serialNumber,
    profile: { name, website },
  } = paymentItem;
  const [isCollapsed, setCollapsed] = useState(false);
  const { t } = useTranslation(Namespaces.UI);
  const renderPaymentTypeIcon = (type: string) => {
    switch (type) {
      case 'card':
        return (
          <IconBlock>
            <Flex justifyContent="center">
              <CustomIcon icon={Visa} width="29px" height="18px" cursor />
              <CustomIcon icon={Mastercard} width="28px" height="18px" cursor />
            </Flex>
          </IconBlock>
        );
      case 'arrival':
        return (
          <IconBlock>
            <CustomIcon icon={PaySite} width="28px" height="23px" cursor margin="auto" />
          </IconBlock>
        );
      case 'invoice':
        return (
          <IconBlock>
            <CustomIcon icon={Invoice} width="28px" height="23px" cursor margin="auto" />
          </IconBlock>
        );
      case 'swish':
        return (
          <IconBlock>
            <CustomIcon icon={Swish} width="28px" height="23px" cursor margin="auto" />
          </IconBlock>
        );
      default:
        return (
          <IconBlock>
            <CustomIcon width="20px" height="20px" icon={Refunded} margin="auto" />
          </IconBlock>
        );
    }
  };
  const renderPaymentStatus = () => {
    if (paymentStatus === 'paid' && !refunds) {
      return (
        <GreenBlock>
          <CustomIcon width="20px" height="20px" margin="0 6px 0 0" icon={CheckIcon} />
          <StatusGreenName>{t('paid').toUpperCase()}</StatusGreenName>
        </GreenBlock>
      );
    }
    if (paymentStatus === 'paid' && refunds) {
      return (
        <GreenBlock>
          <CustomIcon width="20px" height="20px" margin="0 6px 0 0" icon={Refunded} />
          <StatusOrangeName>{t('refunded').toUpperCase()}</StatusOrangeName>
        </GreenBlock>
      );
    }
    if (paymentType === 'invoice' && isDueDate(invoiceExpirationDate)) {
      return (
        <GreenBlock>
          <CustomIcon width="20px" height="20px" margin="0 6px 0 0" icon={AlertImage} />
          <StatusRedName>{t('due').toUpperCase()}</StatusRedName>
        </GreenBlock>
      );
    }
    return null;
  };

  return (
    <CardItem key={id}>
      <Flex>
        <Flex justifyContent="flex-start" alignItems="center" width="100%">
          <Flex flexDirection="column" width="40%">
            <ScheduleItemDate>{getYMD(paidAt)}</ScheduleItemDate>
            {!isCollapsed && <ServiceName>{name}</ServiceName>}
          </Flex>

          {renderPaymentStatus()}

          <Price>{getPrice(-amount, currency)}</Price>
          <CustomIcon
            width="20px"
            height="12px"
            margin="0 0 0 17px"
            icon={isCollapsed ? ArrowUp : ArrowDown}
            onClick={(e) => {
              if (e) {
                e.stopPropagation();
                setCollapsed(!isCollapsed);
              }
            }}
            transition="background-image 0s, bottom 0.5s"
            hover
            cursor
            device={devices.mobile}
            deviceHeight="9px"
            deviceWidth="15px"
          />
        </Flex>
      </Flex>
      {isCollapsed && (
        <Flex alignItems="baseline" justifyContent="flex-start" margin="5px 0">
          <ServiceName>{name}</ServiceName>
          {website ? (
            <CustomIcon
              width="10px"
              height="10px"
              margin="0 6px 0 7px"
              icon={LinkIcon}
              hover
              cursor
              onClick={() => {
                window.open(`http://${website}`, '_blank');
              }}
            />
          ) : null}
        </Flex>
      )}

      {isCollapsed ? (
        <TransactionItemBlock>
          <Flex flexDirection="column" justifyContent="center">
            {refunds && refunds.length ? (
              <Flex flexDirection="column">
                {refunds.map((el) => {
                  return (
                    <TransactionRefundsBlock key={el.id}>
                      <TransactionDateBlock>
                        <TransactionData>{getYMD(el.processedAt)}</TransactionData>
                      </TransactionDateBlock>
                      <Flex justifyContent="center">
                        <Flex justifyContent="center">{renderPaymentTypeIcon('')}</Flex>
                      </Flex>

                      <TransactionPriceBlock>
                        <TransactionRefundedPrice>
                          {getPrice(el.amount, el.currency)}
                        </TransactionRefundedPrice>
                      </TransactionPriceBlock>
                    </TransactionRefundsBlock>
                  );
                })}
              </Flex>
            ) : null}
            <TransactionBlock>
              <TransactionDateBlock>
                <TransactionData>{getYMD(paidAt)}</TransactionData>
              </TransactionDateBlock>
              <Flex>
                <Flex justifyContent="center">{renderPaymentTypeIcon(paymentType)}</Flex>
                <TransactionPriceBlock>
                  <TransactionPrice>{getPrice(-amount, currency)}</TransactionPrice>
                </TransactionPriceBlock>
              </Flex>
            </TransactionBlock>
          </Flex>

          <DownloadReceiptIconBlock>
            {!loading ? (
              <CustomIcon
                width="33px"
                height="49px"
                icon={DownloadReceiptIcon}
                cursor
                hover
                device={devices.mobile}
                deviceHeight="22px"
                deviceWidth="38px"
                onClick={() => {
                  if (!loading) {
                    downloadTransactionPdf(id, serialNumber);
                  }
                }}
              />
            ) : (
              <LoaderPosition>
                <Loader small />
              </LoaderPosition>
            )}
          </DownloadReceiptIconBlock>
        </TransactionItemBlock>
      ) : null}
    </CardItem>
  );
});
