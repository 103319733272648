import { Namespaces } from 'languages';
import { ActiveEditing } from 'pages/Account/types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as jsonpatch from 'fast-json-patch';
import { toJS } from 'mobx';
import { LoginStore } from 'store/LoginStore';
import { useHistory } from 'react-router';
import { ACCOUNT_DELETE_ACTIVE_BOOKINGS } from 'constants/apiErrorCodeConstants';
import { urls } from 'helpers';
import { useObserver } from 'mobx-react-lite';
import { HeaderStore } from 'store/HeaderStore';

type UserInfoKey = 'firstName' | 'surname' | 'phoneNumber' | 'email';

type State = {
  [key: string]: string;
  firstName: string;
  surname: string;
  email: string;
  phoneNumber: string;
};

export const useAccountSettings = (fetchUser?: boolean) => {
  const [state, setState] = useState<State>({
    firstName: '',
    surname: '',
    email: '',
    phoneNumber: '',
  });
  const [isServerError, setServerError] = useState('');
  const [isSuccessUpdate, setSuccessUpdate] = useState(false);
  const [errors, setError] = useState<{ [key: string]: string }>({});
  const [activeEditing, setActiveEditing] = useState<ActiveEditing>({});

  const {
    user,
    getUserInfo,
    deleteUserAccount,
    changeUserInfo,
    updateUserAvatar,
    loading,
    isDeleteUserAccountLoading,
    isUserLoggedIn,
  } = LoginStore;
  const { setBackButton } = HeaderStore;

  const userData = toJS(user);

  const { t } = useTranslation(Namespaces.UI);
  const history = useHistory();

  const updateAvatar = useCallback(
    (file: File) => {
      updateUserAvatar &&
        updateUserAvatar(file, (error?: { code: string; message: string }) => {
          // todo add error handling
          !error && setSuccessUpdate(true);
        });
    },
    [updateUserAvatar]
  );

  const deleteAccount = useCallback(() => {
    deleteUserAccount &&
      deleteUserAccount((error?: { code: string; message: string }) => {
        if (error) {
          const { code, message } = error;
          let errorMessage = message;
          if (code === ACCOUNT_DELETE_ACTIVE_BOOKINGS) {
            errorMessage = t('errorAccountDeleteActiveBookings');
          }
          setServerError(errorMessage);
          return;
        }

        history.push(urls.services(localStorage.profileId));
      });
  }, [deleteUserAccount, history, t, urls]);

  const validateFields = useCallback(() => {
    const errorsData = Object.keys(activeEditing).reduce((acc, el) => {
      const { field, newValue } = activeEditing[el];
      if (field !== 'phoneNumber' && !newValue) {
        acc[field] = t('errorRequired');
      }
      return acc;
    }, {} as { [key: string]: string });

    if (Object.keys(errorsData).length > 0) {
      setError(errorsData);
      return false;
    }
    return true;
  }, [activeEditing]);

  const removeError = useCallback((error: string) => {
    setError((prev) => ({ ...prev, [error]: '' }));
  }, []);

  const fillInputs = useCallback(() => {
    const userInfo = toJS(user);
    setState({
      firstName: userInfo.firstName || '',
      surname: userInfo.surname || '',
      email: userInfo.email || '',
      phoneNumber: userInfo.phoneNumber || '',
    });
  }, [user]);

  const changeUserData = useCallback(() => {
    if (changeUserInfo) {
      setServerError('');
      const errorsMap: { [key: string]: string } = {
        PHONE_INVALID: 'phoneNumber',
      };

      const currentUserData = Object.keys(activeEditing).reduce(
        (currentData: { [key: string]: string | undefined }, data: string) => {
          return { ...currentData, [data]: userData[data as UserInfoKey] };
        },
        {}
      );

      const nextUserData = Object.keys(activeEditing).reduce(
        (currentData: { [key: string]: string | undefined }, data: string) => {
          const { newValue } = activeEditing[data as UserInfoKey];
          return {
            ...currentData,
            [data]:
              data === 'phoneNumber' && newValue.length > 1 ? `+${newValue}` : newValue,
          };
        },
        {}
      );

      const patchBody = jsonpatch.compare(currentUserData, nextUserData);
      if (validateFields()) {
        changeUserInfo(patchBody, (error?: { code: string; message: string }) => {
          if (error) {
            const { code, message } = error;
            const field = errorsMap[code];
            setServerError(message);
            setError({ ...errors, [field]: message });
            return;
          }
          setSuccessUpdate(true);
          setActiveEditing({});
        });
      }
    }
  }, [activeEditing, changeUserInfo, errors, userData, validateFields]);

  useEffect(() => {
    fillInputs();
  }, [user, fillInputs]);

  useEffect(() => {
    setBackButton(true);

    if (fetchUser) {
      if (isUserLoggedIn()) {
        getUserInfo();
      } else {
        localStorage.prevPath = history.location.pathname;
        history.replace('/auth');
      }
    }
  }, [getUserInfo, setBackButton, fetchUser, isUserLoggedIn]);

  return useObserver(() => ({
    user,
    changeUserData,
    fillInputs,
    removeError,
    deleteAccount,
    updateAvatar,
    loading,
    isDeleteUserAccountLoading,
    state: { state, setState },
    isServerError: { isServerError, setServerError },
    isSuccessUpdate: { isSuccessUpdate, setSuccessUpdate },
    errors: { errors, setError },
    activeEditing: { activeEditing, setActiveEditing },
  }));
};
