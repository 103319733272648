import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import FacebookLogin from '@greatsumini/react-facebook-login';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import AppleSignIn from 'react-apple-signin-auth';
// import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { SocialLoginButton } from 'components/SocialLoginButton';
import { Namespaces } from 'languages';
import { Input as CustomField } from 'containers/Input';
import { Divider } from 'containers/Divider';
import { devices } from 'constants/mediaConstants';
import { LoginStore } from 'store/LoginStore';
import { HeaderStore } from 'store/HeaderStore';

import Smile from 'assets/icons/smile.svg';
import Email from 'assets/icons/email.svg';
import Password from 'assets/icons/password.svg';
import Google from 'assets/icons/google.svg';
import Facebook from 'assets/icons/facebook.svg';
// import Apple from 'assets/icons/apple.svg';

import { PrimaryButton } from 'styled/PrimaryButton';
import { useGoogleLogin } from '@react-oauth/google';
//import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const RegistrationPage = styled.div``;

const Error = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
`;

const ErrorServer = styled.div`
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.red};
  font-size: 13px;
`;

const ErrorWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 270px;
  margin: 0 auto;
  padding: 0 12px;
  background: #feecee;
  border-radius: 5px;
  text-align: center;
`;

const FormItem = styled.div`
  position: relative;
  width: 350px;
  margin: 30px auto;
  padding: 0 12px;
  border-radius: 3px;
  @media ${devices.mobile} {
    width: 290px;
  }
`;

const Input = styled(CustomField)`
  width: 350px;
  height: 52px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  @media ${devices.mobile} {
    width: 290px;
  }
`;

const BoldedClickText = styled.b`
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: pointer;
  user-select: none;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  &:hover {
    opacity: 0.8;
  }
`;

const SignUpToLogin = styled.div`
  margin-top: 30px;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
`;

const FormFields = styled.div`
  pointer-events: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : 'auto')};
  user-select: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : 'auto')};
`;

const ButtonRegistration = styled(PrimaryButton)`
  width: 350px;
  margin: 25px auto 30px;

  @media ${devices.mobile} {
    height: 42px;
    width: 290px;
  }
`;

const RegisterFooter = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 360px;
  margin: 35px auto 20px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 15px;
  @media ${devices.mobile} {
    width: 100%;
    font-size: 14px;
    letter-spacing: -0.5px;
  }
`;

const LinkText = styled.a`
  margin: 0 5px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  color: ${({ theme }) => theme.colors.darkBlack};
`;

const RegisterFooterHead = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
`;

export const Registration = observer((data: { setLogin: (state: boolean) => void }) => {
  const history = useHistory();
  const { setLogin } = data;
  const [loading, setLoading] = useState(false);
  const [loadingSocial, setLoadingSocial] = useState('');
  const [isServerError, setServerError] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [errorSurname, setErrorSurname] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirm, setErrorConfirm] = useState('');
  const { registration } = LoginStore;
  const { googleSignIn, facebookSignIn } = LoginStore;
  const { setTitle } = HeaderStore;

  const { t } = useTranslation(Namespaces.UI);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validationForm = (): boolean => {
    let isError = false;
    if (!email) {
      setErrorEmail(t('errorRequired'));
      isError = true;
    } else if (!email || email.includes('+')) {
      isError = true;
      setErrorEmail(t('errorInvalidEmail'));
    }
    if (!password) {
      isError = true;
      setErrorPassword(t('errorRequired'));
    } else if (password.length < 8) {
      isError = true;
      setErrorPassword(t('errorPasswordShort'));
    }
    if (!confirmPassword) {
      isError = true;
      setErrorConfirm(t('errorRequired'));
    } else if (password !== confirmPassword) {
      isError = true;
      setErrorConfirm(t('errorConfirm'));
    }
    if (!firstName) {
      isError = true;
      setErrorFirstName(t('errorRequired'));
    }
    if (!surname) {
      isError = true;
      setErrorSurname(t('errorRequired'));
    }
    return !isError;
  };

  const handleChangeEmail = (value: string) => {
    setErrorEmail('');
    setEmail(value);
  };
  const handleChangeFirstName = (value: string) => {
    setErrorFirstName('');
    setFirstName(value);
  };
  const handleChangeSurname = (value: string) => {
    setErrorSurname('');
    setSurname(value);
  };
  const handleChangePassword = (value: string) => {
    setErrorPassword('');
    setPassword(value);
  };
  const handleChangeConfirm = (value: string) => {
    setErrorConfirm('');
    setConfirmPassword(value);
  };

  const submitForm = () => {
    if (!loading && validationForm()) {
      setLoading(true);
      const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
      registration({ email, password, firstName, surname, timeZoneId }, history).then(
        (error) => {
          setServerError(error);
          setLoading(false);
        }
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const googleSuccessCallback = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleSignIn(
        {
          code: tokenResponse.code,
        },
        history,
        setLoadingSocial
      );
    },
    onError: () => {
      setLoadingSocial('');
    },
    onNonOAuthError: () => {
      setLoadingSocial('');
    },
    flow: 'auth-code',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const facebookSuccessCallback = (response: any) => {
    const { accessToken } = response;
    if (accessToken) {
      facebookSignIn(accessToken, history, setLoadingSocial);
    } else {
      setLoadingSocial('');
    }
  };

  const facebookId = process.env.REACT_APP_FACEBOOK_APP_ID || '';

  /* const appleId = process.env.REACT_APP_APPLE_CLIENT_ID;
  const appleRedirectUrl = process.env.REACT_APP_APPLE_LOGIN_REDIRECT_URI;
  const appleStatePrefix = process.env.REACT_APP_APPLE_LOGIN_STATE_PREFIX || ''; */

  return (
    <RegistrationPage data-testid="registration">
      <SocialLoginButton
        disabled={loading || !!loadingSocial}
        action={() => {
          setLoadingSocial('google');
          googleSuccessCallback();
        }}
        iconURL={Google}
        typeText={t('loginButtonTitle')}
        title={t('google')}
        isLoading={loadingSocial === 'google'}
      />

      <FacebookLogin
        appId={facebookId}
        initParams={{
          version: 'v16.0',
        }}
        fields="name,email,picture"
        scope="public_profile, email"
        autoLoad={false}
        onSuccess={facebookSuccessCallback}
        onFail={() => setLoadingSocial('')}
        render={({ onClick }) => (
          <SocialLoginButton
            disabled={loading || !!loadingSocial}
            action={() => {
              setLoadingSocial('facebook');
              if (onClick) {
                onClick();
              }
            }}
            iconURL={Facebook}
            title={t('facebook')}
            typeText={t('registrationButtonTitle')}
            isLoading={loadingSocial === 'facebook'}
          />
        )}
      />

      {/*
        <AppleSignIn
          disabled={loading || !!loadingSocial}
          authOptions={{
            clientId: appleId,
            scope: 'name email',
            redirectURI: appleRedirectUrl,
            state: `${appleStatePrefix}${uuidv4()}`,
          }}
          render={(props: any) => (
            <RegistrationItem {...props}>
              <CustomIcon icon={Apple} />
              {t('registrationButtonTitle')}
              <CompanyName>{t('apple')}</CompanyName>
            </RegistrationItem>
          )}
        />
        */}

      <Divider text={t('or')} />
      <ErrorWrap>{isServerError && <ErrorServer>{isServerError}</ErrorServer>}</ErrorWrap>
      <FormFields disabled={loading}>
        <FormItem>
          {errorEmail && <Error>{`* ${errorEmail}`}</Error>}
          <Input
            type="text"
            InputIcon={Email}
            placeholder={t('email')}
            change={handleChangeEmail}
            value={email}
          />
        </FormItem>
        <FormItem>
          {errorFirstName && <Error>{`* ${errorPassword}`}</Error>}
          <Input
            type="text"
            InputIcon={Smile}
            placeholder={t('firstName')}
            change={handleChangeFirstName}
            value={firstName}
          />
        </FormItem>
        <FormItem>
          {errorSurname && <Error>{`* ${errorSurname}`}</Error>}
          <Input
            type="text"
            InputIcon={Smile}
            placeholder={t('surname')}
            change={handleChangeSurname}
            value={surname}
          />
        </FormItem>
        <FormItem>
          {errorPassword && <Error>{`* ${errorPassword}`}</Error>}
          <Input
            InputIcon={Password}
            type="password"
            placeholder={t('password')}
            value={password}
            change={handleChangePassword}
          />
        </FormItem>
        <FormItem>
          {errorConfirm && <Error>{`* ${errorConfirm}`}</Error>}
          <Input
            InputIcon={Password}
            type="password"
            placeholder={t('confirmPassword')}
            value={confirmPassword}
            change={handleChangeConfirm}
          />
        </FormItem>
      </FormFields>
      <RegisterFooter>
        <RegisterFooterHead>{t('createAccountMessage1')}</RegisterFooterHead>
        <LinkText
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.agendo.io/terms-and-conditions"
        >
          {t('createAccountMessage2')}
        </LinkText>
        {t('createAccountMessage3')}
        <LinkText
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.agendo.io/privacy-policy"
        >
          {t('createAccountMessage4')}
        </LinkText>
      </RegisterFooter>
      <ButtonRegistration onClick={submitForm} size="large">
        {loading ? <Loader isWhite small /> : t('buttonSignUp')}
      </ButtonRegistration>
      <SignUpToLogin>
        {t('alreadyHaveAccount1')}
        <BoldedClickText
          onClick={() => {
            setTitle(t('loginTitle'));
            setLogin(true);
          }}
        >
          {t('buttonLogin')}
        </BoldedClickText>
      </SignUpToLogin>
    </RegistrationPage>
  );
});
