import { observer } from 'mobx-react';
import styled, { DefaultTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { getPrice, urls } from 'helpers';
import { getYMD } from 'helpers/DateTimeUtils';

import { devices } from 'constants/mediaConstants';

import { SubscriptionsListType } from 'store/SubscriptionStore';

import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import CheckIcon from 'assets/icons/checkmark.svg';
import PastDueIcon from 'assets/icons/alert_triangle_red.svg';
import UnderNoticeIcon from 'assets/icons/alert_triangle_yellow.svg';
import CancelIcon from 'assets/icons/CancelIcon.svg';
import Invoice from 'assets/icons/invoice.svg';
import Visa from 'assets/icons/visa.svg';
import Mastercard from 'assets/icons/mastercard.svg';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';
import { PaymentStore } from 'store/PaymentStore';
import { Loader } from 'components/Loader/Loader';
import PriceIcon from 'assets/icons/price.svg';
import infoIcon from 'assets/icons/infoIcon.svg';
import {
  SUBSCRIPTION_ACTIVE_STATUS,
  SUBSCRIPTION_PAST_DUE_STATUS,
  SUBSCRIPTION_UNDER_NOTICE_STATUS,
  SUBSCRIPTION_CANCELLED_STATUS,
} from 'constants/subscriptionConstants';
import moment from 'moment';

type StatusColorType = { theme: DefaultTheme; status?: string };

const getSubscriptionStatusColor = (status: string | undefined, theme: DefaultTheme) => {
  if (status === SUBSCRIPTION_ACTIVE_STATUS) {
    return theme.colors.green;
  } else if (status === SUBSCRIPTION_PAST_DUE_STATUS) {
    return theme.colors.whiteRed;
  } else if (status === SUBSCRIPTION_UNDER_NOTICE_STATUS) {
    return theme.colors.yellow;
  } else if (status === SUBSCRIPTION_CANCELLED_STATUS) {
    return theme.colors.whiteRed;
  }
  return theme.colors.darkGray;
};

const getPaymentMethodColor = (status: string | undefined, theme: DefaultTheme) => {
  if (status === SUBSCRIPTION_PAST_DUE_STATUS) {
    return theme.colors.whiteRed;
  }

  return theme.colors.darkBlack;
};

const getPaymentMethodBorderColor = (status: string | undefined, theme: DefaultTheme) => {
  if (status === SUBSCRIPTION_PAST_DUE_STATUS) {
    return theme.colors.whiteRed;
  }

  return theme.colors.middleGray;
};

const CardItem = styled.div`
  position: relative;
  align-items: center;
  margin-bottom: 24px;
  padding: 40px 40px 40px;
  border-radius: 30px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  background: ${({ theme }) => theme.colors.white};

  @media ${devices.mobile} {
    padding: 20px 15px;
  }
`;

const ServiceName = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 18px;
  }
`;

const PriceAndStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 18px;
`;

const Price = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  white-space: nowrap;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  @media${devices.mobile} {
    font-size: 13px;
  }
`;

const StatusText = styled.div`
  color: ${(props: StatusColorType) =>
    getSubscriptionStatusColor(props.status, props.theme)};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  white-space: nowrap;
  @media ${devices.mobile} {
    font-size: 12px;
  },
`;

const CardValidBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
`;

const PaymentBlock = styled.div`
  width: 100%;
  padding: 11px 10px 20px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 0 0 10px 10px;
  @media ${devices.mobile} {
    padding: 8px 8px 20px 8;
  },
`;

const PaymentMethodInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PaymentMethodNameText = styled.div`
  font-family:  ${(props: StatusColorType) => props.theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-left: 12px;
  @media ${devices.mobile} {
    font-size: 12px;
  },
`;

const PaymentMethodExpirationText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family:  ${(props: StatusColorType) => props.theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-left: 12px;
  @media ${devices.mobile} {
    font-size: 10px;
  },
`;

const CardDateTitle = styled.div`
  color: ${(props: StatusColorType) =>
    getSubscriptionStatusColor(props.status, props.theme)};
  font-family:  ${(props: StatusColorType) => props.theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  @media ${devices.mobile} {
    font-size: 11px;
  },
`;

const DateText = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  @media(max-width: 500px ){
    font-size: 16px;
  }
  @media ${devices.mobile} {
    font-size: 12px;
  },
`;

const NextAtDateText = styled(DateText)`
  margin-right: 16px;
`;

const SubscriptionStartEndBlock = styled.div`
  display: flex;
  width: 100%;
`;

const SubscriptionStartBlock = styled.div`
  width: 100%;
  margin-bottom: -1px;
  margin-right: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px 0 0 0;
  @media ${devices.mobile} {
    padding: 8px;
  },
`;

const SubscriptionEndBlock = styled.div`
  width: 100%;
  margin-bottom: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 0 10px 0 0;
  @media ${devices.mobile} {
    padding: 8px;
  },
`;

const PaymentInfoButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${devices.mobile} {
    flex-direction: column;
    align-items: center;
  },
`;

const PaymentInfoButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  height: 38px;
  margin-top: 20px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  box-sizing: border-box;
  border-radius: 19px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.darkBlack};

  @media ${devices.mobile} {
    max-height: 38px;
    width: 110px;
    padding: 12px 30px;
    font-size: 11px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const PaymentHistoryButton = styled(PaymentInfoButton)``;

const PaymentMethodChangeButton = styled(PaymentInfoButton)`
  color: ${(props: StatusColorType) => getPaymentMethodColor(props.status, props.theme)};
  border: 1px solid
    ${(props: StatusColorType) => getPaymentMethodBorderColor(props.status, props.theme)};
  margin-left: 20px;

  @media ${devices.mobile} {
    margin-left: 0px;
  }
`;

const CancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  min-width: 244px;
  margin: 40px auto 0;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  box-sizing: border-box;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.darkBlack};

  @media ${devices.mobile} {
    max-height: 38px;
    width: 130px;
    padding: 12px 30px;
    font-size: 12px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const PastDueInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(253, 43, 85, 0.05);
  border: 1px solid ${({ theme }) => theme.colors.whiteRed};
  border-radius: 10px;
  margin-top: 20px;
  justify-content: center;
  padding: 10px 20px;
`;

const PastDueInfoText = styled.div`
  text-align: center;
  font-family: ${(props: StatusColorType) => props.theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.darkBlack};
`;

type PropsType = {
  subscriptionItem: SubscriptionsListType;
  setActiveSubscription: (subscription: SubscriptionsListType) => void;
  setModalType: (type: string) => void;
};

export const SubscriptionItem = observer((props: PropsType) => {
  const { subscriptionItem, setActiveSubscription, setModalType } = props;
  const { loading } = PaymentStore;
  const {
    id,
    status,
    createdAt,
    amount,
    nextAt,
    lastAt,
    recurringUnit,
    currency,
    service: { name },
    paymentType,
    paymentMethod,
  } = subscriptionItem;
  const { t } = useTranslation(Namespaces.UI);
  const history = useHistory();

  const renderSubscriptionStatus = () => {
    if (status === SUBSCRIPTION_ACTIVE_STATUS) {
      return (
        <Flex>
          <CustomIcon width="20px" height="20px" margin="0 6px 0 0" icon={CheckIcon} />
          <StatusText status={status}>
            {t('subscriptionStatusActive').toUpperCase()}
          </StatusText>
        </Flex>
      );
    } else if (status === SUBSCRIPTION_PAST_DUE_STATUS) {
      return (
        <Flex>
          <CustomIcon width="20px" height="20px" margin="0 6px 0 0" icon={PastDueIcon} />
          <StatusText status={status}>
            {t('subscriptionStatusPastDue').toUpperCase()}
          </StatusText>
        </Flex>
      );
    } else if (status === SUBSCRIPTION_UNDER_NOTICE_STATUS) {
      return (
        <Flex>
          <CustomIcon
            width="20px"
            height="20px"
            margin="0 6px 0 0"
            icon={UnderNoticeIcon}
          />
          <StatusText status={status}>
            {t('subscriptionStatusUnderNotice').toUpperCase()}
          </StatusText>
        </Flex>
      );
    } else if (status === SUBSCRIPTION_CANCELLED_STATUS) {
      return (
        <Flex>
          <CustomIcon width="20px" height="20px" margin="0 6px 0 0" icon={CancelIcon} />
          <StatusText status={status}>
            {t('subscriptionStatusCancelled').toUpperCase()}
          </StatusText>
        </Flex>
      );
    }

    return null;
  };

  const renderPaymentInformationCell = () => {
    let paymentMethodInfo = <></>;
    let isCard = false;
    if (paymentMethod) {
      if (paymentMethod.type === 'card') {
        isCard = true;
        paymentMethodInfo = (
          <PaymentMethodInfoContainer>
            {paymentMethod.brand === 'visa' && (
              <CustomIcon icon={Visa} width="29px" height="18px" />
            )}
            {paymentMethod.brand === 'mastercard' && (
              <CustomIcon icon={Mastercard} width="28px" height="18px" />
            )}
            <PaymentMethodNameText>{`**** ${paymentMethod.last4}`}</PaymentMethodNameText>
            <PaymentMethodExpirationText>
              {`${t('subscriptionPaymentMethodExpires')}: ${moment(
                paymentMethod.expiresAt
              ).format('MM/YY')}`}
            </PaymentMethodExpirationText>
          </PaymentMethodInfoContainer>
        );
      } else if (paymentMethod.type === 'invoice') {
        paymentMethodInfo = (
          <PaymentMethodInfoContainer>
            <CustomIcon icon={Invoice} width="28px" height="23px" margin="auto" />
          </PaymentMethodInfoContainer>
        );
      }
    } else if (paymentType) {
      // Old subscriptions format without payment method
      if (paymentType === 'card') {
        isCard = true;
        paymentMethodInfo = (
          <PaymentMethodInfoContainer>
            <CustomIcon icon={Visa} width="29px" height="18px" />
            <CustomIcon icon={Mastercard} width="28px" height="18px" />
          </PaymentMethodInfoContainer>
        );
      } else if (paymentType === 'invoice') {
        paymentMethodInfo = (
          <PaymentMethodInfoContainer>
            <CustomIcon icon={Invoice} width="28px" height="23px" margin="auto" />
          </PaymentMethodInfoContainer>
        );
      }
    }

    return (
      <PaymentBlock>
        <Flex flexDirection="column">
          <CardDateTitle status={status !== SUBSCRIPTION_ACTIVE_STATUS ? status : ''}>
            {(status === SUBSCRIPTION_ACTIVE_STATUS ||
              status === SUBSCRIPTION_UNDER_NOTICE_STATUS) &&
              t('subscriptionNextPayment').toUpperCase()}
            {status === SUBSCRIPTION_PAST_DUE_STATUS &&
              t('subscriptionNextPaymentRetry').toUpperCase()}
          </CardDateTitle>
          <Flex alignItems="center">
            {status !== SUBSCRIPTION_CANCELLED_STATUS && (
              <NextAtDateText>{getYMD(nextAt)}</NextAtDateText>
            )}
            {paymentMethodInfo}
          </Flex>
        </Flex>

        <PaymentInfoButtonContainer>
          <PaymentHistoryButton
            onClick={() => {
              setModalType('paymentHistory');
              setActiveSubscription(subscriptionItem);
            }}
          >
            {t('subscriptionTransactionHistory')}
          </PaymentHistoryButton>
          {isCard &&
            (status === SUBSCRIPTION_ACTIVE_STATUS ||
              status === SUBSCRIPTION_PAST_DUE_STATUS ||
              status == SUBSCRIPTION_UNDER_NOTICE_STATUS) && (
              <PaymentMethodChangeButton
                status={status}
                onClick={() => {
                  history.push(urls.subscriptionChangeCard(id));
                }}
              >
                {t('subscriptionChangePaymentMethod')}
              </PaymentMethodChangeButton>
            )}
        </PaymentInfoButtonContainer>
      </PaymentBlock>
    );
  };

  const getEndsAtText = () => {
    if (status === SUBSCRIPTION_CANCELLED_STATUS) {
      return t('subscriptionStatusCancelled').toUpperCase();
    }

    if (lastAt) {
      return getYMD(lastAt);
    }

    if (status === SUBSCRIPTION_UNDER_NOTICE_STATUS && nextAt) {
      return getYMD(nextAt);
    }

    return t('untilCancellation').toUpperCase();
  };

  return (
    <CardItem key={id}>
      <Flex>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          width="100%"
          flexDirection="column"
        >
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <ServiceName>{name}</ServiceName>
            <CustomIcon
              width="22px"
              height="22px"
              icon={infoIcon}
              cursor
              onClick={() => {
                setModalType('info');
                setActiveSubscription(subscriptionItem);
              }}
            />
          </Flex>

          <PriceAndStatusContainer>
            <Flex alignItems="center">
              <CustomIcon
                width="18px"
                height="18px"
                margin="0 10px 0 0"
                icon={PriceIcon}
              />
              <Price>{getPrice(amount, currency, false, t(recurringUnit))}</Price>
            </Flex>

            {renderSubscriptionStatus()}
          </PriceAndStatusContainer>

          <CardValidBlock>
            <SubscriptionStartEndBlock>
              <SubscriptionStartBlock>
                <CardDateTitle>{t('started').toUpperCase()}</CardDateTitle>
                <DateText>{getYMD(createdAt)}</DateText>
              </SubscriptionStartBlock>
              <SubscriptionEndBlock>
                <CardDateTitle>{t('ends').toUpperCase()}</CardDateTitle>
                <DateText>{getEndsAtText()}</DateText>
              </SubscriptionEndBlock>
            </SubscriptionStartEndBlock>
            {renderPaymentInformationCell()}
          </CardValidBlock>

          {status === SUBSCRIPTION_PAST_DUE_STATUS && (
            <PastDueInfoContainer>
              <PastDueInfoText>{t('subscriptionPastDueInfoText')}</PastDueInfoText>
            </PastDueInfoContainer>
          )}

          {status === SUBSCRIPTION_ACTIVE_STATUS ||
          status === SUBSCRIPTION_PAST_DUE_STATUS ? (
            <>
              <CancelButton
                onClick={() => {
                  setModalType('cancel');
                  setActiveSubscription(subscriptionItem);
                }}
              >
                {loading ? <Loader small /> : t('cancelSubscription')}
              </CancelButton>
            </>
          ) : null}
        </Flex>
      </Flex>
    </CardItem>
  );
});
