import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { Loader } from 'components/Loader/Loader';
import { getPrice } from 'helpers';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';
import { CardDateBlock } from 'components/CardDateBlock/CardDateBlock';
import { APPOINTMENT_TYPE_UNTIL_CANCEL } from 'constants/appointmentConstants';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

import { AppointmentUntilCancellation } from 'store/ServiceStore';
import { CheckoutStore } from 'store/CheckoutStore';

import PriceIcon from 'assets/icons/price.svg';
import ArrowDown from 'assets/icons/arrow-down-white.svg';
import ArrowUp from 'assets/icons/arrow-up-white.svg';
import LocationIcon from 'assets/icons/location.svg';

import AppointmentCardContainer from 'styled/AppointmentCardContainer';
import AppointmentCardCoverImage from 'styled/AppointmentCardCoverImage';
import { PrimaryButton } from 'styled/PrimaryButton';
import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { Avatar } from 'components/Avatar';
import { ExpandCollapseContainer } from 'styled/ExpandCollapseContainer';

const CardHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
`;

const CardDescription = styled.div`
  margin-top: 9px;
  padding: 0 25px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
`;

const CardPrice = styled.div`
  padding: 0 25px;
  display: flex;
  align-items: center;
  margin-top: 17px;
`;

const CardPriceData = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
`;

const CardAddress = styled.div`
  padding: 0 25px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
`;

const CardButton = styled(PrimaryButton)`
  width: 214px;
  max-height: 56px;
  margin: 30px auto 0;
  padding: 17px 50px;
  font-size: 14px;

  @media ${devices.mobile} {
    width: 170px;
    max-height: 38px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;

const renderDescription = (
  isCollapsed: boolean,
  isTextShort: boolean,
  text: string,
  maxWidthString: number
) => {
  return isCollapsed || isTextShort ? text : `${text.slice(0, maxWidthString)}...`;
};

export const AppointmentCardUntilCancellation = observer(
  (props: { appointment: AppointmentUntilCancellation; defaultActive: boolean }) => {
    const [loading, setLoading] = useState(false);

    const {
      appointment: {
        avatarUrl,
        currency,
        name,
        description,
        pricePerUnit,
        price,
        address,
        coverImageUrl,
        startTime,
        endTime,
        checkoutItemData,
      },
      defaultActive,
    } = props;

    const [isCollapsed, setCollapsed] = useState(defaultActive);
    const { profileId } = useParams<{ profileId: string }>();
    const { clientWidth } = document.body;
    const { addItemToCheckout } = CheckoutStore;
    const maxWidthString = clientWidth / 5;
    const isTextShort = description ? description.length < maxWidthString : true;
    const { t } = useTranslation(Namespaces.UI);

    const servicePrice = getPrice(price, currency, true, t(pricePerUnit));

    return (
      <AppointmentCardContainer
        collapsed={isCollapsed}
        data-testid="appointment-card-until-cancellation"
        withPaddingTop={!coverImageUrl}
      >
        {coverImageUrl && isCollapsed ? (
          <AppointmentCardCoverImage img={coverImageUrl} />
        ) : null}
        <Flex alignItems="center" style={{ padding: '0 25px' }}>
          {avatarUrl && (
            <Avatar size={50} url={avatarUrl} style={{ marginRight: '22px' }} />
          )}
          <CardHeader>{name}</CardHeader>
        </Flex>
        <CardDescription>
          {renderDescription(isCollapsed, isTextShort, description, maxWidthString)}
        </CardDescription>
        {servicePrice && (
          <CardPrice>
            <CustomIcon width="21px" height="21px" margin="0 10px 0 0" icon={PriceIcon} />
            <CardPriceData>{servicePrice}</CardPriceData>
          </CardPrice>
        )}
        {address && (
          <CardAddress>
            <CustomIcon
              width="21px"
              height="21px"
              margin="0 10px 0 0"
              icon={LocationIcon}
            />
            <CardAddress>{address}</CardAddress>
          </CardAddress>
        )}
        <div style={{ padding: '0 25px' }}>
          <CardDateBlock
            type={APPOINTMENT_TYPE_UNTIL_CANCEL}
            startTime={startTime}
            endTime={endTime}
          />
        </div>
        <CardButton
          onClick={() => {
            if (!loading) {
              setLoading(true);
              addItemToCheckout(profileId, [checkoutItemData], setLoading);
              postCTAEventMessage(getAddBookingCTAEvent());
            }
          }}
        >
          {loading ? <Loader isWhite small /> : t('addToCart')}
        </CardButton>
        {(!isTextShort || coverImageUrl) && (
          <ExpandCollapseContainer
            onClick={() => {
              setCollapsed(!isCollapsed);
            }}
            style={{ bottom: '-13px', position: 'absolute', right: '0px', left: '0px' }}
          >
            {isCollapsed ? (
              <CustomIcon
                bottom="1px"
                position="relative"
                width="13px"
                height="13px"
                icon={ArrowUp}
                hover
                cursor
              />
            ) : (
              <CustomIcon
                top="1px"
                position="relative"
                width="13px"
                height="13px"
                icon={ArrowDown}
                hover
                cursor
              />
            )}
          </ExpandCollapseContainer>
        )}
      </AppointmentCardContainer>
    );
  }
);
