import { MouseEvent, useState } from 'react';

import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { getPrice } from 'helpers';
import { getDurationTextFromMinutes, isLocalTimeZoneSameAs } from 'helpers/DateTimeUtils';
import { useTranslation } from 'react-i18next';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

import { Loader } from 'components/Loader/Loader';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { TimeButton } from 'components/TimeButton/TimeButton';
import { devices } from 'constants/mediaConstants';

import { Namespaces } from 'languages';
import { CheckoutStore, AddCheckoutItemData } from 'store/CheckoutStore';
import { AppointmentSlotFixed } from 'store/ServiceStore';

import PriceIcon from 'assets/icons/price.svg';
import ArrowDown from 'assets/icons/arrow-down-white.svg';
import ArrowUp from 'assets/icons/arrow-up-white.svg';
import Clock from 'assets/icons/time.svg';
import CloseIcon from 'assets/icons/close.svg';

import AppointmentCardContainer from 'styled/AppointmentCardContainer';
import AppointmentCardCoverImage from 'styled/AppointmentCardCoverImage';
import { PrimaryButton } from 'styled/PrimaryButton';
import { Flex } from 'styled/Flex';
import { Avatar } from 'components/Avatar';
import { ExpandCollapseContainer } from 'styled/ExpandCollapseContainer';
import { CollapseText } from 'components/CollapseText/CollapseText';
import { CardDescription } from './styles';

const CardHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
`;

const CardPriceData = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin-right: 40px;
  @media ${devices.mobile} {
    font-size: 15px;
    margin-right: 25px;
  }
`;

const CardTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  margin-top: 3px;
`;

const ServiceDuration = styled.div`
  margin-left: 5px;
  line-height: 20px;
`;

const SlotsContainer = styled.div`
  margin-top: 24px;
  padding: 0 25px;
`;

const TimeZoneText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 4px;
`;

const SlotsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 145px);
  justify-content: space-between;
  > div {
    width: 145px;
  }
`;

const CardButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 30px auto 0;
  font-size: 14px;

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;

export const AppointmentCardFixedSlots = observer(
  (props: { appointment: AppointmentSlotFixed; defaultActive: boolean }) => {
    const {
      appointment: {
        avatarUrl,
        price,
        fromPrice,
        currency,
        durationMinutes,
        name,
        slots,
        description,
        coverImageUrl,
      },
      defaultActive,
    } = props;

    const { profileId } = useParams<{ profileId: string }>();
    const [loading, setLoading] = useState(false);
    const [activeTimeButtons, setActiveTimeButton] = useState<number[]>([]);
    const [isCollapsed, setCollapsed] = useState(defaultActive);
    const { addItemToCheckout } = CheckoutStore;
    const { t } = useTranslation(Namespaces.UI);
    const getRequestData = () => {
      return activeTimeButtons.reduce((acc: AddCheckoutItemData[] | [], position) => {
        const checkoutItemDataItems = acc;
        if (slots[position]?.checkoutItemData) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore-next-line
          checkoutItemDataItems.push(slots[position].checkoutItemData);
        }

        return checkoutItemDataItems;
      }, []);
    };

    let servicePrice = getPrice(fromPrice || price, currency);
    if (fromPrice) {
      servicePrice = `${t('from')} ${servicePrice}`;
    }

    let localTimeZoneDiffers = false;
    if (slots?.length > 0) {
      const firstSlot = slots[0];
      const lastSlot = slots[slots.length - 1];
      localTimeZoneDiffers =
        !isLocalTimeZoneSameAs(firstSlot.startTime) ||
        !isLocalTimeZoneSameAs(lastSlot.endTime);
    }

    const handleToggle = (e: MouseEvent<HTMLDivElement>) => {
      if (e) {
        e.stopPropagation();
        setCollapsed(!isCollapsed);
      }
    };

    return (
      <div data-testid="appointment-card-fixed-slots">
        <AppointmentCardContainer
          collapsed={isCollapsed}
          onClick={() => {
            setCollapsed(true);
          }}
          withPaddingTop={!coverImageUrl}
        >
          {coverImageUrl && isCollapsed ? (
            <AppointmentCardCoverImage img={coverImageUrl} />
          ) : null}
          <Flex justifyContent="space-between" style={{ padding: '0 25px' }}>
            <Flex alignItems="center">
              {avatarUrl && (
                <Avatar size={50} url={avatarUrl} style={{ marginRight: '22px' }} />
              )}
              <Flex flexDirection="column">
                <CardHeader>{name}</CardHeader>
                <CardDescription>{CollapseText(description, 5.5)}</CardDescription>
                <Flex alignItems="center">
                  {servicePrice && (
                    <>
                      <CustomIcon width="16px" height="16px" icon={PriceIcon} />
                      <CardPriceData>{servicePrice}</CardPriceData>
                    </>
                  )}
                  <CardTime>
                    <CustomIcon width="14px" height="14px" icon={Clock} />
                    <ServiceDuration>
                      {`${getDurationTextFromMinutes(durationMinutes)}`}
                    </ServiceDuration>
                  </CardTime>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          {isCollapsed && (
            <SlotsContainer>
              {localTimeZoneDiffers && (
                <TimeZoneText>{t('timeZoneDifferText')}</TimeZoneText>
              )}
              <SlotsLayout>
                {slots.map((data, i) => (
                  <TimeButton
                    key={i}
                    startTime={data.startTime}
                    endTime={data.endTime}
                    action={() => {
                      if (activeTimeButtons.includes(i)) {
                        setActiveTimeButton(activeTimeButtons.filter((el) => el !== i));
                      } else {
                        setActiveTimeButton([...activeTimeButtons, i]);
                      }
                    }}
                    active={activeTimeButtons.includes(i)}
                  />
                ))}
              </SlotsLayout>
              {activeTimeButtons.length > 0 && (
                <CardButton
                  disabled={!activeTimeButtons.length}
                  onClick={() => {
                    if (!loading) {
                      setLoading(true);
                      addItemToCheckout(profileId, getRequestData(), setLoading);
                      postCTAEventMessage(getAddBookingCTAEvent());
                    }
                  }}
                >
                  {loading ? <Loader isWhite small /> : t('addToCart')}
                </CardButton>
              )}
            </SlotsContainer>
          )}
          <ExpandCollapseContainer
            onClick={handleToggle}
            style={{ bottom: '-13px', position: 'absolute', right: '0px', left: '0px' }}
          >
            {isCollapsed ? (
              <CustomIcon
                bottom="1px"
                position="relative"
                width="13px"
                height="13px"
                icon={ArrowUp}
                hover
                cursor
              />
            ) : (
              <CustomIcon
                top="1px"
                position="relative"
                width="13px"
                height="13px"
                icon={ArrowDown}
                hover
                cursor
              />
            )}
          </ExpandCollapseContainer>
        </AppointmentCardContainer>
      </div>
    );
  }
);
