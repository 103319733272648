import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import { PrimaryButton } from 'styled/PrimaryButton';

export const BookingData = styled.div`
  padding: 0px 40px 20px;
  background: ${({ theme }) => theme.colors.white};
`;

export const ServiceName = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

export const EmptyResultContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

export const ShowMoreButton = styled(PrimaryButton)`
  width: 214px;
  margin: 30px auto;
  background: transparent;
  border-radius: 25px;
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  font-size: 18px;
  font-weight: 400;
`;

export const CardDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.middleDark};
  white-space: pre-wrap;
`;
