import { devices } from 'constants/mediaConstants';
import styled, { DefaultTheme } from 'styled-components';

export const BookingHeader = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  @media ${devices.mobile} {
    width: 80%;
  }
`;

export const BookingSubHeader = styled.div`
  display: flex;
  justify-content: ${(props: { isEnd?: boolean }) =>
    props.isEnd ? 'flex-end' : 'space-between'};
  margin-top: 9px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
`;

export const CardItem = styled.div`
  position: relative;
  margin: 20px 0;
  padding: 26px 34px 20px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 20px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;

  @media ${devices.mobile} {
    padding: 16px 10px 10px;
  }
`;

type ButtonCancelProps = {
  theme: DefaultTheme;
  isSmall?: boolean;
};

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const ButtonCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props: ButtonCancelProps) => (props.isSmall ? '168px' : '220px')};
  height: 50px;
  margin: 0px 20px 0px 20px;
  color: ${(props: ButtonCancelProps) => props.theme.colors.white};
  background: ${(props: ButtonCancelProps) => props.theme.colors.darkBlack};
  border-radius: 28px;
  font-family: ${(props: ButtonCancelProps) => props.theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.9;
  }
  @media ${devices.mobile} {
    width: ${(props: ButtonCancelProps) => (props.isSmall ? '120px' : '180px')};
    font-size: 15px;
  }
`;

export const ButtonPayNow: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  height: 50px;
  margin: 0px 20px 0px 20px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.green};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.9;
  }
  @media ${devices.mobile} {
    width: 120px;
    font-size: 15px;
  } ;
`;

export const Price = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.darkBlack};
  @media ${devices.mobile} {
    font-size: 18px;
  } ;
`;

export const UnpaidLogo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 97px;
  height: 30px;
  border-radius: 0px 20px 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.whiteRed};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white};
  @media ${devices.mobile} {
    width: 80px;
  } ;
`;

export const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

export const DownloadText = styled.div`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
`;
