export const renderInputBorder = (focus: boolean, error?: string) => {
  let border = '1px solid #DCDCDE';
  if (focus) {
    border = '1px solid #4186FF';
  }
  if (error) {
    border = '1px solid #FF033D';
  }
  return border;
};
