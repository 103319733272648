import styled, { css } from 'styled-components';
import { Grid } from 'styled/Grid';
import {
  TimeSlotProps,
  getCirclePosition,
  getSlotBorder,
  getSlotBorderRadius,
  getSlotMargin,
  renderSlotBackground,
} from './TimeLineHelpers';
import { Flex } from 'styled/Flex';

export const TimeBlock = styled(Grid)`
  width: 104%;
  margin: 30px -2% 0px;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 10fr 1fr;
`;

export const TimeWrap = styled.div`
  display: flex;
  width: 100%;
`;

export const HourBlock = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const DragCircle = styled.div`
  position: absolute;
  top: calc(50% - 9px);
  z-index: 1;
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 2px solid
    ${(props: { isStartActive: boolean; isError?: boolean }) =>
      props.isError ? '#FD2B55' : '#34a382'};
  border-radius: 50%;
  cursor: pointer;
  ${(props: { isStartActive: boolean }) => getCirclePosition(props)};
`;

export const DragCircleTouch = styled.div<{
  isFirstSlot?: boolean;
  isLastSlot?: boolean;
  isStartActive: boolean;
}>`
  position: absolute;
  z-index: 2;
  opacity: 0;
  width: ${({ isFirstSlot, isLastSlot }) =>
    isFirstSlot || isLastSlot ? '35px' : '20px'};
  height: 100%;
  ${(props: { isStartActive: boolean }) => getCirclePosition(props)};
  ${(props: { isFirstSlot?: boolean; isLastSlot?: boolean }) => {
    if (props.isFirstSlot) {
      return css`
        left: -20px;
        right: 0px;
      `;
    }
    if (props.isLastSlot) {
      return css`
        right: -20px;
        left: 0px;
      `;
    }
  }}
`;

export const DataDay = styled.div`
  position: absolute;
  top: -25px;
  left: -37px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
`;

export const HourBlockTitle = styled.div`
  margin: 0 0 5px 5px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  user-select: none;
`;

export const MinutesWrapper = styled(Flex)`
  position: relative;
  height: 52px;
  width: 100%;
`;

export const MinuteLine = styled.div`
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 1px;
  background: #e9e9ea;
  mix-blend-mode: multiply;
`;

export const HourLine = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 1px;
  mix-blend-mode: multiply;
  background: ${(props: { isBlack: boolean }) => (props.isBlack ? '#181818' : '#e9e9ea')};
`;

export const MinuteBlock = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  background: ${(props: TimeSlotProps) => renderSlotBackground(props)};
  border-radius: ${(props: TimeSlotProps) => getSlotBorderRadius(props)};
  ${(props: TimeSlotProps) => getSlotBorder(props)};
  ${(props: TimeSlotProps) => getSlotMargin(props)};
`;
