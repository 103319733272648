import { MouseEvent, useState } from 'react';

import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import styled, { DefaultTheme } from 'styled-components';
import { getPrice } from 'helpers';
import { isMobile } from 'react-device-detect';
import {
  getDateFromMinutes,
  getTime,
  getTimeLineMinutesFromDate,
  getYMD,
} from 'helpers/DateTimeUtils';
import { useTranslation } from 'react-i18next';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

import { Loader } from 'components/Loader/Loader';
import { TimeLine } from 'components/TimeLine/TimeLine';
import { TimeButton } from 'components/TimeButton/TimeButton';
import { CardDateBlock } from 'components/CardDateBlock/CardDateBlock';
import { devices } from 'constants/mediaConstants';
import { APPOINTMENT_TYPE_FLEXIBLE } from 'constants/appointmentConstants';

import { Namespaces } from 'languages';
import { CheckoutStore } from 'store/CheckoutStore';
import { AppointmentSlotFlexible } from 'store/ServiceStore';

import PriceIcon from 'assets/icons/price.svg';
import ArrowDown from 'assets/icons/arrow-down-white.svg';
import ArrowUp from 'assets/icons/arrow-up-white.svg';

import AppointmentCardContainer from 'styled/AppointmentCardContainer';
import AppointmentCardCoverImage from 'styled/AppointmentCardCoverImage';
import { PrimaryButton } from 'styled/PrimaryButton';
import { Flex } from 'styled/Flex';
import { CollapseText } from 'components/CollapseText/CollapseText';
import CloseIcon from 'assets/icons/close.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { Avatar } from 'components/Avatar';
import { ExpandCollapseContainer } from 'styled/ExpandCollapseContainer';

const CardHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
`;

const CardDescription = styled.div`
  padding: 0 25px;
  margin: 10px 0;
  padding-right: 40px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.middleDark};
  white-space: pre-wrap;
  @media ${devices.mobile} {
    font-size: 14px;
    padding-right: 20px;
  }
`;

const CardPriceData = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  color: ${({ theme }) => theme.colors.darkBlack};
`;

const CardDateContainer = styled.div`
  margin-bottom: 30px;
  padding: 0 25px;
`;

const CardButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 0px auto 0px;
  font-size: 14px;

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  }
`;

const ModalDateTitle = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  font-size: 18px;
  font-weight: 700;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

const ModalBody = styled.div`
  position: relative;
  height: 80vh;
  width: 545px;
  padding: 65px 67px 40px 65px;
  border-radius: 30px;
  background: ${({ theme }) => theme.colors.white};

  @media ${devices.mobile} {
    width: 90%;
    padding: 30px;
    font-size: 12px;
  }
`;

const ModalTimeButtonWrapper = styled.div`
  height: 54vh;
  overflow: scroll;
`;

const IconClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  @media ${devices.mobile} {
    right: 30px;
    top: 30px;
  }
`;

const ModalCardName = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #a6a6a6;
  margin: 20px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 22px;
  }
`;

const TimeLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  margin-right: 10px;
`;

const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  overflow: hidden;
  height: 35px;
`;

const TimeWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props: { column: number }) => props.column}, 1fr);
  grid-gap: 10px;
  padding-bottom: 20px;
  overflow-x: scroll;
  @media (max-width: 430px) {
    grid-gap: 5px;
  }
`;

type MinMaxBlockProps = {
  theme: DefaultTheme;
  isError?: boolean;
};

const MinMaxBlock = styled.div`
  border: ${(props: MinMaxBlockProps) =>
    props.isError ? '1px solid #fd2b55' : '1px solid #ffffff'};
  border-radius: 5px;
  padding: 2px 8px;
  margin: 6px 10px 0 0;
  box-sizing: border-box;
  color: ${(props: MinMaxBlockProps) =>
    props.isError ? props.theme.colors.whiteRed : props.theme.colors.middleDark};
  font-family: ${(props: MinMaxBlockProps) => props.theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
`;

type PairsAllowedRangesType = {
  start: string;
  end: string;
};

export const AppointmentCardFlexibleSlots = observer(
  (props: { appointment: AppointmentSlotFlexible; defaultActive: boolean }) => {
    const {
      appointment: {
        avatarUrl,
        name,
        description,
        price,
        fromPrice,
        pricePerUnit,
        currency,
        checkoutItemData,
        slots,
        coverImageUrl,
        rangeStartTime,
        rangeEndTime,
        rangeIncrementMinutes,
        maxBookableMinutes,
        minBookableMinutes,
      },
      defaultActive,
    } = props;
    const { profileId } = useParams<{ profileId: string }>();
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setOpenModal] = useState(false);
    const [modalName, setModalName] = useState('');
    const [activeSlots, setActiveSlot] = useState<{
      startRangeTime: number | null;
      endRangeTime: number | null;
    }>({
      startRangeTime: null,
      endRangeTime: null,
    });
    const [isCollapsed, setCollapsed] = useState(defaultActive);
    const { addItemToCheckout } = CheckoutStore;
    const { t } = useTranslation(Namespaces.UI);

    const getRequestTimeSlot = (searchDay: string) => {
      const { startRangeTime, endRangeTime } = activeSlots;
      const checkoutItemDataItems = {
        ...checkoutItemData,
        startTime: getDateFromMinutes(searchDay, startRangeTime as number),
        endTime: getDateFromMinutes(searchDay, endRangeTime as number),
      };
      return checkoutItemDataItems;
    };

    const checkStartTime = (
      pairsAllowedRanges: PairsAllowedRangesType[],
      currentPair: number,
      time: string
    ) => {
      if (activeSlots.endRangeTime === null) {
        if (
          time >= pairsAllowedRanges[currentPair].start &&
          time < pairsAllowedRanges[currentPair].end
        ) {
          return true;
        }
      } else {
        const hours = Math.trunc(activeSlots.endRangeTime / 60);
        const minute = activeSlots.endRangeTime - hours * 60;
        const tempHours = hours < 10 ? `0${hours}` : `${hours}`;
        const tempMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const currentEndRange = `${tempHours}:${tempMinute}`;
        const activeRange = pairsAllowedRanges.find((el) => {
          const { start, end } = el;
          return currentEndRange > start && currentEndRange <= end;
        });

        if (activeRange && time >= activeRange.start && time < currentEndRange) {
          return true;
        }
      }
      return false;
    };

    const checkEndTime = (
      pairsAllowedRanges: PairsAllowedRangesType[],
      currentPair: number,
      time: string
    ) => {
      if (activeSlots.startRangeTime === null) {
        if (
          time > pairsAllowedRanges[currentPair].start &&
          time <= pairsAllowedRanges[currentPair].end
        ) {
          return true;
        }
      } else {
        const hours = Math.trunc(activeSlots.startRangeTime / 60);
        const minute = activeSlots.startRangeTime - hours * 60;
        const tempHours = hours < 10 ? `0${hours}` : `${hours}`;
        const tempMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const currentEndRange = `${tempHours}:${tempMinute}`;
        const activeRange = pairsAllowedRanges.find((el) => {
          const { start, end } = el;
          return currentEndRange >= start && currentEndRange < end;
        });
        if (activeRange && time > currentEndRange && time <= activeRange.end) {
          return true;
        }
      }
      return false;
    };

    const handleToggle = (e: MouseEvent<HTMLDivElement>) => {
      if (e) {
        e.stopPropagation();
        setCollapsed(!isCollapsed);
      }
    };

    const renderModalBody = () => {
      const minutesPattern: number[] = [];
      for (let i = 0; i < 60; i += rangeIncrementMinutes) {
        minutesPattern.push(i);
      }
      const hours = [];

      let currentPair = 0;
      const pairsAllowedRanges = slots.map((slot) => {
        const { startTime, endTime } = slot;
        const tempHours = Math.trunc(
          getTimeLineMinutesFromDate('end', endTime, startTime, true) / 60
        );
        const tempMinutes =
          getTimeLineMinutesFromDate('end', endTime, startTime, true) - 60 * tempHours;
        const endHours = `${tempHours + 1 < 10 ? `0${tempHours + 1}` : tempHours + 1}:${
          tempMinutes < 10 ? `0${tempMinutes}` : `${tempMinutes}`
        }`;

        const startAllowedTime = getTime(startTime, true);
        const endAllowedTime = endHours === '00:00' ? '24:00' : endHours;

        return { start: startAllowedTime, end: endAllowedTime };
      });
      for (
        let i = +pairsAllowedRanges[0].start.slice(0, 2);
        i <= +pairsAllowedRanges[pairsAllowedRanges.length - 1].end.slice(0, 2);
        i++
      ) {
        hours.push(i);
      }

      return (
        <>
          {hours.map((hour, ind) => {
            const hourTitle = hour - 24 >= 0 ? hour - 24 : hour;
            const isShouldRenderDay = hour - 24 === 0;
            return (
              <div key={ind}>
                {isShouldRenderDay && (
                  <ModalDateTitle>{getYMD(rangeEndTime, true)}</ModalDateTitle>
                )}
                <LineWrapper>
                  <TimeLabel>{hourTitle < 10 ? `0${hourTitle}` : hourTitle}</TimeLabel>
                  <Wrapper>
                    <TimeWrapper column={60 / rangeIncrementMinutes} key={hour}>
                      {minutesPattern.map((minute, i) => {
                        const timeHour = hour < 10 ? `0${hour}` : `${hour}`;
                        const hourForRender =
                          hourTitle < 10 ? `0${hourTitle}` : `${hourTitle}`;
                        const timeMinutes = minute >= 10 ? `${minute}` : `0${minute}`;
                        const time = `${timeHour}:${timeMinutes}`;
                        const timeForRender = `${hourForRender}:${timeMinutes}`;
                        let allowedStatus = false;

                        const checkFunctionAllowedTime =
                          modalName === 'start' ? checkStartTime : checkEndTime;
                        if (
                          checkFunctionAllowedTime(pairsAllowedRanges, currentPair, time)
                        ) {
                          allowedStatus = true;
                        } else {
                          if (
                            currentPair + 1 < pairsAllowedRanges.length &&
                            time > pairsAllowedRanges[currentPair].end
                          ) {
                            currentPair++;
                          }
                          if (
                            checkFunctionAllowedTime(
                              pairsAllowedRanges,
                              currentPair,
                              time
                            )
                          ) {
                            allowedStatus = true;
                          }
                        }
                        const timeInMinutes = hour * 60 + minute;

                        return (
                          <TimeButton
                            margin="0"
                            key={i}
                            isParsedTime
                            startTime={timeForRender}
                            action={() => {
                              if (allowedStatus) {
                                if (modalName === 'start') {
                                  setActiveSlot({
                                    ...activeSlots,
                                    startRangeTime: timeInMinutes,
                                  });
                                } else {
                                  setActiveSlot({
                                    ...activeSlots,
                                    endRangeTime: timeInMinutes,
                                  });
                                }
                                setOpenModal(false);
                              }
                            }}
                            active={
                              modalName === 'start'
                                ? activeSlots.startRangeTime === timeInMinutes
                                : activeSlots.endRangeTime === timeInMinutes
                            }
                            disabled={!allowedStatus}
                          />
                        );
                      })}
                    </TimeWrapper>
                  </Wrapper>
                </LineWrapper>
              </div>
            );
          })}
        </>
      );
    };

    const isBookingButtonDisabled =
      activeSlots.startRangeTime === null ||
      activeSlots.endRangeTime === null ||
      (!!maxBookableMinutes &&
        maxBookableMinutes < activeSlots.endRangeTime - activeSlots.startRangeTime) ||
      (!!minBookableMinutes &&
        minBookableMinutes > activeSlots.endRangeTime - activeSlots.startRangeTime);

    let servicePrice = getPrice(fromPrice || price, currency, true, t(pricePerUnit));
    if (fromPrice) {
      servicePrice = `${t('from')} ${servicePrice}`;
    }

    return (
      <>
        <AppointmentCardContainer
          collapsed={isCollapsed}
          onClick={() => {
            setCollapsed(true);
          }}
          data-testid="appointment-card-flexible-slots"
          withPaddingTop={!coverImageUrl}
        >
          <Flex>
            <Flex flexDirection="column" width="100%">
              {coverImageUrl && isCollapsed ? (
                <AppointmentCardCoverImage img={coverImageUrl} />
              ) : null}
              <Flex alignItems="center" style={{ padding: '0 25px' }}>
                {avatarUrl && (
                  <Avatar size={50} url={avatarUrl} style={{ marginRight: '22px' }} />
                )}
                <CardHeader>{name}</CardHeader>
              </Flex>
              <CardDescription>{CollapseText(description)}</CardDescription>
              {servicePrice && (
                <Flex alignItems="center" style={{ padding: '0 25px' }}>
                  <CustomIcon
                    width="16px"
                    height="16px"
                    margin="0 10px 0 0"
                    icon={PriceIcon}
                  />
                  <CardPriceData>{servicePrice}</CardPriceData>
                </Flex>
              )}
              <Flex margin="0 0 0 18px" style={{ padding: '0 25px' }}>
                {minBookableMinutes && (
                  <MinMaxBlock
                    isError={
                      !!activeSlots.endRangeTime &&
                      !!activeSlots.startRangeTime &&
                      activeSlots.endRangeTime - activeSlots.startRangeTime <
                        minBookableMinutes
                    }
                  >
                    {t('min', { value: minBookableMinutes })}
                  </MinMaxBlock>
                )}
                {maxBookableMinutes && (
                  <MinMaxBlock
                    isError={
                      !!activeSlots.endRangeTime &&
                      !!activeSlots.startRangeTime &&
                      activeSlots.endRangeTime - activeSlots.startRangeTime >
                        maxBookableMinutes
                    }
                  >
                    {t('max', { value: maxBookableMinutes })}
                  </MinMaxBlock>
                )}
              </Flex>
              {isCollapsed && (
                <div style={{ padding: '0 25px' }}>
                  <TimeLine
                    rangeIncrementMinutes={rangeIncrementMinutes}
                    maxBookableMinutes={maxBookableMinutes}
                    minBookableMinutes={minBookableMinutes}
                    isMobileBrowser={isMobile}
                    resourceId={checkoutItemData.resourceId}
                    rangeStartTime={rangeStartTime}
                    rangeEndTime={rangeEndTime}
                    activeSlots={activeSlots}
                    setActiveSlot={setActiveSlot}
                    slots={slots}
                  />
                </div>
              )}
            </Flex>
          </Flex>
          {isCollapsed && (
            <>
              <CardDateContainer>
                <CardDateBlock
                  type={APPOINTMENT_TYPE_FLEXIBLE}
                  startAction={() => {
                    setOpenModal(true);
                    setModalName('start');
                  }}
                  endAction={() => {
                    setOpenModal(true);
                    setModalName('end');
                  }}
                  rangeStartTime={rangeStartTime}
                  startTime={activeSlots.startRangeTime}
                  endTime={activeSlots.endRangeTime}
                />
              </CardDateContainer>
              {!isBookingButtonDisabled && (
                <CardButton
                  disabled={isBookingButtonDisabled}
                  onClick={() => {
                    if (!loading) {
                      setLoading(true);
                      addItemToCheckout(
                        profileId,
                        [getRequestTimeSlot(rangeStartTime)],
                        setLoading
                      );
                      postCTAEventMessage(getAddBookingCTAEvent());
                    }
                  }}
                >
                  {loading ? <Loader isWhite small /> : t('addToCart')}
                </CardButton>
              )}
            </>
          )}

          <ExpandCollapseContainer
            onClick={handleToggle}
            style={{ bottom: '-13px', position: 'absolute', right: '0px', left: '0px' }}
          >
            {isCollapsed ? (
              <CustomIcon
                bottom="1px"
                position="relative"
                width="13px"
                height="13px"
                icon={ArrowUp}
                hover
                cursor
              />
            ) : (
              <CustomIcon
                top="1px"
                position="relative"
                width="13px"
                height="13px"
                icon={ArrowDown}
                hover
                cursor
              />
            )}
          </ExpandCollapseContainer>
        </AppointmentCardContainer>
        {isModalOpen && (
          <Modal>
            <ModalBody>
              <IconClose>
                <CustomIcon
                  width="20px"
                  height="20px"
                  icon={CloseIcon}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  hover
                  cursor
                  device={devices.mobile}
                  deviceHeight="25px"
                  deviceWidth="25px"
                />
              </IconClose>
              <ModalCardName>{t(modalName === 'start' ? 'start' : 'end')}</ModalCardName>
              <ModalTimeButtonWrapper>{renderModalBody()}</ModalTimeButtonWrapper>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
);
