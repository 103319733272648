import './style.scss';

type Props = {
  active: boolean;
};

export const Hamburger = (props: Props) => {
  const { active } = props;
  return (
    <div className={`checkbox ${active ? 'active' : ''}`} data-testid="hamburger">
      <div className="hamburger hamburger1">
        <span className="bar bar1" />
        <span className="bar bar4" />
      </div>
    </div>
  );
};
