import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { urls } from 'helpers';
import { Namespaces } from 'languages';
import { Input as CustomField } from 'containers/Input';

import { LoginStore } from 'store/LoginStore';

import Password from 'assets/icons/password.svg';

import { PrimaryButton } from 'styled/PrimaryButton';
import Layout from 'styled/Layout';

const Card = styled(Layout)`
  margin: 20px 15px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin-top: 40px;
  text-align: center;
`;

const FormItem = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto 40px;
  padding: 0 12px;
`;

const Error = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
`;

const Input = styled(CustomField)`
  width: 90%;
  height: 52px;
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
`;

const ButtonSend = styled(PrimaryButton)`
  width: 90%;
  height: 50px;
  margin: 30px auto;
  border-radius: 30px;
`;

const Spinner = styled(Spin)`
  height: 20px;
  i {
    background-color: white;
  }
`;

const ErrorWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  padding: 0 30px;
  margin: 10px auto;
  background: #feecee;
  border-radius: 5px;
  text-align: center;
`;

const ErrorServer = styled.div`
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.red};
  font-size: 13px;
`;

const SuccessWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 10px auto;
  padding: 0 30px;
  background: #04ff8b26;
  border-radius: 5px;
  text-align: center;
`;

const SuccessMessage = styled.div`
  padding: 5px 0;
  color: #47a51a;
  font-size: 13px;
`;

export const ResetPassword = observer(() => {
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirm, setErrorConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  const [isServerError, setServerError] = useState('');
  const [hideFields, setHideFields] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState('');
  const { resetPassword, loading } = LoginStore;
  const history = useHistory();
  const { search } = useLocation<{ hash: string }>();
  const query = new URLSearchParams(search);
  const hash = query.get('hash') as string;

  if (!hash) {
    history.replace(urls.auth);
  }

  const { t } = useTranslation(Namespaces.UI);

  const validationForm = (): boolean => {
    let isError = false;
    if (!password) {
      isError = true;
      setErrorPassword(t('errorRequired'));
    } else if (password.length < 8) {
      isError = true;
      setErrorPassword(t('errorPasswordShort'));
    }
    if (!confirmPassword) {
      isError = true;
      setErrorConfirm(t('errorRequired'));
    } else if (password !== confirmPassword) {
      isError = true;
      setErrorConfirm(t('errorConfirm'));
    }
    return !isError;
  };

  const handleChangePassword = (value: string) => {
    setErrorPassword('');
    setPassword(value);
  };
  const handleChangeConfirm = (value: string) => {
    setErrorConfirm('');
    setConfirmPassword(value);
  };

  const submitForm = () => {
    setServerError('');
    if (validationForm()) {
      setErrorConfirm('');
      setErrorPassword('');
      resetPassword(password, hash).then((error: string) => {
        if (!error) {
          setHideFields(true);
          setSuccessMessage(t('resetPasswordSuccess'));
          setForgotPasswordStatus(true);
        } else {
          setServerError(error);
        }
      });
    }
  };

  const renderButton = () =>
    forgotPasswordStatus ? (
      <ButtonSend
        onClick={() => {
          history.push(urls.auth);
        }}
      >
        {t('buttonGoToLogin')}
      </ButtonSend>
    ) : (
      <ButtonSend onClick={submitForm}>{t('update')}</ButtonSend>
    );
  return (
    <Card>
      <Title>{t('changePassword')}</Title>
      <ErrorWrap>{isServerError && <ErrorServer>{isServerError}</ErrorServer>}</ErrorWrap>
      <SuccessWrap>
        {isSuccessMessage && <SuccessMessage>{isSuccessMessage}</SuccessMessage>}
      </SuccessWrap>
      {!hideFields && (
        <FormItem>
          {errorPassword && <Error>{`* ${errorPassword}`}</Error>}
          <Input
            InputIcon={Password}
            type="password"
            placeholder={t('password')}
            value={password}
            change={handleChangePassword}
          />
        </FormItem>
      )}
      {!hideFields && (
        <FormItem>
          {errorConfirm && <Error>{`* ${errorConfirm}`}</Error>}
          <Input
            InputIcon={Password}
            type="password"
            placeholder={t('confirmPassword')}
            value={confirmPassword}
            change={handleChangeConfirm}
          />
        </FormItem>
      )}

      {loading ? (
        <ButtonSend>
          <Spinner />
        </ButtonSend>
      ) : (
        renderButton()
      )}
    </Card>
  );
});
