import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useHistory, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/Loader/Loader';
import { Checkbox } from 'components/Checkbox';
import { TermsModal } from './TermsModal';
import { getPrice, urls } from 'helpers';
import { Namespaces } from 'languages';
import {
  CARD,
  DONT_ASK,
  INVOICE,
  MANDATORY,
  ON_ARRIVAL,
  SWISH,
} from 'constants/checkoutConstants';
import { devices } from 'constants/mediaConstants';
import { CHECKOUT_BANKID_MANDATORY } from '../../constants/apiErrorCodeConstants';

import { CheckoutData, CheckoutStore } from 'store/CheckoutStore';
import { LoginStore } from 'store/LoginStore';
import { HeaderStore } from 'store/HeaderStore';
import { PaymentStore } from 'store/PaymentStore';

import Invoice from 'assets/icons/invoice.svg';
import Visa from 'assets/icons/visa.svg';
import Mastercard from 'assets/icons/mastercard.svg';
import Swish from 'assets/icons/swish.svg';
import PaySite from 'assets/icons/paySite.svg';
import ArrowRight from 'assets/icons/arrowRight.svg';

import { Flex } from 'styled/Flex';
import { PrimaryButton } from 'styled/PrimaryButton';
import { ErrorStore } from 'store/ErrorStore';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const TotalBlock = styled.div`
  max-width: 400px;
  margin: 30px auto 30px auto;
  padding: 23px 55px 19px 72px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 20px;

  @media ${devices.mobile} {
    margin: 30px auto 0;
    padding: 20px 10px 10px;
  }
`;

const TotalPriceTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 29px;
  margin-right: 4px;

  @media ${devices.mobile} {
    font-size: 26px;
  }
`;

const TotalPrice = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 29px;
  text-align: right;

  @media ${devices.mobile} {
    font-size: 26px;
  }
`;

const VatAmount = styled.div`
  color: #a6a6a6;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

const IconBlock = styled.div`
  width: 75px;
`;

const PaymentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 400px;
  max-height: 58px;
  margin: 15px auto;
  padding: 17px 40px;
  color: ${({ theme }) => theme.colors.darkBlack};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  cursor: pointer;
  opacity: ${(props: { isDisabled: boolean }) => (props.isDisabled ? 0.5 : 1)};
  cursor: ${(props: { isDisabled: boolean }) =>
    props.isDisabled ? 'not-allowed' : 'pointer'};
  :hover {
    opacity: 0.7;
  }

  @media ${devices.mobile} {
    padding: 14px 10px;
  }
`;

const PayText = styled.div`
  margin-right: 5px;
  font-weight: normal;
  font-size: 16px;
`;
const PayBoldText = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const TermsError = styled.div`
  top: 100%;
  color: ${({ theme }) => theme.colors.red};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

const CheckoutErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 30px auto 30px auto;
  padding: 0 30px;
  background: #feecee;
  border-radius: 5px;
  text-align: center;
`;

const CheckoutError = styled.div`
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.red};
  font-size: 13px;
`;

const CheckboxWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0 30px 0;
`;

const ConfirmButton = styled(PrimaryButton)`
  margin: 30px auto 30px auto;
  max-width: 250px;
`;

export const ConfirmCard = observer(
  (props: {
    setIsCheckoutItemsCardOpenByError: Dispatch<SetStateAction<boolean>>;
    setIsAddonsCardOpenByError: Dispatch<SetStateAction<boolean>>;
    setIsCustomerInfoCardOpenByError: Dispatch<SetStateAction<boolean>>;
    setIsDocumentSignCardOpenByError: Dispatch<SetStateAction<boolean>>;
    setIsBankIDCardOpenByError: Dispatch<SetStateAction<boolean>>;
  }) => {
    const {
      setIsCheckoutItemsCardOpenByError,
      setIsAddonsCardOpenByError,
      setIsCustomerInfoCardOpenByError,
      setIsDocumentSignCardOpenByError,
      setIsBankIDCardOpenByError,
    } = props;
    const history = useHistory();
    const [isModalOpen, setModalOpen] = useState(false);
    const [termsError, setTermsError] = useState('');
    const { setBackButton } = HeaderStore;
    const [loader, setLoader] = useState('');
    const [isAcceptedTerms, setAcceptedTerms] = useState(false);
    const {
      checkout,
      replaceStore,
      payOnArrival,
      setFieldError,
      createInvoiceTransaction,
      createCardTransaction,
      createSwishTransaction,
      paymentInfo,
    } = CheckoutStore;
    const { isActivePayment, setActivePayment, removeActivePayment } = PaymentStore;

    const { checkoutId } = useParams<{
      checkoutId: string;
    }>();
    const transactionLinkHash = new URLSearchParams(useLocation().search).get(
      'transactionLinkHash'
    ) as string;
    const { user } = LoginStore;
    const { error, removeError } = ErrorStore;
    const checkoutData = toJS(checkout);
    const paymentInfoData = toJS(paymentInfo);
    if (!checkoutData) {
      return null;
    }
    const { paymentMethods, paymentMode } = checkoutData;
    const { t } = useTranslation(Namespaces.UI);

    const responseCard = (hasError?: boolean) => {
      if (hasError) {
        setLoader('');
        return;
      }
      if (transactionLinkHash) {
        history.push(
          urls.cardPaymentWithTransactionLinkHash(checkoutId, transactionLinkHash)
        );
      } else {
        history.push(urls.cardPayment(checkoutId));
      }
    };

    const responseSwish = (hasError?: boolean) => {
      if (hasError) {
        setLoader('');
        return;
      }
      if (transactionLinkHash) {
        history.push(
          urls.swishPaymentWithTransactionLinkHash(checkoutId, transactionLinkHash)
        );
      } else {
        history.push(urls.swishPayment(checkoutId));
      }
    };

    const responseInvoice = (hasError?: boolean) => {
      setLoader('');
      if (hasError) {
        setLoader('');
        return;
      }
      if (transactionLinkHash) {
        history.push(
          urls.invoiceWithTransactionLinkHash(checkoutId, transactionLinkHash)
        );
      } else {
        history.push(urls.invoice(checkoutId));
      }
    };

    const payOnArrivalCallback = useCallback(
      (newCheckout: CheckoutData | undefined) => {
        setLoader('');
        if (!newCheckout) {
          return;
        }
        replaceStore(newCheckout);
        history.push('/confirmation');
        setBackButton(false);
      },
      [history, replaceStore, setBackButton]
    );

    const isUserReadyToBook = () => {
      if (checkoutData.termsAndConditionsMode === MANDATORY && !isAcceptedTerms) {
        setTermsError(t('errorTerms'));
        return false;
      }

      if (!user.id && !transactionLinkHash) {
        localStorage.prevPath = history.location.pathname + history.location.search;
        setActivePayment();
        history.replace('/auth');
        return false;
      }
      return true;
    };

    const isCheckoutReady = () => {
      let isValid = true;
      let scrollToViewId;

      const checkoutItems = checkoutData?.items ? checkoutData.items : [];
      if (checkoutItems.find((x) => x.proxyUsersMode === MANDATORY)) {
        const userProxyMandatoryItems = checkoutItems.filter(
          (x) => x.proxyUsersMode === MANDATORY
        );
        const itemWithNoUserProxies = userProxyMandatoryItems.find(
          (x) => !x.recipients || x.recipients.length === 0
        );
        if (itemWithNoUserProxies) {
          scrollToViewId = scrollToViewId || 'checkoutItemsCardAnchor';
          setFieldError(
            'checkoutItemRecipient',
            t('checkoutItemUserProxyMandatoryError')
          );
          isValid = false;
          setIsCheckoutItemsCardOpenByError(true);
        }
      }

      if (checkoutData.addonsMode === MANDATORY && !checkoutData.hasMandatoryAddons) {
        scrollToViewId = scrollToViewId || 'addonsCardAnchor';
        setFieldError('addonsMandatory', t('checkoutAddonsMandatoryError'));
        isValid = false;
        setIsAddonsCardOpenByError(true);
      }

      if (checkoutData.phoneNumberMode === MANDATORY && !paymentInfoData?.phoneNumber) {
        scrollToViewId = 'customerInfoCardAnchor';
        setFieldError('phoneNumber', t('errorPhoneNumber'));
        isValid = false;
        setIsCustomerInfoCardOpenByError(true);
      }

      if (
        checkoutData.customerMessageMode === MANDATORY &&
        !paymentInfoData?.customerMessage
      ) {
        scrollToViewId = scrollToViewId || 'customerInfoCardAnchor';
        setFieldError('customerMessage', t('errorMessage'));
        isValid = false;
        setIsCustomerInfoCardOpenByError(true);
      }

      if (
        checkoutData.bankIDMode === MANDATORY &&
        checkoutData?.bankIDStatus !== 'complete'
      ) {
        scrollToViewId = scrollToViewId || 'documentBankIDAnchor';
        setFieldError(
          'bankID',
          t('checkoutBankIDNotCompleted'),
          CHECKOUT_BANKID_MANDATORY
        );
        isValid = false;
        setIsBankIDCardOpenByError(true);
      }

      if (
        checkoutData.documentSigningMode === MANDATORY &&
        checkoutData?.documentSigningStatus !== 'signed'
      ) {
        scrollToViewId = scrollToViewId || 'documentSignCardAnchor';
        setFieldError('documentSigningStatus', t('checkoutSignDocumentNotSignedError'));
        isValid = false;
        setIsDocumentSignCardOpenByError(true);
      }

      if (scrollToViewId) {
        document.getElementById(scrollToViewId)?.scrollIntoView();
      }

      return isValid;
    };

    useEffect(() => {
      if (error) {
        // Clear any previous checkout error
        removeError();
      }
    }, []);

    useEffect(() => {
      if (isActivePayment && user.id) {
        removeActivePayment();
        setAcceptedTerms(true);
      }
    }, [isActivePayment, removeActivePayment, user.id]);

    if (paymentMode === DONT_ASK) {
      return (
        <>
          {checkoutData.termsAndConditionsMode !== DONT_ASK && (
            <CheckboxWrap>
              <Checkbox
                isActive={isAcceptedTerms}
                setSelect={() => {
                  setTermsError('');
                  setAcceptedTerms(!isAcceptedTerms);
                }}
                linkAction={() => {
                  setModalOpen(true);
                }}
              />
              {termsError && <TermsError>{termsError}</TermsError>}
            </CheckboxWrap>
          )}

          {isModalOpen && checkoutData.termsAndConditions && (
            <TermsModal
              title={t('termsModalTitle')}
              description={checkoutData?.termsAndConditions}
              close={() => {
                setModalOpen(false);
              }}
            />
          )}

          {error && error.place === 'onArrival' && (
            <CheckoutErrorContainer>
              <CheckoutError>{error.message || t('errorMessageDefault')}</CheckoutError>
            </CheckoutErrorContainer>
          )}

          <ConfirmButton
            size="large"
            onClick={() => {
              if (error) {
                removeError();
              }
              if (!isUserReadyToBook()) {
                return;
              }
              if (!loader && isCheckoutReady()) {
                setLoader('arrival');
                payOnArrival(checkoutData.id, transactionLinkHash, payOnArrivalCallback);
              }
            }}
            disabled={loader !== 'arrival' && !!loader.length}
          >
            {loader === 'arrival' ? <Loader isWhite small /> : t('confirm')}
          </ConfirmButton>
        </>
      );
    }

    return (
      <>
        <TotalBlock>
          <Flex justifyContent="space-evenly" alignItems="center">
            <TotalPriceTitle>{t('total').toUpperCase()}</TotalPriceTitle>
            <TotalPrice>{`${getPrice(
              checkoutData.price,
              checkoutData.currency,
              false
            )}`}</TotalPrice>
          </Flex>
          <VatAmount>
            {`(${t('includingTax')} ${getPrice(
              Math.round((checkoutData.price - checkoutData.priceExclVat) * 100) / 100,
              checkoutData.currency,
              false
            )})`}
          </VatAmount>
        </TotalBlock>
        {checkoutData.termsAndConditionsMode !== DONT_ASK && (
          <CheckboxWrap>
            <Checkbox
              isActive={isAcceptedTerms}
              setSelect={() => {
                setTermsError('');
                setAcceptedTerms(!isAcceptedTerms);
              }}
              linkAction={() => {
                setModalOpen(true);
              }}
            />

            {termsError && <TermsError>{termsError}</TermsError>}
          </CheckboxWrap>
        )}

        {error &&
          (error.place === 'onArrival' ||
            error.place === 'swishTransaction' ||
            error.place === 'cardTransaction' ||
            error.place === 'invoiceTransaction') && (
            <CheckoutErrorContainer>
              <CheckoutError>{error.message || t('errorMessageDefault')}</CheckoutError>
            </CheckoutErrorContainer>
          )}

        {paymentMethods.includes(ON_ARRIVAL) && (
          <PaymentRow
            onClick={() => {
              if (error) {
                removeError();
              }
              if (!isUserReadyToBook()) {
                return;
              }
              if (!loader && isCheckoutReady()) {
                setLoader('arrival');
                payOnArrival(checkoutData.id, transactionLinkHash, payOnArrivalCallback);
              }
            }}
            isDisabled={loader !== 'arrival' && !!loader.length}
          >
            {loader === 'arrival' ? (
              <Loader small />
            ) : (
              <>
                <IconBlock>
                  <CustomIcon
                    icon={PaySite}
                    width="28px"
                    height="23px"
                    margin="0 7px 0 0"
                    cursor
                  />
                </IconBlock>
                <Flex margin="0 0 0 -50px">
                  <PayText>{t('payOn')}</PayText>
                  <PayBoldText>{t('arrival')}</PayBoldText>
                </Flex>
                <CustomIcon
                  icon={ArrowRight}
                  width="28px"
                  height="16px"
                  cursor
                  margin="0 7px 0 0"
                />
              </>
            )}
          </PaymentRow>
        )}

        {paymentMethods.includes(SWISH) && (
          <PaymentRow
            onClick={() => {
              if (error) {
                removeError();
              }
              if (!isUserReadyToBook()) {
                return;
              }
              if (!loader && isCheckoutReady()) {
                setLoader('swish');
                createSwishTransaction(checkoutId, transactionLinkHash, responseSwish);
              }
            }}
            isDisabled={!!loader}
          >
            {loader === 'swish' ? (
              <Loader small />
            ) : (
              <>
                <IconBlock>
                  <CustomIcon
                    icon={Swish}
                    width="48px"
                    height="28px"
                    cursor
                    margin="0 7px 0 0"
                  />
                </IconBlock>
                <Flex margin="0 0 0 -50px">
                  <PayText>{t('payWith')}</PayText>
                  <PayBoldText>{t('swish')}</PayBoldText>
                </Flex>
                <CustomIcon
                  icon={ArrowRight}
                  width="28px"
                  height="16px"
                  cursor
                  margin="0 7px 0 0"
                />
              </>
            )}
          </PaymentRow>
        )}

        {paymentMethods.includes(CARD) && (
          <PaymentRow
            onClick={() => {
              if (error) {
                removeError();
              }
              if (!isUserReadyToBook()) {
                return;
              }
              if (!loader && isCheckoutReady()) {
                setLoader('card');
                createCardTransaction(checkoutId, transactionLinkHash, responseCard);
              }
            }}
            isDisabled={!!loader}
          >
            {loader === 'card' ? (
              <Loader small />
            ) : (
              <>
                <IconBlock>
                  <Flex>
                    <CustomIcon
                      icon={Visa}
                      width="29px"
                      height="18px"
                      cursor
                      margin="0 7px 0 0"
                    />
                    <CustomIcon
                      icon={Mastercard}
                      width="28px"
                      height="18px"
                      cursor
                      margin="0 7px 0 0"
                    />
                  </Flex>
                </IconBlock>
                <Flex margin="0 0 0 -50px">
                  <PayText>{t('payWith')}</PayText>
                  <PayBoldText>{t('creditCard')}</PayBoldText>
                </Flex>
                <CustomIcon
                  icon={ArrowRight}
                  width="28px"
                  height="16px"
                  cursor
                  margin="0 7px 0 0"
                />
              </>
            )}
          </PaymentRow>
        )}

        {paymentMethods.includes(INVOICE) && (
          <PaymentRow
            onClick={() => {
              if (error) {
                removeError();
              }
              if (!isUserReadyToBook()) {
                return;
              }
              if (!loader && isCheckoutReady()) {
                setLoader('invoice');
                createInvoiceTransaction(
                  checkoutId,
                  transactionLinkHash,
                  responseInvoice
                );
              }
            }}
            isDisabled={loader !== 'invoice' && !!loader.length}
          >
            {loader === 'invoice' ? (
              <Loader small />
            ) : (
              <>
                <IconBlock>
                  <CustomIcon
                    icon={Invoice}
                    width="28px"
                    height="23px"
                    cursor
                    margin="0 7px 0 0"
                  />
                </IconBlock>
                <Flex margin="0 0 0 -50px">
                  <PayText>{t('payWith')}</PayText>
                  <PayBoldText>{t('invoice')}</PayBoldText>
                </Flex>
                <CustomIcon
                  icon={ArrowRight}
                  width="28px"
                  height="16px"
                  cursor
                  margin="0 7px 0 0"
                />
              </>
            )}
          </PaymentRow>
        )}

        {isModalOpen && checkoutData.termsAndConditions && (
          <TermsModal
            title={t('termsModalTitle')}
            description={checkoutData?.termsAndConditions}
            close={() => {
              setModalOpen(false);
            }}
          />
        )}
      </>
    );
  }
);
