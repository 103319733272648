import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';
import { UserStore, UserProxy } from 'store/UserStore';
import {
  EMAIL_INVALID,
  ACCOUNT_ALREADY_EXISTS,
} from '../../constants/apiErrorCodeConstants';

import { Input } from 'components/Input';
import { Loader } from 'components/Loader/Loader';
import { PrimaryButton } from 'styled/PrimaryButton';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

const ModalBody = styled.div`
  position: relative;
  width: 545px;
  padding: 65px 65px 65px 65px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    height: 90vh;
    overflow: scroll;
    padding: 25px;
  }
`;

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
`;

const ModalSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 8px;
`;

const InputWrap = styled.div`
  position: relative;
  margin-top: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  user-select: none;

  @media ${devices.mobile} {
    width: 95%;
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const ButtonSave = styled(PrimaryButton)`
  width: 300px;
  height: 56px;
  font-size: 17px;
`;

const ButtonCancel = styled(ButtonSave)`
  margin-top: 20px;
`;

export const AddUserProxyModal = observer(
  (props: { onUserProxyAdded: () => void; onCloseClicked: () => void }) => {
    const { onUserProxyAdded, onCloseClicked } = props;
    const { addUserProxy, isAddUserProxyLoading } = UserStore;
    const { t } = useTranslation(Namespaces.UI);

    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [birthDay, setBirthDay] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const onSaveClicked = () => {
      if (isAddUserProxyLoading) return;

      let data = {
        firstName,
        surname,
        birthDay,
      } as UserProxy;

      if (email) {
        data.email = email;
      }

      let fieldErrors = {};
      if (!data.firstName) {
        fieldErrors = { ...fieldErrors, firstName: t('errorRequired') };
      }
      if (!data.surname) {
        fieldErrors = { ...fieldErrors, surname: t('errorRequired') };
      }

      if (!data.birthDay) {
        fieldErrors = { ...fieldErrors, birthDay: t('errorRequired') };
      } else {
        if (data.birthDay.length !== 10) {
          fieldErrors = { ...fieldErrors, birthDay: t('errorInvalidDate') };
        } else {
          var birthDayMoment = moment(data.birthDay);
          if (!birthDayMoment.isValid()) {
            fieldErrors = { ...fieldErrors, birthDay: t('errorInvalidDate') };
          }
        }
      }

      if (Object.keys(fieldErrors).length > 0) {
        setErrors(fieldErrors);
        return;
      }

      setErrors({});
      addUserProxy(data, (success, errorCode) => {
        if (success) {
          onUserProxyAdded();
        } else {
          if (errorCode === EMAIL_INVALID) {
            setErrors({ ...fieldErrors, email: t('errorInvalidEmail') });
          } else if (errorCode === ACCOUNT_ALREADY_EXISTS) {
            setErrors({ ...fieldErrors, email: t('addUserProxyErrorEmailExists') });
          }
        }
      });
    };

    return (
      <Modal>
        <ModalBody>
          <ModalTitleContainer>
            <ModalTitle>{t('addUserProxyModalTitle')}</ModalTitle>
            <ModalSubtitle>{t('addUserProxyModalSubtitle')}</ModalSubtitle>
          </ModalTitleContainer>
          <InputWrap>
            {t('addUserProxyModalSectionName')}
            <Input
              value={firstName}
              title={t('firstName')}
              disabled={false}
              required={true}
              error={errors.firstName}
              change={(value: string) => {
                setFirstName(value);
              }}
            />
            <Input
              value={surname}
              title={t('surname')}
              disabled={false}
              required={true}
              error={errors.surname}
              change={(value: string) => {
                setSurname(value);
              }}
            />
          </InputWrap>
          <InputWrap>
            {t('addUserProxyModalSectionBirthday')}
            <Input
              value={birthDay}
              title={t('birthday')}
              disabled={false}
              required={true}
              error={errors.birthDay}
              placeholder={'YYYY-MM-DD'}
              change={(value: string) => {
                setBirthDay(value);
              }}
              maxLength={10}
              onKeyDown={(e) => {
                const element = e.currentTarget as HTMLInputElement;
                const currentValue = element.value;
                const key = e.key;
                if (key === 'Backspace') {
                  return;
                }

                if (isNaN(key as any)) {
                  // Not a number..
                  e.preventDefault();
                }

                var len = currentValue.length;
                let changedValue = currentValue;
                if (len === 4 || len === 7) {
                  changedValue += '-';
                }

                if (changedValue !== currentValue) {
                  setBirthDay(changedValue);
                }
              }}
            />
          </InputWrap>
          <InputWrap>
            {t('addUserProxyModalSectionEmail')}
            <Input
              value={email}
              type={'email'}
              title={t('email')}
              disabled={false}
              required={false}
              error={errors.email}
              change={(value: string) => {
                setEmail(value);
              }}
            />
          </InputWrap>
          <ModalButtonsContainer>
            <ButtonSave size="large" onClick={() => onSaveClicked()}>
              {isAddUserProxyLoading ? <Loader isWhite /> : <>{t('save')}</>}
            </ButtonSave>
            <ButtonCancel secondary size="large" onClick={() => onCloseClicked()}>
              {t('cancel')}
            </ButtonCancel>
          </ModalButtonsContainer>
        </ModalBody>
      </Modal>
    );
  }
);
