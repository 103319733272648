import { useState, useEffect, useContext } from 'react';

import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import styled, { DefaultTheme, ThemeContext } from 'styled-components';
import { getPrice } from 'helpers';
import { getYMD, getTime, isLocalTimeZoneSameAs } from 'helpers/DateTimeUtils';
import moment from 'moment';
import { Namespaces } from 'languages';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

import { Loader } from 'components/Loader/Loader';
import { CollapseText } from 'components/CollapseText/CollapseText';
import { devices } from 'constants/mediaConstants';

import { CheckoutStore, AddCheckoutItemData } from 'store/CheckoutStore';
import { Course } from 'store/ServiceStore';
import { CourseStore } from 'store/CourseStore';
import { LoginStore } from 'store/LoginStore';

import PriceIcon from 'assets/icons/price.svg';
import infoIcon from 'assets/icons/infoIcon.svg';
import CloseIcon from 'assets/icons/close.svg';

import { PrimaryButton } from 'styled/PrimaryButton';
import Layout from 'styled/Layout';
import { Flex } from 'styled/Flex';
import { CourseOccasions } from './CourseOccasions';
import { Avatar } from 'components/Avatar';

const Card = styled(Layout)`
  position: relative;
  padding: 40px 40px 40px;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: ${(props: { collapsed: boolean }) => (props.collapsed ? 'auto' : 'pointer')};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;
  @media ${devices.mobile} {
    padding: 30px 30px 30px;
  }
`;

const CardHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 22px;
  }
`;

const CardDescription = styled.div`
  margin: 14px 0 24px;
  padding-right: 40px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.middleDark};
  white-space: pre-wrap;
  @media ${devices.mobile} {
    font-size: 14px;
    padding-right: 20px;
  } ;
`;

const CardButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 25px auto 0;
  font-size: 14px;

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;

const WaitListButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 25px auto 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.whiteRed};
  color: ${({ theme }) => theme.colors.white};

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  } ;
`;

const CardPriceIcon = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${PriceIcon});
`;

const Price = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'normal' : 'bold'};
  font-size: 18px;
  text-decoration-line: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'line-through' : 'none'};
  flex-grow: 1;
  @media${devices.mobile} {
    font-size: 14px;
  }
`;

const SpotsLeftLabel = styled.div`
  color: ${(props) => props.color};
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  @media${devices.mobile} {
    font-size: 12px;
  }
`;

const TimeZoneText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  margin-top: 12px;
`;

const CardValidBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
`;

const BookingDurationBlock = styled.div`
  width: 100%;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px 10px 0 0;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;
const BookingStartEndBlock = styled.div`
  display: flex;
  width: 100%;
`;

const BookingStartBlock = styled.div`
  width: 100%;
  margin-top: -1px;
  margin-right: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 0 0 0 10px;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;
const BookingEndBlock = styled.div`
  width: 100%;
  margin-top: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 0 0 10px 0;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;

const GroupLeadersBlock = styled.div`
  margin-top: 25px;
`;

const GroupLeadersBlockName = styled.div`
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

const GroupLeaderName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

const OccasionsBlock = styled.div`
  margin-top: 25px;
`;

const CardDateTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  @media ${devices.mobile} {
    font-size: 11px;
  } ;
`;
const GroupLeaderBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px;
  margin-top: 10px;
  @media ${devices.mobile} {
    padding: 8px;
  } ;
`;

const CardDateValues = styled.div`
  color: ${(props) => props.color};
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 700;
  @media (max-width: 500px) {
    font-size: 16px;
  }
  @media ${devices.mobile} {
    font-size: 12px;
  } ;
`;

const Hour = styled.div`
  margin-left: 10px;
`;

const InfoIcon = styled.div`
  height: 22px;
  width: 22px;
  background-size: cover;
  background-image: url(${infoIcon});
  cursor: pointer;
  margin: 0 30px 0 auto;
  @media ${devices.mobile} {
    height: 18px;
    width: 18px;
    margin: 0 5px 0 auto;
  } ;
`;

type MessageBoxProps = {
  theme: DefaultTheme;
  isRed?: boolean;
};

const MessageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px -40px -40px -40px;
  height: 100px;
  background-color: ${(props: MessageBoxProps) =>
    props.isRed ? props.theme.colors.whiteRed : props.theme.colors.darkGray};
  border-radius: 0 0 30px 30px;
  color: ${(props: MessageBoxProps) => props.theme.colors.white};
  font-family: ${(props: MessageBoxProps) => props.theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  opacity: ${(props: MessageBoxProps) => (props.isRed ? 0.5 : 1)};
  @media ${devices.mobile} {
    margin: 20px -30px -30px -30px;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

const ModalBody = styled.div`
  position: relative;
  width: 545px;
  padding: 65px 67px 40px 65px;
  border-radius: 30px;
  background: ${({ theme }) => theme.colors.white};

  @media ${devices.mobile} {
    width: 90%;
    padding: 25px;
  }
`;

const IconClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  height: 20px;
  width: 20px;
  background-size: contain;
  background-image: url(${CloseIcon});
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media ${devices.mobile} {
    right: 30px;
    top: 30px;
    height: 25px;
    width: 25px;
  }
`;
const ModalCardName = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 22px;
  }
`;

const ModalCardDescription = styled.div`
  margin: 10px 0 27px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 18px;
  line-height: 21px;

  @media ${devices.mobile} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CourseDetails = observer((props: { course: Course }) => {
  const { course } = props;
  const {
    currency,
    description,
    startTime,
    endTime,
    firstBookableAt,
    lastBookableAt,
    id,
    name,
    price,
    isBookPerOccasion,
    isFull,
    isWaitingListEnabled,
    isCallerAttending,
    isCallerOnWaitingList,
    resources,
    spotsLeft,
  } = course;
  const history = useHistory();
  const { profileId } = useParams<{ profileId: string }>();
  const [loading, setLoading] = useState(false);
  const [isIdModalOpen, setIdOpenModal] = useState<number[]>([]);
  const [isCollapsed, setCollapsed] = useState(true);
  const { user } = LoginStore;
  const userData = toJS(user);
  const { addItemToCheckout } = CheckoutStore;
  const {
    course: newCourse,
    fetchCourse,
    clearCourse,
    fetchCourseOccasions,
    addCourseToWaitingLists,
    addCourseOccasionsToWaitingLists,
  } = CourseStore;
  const [selectedButtons, setSelectedButtons] = useState<number[]>([]);
  const [itemsToSelect, setItemsToSelect] = useState<number[]>([]);
  const [allEntries, setAllEntries] = useState(false);
  const { t } = useTranslation(Namespaces.UI);
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    fetchCourse(id);
    return () => {
      clearCourse();
      setItemsToSelect([]);
      setSelectedButtons([]);
    };
  }, [id, fetchCourse, clearCourse]);

  useEffect(() => {
    const { courseId } = localStorage;
    if (courseId && userData.id) {
      addCourseToWaitingLists(courseId, setLoading);
      localStorage.removeItem('courseId');
    }
  }, [userData.id, addCourseToWaitingLists]);

  useEffect(() => {
    const currentCourse = toJS(newCourse);
    if (currentCourse) {
      const { data } = currentCourse;
      const selectableButtons = data.reduce((acc, item) => {
        const { isFull: isFullItem, isCallerAttending: isDisabled, id: itemId } = item;
        if (isBookPerOccasion && !isFullItem && !isDisabled) {
          acc.push(itemId);
        }
        return acc;
      }, [] as number[]);
      setItemsToSelect(selectableButtons);
    }
  }, [isBookPerOccasion, newCourse]);

  var detailsCollapseText = CollapseText(description);
  const currentCourse = toJS(newCourse);
  if (!currentCourse) {
    return <Loader />;
  }

  const { data, page, totalPages, totalRows } = currentCourse;

  const getRequestData = () => {
    const requestData = [] as AddCheckoutItemData[];

    if (isBookPerOccasion) {
      if (allEntries) {
        requestData.push({ serviceId: id, allEntries: true });
        return requestData;
      }
      selectedButtons.forEach((el) => {
        requestData.push({ serviceId: id, entryId: el });
      });
    }
    if (!isBookPerOccasion) {
      requestData.push({ serviceId: id });
    }
    return requestData;
  };

  const isCourseBookable = () => {
    let now = moment();
    return now.isAfter(firstBookableAt) && now.isBefore(lastBookableAt);
  };

  const renderCourseFooter = () => {
    if (!isCourseBookable()) {
      return <></>;
    }

    if (!isBookPerOccasion) {
      if (isCallerAttending) {
        return <MessageBox>{t('haveBookingThis')}</MessageBox>;
      }

      if (isFull && isCallerOnWaitingList) {
        return <MessageBox isRed>{t('waitingListText')}</MessageBox>;
      }

      if (isFull && isWaitingListEnabled) {
        return (
          <WaitListButton
            onClick={() => {
              if (!userData.id) {
                localStorage.prevPath = history.location.pathname;
                localStorage.courseId = id;
                history.push('/auth');
                return;
              }
              if (!loading) {
                setLoading(true);
                addCourseToWaitingLists(id, setLoading);
              }
            }}
          >
            {loading ? <Loader isWhite small /> : t('waitingList')}
          </WaitListButton>
        );
      }

      if (isFull) {
        return <MessageBox>{t('courseFull')}</MessageBox>;
      }

      return (
        <CardButton
          onClick={() => {
            if (!loading) {
              setLoading(true);
              addItemToCheckout(profileId, getRequestData(), setLoading);
              postCTAEventMessage(getAddBookingCTAEvent());
            }
          }}
        >
          {loading ? <Loader isWhite small /> : t('addToCart')}
        </CardButton>
      );
    }

    if (!selectedButtons.length) {
      return <></>;
    }

    return (
      <CardButton
        disabled={isBookPerOccasion && !selectedButtons.length}
        onClick={() => {
          if (!loading && selectedButtons.length) {
            setLoading(true);
            addItemToCheckout(profileId, getRequestData(), setLoading);
            postCTAEventMessage(getAddBookingCTAEvent());
          }
        }}
      >
        {loading ? <Loader isWhite small /> : t('addToCart')}
      </CardButton>
    );
  };

  const servicePrice = getPrice(price, currency);

  let localTimeZoneDiffers =
    !isLocalTimeZoneSameAs(firstBookableAt) || !isLocalTimeZoneSameAs(lastBookableAt);

  return (
    <>
      <Card
        collapsed={isCollapsed}
        onClick={() => {
          setCollapsed(true);
        }}
      >
        <Flex flexDirection="column">
          <Flex flexDirection="column">
            <CardHeader>{name}</CardHeader>
            <CardDescription>{detailsCollapseText}</CardDescription>
            <Flex alignItems="center">
              {servicePrice && (
                <>
                  <CardPriceIcon />
                  <Price>{servicePrice}</Price>
                </>
              )}
              {!isFull && spotsLeft !== undefined && spotsLeft >= 0 && (
                <SpotsLeftLabel
                  color={
                    spotsLeft > 0 ? themeContext.colors.yellow : themeContext.colors.red
                  }
                >
                  {spotsLeft === 0
                    ? t('spotsLeftFull')
                    : spotsLeft === 1
                    ? t('spotsLeftSingle')
                    : t('spotsLeft', {
                        count: spotsLeft,
                      })}
                </SpotsLeftLabel>
              )}
            </Flex>

            {localTimeZoneDiffers && (
              <TimeZoneText>{t('timeZoneDifferText')}</TimeZoneText>
            )}

            <CardValidBlock>
              <BookingDurationBlock>
                <CardDateTitle>{t('courseDuration').toUpperCase()}</CardDateTitle>
                <CardDateValues>{`${getYMD(startTime, true)} ~ ${getYMD(
                  endTime,
                  true
                )}`}</CardDateValues>
              </BookingDurationBlock>
              <BookingStartEndBlock>
                <BookingStartBlock>
                  <CardDateTitle>{t('bookingStart').toUpperCase()}</CardDateTitle>
                  <CardDateValues
                    color={
                      moment().isAfter(firstBookableAt)
                        ? themeContext.colors.darkBlack
                        : themeContext.colors.whiteRed
                    }
                  >
                    {getYMD(firstBookableAt, true)}
                    <Hour>{getTime(firstBookableAt, true)}</Hour>
                  </CardDateValues>
                </BookingStartBlock>
                <BookingEndBlock>
                  <CardDateTitle>{t('bookingEnd').toUpperCase()}</CardDateTitle>
                  <CardDateValues
                    color={
                      moment().isBefore(lastBookableAt)
                        ? themeContext.colors.darkBlack
                        : themeContext.colors.whiteRed
                    }
                  >
                    {getYMD(lastBookableAt, true)}
                    <Hour>{getTime(lastBookableAt, true)}</Hour>
                  </CardDateValues>
                </BookingEndBlock>
              </BookingStartEndBlock>
            </CardValidBlock>
            {resources?.length && (
              <GroupLeadersBlock>
                <GroupLeadersBlockName>{t('groupLeaders')}</GroupLeadersBlockName>
                {resources.map((element) => {
                  return (
                    <GroupLeaderBlock key={element.id}>
                      <Avatar
                        size={64}
                        url={element.avatarUrl}
                        style={{ marginRight: '22px' }}
                      />
                      <GroupLeaderName>{element.name}</GroupLeaderName>
                      <InfoIcon
                        onClick={() => {
                          setIdOpenModal([element.id]);
                        }}
                      />
                    </GroupLeaderBlock>
                  );
                })}
              </GroupLeadersBlock>
            )}
            <OccasionsBlock>
              <CourseOccasions
                data={data}
                isBookPerOccasion={isBookPerOccasion}
                selectedButtons={selectedButtons}
                setSelectedButtons={setSelectedButtons}
                page={page}
                totalPages={totalPages}
                totalRows={totalRows}
                id={id}
                itemsToSelect={itemsToSelect}
                allEntries={allEntries}
                isCourseBookable={isCourseBookable()}
                setAllEntries={setAllEntries}
                fetchCourseOccasions={fetchCourseOccasions}
                addCourseOccasionsToWaitingLists={addCourseOccasionsToWaitingLists}
              />
            </OccasionsBlock>
          </Flex>
        </Flex>
        {renderCourseFooter()}
      </Card>
      {isIdModalOpen.length ? (
        <Modal>
          <ModalBody>
            <IconClose
              onClick={() => {
                setIdOpenModal([]);
              }}
            />
            {resources.map((element) => {
              if (isIdModalOpen.includes(element.id)) {
                return (
                  <div key={element.id}>
                    <Avatar
                      size={115}
                      url={element.avatarUrl}
                      style={{ marginRight: '22px' }}
                    />
                    <ModalCardName>{element.name}</ModalCardName>
                    <ModalCardDescription>{element.description}</ModalCardDescription>
                  </div>
                );
              }
              return null;
            })}
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
});
