import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import Layout from 'styled/Layout';

export const BookingsElement = styled(Layout)`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
`;

export const CardBody = styled.div`
  margin-bottom: 15px;
  padding: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  user-select: none;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};

  @media ${devices.mobile} {
    padding: 14px;
  }
`;

export type CardLogoType = {
  size?: string;
  url?: string;
};

export const CardLogo = styled.div`
  width: ${(props: CardLogoType) => props.size};
  height: ${(props: CardLogoType) => props.size};
  margin: 0 16px 0 0;
  background-size: cover;
  background-image: url(${(props: CardLogoType) => props.url});
  border-radius: 50%;
`;

export const CardTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: bold;
  font-size: 24px;

  @media ${devices.mobile} {
    font-size: 20px;
  }
`;

export const Header = styled.div`
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  user-select: none;
`;

export const CardHeader = styled.div`
  margin: 22px 22px 14px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: bold;
  font-size: 24px;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

export const ModalBody = styled.div`
  position: relative;
  width: 545px;
  padding: 65px 67px 40px 65px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    padding: 25px;
  }
`;

export const ModalTitle = styled.div`
  margin-top: 33px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: bold;
  font-size: 24px;

  @media ${devices.mobile} {
    margin-top: 25px;
    font-size: 22px;
  }
`;

export const ModalDescription = styled.div`
  margin-bottom: 27px;
  color: #6f6f6f;
  font-size: 18px;
  line-height: 21px;

  @media ${devices.mobile} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ModalDataText = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;

  @media ${devices.mobile} {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const ModalDataValue = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-size: 18px;
  line-height: 30px;

  @media ${devices.mobile} {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const ModalLink = styled.a`
  border-bottom: 1px solid #40a9ff;
  font-size: 18px;
  line-height: 20px;
`;

export const CancelModalText = styled.div`
  margin: 25px 0 7px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
`;

export const BoldText = styled.b`
  margin: 0 5px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
`;

export const CancelModalSubText = styled.div`
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

export const ModalCancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: #6f6f6f;
  border: 1px solid #a6a6a6;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
  @media ${devices.mobile} {
    margin-bottom: 10px;
  }
`;

export const ModalRejectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkBlack};
  border-radius: 28px;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.9;
  }
  @media ${devices.mobile} {
    margin: 5px auto 10px;
  }
`;

export const IconClose = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;

  @media ${devices.mobile} {
    right: 30px;
    top: 30px;
  }
`;
