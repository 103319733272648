import { useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';
import { getYMD } from 'helpers/DateTimeUtils';
import ArrowDown from 'assets/icons/arrow-down.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const MAX_VALID_FOR_SERVICE = 8;
const CardValidBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
`;

type ValidThruBlockProps = {
  theme: DefaultTheme;
  isLastBlock: boolean;
};
const ValidThruBlock = styled.div`
  width: 100%;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${(props: ValidThruBlockProps) => props.theme.colors.middleGray};
  border-radius: ${(props: ValidThruBlockProps) =>
    props.isLastBlock ? '10px' : '10px 10px 0 0'};;
  @media ${devices.mobile} {
    padding: 8px;
  },
`;
const ValidForBlock = styled.div`
  width: 100%;
  margin-top: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 0 0 10px 10px;
  @media ${devices.mobile} {
    padding: 8px;
  },
`;

const ServiceItem = styled.li`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  line-height: 24px;
  font-size: 15px;
`;
const ServiceList = styled.ul`
  padding: 0 18px;
`;
const ItemMore = styled.div`
  display: flex;
  align-items: center;
  margin-left: -18px;
  margin-top: 7px;
  color: #6f6f6f;
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
`;

const CardDateTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family:  ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  @media ${devices.mobile} {
    font-size: 11px;
  },
`;

const CardDateValues = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 700;
  @media(max-width: 500px ){
    font-size: 16px;
  }
  @media ${devices.mobile} {
    font-size: 12px;
  },
`;

type VoucherValidBlockProps = {
  validTo: string;
  validForAllServices?: boolean;
  validServices?: {
    id: number;
    name: string;
  }[];
};

export const VoucherValidBlock = (props: VoucherValidBlockProps) => {
  const { validTo, validForAllServices, validServices } = props;
  const [isShowMore, setShowMore] = useState(false);
  const { t } = useTranslation(Namespaces.UI);
  const renderValidForList = (services: { id: number; name: string }[]) => {
    const lengthElements = isShowMore ? services.length : MAX_VALID_FOR_SERVICE;
    return (
      <ServiceList>
        {services.slice(0, lengthElements).map((el, i) => {
          return <ServiceItem key={i}>{el.name}</ServiceItem>;
        })}
        {!isShowMore && services.length > MAX_VALID_FOR_SERVICE && (
          <ItemMore
            onClick={() => {
              setShowMore(true);
            }}
          >
            {`+${services.length - MAX_VALID_FOR_SERVICE} ${t('moreServices')}`}
            <CustomIcon
              width="12px"
              height="7px"
              margin="0 0 0 9px"
              icon={ArrowDown}
              cursor
            />
          </ItemMore>
        )}
      </ServiceList>
    );
  };
  return (
    <CardValidBlock>
      <ValidThruBlock isLastBlock={!validForAllServices && !validServices}>
        <CardDateTitle>{t('validTo').toUpperCase()}</CardDateTitle>
        <CardDateValues>
          {validTo ? getYMD(validTo) : t('untilCancellation')}
        </CardDateValues>
      </ValidThruBlock>
      {!validForAllServices && !validServices ? null : (
        <ValidForBlock>
          <CardDateTitle>{t('validFor').toUpperCase()}</CardDateTitle>
          <CardDateValues>
            {validForAllServices
              ? t('allServices')
              : renderValidForList(
                  validServices as {
                    id: number;
                    name: string;
                  }[]
                )}
          </CardDateValues>
        </ValidForBlock>
      )}
    </CardValidBlock>
  );
};
