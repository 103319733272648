import styled from 'styled-components';

const DividerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin: 20px auto;
`;

const Line = styled.div`
  width: 40%;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
`;

export const Divider = (props: { text: string }) => {
  const { text } = props;
  return (
    <DividerStyled data-testid="divider">
      <Line />
      <Text>{text}</Text>
      <Line />
    </DividerStyled>
  );
};
