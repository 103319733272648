import { FC } from 'react';
import {
  CardLogo,
  IconClose,
  Modal,
  ModalBody,
  ModalTitle,
  ModalDescription,
  ModalDataText,
  ModalDataValue,
  ModalLink,
  CancelModalText,
  CancelModalSubText,
  ButtonWrap,
  ModalCancelButton,
  ModalRejectButton,
  BoldText,
} from '../styles';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import CloseIcon from 'assets/icons/close.svg';
import { devices } from 'constants/mediaConstants';
import { Booking, Entries } from 'store/BookingStore';
import { Flex } from 'styled/Flex';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';
import AlertImage from 'assets/icons/alert.svg';
import { Loader } from 'components/Loader/Loader';
import { getTime, getYMD } from 'helpers/DateTimeUtils';
import { BookingModal as BookingModalType } from '../types';

interface IProps {
  onClose: () => void;
  modalType: BookingModalType;
  activeBooking: Booking;
  activeEntry?: Entries;
  modalLoading: boolean;
  deleteAction: () => void;
}

export const BookingModal: FC<IProps> = ({
  onClose,
  modalType,
  activeBooking,
  activeEntry,
  modalLoading,
  deleteAction,
}) => {
  const {
    profile: { description, email, logoUrl, name, phone, website, address },
  } = activeBooking;
  const { t } = useTranslation(Namespaces.UI);

  return modalType ? (
    <Modal>
      <ModalBody>
        {modalType === 'info' && (
          <IconClose>
            <CustomIcon
              width="30px"
              height="30px"
              icon={CloseIcon}
              onClick={onClose}
              hover
              cursor
              device={devices.mobile}
              deviceWidth="25px"
              deviceHeight="25px"
            />
          </IconClose>
        )}
        {modalType === 'info' ? (
          <>
            <CardLogo url={logoUrl} size="115px" />
            <ModalTitle>{name}</ModalTitle>
            <ModalDescription>{description}</ModalDescription>
            {address && (
              <Flex alignItems="center" justifyContent="space-between">
                <ModalDataText>{t('address')}</ModalDataText>
                <ModalDataValue>{address}</ModalDataValue>
              </Flex>
            )}
            {phone && (
              <Flex alignItems="center" justifyContent="space-between">
                <ModalDataText>{t('phone')}</ModalDataText>
                <ModalDataValue>{phone}</ModalDataValue>
              </Flex>
            )}
            {email && (
              <Flex alignItems="center" justifyContent="space-between">
                <ModalDataText>{t('email')}</ModalDataText>
                <ModalLink href={`mailto:${email}`}>{email}</ModalLink>
              </Flex>
            )}
            {website && (
              <Flex alignItems="center" justifyContent="space-between">
                <ModalDataText>{t('homePage')}</ModalDataText>
                <ModalLink target="_blank" href={`https://${website}`}>
                  {website}
                </ModalLink>
              </Flex>
            )}
          </>
        ) : (
          <>
            <CustomIcon width="72px" height="72px" margin="0 auto" icon={AlertImage} />
            <CancelModalText>{t('cancelBooking')}</CancelModalText>
            <CancelModalSubText>
              {t('cancelBookingMessage')}
              {!activeEntry && <BoldText>{activeBooking?.service.name}?</BoldText>}
              {activeEntry && (
                <BoldText>
                  {`${activeBooking?.service.name} ${getYMD(
                    activeEntry.startTime,
                    true
                  )} ${getTime(activeEntry.startTime, true)}`}
                  ?
                </BoldText>
              )}
            </CancelModalSubText>
            <ButtonWrap>
              <ModalCancelButton onClick={deleteAction}>
                {modalLoading ? <Loader small /> : t('bookingCancelButton')}
              </ModalCancelButton>
              <ModalRejectButton onClick={onClose}>{t('noKeepIt')}</ModalRejectButton>
            </ButtonWrap>
          </>
        )}
      </ModalBody>
    </Modal>
  ) : (
    <></>
  );
};
