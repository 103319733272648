import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { callApi, postApi } from 'helpers';

type Service = {
  description: string;
  endTime: string;
  firstBookableAt: string;
  id: number;
  isBookPerOccasion: boolean;
  lastBookableAt: string;
  name: string;
  price: number;
  startTime: string;
  type: string;
};

type Resources = [
  {
    avatarUrl: string;
    description: string;
    id: number;
    name: string;
    type: string;
  }
];

export type Entries = {
  endTime: string;
  id: number;
  startTime: string;
  type: string;
  resources: Resources | [];
};

export type WaitingItemType = {
  entries: Entries[];
  id: number;
  joinedAt: string;
  service: Service;
};

export class WaitingList {
  waitingList?: WaitingItemType[][] | [] = undefined;

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setWaitingList = (waitingList: WaitingItemType[][] | []) => {
    this.waitingList = waitingList;
  };

  leaveWaitingListItem = async (id: number, callback: () => void) => {
    const res = await postApi(`v1/waiting-lists/${id}/leave`, {});
    const data = await res.json();
    const waitingList = toJS(this.waitingList);
    if (waitingList) {
      for (let i = 0; i < waitingList?.length; i++) {
        for (let j = 0; j < waitingList[i]?.length; j++) {
          if (waitingList[i][j].id === data.id) {
            waitingList[i].splice(j, 1);
            if (!waitingList[i].length) {
              waitingList.splice(i, 1);
            }
          }
        }
      }

      runInAction(() => {
        this.waitingList = waitingList;
      });

      callback();
    }
  };

  getWaitingList = async (
    page?: number,
    waitingItems: WaitingItemType[] | [] = []
  ): Promise<void | WaitingItemType[]> => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(`v1/users/me/waiting-lists`);
      const data = await res.json();
      if (data.page === data.totalPages) {
        const waitingList = waitingItems.concat(data.data);
        const sortedWaitingList: WaitingItemType[][] = [];
        let waitingListGroup: WaitingItemType[] = [];
        let {
          service: { id },
        } = waitingList[0];
        for (let i = 0; i < waitingList.length; i++) {
          if (id === waitingList[i].service.id) {
            waitingListGroup.push(waitingList[i]);
          } else {
            sortedWaitingList.push(waitingListGroup);
            id = waitingList[i].service.id;
            waitingListGroup = [waitingList[i]];
          }
        }
        sortedWaitingList.push(waitingListGroup);

        this.setWaitingList(sortedWaitingList);
      } else if (data.totalPages) {
        const waitingList = waitingItems.concat(data.data);
        return await this.getWaitingList(++data.page, waitingList);
      } else {
        this.setWaitingList([]);
      }
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
    return [];
  };
}

export const WaitingListStore = new WaitingList();
