import styled from 'styled-components';

type ButtonStyledProps = {
  disabled?: boolean;
  size?: string;
  secondary?: boolean;
};

const getHeightButton = (size = 'medium') => {
  switch (size) {
    case 'large':
      return '56px';
    case 'small':
      return '38px';
    default:
      return '50px';
  }
};

const getHoverEffect = (
  disabled: boolean | undefined,
  secondary: boolean | undefined
) => {
  if (disabled || secondary) {
    return 0.7;
  }
  return 0.9;
};

export const PrimaryButton = styled.div.attrs(() => ({
  role: 'button',
  tabIndex: 0,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props: ButtonStyledProps) => getHeightButton(props.size)};
  padding: 17px 20px;
  border-radius: 26px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border: ${({ theme, secondary }) =>
    secondary && `1px solid ${theme.colors.middleGray}`};
  color: ${({ theme, secondary }) =>
    secondary
      ? theme.appTheme.secondaryButtonTextColor
      : theme.appTheme.primaryButtonTextColor};
  background: ${({ theme, secondary }) =>
    secondary
      ? theme.appTheme.secondaryButtonBackground
      : theme.appTheme.primaryButtonBackground};
  opacity: ${(props: ButtonStyledProps) => (props.disabled ? 0.7 : 1)};
  user-select: none;

  :hover {
    opacity: ${(props: ButtonStyledProps) =>
      getHoverEffect(props.disabled, props.secondary)};
  }
`;
