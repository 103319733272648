import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';

import { Loader } from 'components/Loader/Loader';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';
import { PaymentStore } from 'store/PaymentStore';
import {
  TRANSACTION_STATUS_CANCELLED,
  TRANSACTION_STATUS_FAILED,
  TRANSACTION_STATUS_SUCCESS,
} from 'constants/transactionConstants';

import { PrimaryButton } from 'styled/PrimaryButton';
import SwishPhone from 'assets/icons/swishPhone.svg';
import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const CardPaymentBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 31px 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;

  @media ${devices.mobile} {
    padding: 24px 11px 30px;
  },
`;

const CancelButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 250px;
  margin: 0px auto 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  box-sizing: border-box;
  border-radius: 28px;
  color: ${({ theme }) => theme.colors.darkBlack};

  @media ${devices.mobile} {
    max-height: 38px;
    width: 130px;
    padding: 12px 30px;
    font-size: 12px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const ConfirmSwishText = styled.div`
  padding: 0 30px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
`;

type Props = {
  flowType: string;
  transactionLinkHash: string | null;
  onCancelled: () => void;
  onFailed: () => void;
  onSuccess: () => void;
};

export const SwishStatusCheck = observer((props: Props) => {
  const { transactionInfo, getTransactionSwishStatus } = PaymentStore;
  const { t } = useTranslation(Namespaces.UI);

  const { flowType, transactionLinkHash } = props;
  const transactionData = toJS(transactionInfo);
  if (!transactionData) return <></>;

  useEffect(() => {
    const intervalMs = 2000;
    const maxIntervals = 180000 / intervalMs;
    let intervalCount = 0;
    const interval = setInterval(() => {
      getTransactionSwishStatus(transactionData.id, transactionLinkHash, (response) => {
        if (response.status === TRANSACTION_STATUS_CANCELLED) {
          props.onCancelled();
        } else if (response.status === TRANSACTION_STATUS_FAILED) {
          props.onFailed();
        } else if (response.status === TRANSACTION_STATUS_SUCCESS) {
          props.onSuccess();
        } else {
          if (++intervalCount >= maxIntervals) {
            clearInterval(interval);
            props.onCancelled();
          }
        }
      });
    }, intervalMs);
    return () => clearInterval(interval);
  }, []);

  if (flowType === 'eCommerce' || flowType === 'mCommerce') {
    return (
      <CardPaymentBlock>
        <Flex flexDirection="column" alignItems="center">
          <CustomIcon
            icon={SwishPhone}
            height="139px"
            width="192px"
            margin="37px 0 58px"
          />
          <ConfirmSwishText>{t('swishStatusWaitingTitle')}</ConfirmSwishText>
          <Loader />
          <CancelButton onClick={() => props.onCancelled()}>{t('cancel')}</CancelButton>
        </Flex>
      </CardPaymentBlock>
    );
  }

  return <></>;
});
