import styled from 'styled-components';
import { devices } from 'constants/mediaConstants';

export default styled.div`
  margin: 0px 0 30px;
  width: 100%;
  height: 320px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props: { img: string }) => props.img});
  @media ${devices.mobile} {
    height: 0;
    padding-bottom: 70%;
  } ;
`;
