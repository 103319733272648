import styled, { DefaultTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  convertMinutesToDate,
  convertMinutesToTime,
  isLocalTimeZoneSameAs,
  getYMD,
  getTime,
} from 'helpers/DateTimeUtils';
import { Namespaces } from 'languages';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { devices } from 'constants/mediaConstants';
import {
  APPOINTMENT_TYPE_FLEXIBLE,
  APPOINTMENT_TYPE_UNTIL_CANCEL,
} from 'constants/appointmentConstants';

import ArrowDownButton from 'assets/icons/arrowButtonDown.svg';
import Close from 'assets/icons/closeCircle.svg';

const Container = styled.div`
  max-width: 100%;
  margin: ${(props: { isBig?: boolean }) => (props.isBig ? '30px 0 0' : '30px 0 0')};
`;

const DatesContainer = styled.div`
  display: flex;
  position: relative;
`;

const CardStartBlock = styled.div`
  width: 50%;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  @media ${devices.widgetDesktop} {
    padding: 8px;
  },
`;

const CardEndBlock = styled.div`
  width: 50%;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-left: 0;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  @media ${devices.widgetDesktop} {
    padding: 8px;
  },
`;

const CardDateTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family:  ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  @media ${devices.mobile} {
    font-size: 11px;
  },
`;

type CardDateValuesType = {
  bigFontSize?: boolean;
  isLeft?: boolean;
  theme: DefaultTheme;
};

const CardDateValues = styled.div`
position: relative;
  display: flex;
  justify-content: ${(props: CardDateValuesType) =>
    props.isLeft ? 'left' : 'space-between'};
  align-items: center;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  flex-wrap: wrap;
  @media ${devices.widgetDesktop}{
    font-size: ${(props: CardDateValuesType) => (props.bigFontSize ? '18px' : '14px')};;
  }
  @media ${devices.mobile} {
    
    font-size: 14px;
  },
`;

const TimeValue = styled.div`
  border-bottom: ${(props: { isUnderLine?: boolean; theme: DefaultTheme }) =>
    props.isUnderLine ? `2px solid ${props.theme.colors.darkBlack}` : 0};
  color: ${({ theme }) => theme.colors.darkBlack};
`;

const DateValue = styled.div`
  margin-right: 11px;
  color: ${(props: { isBlack?: boolean; theme: DefaultTheme }) =>
    props.isBlack ? props.theme.colors.darkBlack : props.theme.colors.darkGray};
`;

const ArrowDownIcon = styled.div`
  @media (max-width: 520px) {
    position: absolute;
    top: -23px;
    right: 0px;
  }
`;

const TimeZoneText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 4px;
`;

type CardDateBlockProps = {
  type?: string;
  startAction?: () => void;
  endAction?: () => void;
  rangeStartTime?: string;
  startTime: string | number | null;
  endTime: string | number | null;
  showRemoveButton?: boolean;
  onRemoveButtonClicked?: () => void;
};

export const CardDateBlock = (props: CardDateBlockProps) => {
  const {
    type,
    startAction,
    endAction,
    startTime,
    endTime,
    rangeStartTime,
    showRemoveButton,
    onRemoveButtonClicked,
  } = props;

  const { t } = useTranslation(Namespaces.UI);

  let localTimeZoneDiffers = false;
  if (startTime && typeof startTime === 'string') {
    localTimeZoneDiffers = !isLocalTimeZoneSameAs(startTime as string);
  } else if (endTime && typeof endTime === 'string') {
    localTimeZoneDiffers =
      localTimeZoneDiffers || !isLocalTimeZoneSameAs(endTime as string);
  } else if (rangeStartTime) {
    localTimeZoneDiffers =
      localTimeZoneDiffers || !isLocalTimeZoneSameAs(rangeStartTime as string);
  }

  switch (type) {
    case APPOINTMENT_TYPE_FLEXIBLE: {
      return (
        <Container isBig>
          {localTimeZoneDiffers && <TimeZoneText>{t('timeZoneDifferText')}</TimeZoneText>}
          <DatesContainer>
            <CardStartBlock onClick={startAction}>
              <CardDateTitle>{t('start').toUpperCase()}</CardDateTitle>
              <CardDateValues bigFontSize>
                <DateValue>
                  {startTime !== null
                    ? convertMinutesToDate(
                        rangeStartTime as string,
                        startTime as number,
                        true
                      )
                    : getYMD(rangeStartTime, true)}
                </DateValue>
                <TimeValue>
                  {startTime === null
                    ? '--:--'
                    : convertMinutesToTime(rangeStartTime as string, startTime as number)}
                </TimeValue>
                <ArrowDownIcon>
                  <CustomIcon
                    width="20px"
                    height="17px"
                    icon={ArrowDownButton}
                    hover
                    cursor
                  />
                </ArrowDownIcon>
              </CardDateValues>
            </CardStartBlock>
            <CardEndBlock onClick={endAction}>
              <CardDateTitle>{t('end').toUpperCase()}</CardDateTitle>
              <CardDateValues bigFontSize>
                <DateValue>
                  {endTime !== null
                    ? convertMinutesToDate(
                        rangeStartTime as string,
                        endTime as number,
                        true
                      )
                    : getYMD(rangeStartTime, true)}
                </DateValue>
                <TimeValue>
                  {endTime === null
                    ? '--:--'
                    : convertMinutesToTime(rangeStartTime as string, endTime as number)}
                </TimeValue>
                <ArrowDownIcon>
                  <CustomIcon
                    width="20px"
                    height="17px"
                    icon={ArrowDownButton}
                    hover
                    cursor
                  />
                </ArrowDownIcon>
              </CardDateValues>
            </CardEndBlock>
          </DatesContainer>
        </Container>
      );
    }
    case APPOINTMENT_TYPE_UNTIL_CANCEL: {
      return (
        <Container>
          {localTimeZoneDiffers && <TimeZoneText>{t('timeZoneDifferText')}</TimeZoneText>}
          <DatesContainer>
            <CardStartBlock>
              <CardDateTitle>{t('start').toUpperCase()}</CardDateTitle>
              <CardDateValues bigFontSize>
                <TimeValue>{getYMD(startTime as string, true)}</TimeValue>
              </CardDateValues>
            </CardStartBlock>
            <CardEndBlock>
              <CardDateTitle>{t('end').toUpperCase()}</CardDateTitle>
              <CardDateValues bigFontSize>
                <TimeValue>
                  {endTime ? getYMD(endTime as string, true) : t('untilCancellation')}
                </TimeValue>
              </CardDateValues>
            </CardEndBlock>
          </DatesContainer>
        </Container>
      );
    }
    default: {
      return (
        <Container>
          {localTimeZoneDiffers && <TimeZoneText>{t('timeZoneDifferText')}</TimeZoneText>}
          <DatesContainer>
            <CardStartBlock>
              <CardDateTitle>{t('start').toUpperCase()}</CardDateTitle>
              <CardDateValues isLeft bigFontSize>
                <DateValue isBlack>{getYMD(startTime as string, true)}</DateValue>
                <TimeValue isUnderLine>{getTime(startTime as string, true)}</TimeValue>
              </CardDateValues>
            </CardStartBlock>
            <CardEndBlock>
              <CardDateTitle>{t('end').toUpperCase()}</CardDateTitle>
              <CardDateValues isLeft bigFontSize>
                {endTime ? (
                  <>
                    {getYMD(endTime as string, true) ===
                    getYMD(startTime as string, true) ? null : (
                      <DateValue isBlack>{getYMD(endTime as string, true)}</DateValue>
                    )}

                    <TimeValue isUnderLine>{getTime(endTime as string, true)}</TimeValue>
                  </>
                ) : (
                  t('untilCancellation')
                )}
              </CardDateValues>
            </CardEndBlock>

            {showRemoveButton && (
              <CustomIcon
                position="absolute"
                right="-15px"
                top="calc(50% - 15px)"
                width="30px"
                height="30px"
                icon={Close}
                onClick={() => {
                  if (onRemoveButtonClicked) {
                    onRemoveButtonClicked();
                  }
                }}
                hover
                cursor
              />
            )}
          </DatesContainer>
        </Container>
      );
    }
  }
};
