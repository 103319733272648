import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

import { Loader } from 'components/Loader/Loader';
import { Namespaces } from 'languages';
import { devices, size } from 'constants/mediaConstants';
import { PrimaryButton } from 'styled/PrimaryButton';
import { renderInputBorder } from 'helpers/StylesUtils';

const CardBody = styled.div`
  padding: 0 30px;

  @media ${devices.mobile} {
    padding: 0 10px;
  } ;
`;

const CardWrap = styled.div`
  display: grid;
  grid-template-columns: 3fr 1.5fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  user-select: none;

  @media ${devices.mobile} {
    grid-gap: 4px;
    grid-template-columns: 6fr 3fr 2fr;
  } ;
`;

const CardInput = styled.div`
  width: 100%;
  .__PrivateStripeElement {
    font-family: ${({ theme }) => theme.fonts.sfProDisplay};
    width: 100%;
  }

  pointer-events: ${(props: { disabled: boolean }) =>
    props.disabled ? 'none ' : 'auto'};
`;

const InputWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 11px 0 13px;
`;

const InputHolder = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.colors.darkBlack};
  background: transparent;
  border: 0;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
`;

const InputHeader = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  color: #a6a6a6;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  user-select: none;

  @media ${devices.mobile} {
    left: 8px;
  } ;
`;

const InputBlock = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 55px;
  padding: 25px 17px 4px;
  color: #a6a6a6;
  background: ${(props: { error: string }) => (props.error ? '#FFFBFB' : '#ffffff')};
  border-radius: 10px;
  border: ${(props: { error: string; focus: boolean }) =>
    renderInputBorder(props.focus, props.error)};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  @media ${devices.mobile} {
    padding: 25px 8px 4px;
  } ;
`;

const ConfirmBlock = styled.div`
  margin-top: 40px;
  padding: 28px 60px 28px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 20px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  @media ${devices.mobile} {
    margin-top: 15px;
    padding: 18px 20px 12px;
  } ;
`;

const ConfirmTitle = styled.div`
  margin-bottom: 12px;
`;

const ConfirmDescription = styled.div`
  width: 300px;
  margin: 0 auto 25px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  @media ${devices.mobile} {
    width: 270px;
  } ;
`;

const ConfirmButton = styled(PrimaryButton)`
  max-width: 300px;
  max-height: 56px;
  margin: 28px auto 0px auto;

  @media ${devices.mobile} {
    padding: 14px 50px;
    max-width: 220px;
  } ;
`;

const PaymentError = styled.div`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.red};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

const defaultInputStyles = {
  style: {
    base: {
      color: '#181818',
      fontFamily: 'SF Pro Display',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '24px',
      '::placeholder': {
        color: '#6F6F6F',
        fontSize: '20px',
      },
    },
    invalid: {
      color: '#FF033D',
    },
  },
};

export const SubscriptionAddCardForm = observer(
  ({
    clientSecret,
    onCardAdded,
  }: {
    clientSecret: string;
    onCardAdded: (paymentMethodId: string) => void;
  }) => {
    const { t } = useTranslation(Namespaces.UI);
    const stripe = useStripe();
    const elements = useElements();
    const [isInit, setInit] = useState(false);
    const [loading, setLoader] = useState(false);
    const [holderName, setHolderName] = useState('');
    const [errors, setError] = useState<{ [key: string]: string }>({});
    const [focus, setFocus] = useState('');

    const handleOnChange = useCallback(
      (
        e:
          | StripeCardNumberElementChangeEvent
          | StripeCardCvcElementChangeEvent
          | StripeCardExpiryElementChangeEvent,
        name: string
      ) => {
        const { error } = e;
        if (error) {
          setError({ ...errors, [name]: 'true' });
        } else {
          setError({ ...errors, [name]: '' });
        }
      },
      [errors]
    );

    useEffect(() => {
      if (window.innerWidth < +size.mobile) {
        defaultInputStyles.style.base['::placeholder'].fontSize = '14px';
      }
      if (elements && !isInit) {
        const cardNumberElement =
          elements.getElement('cardNumber') ||
          elements.create('cardNumber', { ...defaultInputStyles, showIcon: true });
        const cardExpiryElement =
          elements.getElement('cardExpiry') ||
          elements.create('cardExpiry', defaultInputStyles);
        const cardCvcElement =
          elements.getElement('cardCvc') ||
          elements.create('cardCvc', defaultInputStyles);

        cardNumberElement.on('change', (e) => {
          handleOnChange(e, 'cardNumber');
        });

        cardExpiryElement.on('change', (e) => {
          handleOnChange(e, 'cardExpiry');
        });

        cardCvcElement.on('change', (e) => {
          handleOnChange(e, 'cardCvc');
        });

        cardNumberElement.on('focus', () => {
          setFocus('cardNumber');
        });

        cardExpiryElement.on('focus', () => {
          setFocus('cardExpiry');
        });

        cardCvcElement.on('focus', () => {
          setFocus('cardCvc');
        });

        cardNumberElement.on('blur', () => {
          setFocus('');
        });
        cardExpiryElement.on('blur', () => {
          setFocus('');
        });
        cardCvcElement.on('blur', () => {
          setFocus('');
        });

        cardNumberElement.mount('#numberInput');
        cardExpiryElement.mount('#expiryInput');
        cardCvcElement.mount('#cvcInput');
        setInit(true);
      }
    }, [elements, handleOnChange, isInit]);

    const handleSubmit = async () => {
      if (loading) {
        return;
      }

      if (!holderName) {
        setError({ ...errors, holderName: 'true' });
        return;
      }

      if (stripe && elements) {
        if (clientSecret) {
          const card = elements.getElement('cardNumber');
          if (card) {
            setLoader(true);
            setError({ ...errors, ['cardGenericError']: '' });
            const payRequest = await stripe.confirmCardSetup(clientSecret, {
              payment_method: {
                card,
                billing_details: {
                  name: holderName,
                },
              },
            });

            if (!payRequest.error) {
              onCardAdded(payRequest.setupIntent.payment_method as string);
              return;
            }

            setLoader(false);

            if (payRequest.error) {
              const errorCode = payRequest.error.code;

              let errorKey = '';
              if (errorCode === 'incomplete_number') {
                errorKey = 'cardNumber';
              } else if (errorCode === 'incomplete_expiry') {
                errorKey = 'cardExpiry';
              } else if (errorCode === 'incomplete_cvc') {
                errorKey = 'cardCvc';
              } else {
                errorKey = 'cardGenericError';
              }

              setError({ ...errors, [errorKey]: 'true' });
            }
          }
        }
      }
    };

    if (!clientSecret) {
      return <Loader />;
    }

    return (
      <CardBody>
        <InputWrap>
          <InputHeader>{t('cardHolder')}</InputHeader>
          <InputBlock error={errors.holderName} focus={focus === 'holderName'}>
            <InputHolder
              type="text"
              value={holderName}
              disabled={loading}
              onFocus={() => {
                setTimeout(() => {
                  setFocus('holderName');
                }, 10);
              }}
              onBlur={() => {
                setFocus('');
              }}
              onChange={(e) => {
                setError({ ...errors, holderName: '' });
                setHolderName(e.target.value);
              }}
            />
          </InputBlock>
        </InputWrap>
        <CardWrap>
          <InputWrap>
            <InputHeader>{t('cardNumber')}</InputHeader>
            <InputBlock error={errors.cardNumber} focus={focus === 'cardNumber'}>
              <CardInput id="numberInput" disabled={loading} />
            </InputBlock>
          </InputWrap>
          <InputWrap>
            <InputHeader>{t('validTo')}</InputHeader>
            <InputBlock error={errors.cardExpiry} focus={focus === 'cardExpiry'}>
              <CardInput id="expiryInput" disabled={loading} />
            </InputBlock>
          </InputWrap>
          <InputWrap>
            <InputHeader>{t('cvv')}</InputHeader>
            <InputBlock error={errors.cardCvc} focus={focus === 'cardCvc'}>
              <CardInput id="cvcInput" disabled={loading} />
            </InputBlock>
          </InputWrap>
        </CardWrap>
        <ConfirmBlock>
          <ConfirmTitle>{t('subscriptionAddCardConfirmTitle')}</ConfirmTitle>
          <ConfirmDescription>
            {t('subscriptionAddCardConfirmDescription')}
          </ConfirmDescription>
          <ConfirmButton
            size="large"
            disabled={loading}
            onClick={() => {
              if (!loading) {
                handleSubmit();
              }
            }}
          >
            {loading ? <Loader isWhite small /> : t('confirm')}
          </ConfirmButton>
          {errors.cardGenericError && (
            <PaymentError>{t('subscriptionAddCardErrorDefault')}</PaymentError>
          )}
        </ConfirmBlock>
      </CardBody>
    );
  }
);
