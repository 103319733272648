import { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';

import { urls } from 'helpers';
import { Namespaces } from 'languages';
import { Input as CustomField } from 'containers/Input';

import { LoginStore } from 'store/LoginStore';
import { HeaderStore } from 'store/HeaderStore';
import { devices } from 'constants/mediaConstants';

import Email from 'assets/icons/email.svg';

import { PrimaryButton } from 'styled/PrimaryButton';
import Layout from 'styled/Layout';

const ForgotPasswordPage = styled(Layout)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
`;

const Title = styled.div`
  margin-top: 40px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
`;

const Description = styled.div`
  width: 65%;
  margin: 10px auto 20px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 18px;
  text-align: center;
`;

const FormItem = styled.div`
  position: relative;
  width: 350px;
  margin: 30px auto;
  padding: 0 12px;
  border-radius: 3px;
  @media${devices.mobile} {
    width: 290px;
  }
`;

const Error = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
`;

const Input = styled(CustomField)`
  width: 350px;
  height: 52px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  @media${devices.mobile} {
    width: 290px;
  }
`;

const ButtonSend = styled(PrimaryButton)`
  width: 350px;
  margin: 25px auto 30px;

  @media${devices.mobile} {
    height: 42px;
    width: 290px;
  }
`;

const Spinner = styled(Spin)`
  height: 20px;
  i {
    background-color: white;
  }
`;
const RememberWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin: 30px auto 20px;
`;

const RememberText = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
`;

const CancelLink = styled.div`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: 500;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.sfProText};
`;

const ErrorWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 10px auto;
  padding: 0 30px;
  background: #feecee;
  border-radius: 5px;
  text-align: center;
`;

const ErrorServer = styled.div`
  fontsize: 13px;
  color: ${({ theme }) => theme.colors.red};
  padding: 5px 0;
`;

const SuccessWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 10px auto;
  padding: 0 30px;
  background: #04ff8b26;
  border-radius: 5px;
  text-align: center;
`;

const SuccessMessage = styled.div`
  padding: 5px 0;
  color: #47a51a;
  font-size: 13px;
`;

export const ForgotPassword = observer(() => {
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  const [isServerError, setServerError] = useState('');
  const [isSuccessMessage, setSuccessMessage] = useState('');
  const { forgotPassword, loading } = LoginStore;
  const { setTitle } = HeaderStore;

  const history = useHistory();
  const { t } = useTranslation(Namespaces.UI);

  const validationForm = (): boolean => {
    let isError = false;
    if (!email) {
      setErrorEmail(t('errorRequired'));
      isError = true;
    } else if (!email || email.includes('+')) {
      isError = true;
      setErrorEmail(t('errorInvalidEmail'));
    }
    return !isError;
  };

  const handleChangeEmail = (value: string) => {
    setErrorEmail('');
    setEmail(value);
  };

  const submitForm = () => {
    setServerError('');
    if (validationForm()) {
      forgotPassword(email).then((error: string) => {
        if (!error) {
          setSuccessMessage(t('forgotPasswordSuccess'));
          setForgotPasswordStatus(true);
        } else {
          setServerError(error);
        }
      });
    }
  };

  const renderButton = () =>
    forgotPasswordStatus ? (
      <ButtonSend
        onClick={() => {
          history.replace(urls.auth);
        }}
      >
        {t('buttonBackLogin')}
      </ButtonSend>
    ) : (
      <ButtonSend onClick={submitForm} size="large">
        {t('send')}
      </ButtonSend>
    );
  return (
    <ForgotPasswordPage data-testid="forgot-password">
      <Title>{t('resetTitle')}</Title>
      <Description>{t('resetHint')}</Description>
      <ErrorWrap>{isServerError && <ErrorServer>{isServerError}</ErrorServer>}</ErrorWrap>
      <SuccessWrap>
        {isSuccessMessage && <SuccessMessage>{isSuccessMessage}</SuccessMessage>}
      </SuccessWrap>
      <FormItem>
        {errorEmail && <Error>{`* ${errorEmail}`}</Error>}
        <Input
          placeholder={t('email')}
          change={handleChangeEmail}
          value={email}
          InputIcon={Email}
          type="text"
        />
      </FormItem>

      {loading ? (
        <ButtonSend>
          <Spinner />
        </ButtonSend>
      ) : (
        renderButton()
      )}
      <RememberWrap>
        <RememberText>{t('rememberText')}</RememberText>
        <CancelLink
          onClick={() => {
            setTitle(t('loginTitle'));
            history.push(urls.auth);
          }}
        >
          {t('cancel')}
        </CancelLink>
      </RememberWrap>
    </ForgotPasswordPage>
  );
});
