import { useEffect } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { urls } from 'helpers';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';
import { LoginStore } from 'store/LoginStore';
import { CheckoutStore } from 'store/CheckoutStore';

import CheckIcon from 'assets/icons/checkmark.svg';

import { PrimaryButton } from 'styled/PrimaryButton';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const ConfirmationPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
  padding-bottom: 70px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  @media${devices.mobile} {
    padding-bottom: 25px;
  }
`;
const OkButton = styled(PrimaryButton)`
  width: 214px;
  padding: 17px 20px;
  border-radius: 28px;
  @media ${devices.mobile} {
    width: 185px;
    padding: 10px 20px;
  }
`;
const BackButton = styled(PrimaryButton)`
  width: 214px;
  margin: 15px auto 0;
  padding: 17px 20px;
  @media${devices.mobile} {
    width: 185px;
    font-size: 14px;
    padding: 10px 20px;
  }
`;

const Title = styled.div`
  max-width: 400px;
  padding: 20px;
  color: ${({ theme }) => theme.colors.darkBlack};
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  @media${devices.mobile} {
    padding-bottom: 10px;
    font-size: 28px;
  }
`;
const Description = styled.div`
  max-width: 420px;
  padding: 0 20px 30px;
  color: ${({ theme }) => theme.colors.darkBlack};
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  @media${devices.mobile} {
    font-size: 18px;
  }
`;

const BoldText = styled.div`
  display: inline;
  font-weight: bold;
`;

export const Confirmation = observer(() => {
  const history = useHistory();
  const { user, getUserStatus } = LoginStore;
  const { setCheckout, setCheckoutsInfo, setPaymentInfo } = CheckoutStore;
  const userData = toJS(user);
  const { t } = useTranslation(Namespaces.UI);

  useEffect(() => {
    setCheckout(undefined);
    setCheckoutsInfo([]);
    setPaymentInfo(undefined);
    setTimeout(() => {
      getUserStatus();
    }, 3000);
  }, [setCheckout, setCheckoutsInfo, setPaymentInfo, getUserStatus]);

  return (
    <ConfirmationPage data-testid="confirmation">
      <CustomIcon
        width="80px"
        height="80px"
        margin="50px auto 10px"
        icon={CheckIcon}
        borderRadius="50%"
        device={devices.mobile}
        deviceHeight="70px"
        deviceWidth="70px"
        deviceMargin="30px auto 10px"
      />
      {userData.firstName ? (
        <Title>
          {t('confirmationTitle', {
            firstName: ` ${userData?.firstName}`,
          })}
        </Title>
      ) : (
        <Title>{t('confirmationTitle')}</Title>
      )}
      <Description>
        {t('confirmText1')}
        {userData.firstName && (
          <>
            {t('confirmText2')}
            <BoldText>{`${t('confirmText3')}`}</BoldText>
          </>
        )}
      </Description>
      <OkButton
        secondary
        size="large"
        onClick={() => {
          history.push(urls.services(localStorage.profileId));
        }}
      >
        {t('ok')}
      </OkButton>
      {userData.firstName && (
        <BackButton
          size="large"
          onClick={() => {
            history.replace(urls.bookings);
          }}
        >
          {t('showBookings')}
        </BackButton>
      )}
    </ConfirmationPage>
  );
});
