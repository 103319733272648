import { makeAutoObservable, runInAction } from 'mobx';
import { callApi } from 'helpers';

export type Refund = {
  amount: number;
  currency: string;
  id: number;
  processedAt: string;
};

export type PaymentsListType = {
  amount: number;
  currency: string;
  id: number;
  paidAt: string;
  invoiceExpirationDate: string;
  paymentStatus: string;
  paymentType: string;
  profile: {
    id: number;
    name: string;
    logoUrl: string;
    website: string;
  };
  refunds: Refund[];
  refundedAmount: number;
  serialNumber: string;
  status: string;
};

export class PaymentsList {
  paymentsList?: PaymentsListType[] | [] = undefined;

  loading = false;

  loadMore = false;

  page = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setPaymentsList = (paymentsList: PaymentsListType[] | []) => {
    this.paymentsList = paymentsList;
  };

  clearPaymentList = () => {
    this.paymentsList = undefined;
  };

  getPaymentsList = async (
    page?: number,
    paymentItems: PaymentsListType[] | [] = []
  ): Promise<void | PaymentsListType[]> => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(`v1/users/me/transactions${page ? `?page=${page}` : ''}`);
      const data = await res.json();
      const paymentsList = paymentItems.concat(data.data);
      this.setPaymentsList(paymentsList);
      runInAction(() => {
        this.page = data.page;
      });
      if (data.page === data.totalPages) {
        runInAction(() => {
          this.loadMore = false;
        });
      } else {
        this.loadMore = true;
      }
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
    return [];
  };
}

export const PaymentsListStore = new PaymentsList();
