import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { History } from 'history';

import { CheckoutItemsCard } from 'pages/Checkout/CheckoutItemsCard';
import { CustomerInfoCard } from 'pages/Checkout/CustomerInfoCard';
import { PromotionCodeCard } from 'pages/Checkout/PromotionCodeCard';
import { ConfirmCard } from 'pages/Checkout/ConfirmCard';
import { CollapseCard } from 'components/CollapseCard/CollapseCard';
import { Loader } from 'components/Loader/Loader';
import { Error } from 'components/Error/Error';
import { getPrice, urls } from 'helpers';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { DONT_ASK, OPTIONAL, MANDATORY } from 'constants/checkoutConstants';
import { CheckoutData, CheckoutStore } from 'store/CheckoutStore';
import { UserStore, UserProxy } from 'store/UserStore';
import { LoginStore } from 'store/LoginStore';
import { HeaderStore } from 'store/HeaderStore';
import { PaymentStore } from 'store/PaymentStore';
import { ErrorStore } from 'store/ErrorStore';
import { ERROR_NOT_FOUND, CHECKOUT_CONFIRMED } from 'constants/apiErrorCodeConstants';

import AlertImage from 'assets/icons/alert.svg';

import Layout from 'styled/Layout';
import { PrimaryButton } from 'styled/PrimaryButton';
import { Flex } from 'styled/Flex';

import { BankIDCard } from './BankIDCard';
import { SignDocumentCard } from './SignDocumentCard';
import { AddonsCard } from './AddonsCard';

const Header = styled.div`
  margin: 0px 0px 20px 0px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
`;

const HeaderElements = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkBlack};
`;

const CardTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  @media ${devices.mobile} {
    font-size: 20px;
  }
`;

const CardHeaderErrorLabel = styled.div`
  color: ${({ theme }) => theme.colors.peach};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: right;
  white-space: nowrap;
  @media ${devices.mobile} {
    font-size: 18px;
  }
`;

const Price = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: right;

  @media ${devices.mobile} {
    font-size: 18px;
  }
`;
const PriceDiscount = styled.div`
  width: 150px;
  color: #fc8575;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: right;
  white-space: nowrap;
  @media ${devices.mobile} {
    width: 120px;
    font-size: 18px;
  }
`;

const AddBookingButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 250px;
  height: 56px;

  @media ${devices.mobile} {
    max-height: 38px;
    padding: 12px 30px;
    font-size: 12px;
  },
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

const ModalBody = styled.div`
  position: relative;
  width: 545px;
  padding: 65px 67px 40px 65px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    padding: 25px;
  }
`;

const CancelModalText = styled.div`
  margin: 25px 0 7px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
`;
const CancelModalSubText = styled.div`
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const ModalCancelButton = styled(PrimaryButton)`
  box-sizing: border-box;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: #6f6f6f;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #a6a6a6;
  border-radius: 25px;

  @media ${devices.mobile} {
    margin-bottom: 10px;
  }
`;
const ModalRejectButton = styled(PrimaryButton)`
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkBlack};
  border-radius: 28px;

  @media ${devices.mobile} {
    margin: 5px auto 10px;
  }
`;

const renderBookingsHeaderInfo = (
  checkoutData: CheckoutData,
  t: TFunction<Namespaces>
) => {
  const { price, currency } = checkoutData;
  return (
    <HeaderElements>
      <Flex alignItems="center">
        <CardTitle>{t('bookings')}</CardTitle>
      </Flex>
      <Price>{getPrice(price, currency)}</Price>
    </HeaderElements>
  );
};

const renderBookingsBodyInfo = (
  checkoutData: CheckoutData,
  userProxies: UserProxy[],
  t: TFunction<Namespaces>,
  history: History,
  transactionLinkHash: string | null,
  setActiveDeleteItemId: Dispatch<SetStateAction<number | undefined>>
) => {
  const { items } = checkoutData;
  return (
    <>
      {items &&
        items.map((item, i) => (
          <CheckoutItemsCard
            checkoutId={checkoutData.id}
            item={item}
            key={i}
            setActiveDeleteItemId={setActiveDeleteItemId}
            transactionLinkHash={transactionLinkHash}
            userProxies={userProxies}
          />
        ))}
      <Flex justifyContent="center" margin="26px 0 0 0">
        {!transactionLinkHash && (
          <AddBookingButton
            size="large"
            onClick={() => {
              history.push(urls.services(localStorage.profileId));
            }}
          >
            {t('bookingsCardAddButton')}
          </AddBookingButton>
        )}
      </Flex>
    </>
  );
};

export const Checkout = observer(() => {
  const history = useHistory();
  const {
    checkout,
    getCheckout,
    checkoutFieldErrors,
    paymentInfo,
    setPaymentInfo,
    clearFieldErrors,
    removeItemFromCheckout,
  } = CheckoutStore;
  const { userProxies, getUserProxies } = UserStore;
  const { user } = LoginStore;
  const { error } = ErrorStore;

  const [phoneNumber, setPhoneNumber] = useState(paymentInfo?.phoneNumber || '');
  const [customerMessage, setCustomerMessage] = useState(
    paymentInfo?.customerMessage || ''
  );

  const [isCheckoutItemsCardOpenByError, setIsCheckoutItemsCardOpenByError] = useState(
    false
  );
  const [isAddonsCardOpenByError, setIsAddonsInfoCardOpenByError] = useState(false);
  const [isCustomerInfoCardOpenByError, setIsCustomerInfoCardOpenByError] = useState(
    false
  );
  const [isDocumentSignCardOpenByError, setIsDocumentSignCardOpenByError] = useState(
    false
  );
  const [isBankIDCardOpenByError, setIsBankIDCardOpenByError] = useState(false);

  const [activeDeleteItemId, setActiveDeleteItemId] = useState<number | undefined>(
    undefined
  );
  const { activePromotion } = PaymentStore;
  const { setBackButton, setProfileId } = HeaderStore;
  const { t } = useTranslation(Namespaces.UI);
  const checkoutData = toJS(checkout);
  const { checkoutId } = useParams<{
    checkoutId: string;
  }>();
  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  ) as string;

  useEffect(() => {
    setBackButton(true);
  }, [setBackButton]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getCheckout(checkoutId, transactionLinkHash);
  }, [checkoutId, getCheckout, transactionLinkHash]);

  useEffect(() => {
    if (checkoutData?.profile?.id) {
      const checkoutProfileId = checkoutData.profile.id;
      localStorage.profileId = checkoutProfileId;
      setProfileId(checkoutProfileId.toString());
    }
  }, [checkoutData?.profile?.id, setProfileId]);

  useEffect(() => {
    if (
      user?.id &&
      checkout?.items?.find(
        (x) => x.proxyUsersMode === OPTIONAL || x.proxyUsersMode === MANDATORY
      )
    ) {
      getUserProxies();
    }
  }, [user?.id, checkout?.items, getUserProxies]);

  useEffect(() => {
    const paymentInfo: { phoneNumber: string; customerMessage?: string } = {
      phoneNumber: '',
    };

    if (checkoutData?.phoneNumber) {
      paymentInfo.phoneNumber = checkoutData.phoneNumber;
      setPhoneNumber(checkoutData.phoneNumber);
      setPaymentInfo(paymentInfo);
    }

    if (checkoutData?.customerMessage) {
      paymentInfo.customerMessage = checkoutData.customerMessage;
      setCustomerMessage(checkoutData.customerMessage);
      setPaymentInfo(paymentInfo);
    }

    return () => {
      clearFieldErrors();
    };
  }, [
    checkout,
    checkoutData?.customerMessage,
    checkoutData?.phoneNumber,
    clearFieldErrors,
    setPaymentInfo,
  ]);

  if (error && error.place === 'checkout') {
    let errorMessage = error.message;
    if (error.code === ERROR_NOT_FOUND) {
      errorMessage = t('errorCheckoutNotFound');
    } else if (error.code === CHECKOUT_CONFIRMED) {
      errorMessage = t('errorCheckoutConfirmed');
    }

    return (
      <Error
        errorMessage={errorMessage}
        callback={() => {
          history.push(urls.services(localStorage.profileId));
        }}
      />
    );
  }
  if (!localStorage.checkouts && !checkoutData) {
    return <Loader />;
  }
  const checkoutErrors = toJS(checkoutFieldErrors);
  const isSigningDocumentError = checkoutErrors?.documentSigningStatus;
  const showAddonsCard =
    checkoutData?.addonsMode !== DONT_ASK && (checkoutData?.addons?.length || 0) > 0;
  const showCustomerInfoCard =
    checkoutData?.customerMessageMode !== DONT_ASK ||
    checkoutData?.phoneNumberMode !== DONT_ASK;
  const showBankIDCard = checkoutData?.bankIDMode !== DONT_ASK;
  const showDocumentSigningCard = checkoutData?.documentSigningMode !== DONT_ASK;
  const showPromotionCard = checkoutData?.promotionCodeMode !== DONT_ASK;

  const renderModalCancelBody = () => {
    return (
      <>
        <CustomIcon width="72px" height="72px" margin="0 auto" icon={AlertImage} />
        <CancelModalText>{t('checkoutRemoveBooking')}</CancelModalText>
        <CancelModalSubText>{t('checkoutRemoveBookingMessage')}</CancelModalSubText>
        <ButtonWrap>
          <ModalCancelButton
            onClick={() => {
              if (activeDeleteItemId && checkoutData) {
                const lastItem = checkoutData?.itemCount === 1;
                const callback = lastItem
                  ? history.push(urls.services(localStorage.profileId))
                  : setActiveDeleteItemId(undefined);

                removeItemFromCheckout(checkoutId, activeDeleteItemId, () => callback);
              }
            }}
          >
            {t('checkoutRemoveBookingYes')}
          </ModalCancelButton>
          <ModalRejectButton
            onClick={() => {
              setActiveDeleteItemId(undefined);
            }}
          >
            {t('noKeepIt')}
          </ModalRejectButton>
        </ButtonWrap>
      </>
    );
  };

  return (
    <Layout>
      <Header>{t('checkout')}</Header>
      {checkoutData ? (
        <>
          <div id="checkoutItemsCardAnchor">
            <CollapseCard
              header={renderBookingsHeaderInfo(checkoutData, t)}
              number={1}
              body={renderBookingsBodyInfo(
                checkoutData,
                userProxies ? toJS(userProxies) : [],
                t,
                history,
                transactionLinkHash,
                setActiveDeleteItemId
              )}
              isOpen={true}
              isOpenByError={isCheckoutItemsCardOpenByError}
              setIsOpenByError={setIsCheckoutItemsCardOpenByError}
            />
          </div>

          <div id="addonsCardAnchor">
            {showAddonsCard ? (
              <CollapseCard
                header={
                  <HeaderElements>
                    <CardTitle>{t('addons')}</CardTitle>
                    {checkoutErrors?.addonsMandatory && (
                      <CardHeaderErrorLabel>
                        {checkoutErrors.addonsMandatory}
                      </CardHeaderErrorLabel>
                    )}
                  </HeaderElements>
                }
                number={2}
                body={
                  <AddonsCard
                    checkout={checkoutData}
                    transactionLinkHash={transactionLinkHash}
                  />
                }
                isOpen={checkoutData?.addonsMode === MANDATORY}
                isOpenByError={isAddonsCardOpenByError}
                setIsOpenByError={setIsAddonsInfoCardOpenByError}
              />
            ) : null}
          </div>

          <div id="documentBankIDAnchor" />
          {showBankIDCard && (
            <CollapseCard
              header={
                <HeaderElements>
                  <CardTitle>{t('checkoutBankIDCardTitle')}</CardTitle>
                </HeaderElements>
              }
              number={+showAddonsCard + 2}
              body={<BankIDCard checkoutId={checkoutData?.id} />}
              isOpen={checkoutData?.bankIDMode === MANDATORY}
              isOpenByError={isBankIDCardOpenByError}
              setIsOpenByError={setIsBankIDCardOpenByError}
            />
          )}

          <div id="customerInfoCardAnchor" />
          {showCustomerInfoCard && (
            <CollapseCard
              header={
                <HeaderElements>
                  <CardTitle>{t('contactInformation')}</CardTitle>
                </HeaderElements>
              }
              number={+showAddonsCard + +showBankIDCard + 2}
              body={
                <CustomerInfoCard
                  phoneNumberMode={checkoutData?.phoneNumberMode}
                  customerMessageMode={checkoutData?.customerMessageMode}
                  phoneNumber={phoneNumber}
                  checkout={checkoutData}
                  setPhoneNumber={setPhoneNumber}
                  customerMessage={customerMessage}
                  setCustomerMessage={setCustomerMessage}
                />
              }
              isOpen={
                checkoutData?.customerMessageMode === MANDATORY ||
                checkoutData?.phoneNumberMode === MANDATORY
                  ? true
                  : false
              }
              isOpenByError={isCustomerInfoCardOpenByError}
              setIsOpenByError={setIsCustomerInfoCardOpenByError}
            />
          )}

          <div id="documentSignCardAnchor" />
          {showDocumentSigningCard && (
            <CollapseCard
              header={
                <HeaderElements>
                  <CardTitle>{t('checkoutSignDocumentTitle')}</CardTitle>
                </HeaderElements>
              }
              number={+showBankIDCard + +showCustomerInfoCard + +showAddonsCard + 2}
              body={<SignDocumentCard checkoutId={checkoutData?.id} />}
              isOpen={
                checkoutData?.documentSigningMode === MANDATORY || isSigningDocumentError
                  ? true
                  : false
              }
              isOpenByError={isDocumentSignCardOpenByError}
              setIsOpenByError={setIsDocumentSignCardOpenByError}
            />
          )}

          {showPromotionCard && (
            <CollapseCard
              header={
                <HeaderElements>
                  <CardTitle>{t('promotionCode')}</CardTitle>
                  {checkoutData.promotionCodeDiscount ? (
                    <PriceDiscount>
                      {`- ${getPrice(
                        checkoutData.promotionCodeDiscount,
                        checkoutData.currency
                      )}`}
                    </PriceDiscount>
                  ) : (
                    <></>
                  )}
                </HeaderElements>
              }
              isOpen={
                checkoutData?.promotionCode ||
                activePromotion ||
                checkoutData?.promotionCodeMode === MANDATORY
                  ? true
                  : false
              }
              number={
                +showBankIDCard +
                +showCustomerInfoCard +
                +showDocumentSigningCard +
                +showAddonsCard +
                2
              }
              body={<PromotionCodeCard checkoutId={checkoutData?.id} />}
            />
          )}

          <CollapseCard
            header={
              <HeaderElements>
                <CardTitle>{t('confirm')}</CardTitle>
              </HeaderElements>
            }
            isOpen={true}
            number={
              +showBankIDCard +
              +showCustomerInfoCard +
              +showDocumentSigningCard +
              +showPromotionCard +
              +showAddonsCard +
              2
            }
            body={
              <ConfirmCard
                setIsCheckoutItemsCardOpenByError={setIsCheckoutItemsCardOpenByError}
                setIsAddonsCardOpenByError={setIsAddonsInfoCardOpenByError}
                setIsCustomerInfoCardOpenByError={setIsCustomerInfoCardOpenByError}
                setIsDocumentSignCardOpenByError={setIsDocumentSignCardOpenByError}
                setIsBankIDCardOpenByError={setIsBankIDCardOpenByError}
              />
            }
          />
        </>
      ) : (
        <Loader />
      )}
      {activeDeleteItemId && (
        <Modal>
          <ModalBody>{renderModalCancelBody()}</ModalBody>
        </Modal>
      )}
    </Layout>
  );
});
