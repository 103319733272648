import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';

const ReadMore = styled.div`
  display: inline;
  margin-left: 5px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
`;
const ReadLess = styled.div`
  display: inline;
  margin-left: 5px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
`;

export const CollapseText = (props = '', collapseValue = 4) => {
  const text = props;
  const [isCollapsed, setCollapse] = useState(false);
  const { clientWidth } = document.body;
  const maxWidthString = clientWidth / collapseValue;
  const isTextShort = text.length < maxWidthString;
  const { t } = useTranslation(Namespaces.UI);

  return isCollapsed || isTextShort ? (
    <>
      {text}
      {isCollapsed && (
        <ReadLess
          onClick={(e) => {
            e.stopPropagation();
            setCollapse(false);
          }}
        >
          {t('readLess')}
        </ReadLess>
      )}
    </>
  ) : (
    <div>
      {text.slice(0, maxWidthString)}
      <ReadMore
        onClick={(e) => {
          e.stopPropagation();
          setCollapse(true);
        }}
      >
        {t('readMore')}
        ...
      </ReadMore>
    </div>
  );
};
