import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { CheckoutAppointmentAddon } from 'components/CheckoutAddons/CheckoutAppointmentAddon';
import { CheckoutCourseAddon } from 'components/CheckoutAddons/CheckoutCourseAddon';
import { CheckoutVoucherAddon } from 'components/CheckoutAddons/CheckoutVoucherAddon';
import { CheckoutMembershipAddon } from 'components/CheckoutAddons/CheckoutMembershipAddon';
import {
  ADDON_TYPE_APPOINTMENT,
  ADDON_TYPE_COURSE,
  ADDON_TYPE_MEMBERSHIP,
  ADDON_TYPE_VOUCHER,
  ADDON_TYPE_PRODUCT,
} from 'constants/addonConstants';
import { CheckoutData, CheckoutStore } from 'store/CheckoutStore';

import 'react-phone-input-2/lib/style.css';
import './styles.scss';
import { CheckoutProductAddon } from 'components/CheckoutAddons/CheckoutProductAddon';

export const AddonsCard = observer(
  (props: { checkout: CheckoutData; transactionLinkHash: string | null }) => {
    const { checkout, transactionLinkHash } = props;
    const checkoutData = toJS(checkout);
    const { addons, items } = checkoutData;

    const { setFieldError } = CheckoutStore;

    const onAddAddonClicked = () => {
      setFieldError('addonsMandatory', '');
    };

    return (
      <>
        {addons.map((element, index) => {
          switch (element.type) {
            case ADDON_TYPE_APPOINTMENT: {
              return (
                <CheckoutAppointmentAddon
                  addon={element}
                  key={index}
                  onAddAddonClicked={onAddAddonClicked}
                />
              );
            }
            case ADDON_TYPE_COURSE: {
              return (
                <CheckoutCourseAddon
                  addon={element}
                  key={index}
                  onAddAddonClicked={onAddAddonClicked}
                />
              );
            }
            case ADDON_TYPE_VOUCHER: {
              return (
                <CheckoutVoucherAddon
                  addon={element}
                  key={index}
                  onAddAddonClicked={onAddAddonClicked}
                  transactionLinkHash={transactionLinkHash}
                />
              );
            }
            case ADDON_TYPE_MEMBERSHIP: {
              return (
                <CheckoutMembershipAddon
                  addon={element}
                  key={index}
                  onAddAddonClicked={onAddAddonClicked}
                  transactionLinkHash={transactionLinkHash}
                />
              );
            }
            case ADDON_TYPE_PRODUCT: {
              return (
                <CheckoutProductAddon
                  addon={element}
                  key={index}
                  items={items}
                  onAddAddonClicked={onAddAddonClicked}
                  transactionLinkHash={transactionLinkHash}
                />
              );
            }
            default:
              return null;
          }
        })}
      </>
    );
  }
);
