import { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { devices } from 'constants/mediaConstants';
import { Namespaces } from 'languages';
import { getMonthName, getY, getYM } from 'helpers/DateTimeUtils';

import { HeaderStore } from 'store/HeaderStore';
import { PaymentsListStore, PaymentsListType } from 'store/PaymentsListStore';

import { Loader } from 'components/Loader/Loader';
import { EmptyResult } from 'components/EmptyResult/EmptyResult';

import CloseIcon from 'assets/icons/close.svg';

import { Flex } from 'styled/Flex';
import Layout from 'styled/Layout';

import { PaymentsItem } from './PaymentItem';
import { RootPageTitle } from 'components/RootPageTitle';

const PaymentsElement = styled(Layout)`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

const ModalBody = styled.div`
  position: relative;
  width: 545px;
  max-height: 100vh;
  overflow: auto;
  padding: 72px 38px 42px 34px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    padding: 72px 25px 30px;
  }
`;

const IconClose = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-image: url(${CloseIcon});
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media ${devices.mobile} {
    right: 30px;
    top: 30px;
    height: 25px;
    width: 25px;
  }
`;

const PaymentItemHeader = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin: 0 15px 12px;
  @media ${devices.mobile} {
    font-size: 20px;
  }
`;

const LoadMoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 130px;
  margin: 25px auto 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  box-sizing: border-box;
  border-radius: 19px;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.colors.darkBlack};

  @media ${devices.mobile} {
    max-height: 38px;
    width: 130px;
    padding: 12px 30px;
    font-size: 12px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const PaymentsListPage = observer(() => {
  const {
    getPaymentsList,
    paymentsList,
    loading,
    page,
    loadMore,
    clearPaymentList,
  } = PaymentsListStore;
  const { setBackButton } = HeaderStore;
  const { t } = useTranslation(Namespaces.UI);
  const [modalType, setModalType] = useState('');
  const [sortedPaymentsListData, setSortedPaymentsListData] = useState<
    PaymentsListType[][]
  >([]);

  useEffect(() => {
    setBackButton(true);
    getPaymentsList();
  }, [setBackButton, getPaymentsList]);

  const paymentsListData = toJS(paymentsList);
  const pageData = toJS(page);

  useEffect(() => {
    if (paymentsListData?.length && paymentsListData[0]) {
      let paymentsListGroup: PaymentsListType[] = [];
      const sortedPaymentsList: PaymentsListType[][] = [];
      let { paidAt } = paymentsListData[0];
      for (let i = 0; i < paymentsListData.length; i++) {
        if (getYM(paidAt) === getYM(paymentsListData[i].paidAt)) {
          paymentsListGroup.push(paymentsListData[i]);
        } else {
          sortedPaymentsList.push(paymentsListGroup);
          paidAt = paymentsListData[i].paidAt;
          paymentsListGroup = [paymentsListData[i]];
        }
      }
      setSortedPaymentsListData(sortedPaymentsList.concat([paymentsListGroup]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsList]);

  useEffect(() => {
    return () => {
      clearPaymentList();
    };
  }, [clearPaymentList]);

  const handleRefreshPayment = () => {
    getPaymentsList();
  };

  const paymentListCard = (paymentGroup: PaymentsListType[]) => {
    const { id, paidAt } = paymentGroup[0];

    return (
      <div key={id}>
        <Flex justifyContent="space-between" alignItems="center" margin="35px 0 0">
          <PaymentItemHeader>
            {`${t(getMonthName(paidAt) as string)} ${getY(paidAt)}`}
          </PaymentItemHeader>
        </Flex>
        {paymentGroup.map((item: PaymentsListType) => {
          return <PaymentsItem key={item.id} paymentItem={item} />;
        })}
      </div>
    );
  };
  const renderPaymentItems = () => {
    if (!paymentsListData?.length) {
      return (
        <EmptyResult
          text={t('noPaymentsMessage')}
          action={handleRefreshPayment}
          loading={loading}
        />
      );
    }
    return sortedPaymentsListData.map((paymentListGroup: PaymentsListType[]) => {
      return paymentListCard(paymentListGroup);
    });
  };

  return (
    <>
      <>
        <PaymentsElement>
          <RootPageTitle title={t('myPayments')} />
          {!paymentsListData ? (
            <Loader />
          ) : (
            <>
              {renderPaymentItems()}
              {loadMore && !!sortedPaymentsListData.length && (
                <LoadMoreButton
                  onClick={() => {
                    if (!loading) {
                      getPaymentsList(pageData + 1, paymentsListData);
                    }
                  }}
                >
                  {loading ? <Loader small /> : t('loadMore')}
                </LoadMoreButton>
              )}
            </>
          )}
        </PaymentsElement>
      </>

      {modalType && (
        <Modal>
          <ModalBody>
            {modalType === 'info' ? (
              <IconClose
                onClick={() => {
                  setModalType('');
                }}
              />
            ) : null}
            {/* {modalType === 'info' ? renderModalInfoBody() : null}
            {modalType === 'cancel' ? renderModalCancelBody() : null} */}
          </ModalBody>
        </Modal>
      )}
    </>
  );
});
