import { FC, useCallback } from 'react';
import {
  AccountInfoBlock,
  Name,
  Line,
  SuccessWrap,
  SuccessMessage,
  InputWrap,
  ContactRow,
  InputHeader,
  InputBlock,
  IconElement,
  ErrorMessage,
  ErrorServerWrap,
  ErrorServer,
  ButtonContainer,
  DeleteAccountButton,
  ButtonSave,
  ButtonCancel,
  Edit,
} from '../styles';
import { Avatar } from 'components/Avatar';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';
import { Input } from 'components/Input';
import { observer } from 'mobx-react-lite';
import { useAccountSettings } from 'hooks/useAccountSettings';
import PhoneInput from 'react-phone-input-2';
import { getDefaultPhoneCountry } from 'helpers';
import { Loader } from 'components/Loader/Loader';
import { Dispatch, SetStateAction } from 'react';

interface IProps {
  setShowDeleteAccountModal: Dispatch<SetStateAction<boolean>>;
}

export const AccountForm: FC<IProps> = observer(({ setShowDeleteAccountModal }) => {
  const { t } = useTranslation(Namespaces.UI);

  const {
    changeUserData,
    updateAvatar,
    fillInputs,
    removeError,
    user,
    isDeleteUserAccountLoading,
    loading,
    state: { state, setState },
    isServerError: { isServerError, setServerError },
    isSuccessUpdate: { isSuccessUpdate },
    errors: { errors, setError },
    activeEditing: { activeEditing, setActiveEditing },
  } = useAccountSettings();

  const { firstName, surname, email, phoneNumber } = state;

  const activeEditingLength = Object.keys(activeEditing).length;

  const toggleEdit = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const field = event.currentTarget.getAttribute('data-label') || '';
      const isActive = !!activeEditing[field];
      if (isActive) {
        setError({ ...errors, [field]: '' });
        activeEditing[field].callback(activeEditing[field].oldValue);
        const newActiveEditing = activeEditing;
        delete newActiveEditing[field];
        setActiveEditing(newActiveEditing);
      } else {
        setActiveEditing({
          ...activeEditing,
          [field]: {
            field,
            oldValue: state[field],
            newValue: state[field],
            callback: (val: string) => setState((prev) => ({ ...prev, [field]: val })),
          },
        });
      }
    },
    [activeEditing, setActiveEditing, errors, setError, setState, state]
  );

  const onInputChange = useCallback(
    (value: string, field: string) => {
      removeError(field);
      setState((prev) => ({ ...prev, [field]: value }));
      setActiveEditing({
        ...activeEditing,
        [field]: { ...activeEditing[field], newValue: value },
      });
    },
    [activeEditing, removeError, setActiveEditing, setState]
  );

  const changeAvatar = useCallback(
    (file: File) => {
      updateAvatar(file);
    },
    [updateAvatar]
  );

  const cancelEditing = useCallback(() => {
    setServerError('');
    fillInputs();
    setError({});
    setActiveEditing({});
  }, [fillInputs, setActiveEditing, setError, setServerError]);

  return (
    <AccountInfoBlock>
      <Avatar
        url={user.avatarUrl}
        onChange={changeAvatar}
        style={{ margin: '0 auto 20px' }}
      />
      <Name>{`${user.firstName} ${user.surname}`}</Name>
      <Line />
      {isSuccessUpdate && (
        <SuccessWrap>
          <SuccessMessage>{t('successUpdate')}</SuccessMessage>
        </SuccessWrap>
      )}
      <InputWrap>
        {t('accountName')}
        <Input
          value={firstName}
          title={t('firstName')}
          disabled={!activeEditing.firstName}
          error={errors.firstName}
          iconElement={
            <Edit
              data-label="firstName"
              active={!!activeEditing['firstName']}
              onClick={toggleEdit}
            />
          }
          change={(value: string) => onInputChange(value, 'firstName')}
        />
        <Input
          value={surname}
          title={t('surname')}
          disabled={!activeEditing.surname}
          iconElement={
            <Edit
              data-label="surname"
              active={!!activeEditing['surname']}
              onClick={toggleEdit}
            />
          }
          error={errors.surname}
          change={(value: string) => onInputChange(value, 'surname')}
        />
      </InputWrap>
      <InputWrap>
        {t('phone')}
        <>
          <ContactRow>
            <InputHeader>{t('phoneNumber')}</InputHeader>
            <InputBlock error={errors.phoneNumber}>
              <PhoneInput
                inputStyle={{ color: '#181818' }}
                disabled={!activeEditing.phoneNumber}
                country={getDefaultPhoneCountry()}
                value={phoneNumber}
                onChange={(value) => onInputChange(value, 'phoneNumber')}
                masks={{ se: '.. ... .. ..' }}
              />
            </InputBlock>
          </ContactRow>
          <IconElement>
            <Edit
              data-label="phoneNumber"
              active={!!activeEditing['phoneNumber']}
              onClick={toggleEdit}
            />
          </IconElement>
          {errors.phoneNumber && <ErrorMessage>{`*${errors.phoneNumber}`}</ErrorMessage>}
        </>
      </InputWrap>
      <InputWrap>
        {t('email')}
        <Input
          value={email}
          title={t('email')}
          disabled={!activeEditing.email}
          error={errors.email}
          iconElement={
            <Edit
              data-label="email"
              active={!!activeEditing['email']}
              onClick={toggleEdit}
            />
          }
          change={(value: string) => onInputChange(value, 'email')}
        />
      </InputWrap>
      {isServerError && (
        <ErrorServerWrap>
          <ErrorServer>{isServerError}</ErrorServer>
        </ErrorServerWrap>
      )}
      <ButtonContainer>
        {activeEditingLength === 0 && (
          <DeleteAccountButton
            size="large"
            onClick={() => setShowDeleteAccountModal(true)}
          >
            {isDeleteUserAccountLoading ? <Loader /> : <>{t('deleteAccountButton')}</>}
          </DeleteAccountButton>
        )}
        {activeEditingLength > 0 && (
          <>
            <ButtonSave size="large" onClick={changeUserData}>
              {loading ? <Loader isWhite /> : <>{t('save')}</>}
            </ButtonSave>
            <ButtonCancel secondary size="large" onClick={cancelEditing}>
              {t('cancel')}
            </ButtonCancel>
          </>
        )}
      </ButtonContainer>
    </AccountInfoBlock>
  );
});
