import { makeAutoObservable } from 'mobx';

interface ErrorPropsType {
  code: string;
  detail?: string;
  invalidField?: string;
  message?: string;
  status: number;
  place: string;
}

class ErrorHandle {
  error: ErrorPropsType | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setError = (error: ErrorPropsType) => {
    this.error = error;
  };

  removeError = () => {
    this.error = undefined;
  };
}

export const ErrorStore = new ErrorHandle();
