import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { callApi, deleteApi, postApi } from 'helpers';
import { PaymentsListType } from './PaymentsListStore';

export type SubscriptionsListType = {
  amount: number;
  createdAt: string;
  id: number;
  nextAt: string;
  lastAt: string;
  deletedAt: string;
  currency: string;
  paymentType: string;
  recurringUnit: string;
  noticePeriodDays: number;
  lastAtWithNoticePeriod: string;
  service: {
    id: number;
    name: string;
    description: string;
  };
  status: string;
  paymentMethod: {
    id: number;
    type: string;
    brand: string;
    last4: string;
    expiresAt: string;
  };
};

export type SubscriptionChangePaymentMethodSetupType = {
  stripe?: {
    clientSecret: string;
  };
};

export class SubscriptionStore {
  subscriptionsList?: SubscriptionsListType[] | [] = undefined;

  paymentsList?: PaymentsListType[] | [] = undefined;

  addPaymentMethodSetup?: SubscriptionChangePaymentMethodSetupType = undefined;

  loading = false;

  loadMore = false;

  page = 0;

  pageTransaction = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setSubscriptionsList = (subscriptionsList: SubscriptionsListType[] | []) => {
    this.subscriptionsList = subscriptionsList;
  };

  clearSubscriptionsList = () => {
    this.subscriptionsList = undefined;
  };

  setPaymentsList = (paymentsList: PaymentsListType[] | []) => {
    this.paymentsList = paymentsList;
  };

  clearPaymentList = () => {
    this.paymentsList = undefined;
  };

  getSubscriptionsList = async (
    page?: number,
    subscriptionsItems: SubscriptionsListType[] | [] = []
  ): Promise<void | SubscriptionsListType[]> => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(
        `v1/users/me/subscriptions${page ? `?page=${page}` : ''}`
      );
      const data = await res.json();

      const subscriptionsList = subscriptionsItems.concat(data.data);
      this.setSubscriptionsList(subscriptionsList);
      runInAction(() => {
        this.page = data.page;
      });
      if (data.page === data.totalPages) {
        runInAction(() => {
          this.loadMore = false;
        });
      } else {
        runInAction(() => {
          this.loadMore = true;
        });
      }
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
    return [];
  };

  deleteSubscriptionsListItem = async (id: number, callback: () => void) => {
    const res = await deleteApi(`/v1/subscriptions/${id}`);
    const data = await res.json();
    const subscriptionsList = toJS(this.subscriptionsList);
    if (subscriptionsList) {
      for (let i = 0; i < subscriptionsList?.length; i++) {
        if (subscriptionsList[i].id === data.id) {
          subscriptionsList.splice(i, 1, data);
        }
      }

      runInAction(() => {
        this.subscriptionsList = subscriptionsList;
      });

      callback();
    }
  };

  getSubscriptionPaymentHistory = async (
    id: number,
    pageTransaction?: number,
    paymentItems: PaymentsListType[] | [] = []
  ): Promise<void | PaymentsListType[]> => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(
        `/v1/subscriptions/${id}/transactions${
          pageTransaction ? `?page=${pageTransaction}` : ''
        }`
      );
      const data = await res.json();
      const paymentsList = paymentItems.concat(data.data);
      this.setPaymentsList(paymentsList);
      runInAction(() => {
        this.pageTransaction = data.page;
      });
      if (data.page === data.totalPages) {
        runInAction(() => {
          this.loadMore = false;
        });
      } else {
        runInAction(() => {
          this.loadMore = true;
        });
      }
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
    return [];
  };

  getAddSubscriptionPaymentMethodSetup = async (id: number | string, type: string) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(
        `v1/subscriptions/${id}/payment-method-setup?type=${type}`
      );
      const data = await res.json();
      this.addPaymentMethodSetup = data;
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
    return [];
  };

  addSubscriptionPaymentMethod = async (
    id: number | string,
    body: object,
    callback: () => void
  ) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await postApi(`v1/subscriptions/${id}/payment-methods`, body);
      if (res.status === 200) {
        callback();
      }
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
    return [];
  };
}

export const SubscriptionsStore = new SubscriptionStore();
