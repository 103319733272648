import { makeAutoObservable, runInAction, toJS } from 'mobx';

import { callApi, deleteApi } from 'helpers';

type PaymentType = {
  transaction: {
    amount: number;
    createdAt: string;
    currency: string;
    id: number;
    paidAt: string;
    paymentType: string;
    refundedAmount: number;
    serialNumber: string;
    status: string;
  };
  totalPaidAmount: number;
};

type Profile = {
  id: number;
  name: string;
  logoUrl: string;
  phone: string;
  email: string;
  website: string;
};

type ServiceType = {
  type: string;
  balance: number;
  description: string;
  validForAllServices: true;
  validServices: { id: number; name: string }[];
  id: number;
  name: string;
  price: number;
  currency: string;
};

export type Voucher = {
  validTo: string;
  balance: number;
  originalBalance: number;
  service: ServiceType;
  profile: Profile;
  id: number;
  voucherId: number;
  status: string;
  createdAt: string;
  payment: PaymentType;
  price: number;
  currency: string;
  transactionHistory: {
    id: number;
    name: string;
    date: string;
    currency: string;
    price: number;
  }[];
  transactionLinkHash: string;
};
export type Transaction = {
  balanceChange: number;
  createdAt: string;
  id: number;
  service?: ServiceType;
  type: string;
};

export type TransactionData = {
  data: Transaction[];
  page: number;
  perPage: number;
  totalPages: number;
  totalRows: number;
};

export type Membership = {
  validTo: string;
  balance: number;
  originalBalance: number;
  service: ServiceType;
  profile: Profile;
  id: number;
  status: string;
  createdAt: string;
  payment: PaymentType;
  price: number;
  currency: string;
  membershipId: number;
  transactionHistory: {
    id: number;
    name: string;
    date: string;
    currency: string;
    price: number;
  }[];
  transactionLinkHash: string;
};

export class Discounts {
  vouchers?: Voucher[] | [] = undefined;

  memberships?: Membership[] | [] = undefined;

  transactions?: TransactionData = undefined;

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setVouchers = (vouchers: Voucher[] | []) => {
    this.vouchers = vouchers;
  };

  setTransactions = (transactions: TransactionData | undefined) => {
    this.transactions = transactions;
  };

  setMemberships = (memberships: Membership[] | []) => {
    this.memberships = memberships;
  };

  deleteDiscount = async (id: number, callback: () => void) => {
    const res = await deleteApi(`v1/bookings/${id}`);
    const data = await res.json();
    if (data.service.type === 'voucher') {
      const vouchers = toJS(this.vouchers);
      if (vouchers) {
        this.vouchers = vouchers.filter((voucher) => {
          return voucher.id !== data.id;
        });
      }
    } else {
      const memberships = toJS(this.memberships);
      if (memberships) {
        this.memberships = memberships.filter((membership) => {
          return membership.id !== data.id;
        });
      }
    }

    callback();
  };

  getMemberships = async (
    page?: number,
    membershipItems: Membership[] | [] = []
  ): Promise<void | Membership[]> => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(
        `v1/users/me/bookings/memberships${page ? `?page=${page}` : ''} `
      );
      const data = await res.json();
      if (data.page === data.totalPages) {
        const memberships = membershipItems.concat(data.data);
        this.setMemberships(memberships);
      } else if (data.totalPages) {
        const bookings = membershipItems.concat(data.data);
        return await this.getMemberships(++data.page, bookings);
      } else {
        this.setMemberships([]);
      }
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
    return [];
  };

  getMembershipsTransactions = async (
    membershipId: number,
    numberOfPage?: number
  ): Promise<void | TransactionData> => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(
        `/v1/memberships/${membershipId}/transactions${
          numberOfPage ? `?page=${numberOfPage}` : ''
        } `
      );

      const { data, page, perPage, totalPages, totalRows } = await res.json();

      if (data) {
        const oldData = this.transactions ? this.transactions?.data : [];
        const newData = [...oldData, ...data];
        this.setTransactions({ data: newData, page, perPage, totalPages, totalRows });
      }
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  getVouchersTransactions = async (
    voucherId: number,
    numberOfPage?: number
  ): Promise<void | TransactionData> => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(
        `/v1/vouchers/${voucherId}/transactions${
          numberOfPage ? `?page=${numberOfPage}` : ''
        } `
      );
      const { data, page, perPage, totalPages, totalRows } = await res.json();

      if (data) {
        const oldData = this.transactions ? this.transactions?.data : [];
        const newData = [...oldData, ...data];
        this.setTransactions({ data: newData, page, perPage, totalPages, totalRows });
      }
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  getVouchers = async (
    page?: number,
    voucherItems: Voucher[] | [] = []
  ): Promise<void | Voucher[]> => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(
        `v1/users/me/bookings/vouchers${page ? `?page=${page}` : ''} `
      );
      const data = await res.json();
      if (data.page === data.totalPages) {
        const vouchers = voucherItems.concat(data.data);
        this.setVouchers(vouchers);
      } else if (data.totalPages) {
        const vouchers = voucherItems.concat(data.data);
        return await this.getVouchers(++data.page, vouchers);
      } else {
        this.setVouchers([]);
      }
    } catch (error) {
      console.info(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
    return [];
  };
}

export const DiscountStore = new Discounts();
