import { useState } from 'react';
import { observer } from 'mobx-react';
import styled, { DefaultTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getPrice, urls } from 'helpers';
import { getYMD } from 'helpers/DateTimeUtils';
import { Namespaces } from 'languages';

import { devices } from 'constants/mediaConstants';

import { Voucher, Membership } from 'store/DiscountStore';

import {
  DISCOUNT_TYPE_MEMBERSHIP,
  DISCOUNT_TYPE_VOUCHER,
} from 'constants/discountConstants';

import MembershipIcon from 'assets/icons/membership-icon.svg';
import VoucherIcon from 'assets/icons/voucher-icon.svg';
import infoIcon from 'assets/icons/infoIcon.svg';
import PriceIcon from 'assets/icons/price.svg';
import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';
import { Flex } from 'styled/Flex';
import { CheckoutStore } from 'store/CheckoutStore';
import { useHistory } from 'react-router-dom';
import { Loader } from 'components/Loader/Loader';

const MAX_VALID_FOR_SERVICE = 3;

const DiscountHeader = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 20px;
  }
`;

const DiscountDescription = styled.div`
  display: flex;
  justify-content: ${(props: { isEnd?: boolean }) =>
    props.isEnd ? 'flex-end' : 'space-between'};
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.lightBlack};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const CardItem = styled.div`
  position: relative;
  margin: 25px 0;
  padding: 26px 34px 26px;
  border-radius: 30px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  background: ${({ theme }) => theme.colors.white};

  @media ${devices.mobile} {
    padding: 16px 20px 10px;
  }
`;

const ButtonCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  height: 50px;
  margin: 26px auto 0px;
  color: ${({ theme }) => theme.colors.darkBlack};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.9;
  }
  @media ${devices.mobile} {
    width: 120px;
    font-size: 15px;
  },
`;

const ButtonPayNow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  height: 50px;
  margin: 26px auto 0px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.green};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.9;
  }
  @media ${devices.mobile} {
    width: 120px;
    font-size: 15px;
  },
`;

const Price = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.darkBlack};
  @media ${devices.mobile} {
    font-size: 18px;
  },
`;

const CardPriceIcon = styled.div`
  height: 21px;
  width: 21px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${PriceIcon});
`;
type IconProps = {
  icon: string;
};

const CustomIcon = styled.div`
  margin: 6px auto 27px;
  width: 52px;
  height: 52px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props: IconProps) => props.icon});
`;
const CardValidBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;
const ValidThruBlock = styled.div`
  width: 100%;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px 10px 0 0;
  @media ${devices.mobile} {
    padding: 8px;
  },
`;
type ValidForBlockProps = {
  theme: DefaultTheme;
  isLastBlock: boolean;
};

const ValidForBlock = styled.div`
  width: 100%;
  margin-top: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${(props: ValidForBlockProps) => props.theme.colors.middleGray};
  border-radius: ${(props: ValidForBlockProps) =>
    props.isLastBlock ? '0 0 10px 10px' : '0'};
  @media ${devices.mobile} {
    padding: 8px;
  },
`;
const BalanceBlock = styled.div`
  width: 100%;
  margin-top: -1px;
  padding: 11px 10px 11px 18px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 0 0 10px 10px;
  @media ${devices.mobile} {
    padding: 8px;
  },
`;

const ServiceItem = styled.li`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  line-height: 24px;
  font-size: 15px;
`;
const ServiceList = styled.ul`
  margin-bottom: 0;
  padding: 0 18px;
`;

const ArrowIcon = styled.div`
  position: absolute;
  right: 26px;
  top: 0px;
  width: 12px;
  height: 7px;
  margin-left: 9px;
  background: url(${(props: { isCollapsed: boolean }) =>
    props.isCollapsed ? ArrowDown : ArrowUp});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

const ListBlock = styled.div`
  position: relative;
  width: 100%;
`;

const CardDateTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family:  ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  @media ${devices.mobile} {
    font-size: 11px;
  },
`;

const CardDateValues = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 700;
  @media(max-width: 500px ){
    font-size: 16px;
  }
  @media ${devices.mobile} {
    font-size: 12px;
  },
`;
const Balance = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  @media(max-width: 500px ){
    font-size: 20px;
  }
  @media ${devices.mobile} {
    font-size: 20px;
  },
`;

const UsedValue = styled.div`
  margin-right: auto;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
`;

const InfoIcon = styled.div`
  height: 22px;
  width: 22px;
  padding-right: 22px;
  background-size: cover;
  background-image: url(${infoIcon});
  cursor: pointer;
`;

type DiscountProps = {
  discount: Membership | Voucher;
  setActiveDiscount: (discount: Membership | Voucher) => void;
  setModalType: (type: string) => void;
};

export const DiscountItem = observer((props: DiscountProps) => {
  const { discount, setActiveDiscount, setModalType } = props;
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation(Namespaces.UI);
  const { getCheckoutFromHash } = CheckoutStore;
  const [loadingButton, setLoadingButton] = useState(false);
  const history = useHistory();
  const {
    service: { name: serviceName, type, description, validForAllServices, validServices },
    price,
    balance,
    originalBalance,
    currency,
    validTo,
    status,
    transactionLinkHash,
  } = discount;
  const renderValidForList = (services: { id: number; name: string }[]) => {
    return (
      <ListBlock>
        {validServices.length > MAX_VALID_FOR_SERVICE ? (
          <ArrowIcon
            isCollapsed={collapsed}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
        ) : null}
        <ServiceList>
          {services.map((el, i) => {
            if (collapsed && i >= MAX_VALID_FOR_SERVICE) {
              return null;
            }
            return <ServiceItem key={i}>{el.name}</ServiceItem>;
          })}
        </ServiceList>
      </ListBlock>
    );
  };

  return (
    <div key={discount.id}>
      <CardItem>
        <CustomIcon
          icon={type === DISCOUNT_TYPE_MEMBERSHIP ? MembershipIcon : VoucherIcon}
        />
        <Flex justifyContent="space-between" alignItems="center">
          <DiscountHeader>{serviceName}</DiscountHeader>
          <InfoIcon
            onClick={() => {
              setModalType('info');
              setActiveDiscount(discount);
            }}
          />
        </Flex>
        <DiscountDescription>{description}</DiscountDescription>
        <Flex alignItems="center">
          <CardPriceIcon />
          <Price>{getPrice(price, currency)}</Price>
        </Flex>

        <CardValidBlock>
          <ValidThruBlock>
            <CardDateTitle>{t('validTo').toUpperCase()}</CardDateTitle>
            <CardDateValues>{getYMD(validTo)}</CardDateValues>
          </ValidThruBlock>
          <ValidForBlock isLastBlock={type === DISCOUNT_TYPE_MEMBERSHIP}>
            <CardDateTitle>{t('validFor').toUpperCase()}</CardDateTitle>
            <CardDateValues>
              {validForAllServices
                ? t('allServices')
                : renderValidForList(
                    validServices as {
                      id: number;
                      name: string;
                    }[]
                  )}
            </CardDateValues>
          </ValidForBlock>
          {type === DISCOUNT_TYPE_VOUCHER ? (
            <BalanceBlock>
              <CardDateTitle>{t('balance').toUpperCase()}</CardDateTitle>
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="baseline">
                  <Balance>{getPrice(balance, currency)}</Balance>
                  {balance < originalBalance ? (
                    <UsedValue>
                      {`(${getPrice(originalBalance - balance, currency)} ${t('spent')})`}
                    </UsedValue>
                  ) : null}
                </Flex>

                <InfoIcon
                  onClick={() => {
                    setModalType('transactionHistory');
                    setActiveDiscount(discount);
                  }}
                />
              </Flex>
            </BalanceBlock>
          ) : null}
        </CardValidBlock>
        {status === 'unconfirmed' ? (
          <Flex>
            {/*<ButtonCancel
              onClick={() => {
                setActiveDiscount(discount);
                setModalType('cancel');
              }}
            >
              {t('cancel')}
            </ButtonCancel>*/}
            <ButtonPayNow
              onClick={() => {
                getCheckoutFromHash(transactionLinkHash, setLoadingButton).then(
                  (data) => {
                    if (data.profileId && data.checkoutId) {
                      const { profileId, checkoutId } = data;
                      history.push(
                        urls.checkoutWithTransactionLinkHash(
                          profileId,
                          checkoutId,
                          transactionLinkHash
                        )
                      );
                    }
                  }
                );
              }}
            >
              {loadingButton ? <Loader isWhite small /> : t('payNow')}
            </ButtonPayNow>
          </Flex>
        ) : (
          <Flex>
            {/*<ButtonCancel
              onClick={() => {
                setActiveDiscount(discount);
                setModalType('cancel');
              }}
            >
              {t('cancel')}
            </ButtonCancel>*/}
          </Flex>
        )}
      </CardItem>
    </div>
  );
});
