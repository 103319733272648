import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Flex } from 'styled/Flex';
import { devices } from 'constants/mediaConstants';
import { CourseDateType } from 'store/CourseStore';

import DoneIcon from 'assets/icons/DoneIcon.svg';
import ClosedIcon from 'assets/icons/closed-icon.svg';
import WaitingIcon from 'assets/icons/waiting-icon.svg';
import { Namespaces } from 'languages';
import { getTime, getYMD, getWeekdayName } from 'helpers/DateTimeUtils';
import { PrimaryButton } from 'styled/PrimaryButton';
import { LoginStore } from 'store/LoginStore';
import { useHistory } from 'react-router';
import { toJS } from 'mobx';
import { Loader } from 'components/Loader/Loader';

const ScheduleBlock = styled.div`
  display: flex;
  height: 69px;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.middleGray};
`;

type ScheduleContentProps = {
  disabled?: boolean;
  isRed?: boolean;
  theme: DefaultTheme;
};

const ScheduleContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${(props: ScheduleContentProps) =>
    props.isRed ? props.theme.colors.red : props.theme.colors.darkBlack};
  opacity: ${(props: ScheduleContentProps) => (props.disabled ? 0.3 : 1)};
`;

const ScheduleHeadBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.middleGray};
`;

const ScheduleName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  @media ${devices.mobile} {
    font-size: 14px;
  }
`;

const ScheduleLength = styled.div`
  margin-left: 5px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.darkGray};
  @media ${devices.mobile} {
    font-size: 12px;
  },
`;

type IconProps = {
  icon: string;
  disabled?: boolean;
};

const CustomIcon = styled.div`
  height: 20px;
  width: 20px;
  margin: 9px 45px 9px auto;
  opacity: ${(props: IconProps) => (props.disabled ? '0.3' : '1')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props: IconProps) => props.icon});
  @media ${devices.mobile} {
    margin: 9px 35px 9px auto;
  },
`;

const ScheduleWaitListButton = styled.div`
  width: 85px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.whiteRed};
  color: ${({ theme }) => theme.colors.whiteRed};
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  @media ${devices.mobile} {
    width: 65px;
    height: 38px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const SelectButton = styled.div`
  width: 85px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  @media ${devices.mobile} {
    width: 65px;
    height: 38px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const CustomDoneIcon = styled.div`
  height: 20px;
  width: 20px;
  margin: 9px 45px 9px auto;
  opacity: ${(props: IconProps) => (props.disabled ? '0.3' : '1')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props: IconProps) => props.icon});
  cursor:pointer;
  @media ${devices.mobile} {
    margin: 9px 35px 9px auto;
  },
`;

const SelectAllButton = styled.div`
  width: 110px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  @media ${devices.mobile} {
    width: 95px;
    height: 38px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const ScheduleDate = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  margin-left: 10px;
  margin-right: 25px;
  @media ${devices.mobile} {
    font-size: 12px;
  }
`;

const WeekdayName = styled.div`
  margin-left: 10px;
  margin-top: 2px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.middleLightGray};
`;
const ScheduleDurationTime = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  @media ${devices.mobile} {
    font-size: 12px;
  },
`;
const LoadMoreButton = styled(PrimaryButton)`
  max-height: 38px;
  width: 130px;
  margin: 25px auto 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  box-sizing: border-box;
  border-radius: 19px;
  color: ${({ theme }) => theme.colors.darkBlack};

  @media ${devices.mobile} {
    max-height: 38px;
    width: 130px;
    padding: 12px 30px;
    font-size: 12px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

type CourseOccasionsPropsType = {
  data: CourseDateType[];
  isBookPerOccasion: boolean;
  selectedButtons: number[];
  setSelectedButtons: Dispatch<SetStateAction<number[]>>;
  page: number;
  totalPages: number;
  totalRows: number;
  id: number | string;
  itemsToSelect: number[];
  allEntries: boolean;
  isCourseBookable: boolean;
  setAllEntries: (value: boolean) => void;
  fetchCourseOccasions: (
    serviceId: string | number,
    numberOfPage: number,
    setLoading: (value: boolean) => void
  ) => Promise<void>;
  addCourseOccasionsToWaitingLists: (
    id: string | number,
    setLoading: (element: number[]) => void
  ) => Promise<void>;
};

export const CourseOccasions = observer((props: CourseOccasionsPropsType) => {
  const {
    data,
    isBookPerOccasion,
    selectedButtons,
    setSelectedButtons,
    page,
    totalPages,
    totalRows,
    id,
    itemsToSelect,
    allEntries,
    isCourseBookable,
    setAllEntries,
    fetchCourseOccasions,
    addCourseOccasionsToWaitingLists,
  } = props;
  const history = useHistory();
  const { user } = LoginStore;
  const userData = toJS(user);
  const [loading, setLoading] = useState<number[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const { t } = useTranslation(Namespaces.UI);

  useEffect(() => {
    const { elementId } = localStorage;
    if (elementId && userData.id) {
      addCourseOccasionsToWaitingLists(elementId, setLoading);
      localStorage.removeItem('elementId');
    }
  }, [userData.id, addCourseOccasionsToWaitingLists]);

  const renderScheduleButton = (element: CourseDateType) => {
    if (!isCourseBookable) return <></>;

    if (isBookPerOccasion) {
      if (element.isCallerAttending) {
        return <CustomIcon icon={DoneIcon} />;
      }

      if (element.isFull && element.isCallerOnWaitingList) {
        return <CustomIcon icon={WaitingIcon} />;
      }

      if (element.isFull) {
        if (element.isWaitingListEnabled) {
          return (
            <ScheduleWaitListButton
              onClick={() => {
                if (!userData.id) {
                  localStorage.prevPath = history.location.pathname;
                  localStorage.elementId = element.id;
                  history.push('/auth');
                  return;
                }
                if (!loading.length) {
                  setLoading([element.id]);
                  addCourseOccasionsToWaitingLists(element.id, setLoading);
                }
              }}
            >
              {loading.includes(element.id) ? <Loader small red /> : t('waitingList')}
            </ScheduleWaitListButton>
          );
        }
        return <CustomIcon icon={ClosedIcon} />;
      }

      if (itemsToSelect.includes(element.id) && !selectedButtons.includes(element.id)) {
        return (
          <SelectButton
            onClick={() => {
              setSelectedButtons([...selectedButtons, element.id]);
            }}
          >
            {t('select')}
          </SelectButton>
        );
      }

      if (selectedButtons.includes(element.id)) {
        return (
          <CustomDoneIcon
            icon={DoneIcon}
            onClick={() => {
              setSelectedButtons(selectedButtons.filter((el) => el !== element.id));
              if (allEntries) {
                setAllEntries(false);
              }
            }}
          />
        );
      }
    }

    return null;
  };
  return (
    <Flex flexDirection="column">
      <ScheduleHeadBlock>
        <ScheduleName>{t('schedule')}</ScheduleName>
        <ScheduleLength>
          {totalRows === 1
            ? `(${totalRows} ${t('occasion')})`
            : `(${totalRows} ${t('occasions')})`}
        </ScheduleLength>
        {isBookPerOccasion && isCourseBookable && (
          <>
            {allEntries ? (
              <SelectAllButton
                onClick={() => {
                  setSelectedButtons([]);
                  setAllEntries(false);
                }}
              >
                {t('unselectAll')}
              </SelectAllButton>
            ) : (
              <SelectAllButton
                onClick={() => {
                  setSelectedButtons(itemsToSelect);
                  setAllEntries(true);
                }}
              >
                {t('selectAll')}
              </SelectAllButton>
            )}
          </>
        )}
      </ScheduleHeadBlock>
      {data.map((el) => {
        const isDisabled =
          el.isCallerAttending || (el.isFull && !el.isWaitingListEnabled);
        const isRed = el.isFull && el.isCallerOnWaitingList;
        return (
          <ScheduleBlock key={el.id}>
            <ScheduleContent disabled={isDisabled} isRed={isRed}>
              <Flex flexDirection="column">
                <ScheduleDate>{getYMD(el.startTime, true)}</ScheduleDate>
                <WeekdayName>{t(getWeekdayName(el.startTime, true))}</WeekdayName>
              </Flex>
              <ScheduleDurationTime>
                {`${getTime(el.startTime, true)} - ${getTime(el.endTime, true)}`}
              </ScheduleDurationTime>
              {renderScheduleButton(el)}
            </ScheduleContent>
          </ScheduleBlock>
        );
      })}
      {page !== totalPages && (
        <LoadMoreButton
          onClick={() => {
            if (!loadingMore) {
              setLoadingMore(true);
              fetchCourseOccasions(id, page + 1, setLoadingMore);
            }
          }}
        >
          {loadingMore ? <Loader small /> : t('loadMore')}
        </LoadMoreButton>
      )}
    </Flex>
  );
});
