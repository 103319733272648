import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { getPrice } from 'helpers';
import { useTranslation } from 'react-i18next';

import { CustomIcon } from 'components/CustomIcon/CustomIcon';

import { Namespaces } from 'languages';
import { Addon, CheckoutItem } from 'store/CheckoutStore';

import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';

import { QuantityBlock } from './QuantityBlock';

import {
  AddonCard,
  AddonHeaderContainer,
  AddonTitle,
  AddonPrice,
  AddonDescription,
  AddonDescriptionShort,
} from './AddonComponents';

export const CheckoutProductAddon = observer(
  (props: {
    addon: Addon;
    items: CheckoutItem[];
    onAddAddonClicked: () => void;
    transactionLinkHash: string | null;
  }) => {
    const { addon, items, onAddAddonClicked, transactionLinkHash } = props;
    const {
      currency,
      id,
      name,
      serviceId,
      price,
      isAdded,
      description,
      minQuantity,
      maxQuantity,
    } = addon;
    const { checkoutId } = useParams<{ checkoutId: string }>();
    const [isCollapsed, setCollapsed] = useState(false);
    const addonRef = useRef(null);
    const [maxWidthString, setMaxWidthString] = useState(0);
    const { t } = useTranslation(Namespaces.UI);

    useEffect(() => {
      if (addonRef && addonRef.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-next-line
        setMaxWidthString(addonRef.current.clientWidth / 7);
      }
    }, []);
    const itemProduct = items.filter((el: CheckoutItem) => {
      return el.service.type === 'product' && el.service.id === serviceId;
    });
    return (
      <AddonCard isCollapsed={!description} disabled={isAdded} ref={addonRef}>
        <AddonHeaderContainer>
          <AddonTitle>{name}</AddonTitle>
          <AddonPrice>{getPrice(price, currency)}</AddonPrice>
        </AddonHeaderContainer>
        {description && (
          <>
            {isCollapsed ? (
              <AddonDescription>{description}</AddonDescription>
            ) : (
              <AddonDescriptionShort>{description}</AddonDescriptionShort> || null
            )}
          </>
        )}

        <QuantityBlock
          id={id}
          checkoutId={checkoutId}
          currency={currency}
          price={price}
          disabled={isAdded}
          minQuantity={minQuantity}
          maxQuantity={maxQuantity}
          countStart={itemProduct[0] ? itemProduct[0].quantity : minQuantity || 0}
          onAddAddonClicked={onAddAddonClicked}
          transactionLinkHash={transactionLinkHash}
        />
        {description && description.length > maxWidthString && (
          <CustomIcon
            width="20px"
            height="12px"
            margin="20px auto 0"
            icon={isCollapsed ? ArrowUp : ArrowDown}
            onClick={(e) => {
              if (e) {
                e.stopPropagation();
                setCollapsed(!isCollapsed);
              }
            }}
            hover
            cursor
          />
        )}
      </AddonCard>
    );
  }
);
