import { getTimeLineMinutesFromDate } from 'helpers/DateTimeUtils';
import { FlexibleSlot } from 'store/ServiceStore';
import { DefaultTheme } from 'styled-components';

export type TimeSlotProps = {
  isActive?: boolean;
  isAllowed?: boolean;
  isError?: boolean;
  isPrevAllowed: boolean;
  isNextAllowed: boolean;
  isPrevActive: boolean;
  isNextActive: boolean;
  isStart: boolean;
  isEnd: boolean;
  theme: DefaultTheme;
};

export const getRangeTime = (slots: FlexibleSlot[]) => {
  return slots.reduce((acc: number[][], slot) => {
    const time: number[][] = acc;
    const startTime = +getTimeLineMinutesFromDate('start', slot.startTime, null, true);
    const endTime =
      +getTimeLineMinutesFromDate('end', slot.endTime, slot.startTime, true) + 60;
    time.push([startTime, endTime]);
    return time;
  }, []);
};

export const getStartAllowedTime = (minutes: number, hoursPerPage: number) => {
  const hours = Math.trunc(minutes / 60) - 1;
  if (hours + hoursPerPage >= 24) {
    return 24 - hoursPerPage;
  }
  if (hours < 0) {
    return 0;
  }
  return hours;
};

export const renderSlotBackground = ({
  isActive,
  isAllowed,
  isError,
  theme,
}: TimeSlotProps) => {
  if (isActive) {
    return isError ? '#FD6C7C' : '#3EC29A';
  }
  if (isAllowed) {
    return '#9ee0cc';
  }
  return theme.colors.lightGray;
};

export const getCirclePosition = ({ isStartActive }: { isStartActive: boolean }) => {
  if (isStartActive) {
    return 'left: -9px';
  }
  return 'right: -9px';
};

export const getSlotBorderRadius = ({
  isNextActive,
  isPrevActive,
  isActive,
  isAllowed,
  isStart,
  isEnd,
  isNextAllowed,
  isPrevAllowed,
}: TimeSlotProps) => {
  if (isStart && !isNextAllowed && !isAllowed && !isActive && !isNextActive) {
    return '6px 0px 0px 6px';
  }
  if (isEnd && !isPrevAllowed && !isAllowed && !isActive && !isPrevActive) {
    return '0px 6px 6px 0px';
  }
  if (!isAllowed && isNextAllowed && isPrevAllowed) {
    return '6px';
  }
  if (isActive && !isPrevActive && !isNextActive) {
    return '6px';
  }
  if (
    !isActive &&
    isAllowed &&
    !isPrevActive &&
    !isPrevAllowed &&
    isNextAllowed &&
    isNextActive
  ) {
    return '6px';
  }

  if (!isActive && isAllowed && isPrevActive && !isNextAllowed) {
    return '6px';
  }

  if (!isActive && isAllowed && !isPrevAllowed && !isNextAllowed) {
    return '6px';
  }

  if (isActive && !isPrevActive && isNextActive) {
    return '6px 0px 0px 6px';
  }
  if (isActive && isPrevActive && !isNextActive) {
    return '0px 6px 6px 0px';
  }
  if (isAllowed && isPrevAllowed && !isNextAllowed) {
    return '0px 6px 6px 0px';
  }
  if (isAllowed && !isActive && isPrevActive) {
    return '6px 0px 0px 6px';
  }
  if (isAllowed && !isActive && isNextActive) {
    return '0px 6px 6px 0px';
  }
  if (
    (isStart && !isActive && isNextActive && !isAllowed) ||
    (isStart && !isActive && isNextAllowed && !isAllowed)
  ) {
    return '6px';
  }
  if (isAllowed && isNextAllowed && isPrevAllowed) {
    return '0';
  }

  if (isAllowed && !isPrevAllowed && isNextAllowed) {
    return '6px 0px 0px 6px';
  }
  if (isPrevAllowed && !isAllowed && !isActive) {
    return '6px 0px 0px 6px';
  }
  if (!isPrevActive && isActive && !isNextActive) {
    return '6px';
  }
  if (!isStart && !isEnd && !isAllowed && !isActive) {
    return '0';
  }
  if (
    (!isPrevActive && !isActive && !isAllowed && !isNextActive) ||
    (!isPrevAllowed && !isActive && !isAllowed && !isNextAllowed)
  ) {
    return '6px';
  }
  if (!isPrevActive && isPrevAllowed && isNextActive) {
    return '6px';
  }
  if (isPrevActive && !isNextActive) {
    if (isActive || isAllowed) {
      return '0px 6px 6px 0px';
    }
    return '6px 0px 0px 6px';
  }
  if (isPrevAllowed && !isNextAllowed) {
    if (isActive || isAllowed) {
      return '0px 6px 6px 0px';
    }
    return '6px 0px 0px 6px';
  }
  if (!isAllowed && !isPrevAllowed && !isNextAllowed) {
    return '6px';
  }
  if (isStart) {
    return '6px 0px 0px 6px';
  }
  if (isEnd) {
    return '0px 6px 6px 0px';
  }
  return '0';
};

export const getSlotBorder = ({
  isNextActive,
  isPrevActive,
  isActive,
  isError,
}: TimeSlotProps) => {
  if (!isPrevActive && !isNextActive && isActive) {
    return isError ? 'border: 2px solid #FD2B55' : 'border: 2px solid #34A382';
  }
  if (!isPrevActive && isActive) {
    return isError
      ? `border-bottom: 2px solid #FD2B55; border-top: 2px solid #FD2B55; border-left: 2px solid #FD2B55;`
      : `border-bottom: 2px solid #34A382; border-top: 2px solid #34A382; border-left: 2px solid #34A382;`;
  }
  if (!isNextActive && isActive) {
    return isError
      ? `border-bottom: 2px solid #FD2B55; border-top: 2px solid #FD2B55; border-right: 2px solid #FD2B55;`
      : `border-bottom: 2px solid #34A382; border-top: 2px solid #34A382; border-right: 2px solid #34A382;`;
  }
  if (isPrevActive && isNextActive && isActive) {
    return isError
      ? `border-bottom: 2px solid #FD2B55; border-top: 2px solid #FD2B55;`
      : `border-bottom: 2px solid #34A382; border-top: 2px solid #34A382;`;
  }

  return '';
};

export const getSlotMargin = ({
  isNextActive,
  isPrevActive,
  isActive,
  isAllowed,
  isStart,
  isNextAllowed,
  isPrevAllowed,
}: TimeSlotProps) => {
  if (!isPrevAllowed && isStart && !isActive && isNextActive && isNextAllowed) {
    return 'margin: 0 1px';
  }
  if (isAllowed && isNextAllowed) {
    return '';
  }
  if ((isActive && !isNextActive) || (isAllowed && !isNextActive)) {
    return 'margin-right: 1px';
  }
  if ((!isPrevActive && isActive) || (isAllowed && !isPrevActive)) {
    return 'margin-left: 1px';
  }
  if (
    (!isPrevActive && isNextActive && !isActive) ||
    (!isPrevActive && isNextActive && !isAllowed) ||
    (!isPrevAllowed && isNextAllowed && !isActive) ||
    (!isPrevAllowed && isNextAllowed && !isAllowed)
  ) {
    return 'margin-right: 1px';
  }

  if (
    (isPrevActive && !isNextActive && !isActive) ||
    (isPrevActive && !isNextActive && !isAllowed)
  ) {
    return 'margin-left: 1px';
  }
  return '';
};
