import { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Login } from 'pages/Auth/Login';
import { Registration } from 'pages/Auth/Registration';

import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';

import { HeaderStore } from 'store/HeaderStore';

import Agendo from 'assets/icons/powered.svg';

import Layout from 'styled/Layout';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const AuthPage = styled(Layout)`
  padding: 20px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
`;

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin: 10px auto;
  @media ${devices.mobile} {
    width: 90%;
  }
`;

const ToggleElement = styled.div<{
  isActive: boolean;
}>`
  border-bottom: 1px solid
    ${(props) =>
      props.isActive ? props.theme.colors.darkBlack : props.theme.colors.middleGray};
  width: 48%;
  text-align: center;
  padding-bottom: 5px;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 30px;
  color: ${(props) => props.theme.colors.darkBlack};

  @media${devices.mobile} {
    font-size: 22px;
  } ;
`;

const WrapLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const Auth = observer(() => {
  const [isLogin, setLogin] = useState(true);
  const { setTitle } = HeaderStore;
  const { t } = useTranslation(Namespaces.UI);

  return (
    <>
      <AuthPage data-testid="auth">
        <ToggleWrapper>
          <ToggleElement
            isActive={isLogin}
            onClick={() => {
              setTitle(t('loginTitle'));
              setLogin(true);
            }}
          >
            {t('buttonLogin')}
          </ToggleElement>
          <ToggleElement
            isActive={!isLogin}
            onClick={() => {
              setTitle(t('registrationTitle'));
              setLogin(false);
            }}
          >
            {t('buttonSignUp')}
          </ToggleElement>
        </ToggleWrapper>

        {isLogin ? <Login setLogin={setLogin} /> : <Registration setLogin={setLogin} />}
      </AuthPage>
      <WrapLogo>
        <CustomIcon width="100px" height="50px" margin="0 10px 0 0" icon={Agendo} />
      </WrapLogo>
    </>
  );
});
