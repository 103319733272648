import { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import styled, { DefaultTheme } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { devices } from 'constants/mediaConstants';
import { getPrice } from 'helpers';
import { getTime, getYMD } from 'helpers/DateTimeUtils';
import { Namespaces } from 'languages';

import { HeaderStore } from 'store/HeaderStore';
import { DiscountStore, Membership, Voucher, Transaction } from 'store/DiscountStore';
import { LoginStore } from 'store/LoginStore';
import { Loader } from 'components/Loader/Loader';
import { EmptyResult } from 'components/EmptyResult/EmptyResult';

import CloseIcon from 'assets/icons/close.svg';
import AlertImage from 'assets/icons/alert.svg';
import PriceIcon from 'assets/icons/price.svg';

import { Flex } from 'styled/Flex';
import Layout from 'styled/Layout';

import { DiscountItem } from './DiscountItem';
import { RootPageTitle } from 'components/RootPageTitle';

const BookingsElement = styled(Layout)`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

const ModalBody = styled.div`
  position: relative;
  width: 545px;
  padding: 65px 67px 40px 65px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    padding: 25px;
  }
`;

const TransactionHistoryBlock = styled.div`
  width: 100%;
  max-height: 60vh;
  overflow-y: scroll;
`;

const ModalTitle = styled.div`
  margin-top: 33px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;

  @media ${devices.mobile} {
    margin-top: 25px;
    font-size: 18px;
  }
`;
const NotFoundTitle = styled.div`
  margin-top: 33px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;

  @media ${devices.mobile} {
    margin-top: 25px;
    font-size: 18px;
  }
`;

const ModalDescription = styled.div`
  margin-bottom: 27px;
  color: ${({ theme }) => theme.colors.middleDark};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  @media ${devices.mobile} {
    font-size: 13px;
    line-height: 20px;
  }
`;

const Price = styled.div`
  margin-left: 5px;
  font-style: normal;
  white-space: nowrap;
  font-weight: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'normal' : 'bold'};
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'line-through' : 'none'};
  @media${devices.mobile} {
    font-size: 13px;
  }
`;
const TransactionPriceValue = styled.div`
  margin-left: 5px;
  font-style: normal;
  white-space: nowrap;
  font-weight: bold;
  font-size: 25px;

  @media${devices.mobile} {
    font-size: 15px;
  }
`;

const CardPriceIcon = styled.div`
  height: 21px;
  width: 21px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${PriceIcon});
`;

const IconClose = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-image: url(${CloseIcon});
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media ${devices.mobile} {
    right: 30px;
    top: 30px;
    height: 25px;
    width: 25px;
  }
`;
const CardDateTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  margin-left: 5px;
  @media ${devices.mobile} {
    font-size: 11px;
  }
`;

const TransactionBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.middleGray};
`;

type ScheduleContentProps = {
  disabled?: boolean;
  isRed?: boolean;
  theme: DefaultTheme;
};

const DiscountTransactionListItem = styled.div`
  color: ${(props: ScheduleContentProps) =>
    props.isRed ? props.theme.colors.red : props.theme.colors.darkBlack};
  opacity: ${(props: ScheduleContentProps) => (props.disabled ? 0.3 : 1)};
`;

const TransactionDate = styled.div`
  margin-left: 10px;
  margin-top: 2px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.middleLightGray};
  @media ${devices.mobile} {
    font-size: 12px;
  }
`;

const TransactionName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  margin-left: 10px;
  margin-right: 25px;

  @media ${devices.mobile} {
    font-size: 15px;
  }
`;

const TransactionPrice = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  white-space: nowrap;
  @media ${devices.mobile} {
    font-size: 15px;
  }
`;

const CancelIcon = styled.div`
  width: 72px;
  height: 72px;
  margin: 0 auto;
  background-size: contain;
  background-image: url(${AlertImage});
`;

const CancelModalText = styled.div`
  margin: 25px 0 7px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
`;

const BoldText = styled.b`
  margin: 0 5px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
`;

const CancelModalSubText = styled.div`
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const ModalCancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: #6f6f6f;
  border: 1px solid #a6a6a6;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
  @media ${devices.mobile} {
    margin-bottom: 10px;
  }
`;
const ModalRejectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkBlack};
  border-radius: 28px;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.9;
  }
  @media ${devices.mobile} {
    margin: 5px auto 10px;
  }
`;
const ModalTitleBlock = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.middleGray};
`;

const ShowMoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 130px;
  margin: 25px auto 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  box-sizing: border-box;
  border-radius: 19px;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: pointer;
  text-align: center;
  @media ${devices.mobile} {
    max-height: 38px;
    width: 130px;
    padding: 12px 30px;
    font-size: 12px;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const MembershipPage = observer(() => {
  const {
    getMemberships,
    memberships,
    transactions,
    deleteDiscount,
    getMembershipsTransactions,
    setTransactions,
    loading,
  } = DiscountStore;
  const { setBackButton } = HeaderStore;
  const { getUserStatus } = LoginStore;
  const { t } = useTranslation(Namespaces.UI);
  const [modalType, setModalType] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const [discountId, setDiscountId] = useState<number>(-1);

  const [activeDiscount, setActiveDiscount] = useState<
    Voucher | Membership | undefined
  >();

  useEffect(() => {
    setBackButton(true);
    getMemberships();
    setActiveDiscount(undefined);
  }, [getMemberships, setBackButton]);

  useEffect(() => {
    if (modalType === 'transactionHistory') {
      if (activeDiscount) {
        getMembershipsTransactions((activeDiscount as Membership).membershipId);
        setDiscountId((activeDiscount as Membership).membershipId);
      }
    }
  }, [getMembershipsTransactions, activeDiscount, modalType]);

  const membershipItems = toJS(memberships);
  const handleRemoveItem = () => {
    getUserStatus();
    setModalType('');
    setModalLoading(false);
  };
  const handleRefreshDiscount = () => {
    getMemberships();
  };

  const renderModalInfoBody = () => {
    const {
      service: { name, description },
      price,
      currency,
    } = activeDiscount as Membership;
    return (
      <>
        <ModalTitle>{name}</ModalTitle>
        {description ? <ModalDescription>{description}</ModalDescription> : null}
        <Flex alignItems="center">
          <CardPriceIcon />
          <Price>{getPrice(price, currency)}</Price>
        </Flex>
      </>
    );
  };

  const renderTransactionName = (name: string, type: string) => {
    switch (type) {
      case 'refund':
        return `${t('cancelled')} ${name}`;
      case 'manualAdjustment':
        return `${t('manualAdjustment')} `;
      default:
        return name;
    }
  };

  const renderTransactionHistoryModal = () => {
    const { balance, currency } = activeDiscount as Membership;
    const transactionsItem = toJS(transactions);

    if (!transactionsItem) {
      return <Loader />;
    }
    const { data, page, totalPages } = transactionsItem;

    return (
      <>
        <ModalTitle>{t('transactionHistory')}</ModalTitle>
        <ModalTitleBlock>
          <CardDateTitle>{t('balance').toUpperCase()}</CardDateTitle>
          <TransactionPriceValue>{getPrice(balance, currency)}</TransactionPriceValue>
        </ModalTitleBlock>
        {transactionsItem ? (
          <TransactionHistoryBlock>
            {data.length ? (
              <>
                {data?.map((el: Transaction) => {
                  return (
                    <TransactionBlock key={el.id}>
                      <Flex flexDirection="column">
                        <TransactionName>
                          {renderTransactionName(
                            el.service ? el.service.name : '',
                            el.type
                          )}
                        </TransactionName>
                        <Flex>
                          <TransactionDate>{getYMD(el.createdAt)}</TransactionDate>
                          <TransactionDate>{getTime(el.createdAt)}</TransactionDate>
                        </Flex>
                      </Flex>
                      <DiscountTransactionListItem isRed={el.balanceChange < 0}>
                        <TransactionPrice>
                          {getPrice(el.balanceChange, currency)}
                        </TransactionPrice>
                      </DiscountTransactionListItem>
                    </TransactionBlock>
                  );
                })}
                {page !== totalPages ? (
                  <ShowMoreButton
                    onClick={() => {
                      if (!loading) {
                        getMembershipsTransactions(discountId, page + 1);
                      }
                    }}
                  >
                    {loading ? <Loader small /> : t('showMore')}
                  </ShowMoreButton>
                ) : null}
              </>
            ) : (
              <NotFoundTitle>{t('noTransactionsFound')}</NotFoundTitle>
            )}
          </TransactionHistoryBlock>
        ) : (
          <Loader />
        )}
      </>
    );
  };

  const renderModalCancelBody = () => {
    const { id } = activeDiscount as Voucher | Membership;
    return (
      <>
        <CancelIcon />
        <CancelModalText>{t('cancel')}</CancelModalText>
        <CancelModalSubText>
          {t('cancelBookingMessage')}
          <BoldText>{activeDiscount?.service.name}</BoldText>
          <>?</>
        </CancelModalSubText>
        <ButtonWrap>
          <ModalCancelButton
            onClick={() => {
              if (!modalLoading) {
                setModalLoading(true);
                deleteDiscount(id, handleRemoveItem);
              }
            }}
          >
            {modalLoading ? <Loader small /> : t('bookingCancelButton')}
          </ModalCancelButton>
          <ModalRejectButton
            onClick={() => {
              setModalType('');
            }}
          >
            {t('noKeepIt')}
          </ModalRejectButton>
        </ButtonWrap>
      </>
    );
  };
  if (memberships) {
    if (!memberships.length) {
      return (
        <BookingsElement>
          <RootPageTitle title={t('myMemberships')} />
          <EmptyResult
            text={t('noMembershipMessage')}
            action={handleRefreshDiscount}
            loading={loading}
          />
        </BookingsElement>
      );
    }
  }

  return (
    <>
      {!membershipItems ? (
        <Loader />
      ) : (
        <>
          {membershipItems?.length ? (
            <BookingsElement>
              <RootPageTitle title={t('myMemberships')} />
              {membershipItems.map((el: Membership) => {
                return (
                  <DiscountItem
                    key={el.id}
                    discount={el}
                    setActiveDiscount={setActiveDiscount}
                    setModalType={setModalType}
                  />
                );
              })}
            </BookingsElement>
          ) : null}
        </>
      )}

      {modalType && (
        <Modal>
          <ModalBody>
            {modalType === 'info' || modalType === 'transactionHistory' ? (
              <IconClose
                onClick={() => {
                  setModalType('');
                  if (modalType === 'transactionHistory') {
                    setTransactions(undefined);
                  }
                }}
              />
            ) : null}
            {modalType === 'info' ? renderModalInfoBody() : null}
            {modalType === 'transactionHistory' ? renderTransactionHistoryModal() : null}
            {modalType === 'cancel' ? renderModalCancelBody() : null}
          </ModalBody>
        </Modal>
      )}
    </>
  );
});
