import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { urls } from 'helpers';
import { Namespaces } from 'languages';
import { LoginStore } from 'store/LoginStore';
import { HeaderStore } from 'store/HeaderStore';

import { UserPanel } from 'containers/UserPanel/UserPanel';

import ArrowBack from 'assets/icons/arrow-back.svg';
import HomeImage from 'assets/icons/homeIcon.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import {
  HeaderStyled,
  HeaderItems,
  BackBlock,
  BackButton,
  HeaderText,
  HomeIcon,
} from './styles';
import { useLocation } from 'react-router-dom';

export const Header: FC = observer(() => {
  const history = useHistory();
  const { user, setUserInfo } = LoginStore;
  const { title, clearTitle, setTitle, isBackButton, withBottomShadow } = HeaderStore;
  const { t } = useTranslation(Namespaces.UI);
  const { pathname } = useLocation();
  const [isHomeIcon, setHomeIcon] = useState(false);
  const userData = toJS(user);
  const titleMap: { [key: string]: string } = useMemo(() => {
    return {
      '/auth': t('loginTitle'),
      '/forgotPassword': t('titleForgotPage'),
    };
  }, [t]);

  useEffect(() => {
    history.listen((location) => {
      if (localStorage.profileId) {
        setHomeIcon(location.pathname !== urls.services(localStorage.profileId));
      }
    });
  }, [history]);

  useEffect(() => {
    if (localStorage.profileId) {
      setHomeIcon(pathname !== urls.services(localStorage.profileId));
    }
  }, [pathname]);

  useEffect(() => {
    if (titleMap[pathname] && title === undefined) {
      setTitle(titleMap[pathname]);
    }
  }, [setTitle, title, pathname, titleMap]);

  useEffect(() => {
    if (title && userData.id) {
      clearTitle();
    }
    if (!userData?.id && localStorage.auth) {
      const userInfo = JSON.parse(localStorage.auth);
      setUserInfo(userInfo);
    }
  }, [setUserInfo, userData?.id, clearTitle, title, pathname, titleMap]);

  const handleBack = () => {
    if (title) {
      if (pathname === '/forgotPassword') {
        setTitle(t('loginTitle'));
        history.push(urls.auth);
      } else if (pathname === '/auth') {
        setTitle('');
        history.push(localStorage.prevPath);
      } else {
        clearTitle();
        history.goBack();
      }
    } else {
      if (pathname === '/forgotPassword') {
        setTitle(t('loginTitle'));
        history.push(urls.auth);
      } else {
        if (localStorage.prevPath) {
          history.replace(localStorage.prevPath);
          localStorage.removeItem('prevPath');
          return;
        }
        clearTitle();
        history.goBack();
      }
    }
  };

  return (
    <HeaderStyled data-testid="header" withoutBottomShadow={!withBottomShadow}>
      <HeaderItems>
        <BackBlock>
          {isBackButton && (
            <BackButton onClick={handleBack}>
              <CustomIcon width="13px" height="19px" icon={ArrowBack} cursor />
            </BackButton>
          )}
          {isHomeIcon && (
            <HomeIcon
              onClick={() => {
                history.push(urls.services(localStorage.profileId));
              }}
            >
              <CustomIcon width="25px" height="25px" icon={HomeImage} cursor />
            </HomeIcon>
          )}
          {title && <HeaderText>{title}</HeaderText>}
        </BackBlock>
        {!title && <UserPanel />}
      </HeaderItems>
    </HeaderStyled>
  );
});
