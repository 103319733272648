import i18n, { ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Namespaces } from '.';
import { sv } from './sv';
import { en } from './en';

interface EnglishResourcesWithGeneratedErrors
  extends EnglishResources,
    ResourceLanguage {}

type EnglishResources = typeof en;
enum SupportedLanguages {
  SV = 'sv',
  EN = 'en',
}

const resources: Record<SupportedLanguages, EnglishResourcesWithGeneratedErrors> = {
  sv,
  en,
};

// Setup i18n: https://react.i18next.com/latest/using-with-hooks
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    // lng: 'en', // Forces language
    fallbackLng: 'en',
    ns: [Namespaces.UI],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
