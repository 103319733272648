import { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Loader } from 'components/Loader/Loader';
import { EmptyResult } from 'components/EmptyResult/EmptyResult';
import { getYMD } from 'helpers/DateTimeUtils';
import { Namespaces } from 'languages';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { Booking, Entries } from 'store/BookingStore';
import { LoginStore } from 'store/LoginStore';
import infoIcon from 'assets/icons/infoIcon.svg';
import { Flex } from 'styled/Flex';
import { BookingItem } from './BookingItem';
import { CardLogo, CardBody, CardHeader, CardTitle, BookingsElement } from './styles';
import { BookingModal } from './BookingModal';
import { BookingModal as BookingModalType } from './types';
import { useBookings } from 'hooks/useBookings';
import { RootPageTitle } from 'components/RootPageTitle';

const BOOKINGS_PER_PAGE = 30;

export const BookingPage = observer(() => {
  const {
    getBookings,
    bookings,
    loading,
    deleteBooking,
    activeBooking: { activeBooking, setActiveBooking },
    activeEntry: { activeEntry, setActiveEntry },
  } = useBookings({
    bookingsPerPage: BOOKINGS_PER_PAGE,
  });

  const { getUserStatus } = LoginStore;
  const { t } = useTranslation(Namespaces.UI);
  const [modalType, setModalType] = useState<BookingModalType>(null);
  const [modalLoading, setModalLoading] = useState(false);

  const closeModal = useCallback(() => setModalType(null), []);

  const handleRemoveItem = useCallback(() => {
    getUserStatus();
    setModalType(null);
    setModalLoading(false);
  }, [getUserStatus]);

  const handleDeleteBooking = useCallback(() => {
    if (!modalLoading && activeBooking) {
      setModalLoading(true);
      deleteBooking(activeBooking.id, activeEntry?.id, handleRemoveItem);
    }
  }, [activeBooking, activeEntry, deleteBooking, handleRemoveItem, modalLoading]);

  const renderCard = useCallback(
    (bookingGroup: Booking[], i: number) => (
      <BookingCard
        key={`${bookingGroup[0].id}-${i}`}
        bookingGroup={bookingGroup}
        setActiveBooking={setActiveBooking}
        setActiveEntry={setActiveEntry}
        setModalType={setModalType}
      />
    ),
    [setActiveBooking, setActiveEntry, setModalType]
  );

  return (
    <>
      <BookingsElement data-testid="booking-list">
        <RootPageTitle title={t('myBookings')} />
        {!bookings ? (
          <Loader />
        ) : !bookings?.length ? (
          <EmptyResult
            text={t('noBookingMessage')}
            action={getBookings}
            loading={loading}
          />
        ) : (
          bookings.map(renderCard)
        )}
      </BookingsElement>
      {activeBooking && (
        <BookingModal
          activeBooking={activeBooking}
          modalType={modalType}
          onClose={closeModal}
          activeEntry={activeEntry}
          deleteAction={handleDeleteBooking}
          modalLoading={modalLoading}
        />
      )}
    </>
  );
});

interface CardProps {
  bookingGroup: Booking[];
  setActiveBooking: (booking: Booking) => void;
  setActiveEntry: (entry: Entries) => void;
  setModalType: (type: BookingModalType) => void;
}

const BookingCard: FC<CardProps> = ({
  bookingGroup,
  setActiveBooking,
  setActiveEntry,
  setModalType,
}) => {
  const { t } = useTranslation(Namespaces.UI);

  const {
    day,
    profile: { logoUrl, name },
  } = bookingGroup[0];
  let time;
  switch (day) {
    case getYMD(new Date()):
      time = t('today');
      break;
    case getYMD(moment().add(1, 'days').toDate()):
      time = t('tomorrow');
      break;
    default:
      time = day;
      break;
  }
  return (
    <div>
      <CardHeader>{time}</CardHeader>
      <CardBody>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <CardLogo url={logoUrl} size="50px" />
            <CardTitle>{name}</CardTitle>
          </Flex>
          <CustomIcon
            width="22px"
            height="22px"
            icon={infoIcon}
            onClick={() => {
              setActiveBooking(bookingGroup[0]);
              setModalType('info');
            }}
            cursor
          />
        </Flex>
        {bookingGroup.map((booking) => (
          <BookingItem
            key={booking.id}
            booking={booking}
            setActiveBooking={setActiveBooking}
            setActiveEntry={setActiveEntry}
            setModalType={setModalType}
          />
        ))}
      </CardBody>
    </div>
  );
};
