import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { CreditCardInformation } from 'pages/Payment/CardPayment/CreditCardInformation';

import { Loader } from 'components/Loader/Loader';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';

import { HeaderStore } from 'store/HeaderStore';
import { PaymentStore } from 'store/PaymentStore';
import { CheckoutStore } from 'store/CheckoutStore';

import CardIconSvg from 'assets/icons/card.svg';

import Layout from 'styled/Layout';
import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { PaymentSpecification } from 'pages/Payment/PaymentSpecification';

const CardPaymentBlock = styled.div`
  padding: 24px 31px 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;

  @media ${devices.mobile} {
    padding: 24px 11px 30px;
  },
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 11px;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  user-select: none;
`;

export const CardPayment = observer(() => {
  const { t } = useTranslation(Namespaces.UI);
  const { setBackButton } = HeaderStore;
  const {
    checkout,
    getCheckout,
    getStorageCheckoutData,
    createCardTransaction,
  } = CheckoutStore;
  const { transactionInfo } = PaymentStore;
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const { id: checkoutId } = useParams<{ id: string }>();
  const checkoutData = toJS(checkout);
  const transactionData = toJS(transactionInfo);
  const stripePublishKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  ) as string;

  useEffect(() => {
    if (!stripePromise) {
      setBackButton(true);
      setStripePromise(loadStripe(stripePublishKey as string));
    }
  }, [stripePromise, stripePublishKey, setBackButton]);

  useEffect(() => {
    getStorageCheckoutData();
  }, [getStorageCheckoutData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!transactionData) {
      // No transaction data probably because of refresh.
      // Fetch checkout info (mostly for phone number in transaction request) and create a new transaction
      getCheckout(checkoutId, transactionLinkHash, () => {
        createCardTransaction(checkoutId, transactionLinkHash);
      });
    }
  }, [
    createCardTransaction,
    getCheckout,
    checkoutId,
    transactionData,
    transactionLinkHash,
  ]);

  if (!checkoutData || !transactionData) {
    return <Loader />;
  }

  return (
    <Layout>
      <PaymentSpecification
        transactionData={transactionData}
        checkoutData={checkoutData}
      />
      <>
        <CardPaymentBlock>
          <Flex alignItems="center" margin="0 0 20px 0">
            <IconWrap>
              <CustomIcon width="19px" height="13px" icon={CardIconSvg} />
            </IconWrap>
            <Title>{t('cardPayment')}</Title>
          </Flex>

          {transactionData?.stripe?.clientSecret ? (
            <Elements stripe={stripePromise}>
              <CreditCardInformation />
            </Elements>
          ) : (
            <Loader />
          )}
        </CardPaymentBlock>
      </>
    </Layout>
  );
});
