import { FC, useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Loader } from 'components/Loader/Loader';
import { EmptyResult } from 'components/EmptyResult/EmptyResult';
import { Namespaces } from 'languages';

import { ReactCalendar, PERSPECTIVE_WEEK, PERSPECTIVE_MONTH } from './ReactCalendar';

import { CalendarStore } from 'store/Calendar';
import {
  AppointmentSlotFixed,
  AppointmentSlotFixedData,
  AppointmentSlotFlexible,
  AppointmentUntilCancellation,
  AppointmentUntilCancellationData,
  AppointmentDayItem,
  ServiceStore,
} from 'store/ServiceStore';
import { HeaderStore } from 'store/HeaderStore';
import {
  APPOINTMENT_TYPE_FIXED,
  APPOINTMENT_TYPE_FLEXIBLE,
  APPOINTMENT_TYPE_UNTIL_CANCEL,
  APPOINTMENT_TYPE_DAYS,
} from 'constants/appointmentConstants';

import { AppointmentCardFixedSlots } from './AppointmentCardFixedSlots';
import { AppointmentCardFlexibleSlots } from './AppointmentCardFlexibleSlots';
import { AppointmentCardUntilCancellation } from 'components/AppointmentDetails/AppointmentCardUntilCancellation';
import { AppointmentCardDaysItem } from 'components/AppointmentDetails/AppointmentCardDaysItem';
import { EmptyResultContainer, ShowMoreButton, BookingData } from './styles';

export interface Service {
  currency: string;
  description: string;
  durationMinutes: number;
  id: number;
  name: string;
  price: number;
  type: string;
}

interface IProps {
  service: Service;
}

export const AppointmentDetails: FC<IProps> = observer(({ service }) => {
  const { name, id } = service;
  const { t } = useTranslation(Namespaces.UI);
  const {
    getCalendarSettings,
    clearCalendar,
    setCalendarSelectedDates,
    selectedDates: storeSelectedDates,
    calendarSettings: storeCalendarSettings,
    isLoading: isCalendarLoading,
  } = CalendarStore;
  const { setBackButton, setBottomShadow } = HeaderStore;
  const {
    appointmentDetails,
    getAvailableAppointments,
    setAppointmentDetails,
    clearAppointmentDetails,
    loading: isAppointmentsLoading,
  } = ServiceStore;
  const appointmentsData:
    | AppointmentUntilCancellationData
    | AppointmentSlotFixedData
    | undefined = toJS(appointmentDetails);

  const [calendarPerspective, setCalendarPerspective] = useState(PERSPECTIVE_WEEK);
  const [calendarActiveStartDate, setCalendarActiveStartDate] = useState(
    moment().startOf('isoWeek').format('YYYY-MM-DD')
  );
  const [isSearchExtended, setIsSearchExtended] = useState(false);

  const selectedDates = toJS(storeSelectedDates);
  const calendarSettings = toJS(storeCalendarSettings);

  useEffect(() => {
    setBackButton(true);

    // First default calendar fetch
    const now = moment();
    now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const defaultStartDate = now.startOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss');

    now.add(1, 'week').subtract(1, 'seconds');
    const defaultEndDate = now.format('YYYY-MM-DDTHH:mm:ss');

    getCalendarSettings(id, defaultStartDate, defaultEndDate, (calendarSettings) => {
      if (calendarSettings.defaultSelectedDate) {
        // Only used to force empty result, should probably be changed to default value in store
        const emptyAppointmentResult = {
          appointments: [],
          type: '',
          page: 1,
          totalPages: 1,
        };

        if (!calendarSettings.isRangeAllowed) {
          if (calendarSettings.hasAvailableDates) {
            getAvailableAppointments(calendarSettings.defaultSelectedDate, null);
          } else {
            setAppointmentDetails(emptyAppointmentResult);
          }

          setCalendarSelectedDates([calendarSettings.defaultSelectedDate]);
          setNewActiveStartDateFromDate(calendarSettings.defaultSelectedDate);
        } else {
          if (!calendarSettings.hasAvailableDates) {
            setAppointmentDetails(emptyAppointmentResult);
          }
        }
      }
    });

    return () => {
      clearCalendar();
      clearAppointmentDetails();
      setCalendarSelectedDates([]);
    };
  }, [
    setBackButton,
    getCalendarSettings,
    clearCalendar,
    clearAppointmentDetails,
    setCalendarSelectedDates,
  ]);

  useEffect(() => {
    setBottomShadow(false);
    return () => {
      setBottomShadow(true);
    };
  }, []);

  const fetchAppointments = (
    startDate: string,
    endDate?: string | null,
    pageNumber?: number
  ) => {
    if (!endDate) {
      getAvailableAppointments(moment(startDate).format('YYYY-MM-DD'), null, pageNumber);
    } else {
      getAvailableAppointments(
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        pageNumber
      );
    }
  };

  const setNewActiveStartDateFromDate = (date: string) => {
    if (calendarPerspective === PERSPECTIVE_WEEK) {
      setCalendarActiveStartDate(moment(date).startOf('isoWeek').format('YYYY-MM-DD'));
    } else if (calendarPerspective === PERSPECTIVE_MONTH) {
      setCalendarActiveStartDate(moment(date).startOf('month').format('YYYY-MM-DD'));
    }
  };

  const renderAppointments = () => {
    if (!appointmentsData) {
      if (!isCalendarLoading && !calendarSettings.isRangeAllowed) return <Loader />;
      if (!isCalendarLoading && selectedDates.length === 2) return <Loader />;
      return <></>;
    }

    if (!calendarSettings.hasAvailableDates) {
      return (
        <EmptyResultContainer>
          <EmptyResult
            text={
              !isSearchExtended || isCalendarLoading
                ? t('noAppointmentTimesFoundExtend')
                : calendarSettings.noAvailabilityText || t('noAppointmentTimesFoundFinal')
            }
            buttonText={t('noAppointmentTimesFoundExtendButton')}
            showButton={!isSearchExtended || isCalendarLoading}
            action={() => {
              if (!isSearchExtended) {
                const startDate = moment(selectedDates[0]);
                let endDate = moment(selectedDates[0]);

                const monthsAhead = 2;
                if (calendarPerspective === PERSPECTIVE_WEEK) {
                  endDate = endDate
                    .add(monthsAhead, 'months')
                    .endOf('isoWeek')
                    .endOf('day');
                } else {
                  endDate = endDate
                    .add(monthsAhead, 'months')
                    .endOf('month')
                    .endOf('isoWeek') // For neighbouring month days
                    .endOf('day');
                }

                getCalendarSettings(
                  id,
                  startDate.format('YYYY-MM-DDTHH:mm:ss'),
                  endDate.format('YYYY-MM-DDTHH:mm:ss'),
                  (calendarSettings) => {
                    if (
                      calendarSettings.defaultSelectedDate &&
                      calendarSettings.defaultSelectedDate !==
                        moment(startDate).format('YYYY-MM-DD')
                    ) {
                      setNewActiveStartDateFromDate(calendarSettings.defaultSelectedDate);
                      if (!calendarSettings.isRangeAllowed) {
                        // No range, select new default day and fetch appointments
                        setCalendarSelectedDates([calendarSettings.defaultSelectedDate]);
                        getAvailableAppointments(
                          calendarSettings.defaultSelectedDate,
                          null
                        );
                      } else {
                        // Range, go to default date but let user select new range
                        setCalendarSelectedDates([]);
                      }
                    }
                  }
                );
                setIsSearchExtended(true);
              }
            }}
            loading={isCalendarLoading}
          />
        </EmptyResultContainer>
      );
    }

    const { appointments, page, totalPages, type } = appointmentsData;
    return (
      <div style={{ marginTop: '15px' }}>
        {appointments.map(
          (
            el:
              | AppointmentSlotFixed
              | AppointmentSlotFlexible
              | AppointmentUntilCancellation
              | AppointmentDayItem,
            i: number
          ) => {
            switch (el.type) {
              case APPOINTMENT_TYPE_FIXED:
                return (
                  <AppointmentCardFixedSlots
                    defaultActive={appointments.length <= 3}
                    key={i}
                    appointment={el as AppointmentSlotFixed}
                  />
                );
              case APPOINTMENT_TYPE_FLEXIBLE:
                return (
                  <AppointmentCardFlexibleSlots
                    defaultActive={appointments.length <= 3}
                    key={i}
                    appointment={el as AppointmentSlotFlexible}
                  />
                );
              case APPOINTMENT_TYPE_UNTIL_CANCEL:
                return (
                  <AppointmentCardUntilCancellation
                    defaultActive={appointments.length <= 3}
                    key={i}
                    appointment={el as AppointmentUntilCancellation}
                  />
                );
              case APPOINTMENT_TYPE_DAYS:
                return (
                  <AppointmentCardDaysItem
                    defaultActive={appointments.length <= 3}
                    key={i}
                    appointment={el as AppointmentDayItem}
                  />
                );
              default:
                return null;
            }
          }
        )}
        {totalPages > page && (
          <ShowMoreButton
            secondary
            onClick={() => {
              if (selectedDates.length === 1) {
                fetchAppointments(selectedDates[0], null, page + 1);
              } else if (selectedDates.length === 2) {
                fetchAppointments(selectedDates[0], selectedDates[1], page + 1);
              }
            }}
          >
            {isAppointmentsLoading ? <Loader small /> : t('showMore')}
          </ShowMoreButton>
        )}
      </div>
    );
  };

  return (
    <>
      <BookingData data-testid="appointment-details" />
      <ReactCalendar
        selectedDates={selectedDates}
        activeStartDate={calendarActiveStartDate}
        perspective={calendarPerspective}
        disabledDates={calendarSettings.disabledDates}
        onSelectedDateChanged={(firstDate, secondDate) => {
          if (!calendarSettings.isRangeAllowed) {
            const selectedDate = moment(firstDate).format('YYYY-MM-DD');
            setCalendarSelectedDates([selectedDate]);
            fetchAppointments(selectedDate);
          } else {
            const selectedDate1 = moment(firstDate).format('YYYY-MM-DD');
            if (!secondDate) {
              // Partial range selected
              setCalendarSelectedDates([selectedDate1]);
            } else {
              const selectedDate2 = moment(secondDate).format('YYYY-MM-DD');
              setCalendarSelectedDates([selectedDate1, selectedDate2]);
              fetchAppointments(selectedDate1, selectedDate2);
            }
          }
        }}
        onVisibleDateRangeChanged={(newStartDate, newEndDate, newActiveStartDate) => {
          const fetchStartDate = moment(newStartDate).format('YYYY-MM-DDTHH:mm:ss');
          const fetchEndDate = moment(newEndDate)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss');
          getCalendarSettings(id, fetchStartDate, fetchEndDate);
          setCalendarActiveStartDate(newActiveStartDate);
        }}
        onPerspectiveChanged={(newPerspective) => setCalendarPerspective(newPerspective)}
        isLoading={isCalendarLoading}
        showNeighboringMonth={true}
        allowRangeSelection={calendarSettings.isRangeAllowed}
        minRangeDays={calendarSettings.minRangeDays}
        maxRangeDays={calendarSettings.maxRangeDays}
      />

      {renderAppointments()}
    </>
  );
});
