import './styles.scss';

export const Loader = (props: { isWhite?: boolean; small?: boolean; red?: boolean }) => {
  const { isWhite, small, red } = props;
  return (
    <div
      className={`loader ${isWhite ? 'white' : ''} ${small ? 'small' : ''} ${
        red ? 'red' : ''
      }`}
      data-testid="loader"
    >
      <div className="ball one">
        <div className="inner" />
      </div>
      <div className="ball two">
        <div className="inner" />
      </div>
      <div className="ball three">
        <div className="inner" />
      </div>
      <div className="ball four">
        <div className="inner" />
      </div>
      <div className="ball five">
        <div className="inner" />
      </div>
      <div className="ball six">
        <div className="inner" />
      </div>
      <div className="ball center">
        <div className="inner" />
      </div>
    </div>
  );
};
