export const ERROR_NOT_FOUND = 'ERROR_NOT_FOUND';
export const EMAIL_INVALID = 'EMAIL_INVALID';
export const PHONE_INVALID = 'PHONE_INVALID';

// Checkout
export const CHECKOUT_CONFIRMED = 'CHECKOUT_CONFIRMED';
export const CHECKOUT_DELETED = 'CHECKOUT_DELETED';
export const CUSTOMER_MESSAGE_INVALID = 'CUSTOMER_MESSAGE_INVALID';
export const CHECKOUT_ITEM_RECIPIENT_MANDATORY = 'CHECKOUT_ITEM_RECIPIENT_MANDATORY';
export const CHECKOUT_ITEM_RECIPIENT_ALREADY_BOOKED =
  'CHECKOUT_ITEM_RECIPIENT_ALREADY_BOOKED';
export const CHECKOUT_ITEM_RECIPIENT_BOOKING_FULL =
  'CHECKOUT_ITEM_RECIPIENT_BOOKING_FULL';
export const CHECKOUT_ITEM_MAX_RECIPIENTS = 'CHECKOUT_ITEM_MAX_RECIPIENTS';
export const TRANSACTION_LINK_EXPIRED = 'TRANSACTION_LINK_EXPIRED';
export const CHECKOUT_BANKID_MANDATORY = 'CHECKOUT_BANKID_MANDATORY';
export const BANKID_COULD_NOT_START_TRANSACTION = 'BANKID_COULD_NOT_START_TRANSACTION';

// Account
export const ACCOUNT_ALREADY_EXISTS = 'ACCOUNT_ALREADY_EXISTS';
export const ACCOUNT_DELETE_ACTIVE_BOOKINGS = 'ACCOUNT_DELETE_ACTIVE_BOOKINGS';
