import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import { devices } from 'constants/mediaConstants';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';

import { Flex } from 'styled/Flex';

const Card = styled.div`
  margin-bottom: 15px;
  padding: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  user-select: none;
  cursor: pointer;

  @media ${devices.mobile} {
    padding: 14px;
  }
`;
const CardHeader = styled.div`
  display: grid;
  grid-template-columns: 42px 1fr 35px;
  align-items: center;
`;
const CardNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 12px;
  border-radius: 50%;
  color: white;
  background: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  user-select: none;
`;

type CollapseCardProps = {
  header: JSX.Element;
  body: JSX.Element[] | JSX.Element;
  number: number;
  collapsedHeader?: JSX.Element;
  isOpen?: boolean;
  isOpenByError?: boolean;
  setIsOpenByError?: Dispatch<SetStateAction<boolean>>;
};

export const CollapseCard = (props: CollapseCardProps) => {
  const {
    header,
    number,
    body,
    collapsedHeader,
    isOpen: propIsOpen = true,
    isOpenByError = false,
    setIsOpenByError,
  } = props;

  const [isOpen, setIsOpen] = useState(propIsOpen);

  useEffect(() => {
    if (isOpenByError) {
      setIsOpen(true);

      // Reset state so that manual collapse will open it again on error
      if (setIsOpenByError) {
        setIsOpenByError(false);
      }
    }
  }, [isOpenByError]);

  return (
    <Card data-testid="collapse-card">
      <CardHeader
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <CardNumber>{number}</CardNumber>
        <Flex alignItems="center">
          {header}
          {collapsedHeader && !isOpen ? collapsedHeader : null}
        </Flex>
        <CustomIcon
          width="20px"
          height="12px"
          margin="0 0 0 15px"
          icon={isOpen ? ArrowUp : ArrowDown}
          onClick={() => {
            if (setIsOpenByError && isOpenByError && isOpen) {
              setIsOpenByError(false);
            }
            setIsOpen(!isOpen);
          }}
          cursor
          hover
        />
      </CardHeader>
      {isOpen && <div>{body}</div>}
    </Card>
  );
};
