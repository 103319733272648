import { Dispatch, SetStateAction, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import styled, { DefaultTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { getPrice } from 'helpers';
import { Namespaces } from 'languages';
import { CardDateBlock } from 'components/CardDateBlock/CardDateBlock';
import { VoucherValidBlock } from 'components/VoucherDetails/VoucherValidBlock';
import { VoucherIcon } from 'components/VoucherIcon/VoucherIcon';
import { devices } from 'constants/mediaConstants';
import {
  SERVICE_TYPE_MEMBERSHIP,
  SERVICE_TYPE_VOUCHER,
  SERVICE_TYPE_PRODUCT,
  SERVICE_TYPE_COURSE,
} from 'constants/serviceConstants';
import { DONT_ASK } from 'constants/checkoutConstants';
import {
  CheckoutStore,
  CheckoutItem,
  CheckoutItemRecipient,
  CheckoutFieldErrorDataType,
} from 'store/CheckoutStore';
import { UserProxy } from 'store/UserStore';
import { LoginStore } from 'store/LoginStore';
import {
  CHECKOUT_ITEM_RECIPIENT_MANDATORY,
  CHECKOUT_ITEM_RECIPIENT_ALREADY_BOOKED,
  CHECKOUT_ITEM_RECIPIENT_BOOKING_FULL,
  CHECKOUT_ITEM_MAX_RECIPIENTS,
} from '../../constants/apiErrorCodeConstants';

import Close from 'assets/icons/closeCircle.svg';
import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';
import CheckIcon from 'assets/icons/checkmark.svg';
import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import { PrimaryButton } from 'styled/PrimaryButton';
import { AddUserProxyModal } from './AddUserProxyModal';
import { RemoveRecipientConfirmationModal } from './RemoveRecipientConfirmationModal';
import { Loader } from 'components/Loader/Loader';

const CardItem = styled.div`
  position: relative;
  margin: 20px 0;
  padding: ${(props: { isCollapsed: boolean }) =>
    props.isCollapsed ? '26px 34px 48px' : '26px 34px 10px'};
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 20px;
  line-height: 20px;

  @media ${devices.mobile} {
    padding: 16px 10px 10px;
  }
`;

const HeaderElements = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: 13px;
  color: ${({ theme }) => theme.colors.darkBlack};
  line-height: 24px;
`;

const CardTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
`;

const CardDescription = styled.div`
  margin: 4px 10px 13px 5px;
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
`;

const CardPrice = styled.div`
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-decoration-line: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'line-through' : 'none'};
  white-space: nowrap;
  @media${devices.mobile} {
    font-size: 14px;
  }
`;

type PriceStyledProps = {
  isRed?: boolean;
  isBold?: boolean;
  isLineThrough?: boolean;
  theme: DefaultTheme;
};

const Price = styled.div`
  color: ${(props: PriceStyledProps) =>
    props.isRed ? props.theme.colors.red : props.theme.colors.darkBlack};
  margin-left: 5px;
  font-style: normal;
  font-weight: ${(props: PriceStyledProps) => (props.isBold ? 'bold' : 'normal')};
  font-size: 18px;
  white-space: nowrap;
  text-decoration-line: ${(props: PriceStyledProps) =>
    props.isLineThrough ? 'line-through' : 'none'};
  @media${devices.mobile} {
    font-size: 14px;
  }
`;

const DiscountBlock = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column;
  border-bottom: 1px solid #dcdcdc;
`;

const DiscountDescription = styled.div`
  margin: 4px 10px 13px 5px;
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
`;

const DiscountValue = styled.div`
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  color: ${({ theme }) => theme.colors.red};
  @media${devices.mobile} {
    font-size: 16px;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 38px;
  margin-right: 30px;

  @media ${devices.mobile} {
    font-size: 16px;
  }
`;

const TotalPrice = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  text-align: right;
  @media${devices.mobile} {
    font-size: 16px;
  }
`;

const TotalBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #181818;
  margin-top: 26px;
  @media${devices.mobile} {
    height: 26px;
  }
`;

const UserProxyBlockContainer = styled.div`
  margin-top: 40px;
`;

const UserProxyBlockTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
`;

const UserProxyBlockDescription = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;
`;

const UserProxyItemContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px;
  height: 58px;
  padding: 0px 16px 0px 16px;
`;

const UserProxyItemLeftContainer = styled.div`
  flex-grow: 1;
`;

const UserProxyItemRightContainer = styled.div`
  margin-right: 16px;
`;

const UserProxyItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.lightBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
`;

const UserProxyItemAddButton = styled(PrimaryButton)`
  font-size: 14px;
`;

const AddUserProxyButton = styled(PrimaryButton)`
  font-size: 14px;
  margin-top: 20px;
  width: 175px;
`;

const ErrorText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  margin-top: 12px;
  margin-bottom: 12px;
`;

type PriceProps = {
  type: string;
  name: string;
  description: string;
  reduction: number;
};

export const CheckoutItemsCard = observer(
  (props: {
    checkoutId: number;
    item: CheckoutItem;
    setActiveDeleteItemId: Dispatch<SetStateAction<number | undefined>>;
    transactionLinkHash: string | null;
    userProxies: UserProxy[];
  }) => {
    const {
      checkoutId,
      item: {
        id,
        currency,
        price,
        startTime,
        endTime,
        validTo,
        quantity,
        service: { name, description, type, balance, isBookPerOccasion },
        priceReductions,
        proxyUsersMode,
        recipients,
        maxRecipients,
      },
      setActiveDeleteItemId,
      transactionLinkHash,
      userProxies,
    } = props;

    const history = useHistory();
    const { t } = useTranslation(Namespaces.UI);

    const {
      addCheckoutItemRecipient,
      checkoutFieldErrors,
      setFieldError,
    } = CheckoutStore;
    const { user } = LoginStore;

    const [isCollapsed, setCollapsed] = useState(false);
    const [showAddUserProxyModal, setShowAddUserProxyModal] = useState(false);
    const [addInProgressUserProxyIds, setAddInProgressUserProxyIds] = useState<number[]>(
      []
    );
    const [recipientToRemoveId, setRecipientToRemoveId] = useState<number | null>(null);

    const fieldErrors = toJS(checkoutFieldErrors);

    const priceReduce = (reductions: PriceProps[], actualPrice: number) => {
      return reductions.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.reduction;
      }, actualPrice);
    };

    const renderPrice = () => {
      if (type === SERVICE_TYPE_VOUCHER && priceReductions) {
        return (
          <Flex flexDirection="column">
            <CardPrice>
              {getPrice(priceReduce(priceReductions, price), currency, false)}
            </CardPrice>
            <Price isLineThrough>{`(${getPrice(balance, currency, false)})`}</Price>
          </Flex>
        );
      }
      if (type === SERVICE_TYPE_VOUCHER) {
        return (
          <Flex flexDirection="column">
            <CardPrice>{getPrice(price, currency, false)}</CardPrice>
            <Price isLineThrough>{`(${getPrice(balance, currency, false)})`}</Price>
          </Flex>
        );
      }
      return priceReductions ? (
        <Price isLineThrough isBold>
          {`${getPrice(priceReduce(priceReductions, price), currency, false)}`}
        </Price>
      ) : (
        <Price isBold>{`${getPrice(price, currency)}`}</Price>
      );
    };

    const renderBookingCardBody = () => {
      switch (type) {
        case SERVICE_TYPE_VOUCHER:
          return <VoucherValidBlock validTo={validTo} />;
        case SERVICE_TYPE_MEMBERSHIP:
          return <VoucherValidBlock validTo={validTo} />;
        case SERVICE_TYPE_COURSE: {
          if (isBookPerOccasion) {
            return <CardDateBlock startTime={startTime} endTime={endTime} />;
          }
          return null;
        }

        case SERVICE_TYPE_PRODUCT:
          return null;
        default:
          return <CardDateBlock startTime={startTime} endTime={endTime} />;
      }
    };

    const renderDiscountBody = () => {
      if (!priceReductions) return <></>;
      return (
        <>
          {priceReductions.map((element: PriceProps, index: number) => {
            return (
              <DiscountBlock key={index}>
                <Flex justifyContent="flex-start">
                  <VoucherIcon text={t(`${element.name}`)} />
                </Flex>
                {isCollapsed && (
                  <DiscountDescription>{element.description}</DiscountDescription>
                )}
                <Flex justifyContent="flex-end">
                  <DiscountValue>{`-${getPrice(
                    element.reduction,
                    currency
                  )}`}</DiscountValue>
                </Flex>
              </DiscountBlock>
            );
          })}

          <Flex justifyContent="flex-end">
            <TotalBlock>
              <Title>{t('total').toUpperCase()}</Title>
              <TotalPrice>{`${getPrice(price, currency, false)}`}</TotalPrice>
            </TotalBlock>
          </Flex>
        </>
      );
    };

    const getUserProxyErrorMessage = () => {
      if (!fieldErrors?.checkoutItemRecipient) return <></>;

      let errorMessage = fieldErrors.checkoutItemRecipient;
      if (typeof fieldErrors.checkoutItemRecipient === 'object') {
        const fieldError = fieldErrors.checkoutItemRecipient as CheckoutFieldErrorDataType;

        if (fieldError.errorCode === CHECKOUT_ITEM_RECIPIENT_MANDATORY) {
          errorMessage = t('checkoutItemUserProxyMandatoryError');
        } else if (fieldError.errorCode === CHECKOUT_ITEM_RECIPIENT_ALREADY_BOOKED) {
          errorMessage = t('checkoutItemUserProxyAlreadyBookedError');
        } else if (fieldError.errorCode === CHECKOUT_ITEM_RECIPIENT_BOOKING_FULL) {
          errorMessage = t('checkoutItemUserProxyBookingFullError');
        } else if (fieldError.errorCode === CHECKOUT_ITEM_MAX_RECIPIENTS) {
          errorMessage = t('checkoutItemUserProxyMaxRecipientsError');
        } else {
          errorMessage = fieldError.errorMessage;
        }
      }

      return <ErrorText>{errorMessage}</ErrorText>;
    };

    const renderUserProxiesBody = () => {
      if (!proxyUsersMode || proxyUsersMode === DONT_ASK) return <></>;

      return (
        <UserProxyBlockContainer>
          <UserProxyBlockTitle>{t('checkoutItemUserProxyTitle')}</UserProxyBlockTitle>
          <UserProxyBlockDescription>
            {!maxRecipients
              ? t('checkoutItemUserProxyDescription')
              : t('checkoutItemUserProxyMaxRecipientsDescription', {
                  maxRecipients,
                })}
          </UserProxyBlockDescription>

          {getUserProxyErrorMessage()}

          {userProxies.map((userProxy) => {
            const isAdded = recipients
              ? recipients.find((x) => x.userProxyId === userProxy.id)
              : false;
            let isMaxRecipientsAdded = maxRecipients
              ? recipients && recipients.length >= maxRecipients
              : false;
            return (
              <UserProxyItemContainer key={`user_proxy_${userProxy.id}`}>
                <UserProxyItemLeftContainer>
                  <UserProxyItemTitle>{`${userProxy.firstName} ${userProxy.surname}`}</UserProxyItemTitle>
                </UserProxyItemLeftContainer>
                <UserProxyItemRightContainer>
                  {isAdded && <CustomIcon width="34px" height="34px" icon={CheckIcon} />}
                  {!isAdded && (
                    <UserProxyItemAddButton
                      size={'small'}
                      disabled={isMaxRecipientsAdded}
                      onClick={() => {
                        if (isMaxRecipientsAdded) return;
                        const recipientData = {
                          userProxyId: userProxy.id,
                        } as CheckoutItemRecipient;

                        if (transactionLinkHash) {
                          recipientData.transactionLinkHash = transactionLinkHash;
                        }

                        setAddInProgressUserProxyIds([
                          ...addInProgressUserProxyIds,
                          userProxy.id,
                        ]);

                        addCheckoutItemRecipient(
                          checkoutId,
                          id,
                          recipientData,
                          (success) => {
                            const idsAfterRemoval = addInProgressUserProxyIds.filter(
                              (x) => x !== userProxy.id
                            );
                            setAddInProgressUserProxyIds(idsAfterRemoval);
                            if (success) {
                              setFieldError('checkoutItemRecipient', '');
                            }
                          }
                        );
                      }}
                    >
                      {addInProgressUserProxyIds.find((x) => x === userProxy.id) ? (
                        <Loader isWhite small />
                      ) : (
                        <>{t('checkoutItemAddUserProxyToBookingButton')}</>
                      )}
                    </UserProxyItemAddButton>
                  )}
                </UserProxyItemRightContainer>
                {isAdded && (
                  <CustomIcon
                    position="absolute"
                    right="-12px"
                    top="calc(50% - 12px)"
                    width="24px"
                    height="24px"
                    icon={Close}
                    onClick={() => {
                      const recipient = recipients.find(
                        (x) => x.userProxyId === userProxy.id
                      );
                      if (!recipient) return;
                      setRecipientToRemoveId(recipient.id);
                    }}
                    hover
                    cursor
                  />
                )}
              </UserProxyItemContainer>
            );
          })}

          <AddUserProxyButton
            secondary
            size={'small'}
            onClick={() => {
              if (!user.id) {
                localStorage.prevPath =
                  history.location.pathname + history.location.search;
                history.replace('/auth');
                return;
              }
              setShowAddUserProxyModal(true);
            }}
          >
            {t('checkoutItemAddUserProxyButton')}
          </AddUserProxyButton>
        </UserProxyBlockContainer>
      );
    };

    return (
      <CardItem isCollapsed={!description}>
        <HeaderElements>
          <CardTitle>
            {type === SERVICE_TYPE_PRODUCT ? `${name} (x${quantity})  ` : name}
          </CardTitle>
          {renderPrice()}
        </HeaderElements>

        {isCollapsed && <CardDescription>{description}</CardDescription>}

        {renderBookingCardBody()}

        {renderDiscountBody()}

        {renderUserProxiesBody()}

        {description && (
          <CustomIcon
            width="20px"
            height="12px"
            margin="40px auto 0"
            icon={isCollapsed ? ArrowUp : ArrowDown}
            onClick={() => {
              setCollapsed(!isCollapsed);
            }}
            cursor
            hover
            device={devices.mobile}
            deviceMargin="20px auto 0"
          />
        )}

        {!transactionLinkHash && (
          <CustomIcon
            position="absolute"
            right="-15px"
            top="calc(50% - 15px)"
            width="30px"
            height="30px"
            icon={Close}
            onClick={() => {
              setActiveDeleteItemId(id);
            }}
            hover
            cursor
          />
        )}

        {showAddUserProxyModal && (
          <AddUserProxyModal
            onUserProxyAdded={() => setShowAddUserProxyModal(false)}
            onCloseClicked={() => setShowAddUserProxyModal(false)}
          />
        )}

        {recipientToRemoveId && (
          <RemoveRecipientConfirmationModal
            checkoutId={checkoutId}
            checkoutItemId={id}
            recipientId={recipientToRemoveId}
            transactionLinkHash={transactionLinkHash}
            onConfirmClicked={() => setRecipientToRemoveId(null)}
            onCloseClicked={() => setRecipientToRemoveId(null)}
          />
        )}
      </CardItem>
    );
  }
);
