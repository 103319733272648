import styled from 'styled-components';
import { IconType } from 'react-icons';

const InputStyled = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray};
`;

const Field = styled.input`
  width: 100%;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.darkBlack};
  background: transparent;
  border: 0;
  outline: none;
  font-size: 16px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

const Icon = styled.div((props: { icon: string | IconType }) => ({
  height: '24px',
  width: '24px',
  marginRight: '10px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  backgroundImage: `url(${props.icon})`,
}));

interface Props {
  value: string;
  InputIcon?: string | IconType;
  placeholder: string;
  type: string;
  change: (value: string) => void;
}

export const Input = (props: Props) => {
  const { value, InputIcon, placeholder, type, change } = props;
  return (
    <InputStyled data-testid="input">
      {InputIcon && <Icon icon={InputIcon} />}
      <Field
        type={type}
        placeholder={placeholder}
        onChange={(e) => {
          change(e?.target?.value);
        }}
        value={value}
      />
    </InputStyled>
  );
};
