import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FacebookLogin from '@greatsumini/react-facebook-login';
// import AppleSignIn from 'react-apple-signin-auth';
// import { v4 as uuidv4 } from 'uuid';

import { Loader } from 'components/Loader/Loader';
import { SocialLoginButton } from 'components/SocialLoginButton';
import { urls } from 'helpers';
import { Namespaces } from 'languages';
import { Input as CustomInput } from 'containers/Input';
import { Divider } from 'containers/Divider';
import { devices } from 'constants/mediaConstants';
import { LoginStore } from 'store/LoginStore';
import { HeaderStore } from 'store/HeaderStore';

import Email from 'assets/icons/email.svg';
import Password from 'assets/icons/password.svg';
import Google from 'assets/icons/google.svg';
import Facebook from 'assets/icons/facebook.svg';
// import Apple from 'assets/icons/apple.svg';
import { useGoogleLogin } from '@react-oauth/google';
import { PrimaryButton } from 'styled/PrimaryButton';

const LoginPage = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
`;

const LoginForgotPassword = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
`;

const LoginToSignUp = styled.div`
  margin-top: 30px;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fonts.sfProText};
`;

const Error = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
`;

const ErrorWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  padding: 0 30px;
  background: #feecee;
  border-radius: 5px;
  text-align: center;
`;

const ErrorServer = styled.div`
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.red};
  font-size: 13px;
`;

const FormItem = styled.div`
  position: relative;
  width: 350px;
  margin: 30px auto;
  padding: 0 12px;
  border-radius: 3px;
  @media ${devices.mobile} {
    width: 290px;
  }
`;

const Input = styled(CustomInput)`
  width: 350px;
  height: 52px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  @media ${devices.mobile} {
    width: 290px;
  }
`;

const LinkForgot = styled.div`
  left: 0px;
  bottom: -30px;
  color: ${(props) => props.theme.colors.darkBlack};
  cursor: pointer;
  user-select: none;
  font-family: ${({ theme }) => theme.fonts.sfProText};
`;

const BoldedClickText = styled.b`
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: pointer;
  user-select: none;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  &:hover {
    opacity: 0.8;
  }
`;

/* const AppleLogin = styled(AppleSignIn)`
  pointer-events: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : 'auto')};
`; */

const ButtonLogin = styled(PrimaryButton)`
  width: 350px;
  margin: 25px auto 30px;

  @media ${devices.mobile} {
    height: 42px;
    width: 290px;
  }
`;

const FormFields = styled.div`
  pointer-events: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : 'auto')};
  user-select: ${(props: { disabled: boolean }) => (props.disabled ? 'none' : 'auto')};
`;

export const Login = observer((data: { setLogin: (state: boolean) => void }) => {
  const { setLogin } = data;
  const [isServerError, setServerError] = useState('');
  const [loading, setLoading] = useState(false);
  const { setTitle } = HeaderStore;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loadingSocial, setLoadingSocial] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const { emailSignIn, googleSignIn, facebookSignIn } = LoginStore;

  const facebookId = process.env.REACT_APP_FACEBOOK_APP_ID || '';

  /* const appleId = process.env.REACT_APP_APPLE_CLIENT_ID;
  const appleRedirectUrl = process.env.REACT_APP_APPLE_LOGIN_REDIRECT_URI;
  const appleStatePrefix = process.env.REACT_APP_APPLE_LOGIN_STATE_PREFIX || ''; */

  const history = useHistory();
  const { t } = useTranslation(Namespaces.UI);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validationForm = (): boolean => {
    let isError = false;
    if (!email) {
      setErrorEmail(t('errorRequired'));
      isError = true;
    }
    if (!password) {
      isError = true;
      setErrorPassword(t('errorRequired'));
    }
    return !isError;
  };

  const handleChangeEmail = (value: string) => {
    setErrorEmail('');
    setEmail(value);
  };

  const handleChangePassword = (value: string) => {
    setErrorPassword('');
    setPassword(value);
  };

  const submitForm = () => {
    if (!loading && !loadingSocial && validationForm()) {
      setLoading(true);
      emailSignIn({ email, password }, history).then((error) => {
        if (error) {
          setServerError(error);
        }
        setLoading(false);
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const googleSuccessCallback = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleSignIn(
        {
          code: tokenResponse.code,
        },
        history,
        setLoadingSocial
      );
    },
    onError: () => {
      setLoadingSocial('');
    },
    onNonOAuthError: () => {
      setLoadingSocial('');
    },
    flow: 'auth-code',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const facebookSuccessCallback = (response: any) => {
    const { accessToken } = response;
    if (accessToken) {
      facebookSignIn(accessToken, history, setLoadingSocial);
    } else {
      setLoadingSocial('');
    }
  };

  return (
    <>
      <LoginPage className="login-page" data-testid="login">
        <SocialLoginButton
          disabled={loading || !!loadingSocial}
          action={() => {
            setLoadingSocial('google');
            googleSuccessCallback();
          }}
          iconURL={Google}
          typeText={t('loginButtonTitle')}
          title={t('google')}
          isLoading={loadingSocial === 'google'}
        />

        <FacebookLogin
          appId={facebookId}
          initParams={{
            version: 'v16.0',
          }}
          fields="name,email,picture"
          scope="public_profile, email"
          autoLoad={false}
          onSuccess={facebookSuccessCallback}
          onFail={() => setLoadingSocial('')}
          render={({ onClick }) => (
            <SocialLoginButton
              disabled={loading || !!loadingSocial}
              action={() => {
                setLoadingSocial('facebook');
                if (onClick) {
                  onClick();
                }
              }}
              iconURL={Facebook}
              title={t('facebook')}
              typeText={t('loginButtonTitle')}
              isLoading={loadingSocial === 'facebook'}
            />
          )}
        />
        {/*
        <AppleLogin
          disabled={loading || !!loadingSocial}
          authOptions={{
            clientId: appleId,
            scope: 'name email',
            redirectURI: appleRedirectUrl,
            state: `${appleStatePrefix}${uuidv4()}`,
          }}
          render={(props: any) => (
            <LoginItem
              disabled={loading || !!loadingSocial}
              {...props}
              onClick={(e) => {
                setLoadingSocial('apple');
                props.onClick(e);
              }}
            >
              {loadingSocial === 'apple' ? (
                <Loader small />
              ) : (
                <>
                  <CustomIcon icon={Apple} />
                  {t('loginButtonTitle')}
                  <CompanyName>{t('apple')}</CompanyName>
                </>
              )}
            </LoginItem>
          )}
        />
        */}
        <Divider text={t('or')} />
      </LoginPage>
      <ErrorWrap>{isServerError && <ErrorServer>{isServerError}</ErrorServer>}</ErrorWrap>
      <FormFields disabled={loading || !!loadingSocial}>
        <FormItem>
          {errorEmail && <Error>{`* ${errorEmail}`}</Error>}
          <Input
            placeholder={t('email')}
            change={handleChangeEmail}
            value={email}
            InputIcon={Email}
            type="text"
          />
        </FormItem>
        <FormItem>
          {errorPassword && <Error>{`* ${errorPassword}`}</Error>}
          <Input
            type="password"
            placeholder={t('password')}
            value={password}
            InputIcon={Password}
            change={handleChangePassword}
          />
        </FormItem>
      </FormFields>
      <LoginForgotPassword>
        {t('forgotPasswordText1')}
        <LinkForgot
          onClick={() => {
            setTitle(t('titleForgotPage'));
            history.replace(urls.forgotPassword);
          }}
        >
          <BoldedClickText>{t('forgotPasswordText2')}</BoldedClickText>
        </LinkForgot>
      </LoginForgotPassword>
      <ButtonLogin onClick={submitForm} size="large">
        {loading ? <Loader isWhite small /> : t('buttonLogin')}
      </ButtonLogin>
      <LoginToSignUp>
        {t('dontHaveAccount1')}
        <BoldedClickText
          onClick={() => {
            setTitle(t('registrationTitle'));
            setLogin(false);
          }}
        >
          {t('buttonSignUp')}
        </BoldedClickText>
      </LoginToSignUp>
    </>
  );
});
