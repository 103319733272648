import { useState } from 'react';

import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { getPrice } from 'helpers';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/Loader/Loader';
import { CollapseText } from 'components/CollapseText/CollapseText';
import { VoucherValidBlock } from 'components/VoucherDetails/VoucherValidBlock';
import { devices } from 'constants/mediaConstants';
import { getAddBookingCTAEvent, postCTAEventMessage } from 'helpers/EventUtils';

import { Namespaces } from 'languages';
import { CheckoutStore } from 'store/CheckoutStore';
import { Membership } from 'store/ServiceStore';

import PriceIcon from 'assets/icons/price.svg';
import ArrowDown from 'assets/icons/arrow-down.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';

import { PrimaryButton } from 'styled/PrimaryButton';
import Layout from 'styled/Layout';
import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const Card = styled(Layout)`
  position: relative;
  padding: 35px 39px 23px;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: ${(props: { collapsed: boolean }) => (props.collapsed ? 'auto' : 'pointer')};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;
  @media ${devices.mobile} {
    padding: 30px 30px 15px;
  }
`;

const CardHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
`;

const CardDescription = styled.div`
  margin: 10px 0;
  padding-right: 40px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.middleDark};
  white-space: pre-wrap;
  @media ${devices.mobile} {
    font-size: 15px;
    padding-right: 20px;
  } ;
`;

const CardButton = styled(PrimaryButton)`
  max-height: 56px;
  width: 214px;
  padding: 17px 50px;
  margin: 25px auto 0;
  font-size: 14px;

  @media ${devices.mobile} {
    max-height: 38px;
    width: 170px;
    padding: 12px 30px;
    font-size: 12px;
  },
`;

const Price = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'normal' : 'bold'};
  font-size: 20px;
  text-decoration-line: ${(props: { isLineThrough?: boolean }) =>
    props.isLineThrough ? 'line-through' : 'none'};
`;

export const MembershipDetails = observer((props: { memberships: Membership }) => {
  const { memberships } = props;
  const {
    name,
    price,
    currency,
    description,
    validTo,
    id,
    validForAllServices,
    validServices,
  } = memberships;
  const { profileId } = useParams<{ profileId: string }>();
  const [loading, setLoading] = useState(false);
  const [isCollapsed, setCollapsed] = useState(true);
  const { addItemToCheckout } = CheckoutStore;
  const { t } = useTranslation(Namespaces.UI);
  const getRequestData = () => {
    return [
      {
        serviceId: id,
      },
    ];
  };

  return (
    <>
      <Card
        collapsed={isCollapsed}
        onClick={() => {
          setCollapsed(true);
        }}
      >
        <Flex>
          <Flex flexDirection="column">
            <CardHeader>{name}</CardHeader>
            <CardDescription>{CollapseText(description)}</CardDescription>
            <Flex alignItems="center">
              <CustomIcon
                width="16px"
                height="16px"
                margin="0 10px 0 0"
                icon={PriceIcon}
              />
              <Price>{getPrice(price, currency)}</Price>
            </Flex>
          </Flex>
        </Flex>
        {isCollapsed && (
          <>
            <VoucherValidBlock
              validTo={validTo}
              validForAllServices={validForAllServices}
              validServices={validServices}
            />
            <CardButton
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  addItemToCheckout(profileId, getRequestData(), setLoading);
                  postCTAEventMessage(getAddBookingCTAEvent());
                }
              }}
            >
              {loading ? <Loader isWhite small /> : t('addToCart')}
            </CardButton>
          </>
        )}
        <CustomIcon
          position="absolute"
          right="24px"
          bottom={isCollapsed ? '23px' : 'calc(50% - 6px)'}
          width="20px"
          height="12px"
          icon={isCollapsed ? ArrowUp : ArrowDown}
          onClick={(e) => {
            if (e) {
              e.stopPropagation();
              setCollapsed(!isCollapsed);
            }
          }}
          transition="background-image 0s, bottom 0.5s"
          hover
          cursor
          device={devices.mobile}
          deviceHeight="9px"
          deviceWidth="15px"
        />
      </Card>
    </>
  );
});
