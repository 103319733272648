import { FC, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getPrice, urls } from 'helpers';
import { Namespaces } from 'languages';
import { CardDateBlock } from 'components/CardDateBlock/CardDateBlock';
import { Loader } from 'components/Loader/Loader';

import { Booking, Entries } from 'store/BookingStore';
import { PaymentStore } from 'store/PaymentStore';
import { CheckoutStore } from 'store/CheckoutStore';

import DownloadImage from 'assets/icons/download.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import {
  CardItem,
  UnpaidLogo,
  BookingHeader,
  BookingSubHeader,
  Price,
  DownloadButton,
  DownloadText,
  ButtonContainer,
  ButtonCancel,
  ButtonPayNow,
} from './styles';
import { BookingModal } from '../types';

interface BookingProps {
  booking: Booking;
  setActiveBooking: (booking: Booking) => void;
  setActiveEntry: (entry: Entries) => void;
  setModalType: (type: BookingModal) => void;
}

export const BookingItem: FC<BookingProps> = observer(
  ({ booking, setActiveBooking, setActiveEntry, setModalType }) => {
    const { downloadTransactionPdf, loading } = PaymentStore;
    const { t } = useTranslation(Namespaces.UI);
    const history = useHistory();
    const {
      service: { name: serviceName },
      entries,
      price,
      currency,
      status,
      transactionLinkHash,
    } = booking;

    const { getCheckoutFromHash } = CheckoutStore;
    const [loadingButton, setLoadingButton] = useState(false);

    const resources = useMemo(
      () =>
        entries[0] && entries[0].resources && entries[0].resources.length
          ? entries[0].resources[0]
          : undefined,
      [entries]
    );

    const transaction = useMemo(() => booking?.payment?.transaction, [booking]);
    const downloadText = useMemo(
      () =>
        transaction?.paymentType === 'card' || transaction?.paymentType === 'swish'
          ? t('receipt')
          : t('invoice'),
      [transaction]
    );

    const handleCancel = useCallback(() => {
      setActiveBooking(booking);
      setModalType('cancel');
    }, [booking, setActiveBooking, setModalType]);

    const handlePayNow = useCallback(() => {
      setLoadingButton(true);
      transactionLinkHash &&
        getCheckoutFromHash(transactionLinkHash, setLoadingButton).then((data) => {
          if (data.profileId && data.checkoutId) {
            const { profileId, checkoutId } = data;
            history.push(
              urls.checkoutWithTransactionLinkHash(
                profileId,
                checkoutId,
                transactionLinkHash
              )
            );
          }
        });
    }, [booking, history, transactionLinkHash]);

    return (
      <div>
        <CardItem data-testid="booking-item">
          {status === 'unconfirmed' && (
            <UnpaidLogo>{t('unpaid').toLocaleUpperCase()}</UnpaidLogo>
          )}
          <BookingHeader>{serviceName}</BookingHeader>
          {resources ? (
            <BookingSubHeader>
              <>{resources.name}</>
              <Price>{getPrice(price, currency)}</Price>
            </BookingSubHeader>
          ) : (
            <BookingSubHeader isEnd>
              <Price>{getPrice(price, currency)}</Price>
            </BookingSubHeader>
          )}

          {transaction && (
            <DownloadButton
              onClick={() => {
                if (!loading) {
                  downloadTransactionPdf(transaction.id, transaction.serialNumber);
                }
              }}
            >
              <DownloadText>{downloadText}</DownloadText>
              <CustomIcon width="15px" height="17px" icon={DownloadImage} />
            </DownloadButton>
          )}
          {entries.map((entry) => (
            <CardDateBlock
              key={entry.id}
              startTime={entry.startTime}
              endTime={entry.endTime}
              showRemoveButton={entry.isCancellable}
              onRemoveButtonClicked={() => {
                setActiveBooking(booking);
                setActiveEntry(entry);
                setModalType('cancel');
              }}
            />
          ))}
          {(booking.isCancellable || transactionLinkHash) && (
            <ButtonContainer>
              {booking.isCancellable && (
                <ButtonCancel
                  isSmall={transactionLinkHash ? true : false}
                  onClick={handleCancel}
                >
                  {t('cancelBooking')}
                </ButtonCancel>
              )}
              {transactionLinkHash && (
                <ButtonPayNow onClick={handlePayNow}>
                  {loadingButton ? <Loader isWhite small /> : t('payNow')}
                </ButtonPayNow>
              )}
            </ButtonContainer>
          )}
        </CardItem>
      </div>
    );
  }
);
