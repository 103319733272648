import { devices } from 'constants/mediaConstants';
import styled from 'styled-components';
import Layout from 'styled/Layout';
import EditCircle from 'assets/icons/editCircle.svg';
import { PrimaryButton } from 'styled/PrimaryButton';
import { renderInputBorder } from 'helpers/StylesUtils';

export const AccountPage = styled(Layout)`
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
`;

export const Header = styled.div`
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  user-select: none;
`;

export const AccountInfoBlock = styled.div`
  padding: 68px 72px 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  margin-top: 30px;

  @media ${devices.mobile} {
    padding: 68px 22px 50px;
  }
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  user-select: none;
`;
export const Line = styled.div`
  height: 1px;
  margin: 25px 0 25px;
  background: #a6a6a6;
`;

export const InputWrap = styled.div`
  position: relative;
  margin-top: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  user-select: none;

  @media ${devices.mobile} {
    width: 87%;
  }
`;

export const ContactRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 11px 0 13px;
`;

export const InputHeader = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  color: #a6a6a6;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;

export const InputBlock = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 25px 17px 4px;
  color: #a6a6a6;
  background: ${(props: { error: string }) => (props.error ? '#FFFBFB' : '#ffffff')};
  border: ${(props: { error: string }) => renderInputBorder(false, props.error)};
  border-radius: 10px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  :focus-within {
    border: ${(props: { error: string }) =>
      renderInputBorder(true, props.error)} !important;
  }

  .react-tel-input {
    input {
      font-family: ${({ theme }) => theme.fonts.sfProDisplay};
      background: ${(props: { error: string }) => (props.error ? '#FFFBFB' : '#ffffff')};
    }
  }
`;

export const ErrorMessage = styled.div`
  margin: -10px 0 0 25px;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

export const Edit = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-image: url(${EditCircle});
  cursor: pointer;
  opacity: ${(props: { active: boolean | undefined }) => (props.active ? 1 : 0.6)};
`;

export const IconElement = styled.div`
  position: absolute;
  right: -50px;
  top: 50%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
`;

export const ButtonSave = styled(PrimaryButton)`
  width: 300px;
  height: 56px;
  font-size: 17px;
`;

export const ButtonCancel = styled(ButtonSave)`
  margin-top: 20px;
`;

export const SuccessWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 10px auto;
  padding: 0 30px;
  background: #04ff8b26;
  border-radius: 5px;
  text-align: center;
`;

export const SuccessMessage = styled.div`
  font-size: 13px;
  color: #47a51a;
  padding: 10px 0;
`;

export const ErrorServerWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 40px auto;
  padding: 0 30px;
  background: #feecee;
  border-radius: 5px;
  text-align: center;
`;

export const ErrorServer = styled.div`
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.red};
  font-size: 13px;
`;

export const DeleteAccountButton = styled(ButtonSave)`
  border: 1px solid ${({ theme }) => theme.colors.whiteRed};
  color: ${({ theme }) => theme.colors.whiteRed};
  background: ${({ theme }) => theme.colors.white};

  &:hover {
    opacity: 0.7;
  }
`;

export const DeleteAccountModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
`;

export const DeleteAccountModalBody = styled.div`
  position: relative;
  width: 545px;
  padding: 65px 67px 40px 65px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  @media ${devices.mobile} {
    width: 90%;
    padding: 25px;
  }
`;

export const IconClose = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;

  @media ${devices.mobile} {
    right: 30px;
    top: 30px;
  }
`;

export const DeleteAccountModalTitle = styled.div`
  margin: 25px 0 7px;
  color: ${({ theme }) => theme.colors.darkBlack};
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
`;

export const DeleteAccountModalText = styled.div`
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;

export const DeleteAccountModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

export const DeleteAccountModalButtonConfirm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  border-radius: 25px;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.colors.whiteRed};
  color: ${({ theme }) => theme.colors.whiteRed};
  background: ${({ theme }) => theme.colors.white};

  &:hover {
    opacity: 0.7;
  }

  @media ${devices.mobile} {
    margin-bottom: 10px;
  }
`;

export const DeleteAccountModalButtonCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 50px;
  margin: 20px auto;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkBlack};
  border-radius: 28px;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.9;
  }
  @media ${devices.mobile} {
    margin: 5px auto 10px;
  }
`;
