import { ChangeEvent, FC, useRef } from 'react';
import { AvatarWrapper, Counter, UpdateAvatar } from './styles';
import CameraActive from 'assets/icons/camera_active.svg';
import AvatarIcon from 'assets/icons/avatar.svg';
import { HTMLAttributes } from 'react';

interface AvatarProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  url?: string;
  onChange?: (file: File) => void;
  size?: number;
  radius?: string;
  counter?: number;
}

export const Avatar: FC<AvatarProps> = ({
  url,
  onChange,
  size,
  radius,
  counter,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = () => inputRef.current?.click();

  const handleChange = ({ target: { files } }: ChangeEvent<HTMLInputElement>) =>
    files && files.length > 0 && onChange && onChange(files[0]);

  return (
    <AvatarWrapper size={size} radius={radius} {...rest}>
      <img src={url || AvatarIcon} alt="avatar" />
      {counter && (
        <Counter>
          <span>{counter}</span>
        </Counter>
      )}
      {onChange && (
        <>
          <UpdateAvatar onClick={handleFileUpload}>
            <img src={CameraActive} alt="camera" />
          </UpdateAvatar>
          <input
            style={{ display: 'none' }}
            type="file"
            accept="image/png, image/jpeg"
            ref={inputRef}
            onChange={handleChange}
          />
        </>
      )}
    </AvatarWrapper>
  );
};
