import { makeAutoObservable, runInAction } from 'mobx';
import { postApi, callApi, saveFile } from 'helpers';
import { CheckoutData, CheckoutStore } from './CheckoutStore';
import { ErrorStore } from './ErrorStore';

type InvoiceRequest = {
  type: string;
  personalIdentityNumber?: string;
  companyNumber?: string;
  companyName?: string;
  companyReference?: string;
  firstName: string;
  surname: string;
  email: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
  transactionLinkHash?: string;
  countryCode: string;
};

export type AllowedCountry = {
  name: string;
  code: string;
  personalIdentityNumberRegex: string;
  personalIdentityNumberMask: string;
  companyNumberRegex: string;
  companyNumberMask: string;
};

export type TransactionInvoiceData = {
  allowedTypes: string[];
  allowedCountries: AllowedCountry[];
  firstName: string;
  surname: string;
  email: string;
  phoneNumber: string;
  fee: number;
  dueDate: string;
};

export type TransactionSubscriptionSetup = {
  billingPeriod: string;
  nextAt: string;
  firstPaymentAmountReduction: number;
};

export type Transaction = {
  id: number;
  status: string;
  amount: number;
  amountExclVat: number;
  currency: string;
  createdAt: Date;
  checkout: CheckoutData;
  subscriptionSetup?: TransactionSubscriptionSetup;
  stripe?: {
    clientSecret: string;
  };
  invoiceData?: TransactionInvoiceData;
  dueDate?: string;
  adminFee?: number;
};

export type SwishCreatePaymentRequest = {
  phoneNumber?: string;
};

export type SwishCreatePaymentResponse = {
  token?: string;
};

export type SwishTransactionStatusResponse = {
  status: string;
};

class Payment {
  isActivePayment = false;

  activePromotion = '';

  transactionInfo: Transaction | undefined = undefined;

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getTransaction = async (id: number) => {
    try {
      const res = await callApi(`v1/transactions/${id}`);
      const data = await res.json();
      // this.setTransaction({ ...data.transactionData, id });
      this.setTransaction(data);
    } catch (e) {
      console.error(e);
    }
  };

  payTransaction = async (
    id: number,
    body: InvoiceRequest,
    callback: (newCheckout: CheckoutData) => void
  ) => {
    try {
      const res = await postApi(`v1/transactions/${id}/pay`, body);
      const data = await res.json();
      if (data.checkout) {
        CheckoutStore.setPaymentInfo(undefined);
        callback(data.checkout);
      } else {
        ErrorStore.setError({ ...data, place: 'payTransaction' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  createSwishTransactionPayment = async (
    id: number,
    body: SwishCreatePaymentRequest,
    callback: (hasError: boolean, swishResponse?: SwishCreatePaymentResponse) => void
  ) => {
    try {
      const res = await postApi(`v1/transactions/${id}/swish-create-payment`, body);
      const data = await res.json();
      if (res.status === 200) {
        callback(false, data);
      } else {
        callback(true);
        ErrorStore.setError({ ...data, place: 'createSwishTransaction' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  getTransactionSwishStatus = async (
    id: number,
    transactionLinkHash: string | null,
    callback: (response: SwishTransactionStatusResponse) => void
  ) => {
    try {
      const res = await callApi(
        `v1/transactions/${id}/swish-status${
          transactionLinkHash ? `?transactionLinkHash=${transactionLinkHash}` : ''
        }`
      );
      const data = await res.json();
      callback(data);
    } catch (e) {
      console.error(e);
    }
  };

  downloadTransactionPdf = async (transactionId: number, fileName: string) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const res = await callApi(`v1/transactions/${transactionId}/pdf`);
      const data = await res.arrayBuffer();
      saveFile(data, `${fileName}.pdf`, 'application/pdf');
      runInAction(() => {
        this.loading = false;
      });
    } catch (e) {
      console.error(e);
    }
  };

  setTransaction = (data: Transaction) => {
    this.transactionInfo = data;
  };

  setActivePayment = () => {
    this.isActivePayment = true;
  };

  removeActivePayment = () => {
    this.isActivePayment = false;
  };

  setActivePromotion = (code: string) => {
    this.activePromotion = code;
  };

  removeActivePromotion = () => {
    this.activePromotion = '';
  };
}

export const PaymentStore = new Payment();
