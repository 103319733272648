import { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/Loader/Loader';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';

import { CheckoutStore, CheckoutData } from 'store/CheckoutStore';
import { LoginStore } from 'store/LoginStore';
import { HeaderStore } from 'store/HeaderStore';

import CheckIcon from 'assets/icons/checkmark.svg';

import { PrimaryButton } from 'styled/PrimaryButton';
import { Flex } from 'styled/Flex';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const ContainerBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 27px 41px 11px 51px;

  @media ${devices.mobile} {
    padding: 27px 21px 11px 11px;
  }
`;

const StatusTextContainer = styled.div`
  text-align: center;
`;

const StatusText = styled.div`
  display: inline;
  font-weight: normal;
  font-size: 17px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
`;

const StatusTextBold = styled.div`
  display: inline;
  font-weight: bold;
  font-size: 17px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  text-align: center;
`;

const StatusTextReturnToFinishContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const StatusTextReturnToFinish = styled.div`
  display: inline;
  font-weight: normal;
  font-size: 17px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  color: ${({ theme }) => theme.colors.red};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${devices.mobile} {
    flex-direction: column;
  },
`;

const ButtonSendDocument = styled(PrimaryButton)`
  width: 250px;
  height: 56px;
  margin-top: 20px;
  font-size: 17px;
`;

const ButtonOpenDocument = styled(PrimaryButton)`
  width: 250px;
  height: 56px;
  margin-top: 20px;
  font-size: 17px;
  margin-right: 20px;

  @media ${devices.mobile} {
    margin-right: 0px;
  },
`;

const ButtonDocumentSigned = styled(PrimaryButton)`
  width: 250px;
  height: 56px;
  margin-top: 20px;
  font-size: 17px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.green};
`;

const ErrorText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.red};
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  text-align: center;
`;

export const SignDocumentCard = observer((props: { checkoutId: number }) => {
  const history = useHistory();
  const { checkoutId } = props;
  const {
    checkout,
    sendSigningDocument,
    verifySigningDocument,
    checkoutFieldErrors,
    setFieldError,
  } = CheckoutStore;
  const { user } = LoginStore;
  const { setBackButton, setTitle } = HeaderStore;
  const userData = toJS(user);
  const [isLoading, setIsLoading] = useState(false);
  const [isSigningCheckLoading, setIsSigningCheckLoading] = useState(false);
  const checkoutData = toJS(checkout);
  const transactionLinkHash = new URLSearchParams(useLocation().search).get(
    'transactionLinkHash'
  ) as string;
  const { t } = useTranslation(Namespaces.UI);
  const errors = toJS(checkoutFieldErrors);
  const isApiDelivery = checkoutData?.documentSigningDeliveryMethod === 'api';

  const onSendSigningDocumentClicked = (
    callback: (updatedCheckout: CheckoutData) => void
  ) => {
    if (isLoading) return;

    if (!userData.id) {
      goToLogin();
      return;
    }

    setIsLoading(true);
    sendSigningDocument(
      checkoutId,
      (updatedCheckout) => {
        setIsLoading(false);
        callback(updatedCheckout);
      },
      transactionLinkHash
    );
  };

  const goToLogin = () => {
    setBackButton(true);
    setTitle(t('loginTitle'));
    history.push('/auth');
  };

  return (
    <ContainerBlock data-testid="sign-document-card">
      {checkoutData?.documentSigningStatus === 'notSent' && (
        <>
          <StatusTextContainer>
            <StatusText>{t('checkoutSignDocumentNotSentPart1Text')}</StatusText>
            <StatusTextBold>{t('checkoutSignDocumentNotSentPart2Text')}</StatusTextBold>
            {isApiDelivery ? (
              <>
                <StatusText>
                  {t('checkoutSignDocumentNotSentPart3TextApiDelivery')}
                </StatusText>
                <StatusTextBold>
                  {t('checkoutSignDocumentNotSentPart4TextApiDelivery')}
                </StatusTextBold>
              </>
            ) : (
              <>
                <StatusText>{t('checkoutSignDocumentNotSentPart3Text')}</StatusText>
                <StatusTextBold>
                  {t('checkoutSignDocumentNotSentPart4Text')}
                </StatusTextBold>
              </>
            )}
            <StatusTextReturnToFinishContainer>
              <StatusTextReturnToFinish>
                {t('checkoutSignDocumentReturnToFinishText')}
              </StatusTextReturnToFinish>
            </StatusTextReturnToFinishContainer>
          </StatusTextContainer>
          <ButtonSendDocument
            size="large"
            onClick={() => {
              if (isApiDelivery) {
                onSendSigningDocumentClicked((updatedCheckout) => {
                  window.open(updatedCheckout.documentSigningUrl, '_blank');
                });
              } else {
                onSendSigningDocumentClicked(() => {});
              }
            }}
          >
            {isLoading ? (
              <Loader isWhite />
            ) : !isApiDelivery ? (
              t('checkoutSignDocumentSendButton')
            ) : (
              t('checkoutSignDocumentOpenButton')
            )}
          </ButtonSendDocument>
        </>
      )}
      {checkoutData?.documentSigningStatus === 'pending' && (
        <>
          <StatusTextBold>{t('checkoutSignDocumentPendingPart1Text')}</StatusTextBold>
          {!errors.documentSigningStatus && (
            <StatusText>{t('checkoutSignDocumentPendingPart2Text')}</StatusText>
          )}
          {errors.documentSigningStatus && (
            <ErrorText>{errors.documentSigningStatus}</ErrorText>
          )}

          <ButtonContainer>
            {isApiDelivery && (
              <ButtonOpenDocument
                onClick={() => {
                  window.open(checkoutData.documentSigningUrl, '_blank');
                }}
              >
                {t('checkoutSignDocumentOpenButton')}
              </ButtonOpenDocument>
            )}
            <ButtonDocumentSigned
              onClick={() => {
                if (isSigningCheckLoading) return;

                if (!userData.id) {
                  goToLogin();
                  return;
                }

                setIsSigningCheckLoading(true);
                verifySigningDocument(checkoutId, (success) => {
                  setIsSigningCheckLoading(false);
                  if (success) {
                    setFieldError('documentSigningStatus', '');
                  } else {
                    setFieldError(
                      'documentSigningStatus',
                      t('checkoutSignDocumentNotSignedError')
                    );
                  }
                });
              }}
            >
              {isSigningCheckLoading ? (
                <Loader isWhite />
              ) : (
                t('checkoutSignDocumentPendingButton')
              )}
            </ButtonDocumentSigned>
          </ButtonContainer>
        </>
      )}
      {checkoutData?.documentSigningStatus === 'signed' && (
        <Flex alignItems="center">
          <CustomIcon width="39px" height="39px" icon={CheckIcon} margin="0 16px 0 0" />
          <StatusText>{t('checkoutSignDocumentSignedText')}</StatusText>
        </Flex>
      )}
    </ContainerBlock>
  );
});
