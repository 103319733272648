import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useParams } from 'react-router';

import { Loader } from 'components/Loader/Loader';
import { VoucherDetails } from 'components/VoucherDetails/VoucherDetails';
import { MembershipDetails } from 'components/MembershipDetails/MembershipDetails';
import {
  AppointmentDetails,
  Service,
} from 'components/AppointmentDetails/AppointmentDetails';

import { Membership, ServiceStore, Voucher, Course } from 'store/ServiceStore';
import { HeaderStore } from 'store/HeaderStore';
import {
  SERVICE_TYPE_APPOINTMENT,
  SERVICE_TYPE_MEMBERSHIP,
  SERVICE_TYPE_VOUCHER,
  SERVICE_TYPE_COURSE,
} from 'constants/serviceConstants';
import { CourseDetails } from 'components/CourseDetails/CourseDetails';

export const ServiceDetails = observer(() => {
  const { fetchService, service, clearService } = ServiceStore;
  const { setProfileId } = HeaderStore;
  const { serviceId, profileId } = useParams<{ serviceId: string; profileId: string }>();

  localStorage.profileId = profileId;
  useEffect(() => {
    fetchService(serviceId);
    setProfileId(profileId);
    return () => {
      clearService();
    };
  }, [fetchService, serviceId, clearService, setProfileId, profileId]);
  const currentService = toJS(service);
  if (!currentService) {
    return <Loader />;
  }

  const { type } = currentService;
  switch (type) {
    case SERVICE_TYPE_APPOINTMENT:
      return <AppointmentDetails service={currentService as Service} />;
    case SERVICE_TYPE_VOUCHER:
      return <VoucherDetails voucher={currentService as Voucher} />;
    case SERVICE_TYPE_MEMBERSHIP:
      return <MembershipDetails memberships={currentService as Membership} />;
    case SERVICE_TYPE_COURSE:
      return <CourseDetails course={currentService as Course} />;
    default:
      return null;
  }
});
