import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getPrice } from 'helpers';
import { getYMD, getTime } from 'helpers/DateTimeUtils';
import { Namespaces } from 'languages';

import { devices } from 'constants/mediaConstants';
import {
  SERVICE_TYPE_COURSE,
  SERVICE_TYPE_MEMBERSHIP,
  SERVICE_TYPE_PRODUCT,
  SERVICE_TYPE_VOUCHER,
} from 'constants/serviceConstants';

import { CheckoutData, CheckoutItem } from 'store/CheckoutStore';
import { Transaction } from 'store/PaymentStore';

import { Flex } from 'styled/Flex';

const SpecificationBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 20px;
  padding: 43px 60px 50px 65px;
  color: ${({ theme }) => theme.colors.darkBlack};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};

  @media${devices.mobile} {
    padding: 15px 20px 20px 20px;
  }
`;

const InvoiceTitle = styled.div`
  margin-bottom: 34px;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
`;

const Logo = styled.div`
  width: 103px;
  height: 103px;
  background-image: url(${(props: { src: string }) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;

  @media${devices.mobile} {
    height: 70px;
    width: 70px;
    margin: 30px auto 10px;
  }
`;

const InvoiceDataBlock = styled.div`
  margin: -40px 0 0 30px;
`;

const ThinText = styled.div`
  color: #a6a6a6;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;
const BoldText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;

const SubTotalTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
`;
const TotalBlock = styled.div`
  margin-top: 30px;
  padding: 28px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 20px;
`;

const DiscountTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
`;
const DiscountValue = styled.div`
  margin-right: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;

const DiscountNegativeValue = styled.div`
  margin-right: 5px;
  color: #fc8575;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;

const Line = styled.div`
  margin: 6px 0;
  border-bottom: 3px solid;
`;

const TotalDue = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
`;
const TotalValue = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
`;
const TaxBlock = styled.div`
  color: #6f6f6f;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  float: right;
`;

const SubscriptionInfoBlock = styled.div`
  margin-top: 30px;
  padding: 28px 60px 28px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 20px;
  font-family:  ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  @media ${devices.mobile} {
    margin-top: 15px;
    padding: 18px 20px 12px;
  },
`;

const SubscriptionInfoTitle = styled.div`
  margin-bottom: 12px;
`;

const SubscriptionInfoDescription = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`;

const SubscriptionInfoDescriptionBold = styled.span`
  font-family: ${({ theme }) => theme.fonts.sfProDisplay};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
`;

const ItemBlock = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #a6a6a6;
`;

const ItemTitle = styled.div`
  margin: 30px 0 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;

const ItemTimeValue = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  margin-left: 11px;
`;

const ItemDateTitle = styled.div`
  width: 40px;
  color: #a6a6a6;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-right: 11px;
`;
const ItemValidTitle = styled.div`
  color: #a6a6a6;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-right: 11px;
`;
const ItemDateValue = styled.div`
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
`;
const ItemCurrencyBlock = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 5px;
`;

export const PaymentSpecification = (props: {
  transactionData: Transaction;
  checkoutData: CheckoutData;
}) => {
  const { transactionData, checkoutData } = props;
  const { t } = useTranslation(Namespaces.UI);

  if (!checkoutData.items) {
    return null;
  }

  const renderItemData = () => {
    return checkoutData?.items.map((item: CheckoutItem, index: number) => {
      const {
        currency,
        price,
        startTime,
        endTime,
        quantity,
        service: { name, type, isBookPerOccasion },
        validTo,
      } = item;
      const renderCardBody = () => {
        switch (type) {
          case SERVICE_TYPE_VOUCHER:
            return (
              <>
                <ItemTitle>{name}</ItemTitle>
                <Flex justifyContent="space-between" alignItems="flex-end">
                  <>
                    <Flex alignItems="center">
                      <ItemValidTitle>{t('validTo').toUpperCase()}</ItemValidTitle>
                      <ItemDateValue>
                        {validTo ? getYMD(validTo) : t('untilCancellation')}
                      </ItemDateValue>
                    </Flex>
                  </>
                  <ItemCurrencyBlock>{getPrice(price, currency)}</ItemCurrencyBlock>
                </Flex>
              </>
            );
          case SERVICE_TYPE_MEMBERSHIP:
            return (
              <>
                <ItemTitle>{name}</ItemTitle>
                <Flex justifyContent="space-between" alignItems="flex-end">
                  <>
                    <Flex alignItems="center">
                      <ItemValidTitle>{t('validTo').toUpperCase()}</ItemValidTitle>
                      <ItemDateValue>
                        {validTo ? getYMD(validTo) : t('untilCancellation')}
                      </ItemDateValue>
                    </Flex>
                  </>
                  <ItemCurrencyBlock>{getPrice(price, currency)}</ItemCurrencyBlock>
                </Flex>
              </>
            );
          case SERVICE_TYPE_PRODUCT:
            return (
              <>
                <Flex justifyContent="space-between" alignItems="flex-end">
                  <ItemTitle>{`${name} (x${quantity})`}</ItemTitle>
                  <ItemCurrencyBlock>{getPrice(price, currency)}</ItemCurrencyBlock>
                </Flex>
              </>
            );
          case SERVICE_TYPE_COURSE: {
            if (isBookPerOccasion) {
              return (
                <>
                  <ItemTitle>{name}</ItemTitle>
                  <Flex justifyContent="space-between" alignItems="flex-end">
                    <div>
                      <Flex alignItems="center">
                        <ItemDateTitle>{t('start').toUpperCase()}</ItemDateTitle>
                        <ItemDateValue>{getYMD(startTime, true)}</ItemDateValue>
                        <ItemTimeValue>{getTime(startTime, true)}</ItemTimeValue>
                      </Flex>
                      <Flex alignItems="center">
                        <ItemDateTitle>{t('end').toUpperCase()}</ItemDateTitle>
                        <ItemDateValue>{getYMD(endTime, true)}</ItemDateValue>
                        <ItemTimeValue>{getTime(endTime, true)}</ItemTimeValue>
                      </Flex>
                    </div>
                    <ItemCurrencyBlock>{getPrice(price, currency)}</ItemCurrencyBlock>
                  </Flex>
                </>
              );
            }
            return (
              <>
                <Flex justifyContent="space-between" alignItems="flex-end">
                  <ItemTitle>{name}</ItemTitle>
                  <ItemCurrencyBlock>{getPrice(price, currency)}</ItemCurrencyBlock>
                </Flex>
              </>
            );
          }

          default:
            return (
              <>
                <ItemTitle>{name}</ItemTitle>
                <Flex justifyContent="space-between" alignItems="flex-end">
                  <div>
                    <Flex alignItems="center">
                      <ItemDateTitle>{t('start').toUpperCase()}</ItemDateTitle>
                      <ItemDateValue>{getYMD(startTime, true)}</ItemDateValue>
                      <ItemTimeValue>{getTime(startTime, true)}</ItemTimeValue>
                    </Flex>
                    <Flex alignItems="center">
                      <ItemDateTitle>{t('end').toUpperCase()}</ItemDateTitle>
                      {endTime ? (
                        <>
                          <ItemDateValue>{getYMD(endTime, true)}</ItemDateValue>
                          <ItemTimeValue>{getTime(endTime, true)}</ItemTimeValue>
                        </>
                      ) : (
                        <ItemDateValue>{t('untilCancellation')}</ItemDateValue>
                      )}
                    </Flex>
                  </div>
                  <ItemCurrencyBlock>{getPrice(price, currency)}</ItemCurrencyBlock>
                </Flex>
              </>
            );
        }
      };
      return <ItemBlock key={index}>{renderCardBody()}</ItemBlock>;
    });
  };

  const {
    amount: transactionAmount,
    amountExclVat: transactionAmountExclVat,
    currency,
    subscriptionSetup,
  } = transactionData;
  const {
    price: checkoutPrice,
    promotionCodeDiscount,
    promotionCode,
    profile: { logoUrl: logo },
  } = checkoutData;

  const invoiceFee = transactionData.invoiceData ? transactionData.invoiceData.fee : 0;
  const adminFee = transactionData.adminFee ? transactionData.adminFee : 0;
  const firstPaymentAmountReduction = subscriptionSetup
    ? subscriptionSetup.firstPaymentAmountReduction
    : 0;

  return (
    <SpecificationBlock>
      <InvoiceTitle>{t('orderDetailsTitle')}</InvoiceTitle>
      <Flex alignItems="center">
        <Logo src={logo} />

        {transactionData.invoiceData ? (
          <InvoiceDataBlock>
            <ThinText>{t('dueDate').toUpperCase()}</ThinText>
            <BoldText>{transactionData.invoiceData.dueDate}</BoldText>
          </InvoiceDataBlock>
        ) : null}
      </Flex>
      {renderItemData()}
      <Flex justifyContent="space-between" margin="15px 0 0 0">
        <SubTotalTitle>{t('subtotal')}</SubTotalTitle>
        <ItemCurrencyBlock>{getPrice(checkoutPrice, currency)}</ItemCurrencyBlock>
      </Flex>
      <TotalBlock>
        {promotionCodeDiscount && (
          <Flex alignItems="center" justifyContent="space-between">
            <DiscountTitle>
              {t('promotionCodeOrderText', {
                name: promotionCode?.code,
              })}
            </DiscountTitle>
            <DiscountNegativeValue>
              {`-${getPrice(promotionCodeDiscount, currency)}`}
            </DiscountNegativeValue>
          </Flex>
        )}

        {firstPaymentAmountReduction > 0 && (
          <Flex alignItems="center" justifyContent="space-between">
            <DiscountTitle>{t('subscriptionReduction')}</DiscountTitle>
            <DiscountNegativeValue>
              {getPrice(-firstPaymentAmountReduction, currency)}
            </DiscountNegativeValue>
          </Flex>
        )}

        {invoiceFee > 0 && (
          <Flex alignItems="center" justifyContent="space-between">
            <DiscountTitle>{t('invoiceFee')}</DiscountTitle>
            <DiscountValue>{getPrice(invoiceFee, currency)}</DiscountValue>
          </Flex>
        )}

        {adminFee > 0 && (
          <Flex alignItems="center" justifyContent="space-between">
            <DiscountTitle>{t('adminFee')}</DiscountTitle>
            <DiscountValue>{getPrice(adminFee, currency)}</DiscountValue>
          </Flex>
        )}

        {(invoiceFee > 0 ||
          adminFee > 0 ||
          promotionCodeDiscount ||
          firstPaymentAmountReduction > 0) && <Line />}

        <Flex alignItems="center" justifyContent="space-between">
          <TotalDue>{t('total')}</TotalDue>
          <TotalValue>{getPrice(transactionAmount, currency, false)}</TotalValue>
        </Flex>
        <TaxBlock>
          (
          {t('tax', {
            value: getPrice(
              Math.round((transactionAmount - transactionAmountExclVat) * 100) / 100,
              currency,
              false
            ),
          })}
          )
        </TaxBlock>
      </TotalBlock>

      {subscriptionSetup && (
        <SubscriptionInfoBlock>
          <SubscriptionInfoTitle>{t('subscriptionInfoTitle')}</SubscriptionInfoTitle>
          <SubscriptionInfoDescription>
            {transactionData.invoiceData
              ? t('subscriptionInfoInvoiceDescription1')
              : t('subscriptionInfoCardDescription1')}
            <SubscriptionInfoDescriptionBold>
              {transactionData.invoiceData
                ? t('subscriptionInfoInvoiceDescription2', {
                    time: getYMD(subscriptionSetup.nextAt),
                  })
                : t('subscriptionInfoCardDescription2', {
                    time: getYMD(subscriptionSetup.nextAt),
                  })}
            </SubscriptionInfoDescriptionBold>
            {firstPaymentAmountReduction > 0 &&
              (transactionData.invoiceData
                ? t('subscriptionInfoInvoiceDescription3FirstPayment')
                : t('subscriptionInfoCardDescription3FirstPayment'))}
          </SubscriptionInfoDescription>
        </SubscriptionInfoBlock>
      )}
    </SpecificationBlock>
  );
};
