import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'languages';
import { devices } from 'constants/mediaConstants';
import { getPrice } from 'helpers';
import { Loader } from 'components/Loader/Loader';
import { CheckoutStore } from 'store/CheckoutStore';
import { PrimaryButton } from 'styled/PrimaryButton';
import { Flex } from 'styled/Flex';

import MinusButton from 'assets/icons/Minus-button.svg';
import PlusButton from 'assets/icons/Plus-button.svg';
import CheckCircle from 'assets/icons/Check-circle.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';

const CardBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;
const QuantityBorderBlock = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  padding: 12px 12px 12px 12px;
  border: 1px solid ${({ theme }) => theme.colors.middleGray};
  border-radius: 10px;
  @media ${devices.mobile} {
    padding: 8px;
  },
`;

const QuantityValue = styled.div`
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.sfProText};
  font-weight: 700;
  margin: 0 10px;
  @media${devices.mobile} {
    font-size: 14px;
  }
`;

const SumTitle = styled.div`
  min-width:110px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-family:  ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  @media ${devices.mobile} {
    font-size: 11px;
    min-width: 90px;
  },
`;

const CardDateTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family:  ${({ theme }) => theme.fonts.sfProText};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  @media ${devices.mobile} {
    font-size: 11px;
  },
`;

const CardButton = styled(PrimaryButton)`
  max-height: 37px;
  width: 80px;
  padding: 17px 50px;
  font-size: 14px;
  margin-top: auto;
  white-space: nowrap;
  @media ${devices.mobile} {
    padding: 12px 30px;
    font-size: 12px;
  },
`;

const Price = styled.div`
  font-style: normal;
  white-space: nowrap;
  font-weight: 700;
  font-size: 22px;
  @media${devices.mobile} {
    font-size: 14px;
  }
`;

type QuantityBlockProps = {
  id: number;
  price: number;
  currency: string;
  checkoutId: string;
  disabled: boolean;
  minQuantity: number;
  maxQuantity: number;
  countStart: number;
  onAddAddonClicked: () => void;
  transactionLinkHash: string | null;
};

export const QuantityBlock = (props: QuantityBlockProps) => {
  const {
    checkoutId,
    currency,
    price,
    disabled,
    minQuantity,
    maxQuantity,
    id,
    countStart,
    onAddAddonClicked,
    transactionLinkHash,
  } = props;
  const [count, setCount] = useState(countStart);
  const { addAddonToCheckout } = CheckoutStore;
  const { t } = useTranslation(Namespaces.UI);
  const [loading, setLoading] = useState(false);

  const getRequestData = () => {
    return [
      {
        id,
        quantity: count,
      },
    ];
  };

  return (
    <CardBlock>
      <QuantityBorderBlock>
        <Flex flexDirection="column">
          <CardDateTitle>{t('quantity').toUpperCase()}</CardDateTitle>
          <Flex>
            <CustomIcon
              height="20px"
              width="20px"
              cursor
              icon={MinusButton}
              disabled={!count || disabled || count <= minQuantity}
              onClick={() => {
                if (!disabled) {
                  if (count > minQuantity) {
                    setCount(count - 1);
                  }
                }
              }}
            />
            <QuantityValue>{count}</QuantityValue>
            <CustomIcon
              height="20px"
              width="20px"
              cursor
              icon={PlusButton}
              disabled={disabled || count >= maxQuantity}
              onClick={() => {
                if (!disabled) {
                  if (count < maxQuantity) {
                    setCount(count + 1);
                  }
                }
              }}
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <SumTitle>{t('sum').toUpperCase()}</SumTitle>
          <Flex>
            <Price>{getPrice(count * price, currency)}</Price>
          </Flex>
        </Flex>
        {disabled ? (
          <CustomIcon
            width="60px"
            height="60px"
            margin="0 15px 0 0"
            icon={CheckCircle}
            device={devices.mobile}
            deviceHeight="40px"
            deviceWidth="40px"
          />
        ) : (
          <CardButton
            disabled={!count}
            onClick={() => {
              if (count) {
                if (!loading) {
                  setLoading(true);
                  addAddonToCheckout(
                    checkoutId,
                    getRequestData(),
                    transactionLinkHash,
                    setLoading
                  );
                  onAddAddonClicked();
                }
              }
            }}
          >
            {loading ? <Loader isWhite small /> : t('add')}
          </CardButton>
        )}
      </QuantityBorderBlock>
    </CardBlock>
  );
};
